import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";


import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";



const styles = {

};


class ApplicationForm extends FormComponent {
 

  render() {
    if (this.state.object === undefined) {
      return(<div></div>);
    }


    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <TextField
          id="name"
          label="Application server name"
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          helperText="The name may only contain words, numbers and dashes."
          fullWidth
          required
          disabled={this.props.disabled}
        />
        <TextField
          id="description"
          label="Application server description"
          margin="normal"
          value={this.state.object.description || ""}
          onChange={this.onChange}
          fullWidth
          disabled={this.props.disabled}
        />

        <FormControl fullWidth margin="normal">
          <FormGroup>
            <FormControlLabel
              label="Is active"
              control={
                <Checkbox
                  id="isActive"
                  checked={!!this.state.object.isActive}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormGroup>
        </FormControl>
     
      </Form>
    );
  }
}

export default withStyles(styles)(ApplicationForm);

import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DataTable from "../../components/DataTable";
import UserStore from "../../stores/UserStore";
import { withRouter } from "react-router-dom";
import { diff } from 'json-diff';
import JSONTree from "../../components/JSONTree";
import InputAdornment from "@material-ui/core/InputAdornment";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { ExpandRows, TableRowToggleEvent ,handleSearchChange,clearSearch} from "../../classes/Helper";
import moment from "moment";

const highLightIfDiffs = (raw, stateDiff) => {

  if (!stateDiff) {

    return false;
  }

  if (Number.isInteger(raw[0])) {
    raw = raw.shift();
  }

  let length = raw.length;

  if (length === 1 && ((!stateDiff['__new'] && !stateDiff['__old']) || (stateDiff['__new'] !== undefined && stateDiff['__new'] === null) || (stateDiff['__old'] !== undefined && stateDiff['__old'] === null))) {

      return true;
  } else if (length > 1) {
    if (stateDiff[raw[0]] !== undefined || stateDiff[raw[0] + '__added'] !== undefined) {

      return true;
    } else {
      let curObj = stateDiff;
      for (let i = length - 2; i >= 0; i--) {
        let val = curObj[raw[i]] || curObj[raw[i] + '__added']
        if (val !== undefined) {
          if (raw[0] === raw[i]) {

              return true;
          }
          curObj = val;
        }
      }
    }
  }

  return false
}

const styles = {
  flex: {display: 'flex'},
  flexCenter: {alignItems: 'center', justifyContent: 'center'},
  dNone: {display: 'none'},
  w100: {width: '100%'},
  mr30: {marginRight: '30px'},
  pointer: {cursor: 'pointer'},

  iconCell: {width: '15px'},
  icon: {fontSize: 15, verticalAlign: 'middle'},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}

class UserLogs extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.handleSearchChange = handleSearchChange.bind(this);
    this.clearSearch = clearSearch.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.TableRef = React.createRef()
    this.state = {
      search: null
    }
  }

  getPage(limit, offset, callbackFunc) {
    if (offset){
      this.offset = offset
    }
   
    let orgId = this.props.match.params.organizationID || 0;
    if(this.timeOut) {
      clearTimeout(this.timeOut)
    }
    this.timeOut =  setTimeout(()=>{
      ExpandRows(false, () => {
        UserStore.logs(orgId, this.state.search, limit, this.offset||offset, this.state.orderBy, this.state.order, callbackFunc);
      })
    }, 400)
  
  }

  getRow(obj) {
 

    const id = "J" + Math.ceil(Math.random() * 10000000000000000);
    let stateCur = (obj.stateCur) ? JSON.parse(obj.stateCur) : null;
    let statePrev = (obj.statePrev) ? JSON.parse(obj.statePrev) : null;
    let stateDiff = diff(statePrev, stateCur)

    let classes = this.props.classes;

    return (
      <>
        <TableRow
          hover
          className={classes.pointer}
          onClick={(ev) => {
            TableRowToggleEvent(ev)
          }}
          id={id}
        >
          <TableCell   className={classNames(classes.iconCell)}> <Icon
            className={classNames("fa fa-plus-circle", classes.icon)}/> </TableCell>
          <TableCell   >{obj.userName}</TableCell>
          <TableCell   >{obj.userId}</TableCell>
          <TableCell   >{obj.event}</TableCell>
          <TableCell  >{moment(obj.createdAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
        </TableRow>
        <TableRow className={id} id={'extended'} style={{display: 'none'}}>
          <TableCell colSpan={5}>

            <div className={classNames(classes.flex, classes.w100)}>
              <div className={classes.mr30}>
                <JSONTree shouldExpandNode={() => {
                  return true
                }} data={{"Previous State": statePrev}}/>
              </div>
              {!stateDiff && <div className={classNames(classes.mr30, classes.flex, classes.flexCenter)}>
                <DragHandleIcon/>
              </div>}
              <div className={classes.mr30}>
                <JSONTree
                  labelRenderer={(raw) => {
                    let color = '';
                    if (highLightIfDiffs(raw, stateDiff)) {
                      color = 'yellow'
                    }
                    return (<span style={{background: color}}>{raw[0]}</span>)
                  }}
                  shouldExpandNode={() => {
                    return true
                  }} data={{"Current State": stateCur}}/>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </>
    );
  }



  changeSort(event) {
    let state = {
      orderBy: event.currentTarget.dataset.key,
      order: this.state.order
    }

    if (state.orderBy !== this.state.orderBy) {
      state.order = null;
    }

    if (state.order === null) {
      state.order = 'asc'
    } else if (state.order === 'asc') {
      state.order = 'desc'
    } else if (state.order === 'desc') {
      state.order = null
      state.orderBy = null
    }

    this.setState(state);
  }

  makeSortLabel(keyName, viewName) {
    return <TableSortLabel
      data-key={keyName}
      active={this.state.orderBy === keyName}
      direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
      onClick={this.changeSort}
    >
      {viewName}
      {this.state.orderBy === keyName ? (
        <span className={this.props.classes.visuallyHidden}>
                  {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
      ) : null}
    </TableSortLabel>
  }

  makeCellSortProps(keyName) {

    return {
      key: keyName,
      align: 'left',
      padding: 'normal',
      sortDirection: this.state.orderBy === keyName ? this.state.order : false
    }
  }

  render() {

    return (
      <Grid container spacing={4}>
        <Grid className={'paddingBottomZero'} item xs={12}>
          <TextField
            label={"Search"}
            id="Search"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleSearchChange}
            value={this.state.search || ""}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.clearSearch}
                    style={{padding: "0"}} color="default" component="span">
                    <Icon className="fa fa-times"/>
                  </IconButton>
                </InputAdornment>,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <DataTable
          orderBy={this.state.orderBy}
          ref={this.TableRef}
            header={
              <TableRow  key={"IDhead-"+1}>
                <TableCell key="empty"><></>
                </TableCell>
                <TableCell {...this.makeCellSortProps('userName')}>{this.makeSortLabel('userName', 'User Name')}</TableCell>
                <TableCell {...this.makeCellSortProps('userId')}>{this.makeSortLabel('userId', 'User Id')}</TableCell>
                <TableCell {...this.makeCellSortProps('event')}>{this.makeSortLabel('event', 'Event')}</TableCell>
                <TableCell {...this.makeCellSortProps('createdAt')}>{this.makeSortLabel('createdAt', 'Created at (Local)')}</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(UserLogs));

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";

import OrganizationStore from "../../stores/OrganizationStore";
import OrganizationUserForm from "./OrganizationUserForm";
import SessionStore from "../../stores/SessionStore";
import UserForm from "../users/UserForm";
import moment from 'moment'


class UpdateOrganizationUser extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(organizationUser) {
    organizationUser.id = organizationUser.userID;
    if(organizationUser.phone){organizationUser.phone = organizationUser.phone.toString()}
    OrganizationStore.updateUser(organizationUser, resp => {
      this.props.history.push(`/organizations/${organizationUser.organizationID}/users`);
    });
  }

  render() {
    let dates = {}

    if (this.props.organizationUser.createdAt){
      dates.createdAt = moment(this.props.organizationUser.createdAt).format('DD-MM-YYYY HH:mm:ss')
    }
    if(this.props.organizationUser.organizationUser.loginAt && this.props.organizationUser.organizationUser.loginAt!=="0001-01-01T00:00:00Z"){
      dates.loginAt = moment(this.props.organizationUser.organizationUser.loginAt).format('DD-MM-YYYY HH:mm:ss')
    }else{
      dates.loginAt = 'Never'
    }


    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              {SessionStore.isAdmin() && <OrganizationUserForm
                submitLabel="Update user"
                object={this.props.organizationUser.organizationUser}
                onSubmit={this.onSubmit}
                update={true}
              />}
              {!SessionStore.isAdmin()&&SessionStore.isOrganizationAdmin( SessionStore.getOrganizationID() ) &&
              <UserForm
                  dates={dates}
                  submitLabel="Update user"
                  object={this.props.organizationUser.organizationUser}
                  onSubmit={this.onSubmit}
              />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(UpdateOrganizationUser);

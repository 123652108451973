import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";

import DeviceProfileForm from "./DeviceProfileForm";
import DeviceProfileStore from "../../stores/DeviceProfileStore";
import { errorHandler } from "../../stores/helpers";


const styles = {
  card: {
    overflow: "visible",
  },
};


class CreateDeviceProfile extends Component {
  constructor() {
    super();
    this.state = {
      errorNetworkServerID: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.clearErrorNetworkServerID = this.clearErrorNetworkServerID.bind(this);
  }



  clearErrorNetworkServerID() {
    this.setState({
      errorNetworkServerID: false,
    });
  }

  onSubmit(deviceProfile) {
    let dp = deviceProfile;
    dp.organizationID = this.props.match.params.organizationID;
    dp.adrAlgorithmID = !dp.adrAlgorithmID || !dp.adrAlgorithmID.length ? "default" : dp.adrAlgorithmID;
    if (dp.supportsJoin === true) {
      delete dp.rxDelay1;
      delete dp.rxDROffset1;
      delete dp.rxDataRate2;
      delete dp.rxFreq2;
      delete dp.factoryPresetFreqs;
      dp.joinAcceptDelay2 = (dp.joinAcceptDelay1>0)?dp.joinAcceptDelay1 + 1 : 0
    } else {
      delete dp.joinAcceptDelay1;
      delete dp.joinAcceptDelay2;
    }

    if (!dp.networkServerID || (dp.networkServerID && !dp.networkServerID.length)) {
      this.setState({
        errorNetworkServerID: true,
      });
      return;
    } else {
      this.setState({
        errorNetworkServerID: false,
      });
    }
    DeviceProfileStore.list({limit: 999999, offset: 0}, (resp)=>{

let res = resp.result.filter((el)=>{
  return (dp.name===el.name)
})

if(res.length>0){
  errorHandler({message:"The Device-Profile with this name already exist"})
  return
}

      DeviceProfileStore.create(dp, resp => {
        this.props.history.push(`/device-profiles`);
      });

    }, this.state.orderBy, this.state.order);
   
  }

  render() {


    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title="Device-profiles" to={`/device-profiles`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Create" />
        </TitleBar>

        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <DeviceProfileForm
                submitLabel="Create device-profile"
                onSubmit={this.onSubmit}
                match={this.props.match}
                errorNetworkServerID={this.state.errorNetworkServerID}
                clearErrorNetworkServerID={this.clearErrorNetworkServerID}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(CreateDeviceProfile));

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';

import Delete from "mdi-material-ui/Delete";
import DataTable from "../../components/DataTable";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import Admin from "../../components/Admin";
import DeviceStore from "../../stores/DeviceStore";
import ServiceProfileStore from "../../stores/ServiceProfileStore";
import SessionStore from "../../stores/SessionStore";
import UpdateServiceProfile from "./UpdateServiceProfile";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TableCell, TableRow } from "@material-ui/core";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


class ServiceProfileLayout extends Component {
  constructor() {
    super();
    this.state = {
      admin: false,
      dialogOpen: false,
      dialogConfirmOpen: false,
    };
    this.deleteServiceProfile = this.deleteServiceProfile.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
    this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this);
    this.toggleDeleteConfirmDialog = this.toggleDeleteConfirmDialog.bind(this);
    this.continueDeleteSP = this.continueDeleteSP.bind(this);
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  componentDidMount() {
    ServiceProfileStore.get(this.props.match.params.serviceProfileID, resp => {
      this.setState({
        serviceProfile: resp,
      });
    });

    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin(),
    });
  }

  deleteServiceProfile() {
    DeviceStore.list({
      organizationId: this.props.match.params.organizationID,
      serviceProfileID: this.props.match.params.serviceProfileID,
      limit: 9999,
    }, resp => {
      if (resp.result && resp.result.length) {
        this.toggleDeleteDialog();
      } else {
        if (window.confirm("Are you sure you want to delete this service-profile?")) {
          ServiceProfileStore.delete(this.props.match.params.serviceProfileID, resp => {
            this.props.history.push(`/organizations/${this.props.match.params.organizationID}/service-profiles`);
          });
        }
      }
    });
  }

  continueDeleteSP() {
    ServiceProfileStore.delete(this.props.match.params.serviceProfileID, resp => {
      this.toggleDeleteConfirmDialog();
      this.toggleDeleteDialog();
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/service-profiles`);
    });
  }

  toggleDeleteDialog() {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
    });
  }

  toggleDeleteConfirmDialog() {
    this.setState({
      dialogConfirmOpen: !this.state.dialogConfirmOpen,
    });
  }

  getPage(limit, offset, callbackFunc) {
    DeviceStore.list({
      organizationId: this.props.match.params.organizationID,
      serviceProfileID: this.props.match.params.serviceProfileID,
      limit: limit,
      offset: offset,
      orderBy: this.state.orderBy,
      order: this.state.order
    }, callbackFunc);
  }

  getRow(obj) {
    return (
      <TableRow
        key={obj.devEUI}
        hover
      >
        <TableCell>{obj.name}</TableCell>
        <TableCell>{obj.devEUI.toUpperCase()}</TableCell>
      </TableRow>
    );
  }

  render() {
    if (this.state.serviceProfile === undefined) {
      return(<div></div>);
    }

    const {availableDevicesCount, usedDevicesCount, serviceProfile} = this.state.serviceProfile;

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <Admin>
              <TitleBarButton
                label="Delete"
                icon={<Delete />}
                color="secondary"
                onClick={this.deleteServiceProfile}
              />
            </Admin>
          }
        >

          <OrganizationBreadcrumps org={this.props.org}>
              <TitleBarTitle to={`/organizations/${this.props.match.params.organizationID}/service-profiles`} title="Service-profiles" />
              <TitleBarTitle title="/" />
              <TitleBarTitle title={this.state.serviceProfile.serviceProfile.name} />
            </OrganizationBreadcrumps>
        </TitleBar>

        <Grid item xs={12}>
          <UpdateServiceProfile
            serviceProfile={serviceProfile}
            availableDevicesCount={availableDevicesCount}
            usedDevicesCount={usedDevicesCount}
            admin={this.state.admin} />
        </Grid>
        <Grid item xs={12}>
          <Dialog
            open={this.state.dialogOpen}
            onClose={this.toggleDeleteDialog}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
            maxWidth="md"
          >
            <DialogTitle id="delete-dialog-title">Delete service-profile</DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-dialog-description">
                Are you sure you want to delete this service-profile and all relations to devices? This action cannot be reversed.
              </DialogContentText>
              <DataTable
                header={
                  <TableRow>
                    <TableCell>Device name</TableCell>
                    <TableCell>Device EUI</TableCell>
                  </TableRow>
                }
                getPage={this.getPage}
                getRow={this.getRow}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleDeleteDialog} color="default">Cancel</Button>
              <Button onClick={this.toggleDeleteConfirmDialog} color="primary">Delete</Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.dialogConfirmOpen}
            onClose={this.toggleDeleteConfirmDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleDeleteConfirmDialog} color="primary">
                No
              </Button>
              <Button onClick={this.continueDeleteSP} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>

        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ServiceProfileLayout);

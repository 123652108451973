import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import DeviceProfileStore from "../../stores/DeviceProfileStore";
import DeviceFormApp from "./DeviceFormApp";
import DeviceStore from "../../stores/DeviceStore";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


const styles = {
  card: {
    overflow: "visible",
  },
};

class CreateDeviceApp extends Component {
  constructor() {
    super();
    this.state = {
      dpDialog: false,
      errorName: false,
        orderBy: null,
        order: null,
      devEUIList: []
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  closeDialog() {
    this.setState({
      dpDialog: false,
    });
  }

  async deviceExists(name) {
    return new Promise((resolve, reject) => {
      DeviceStore.list(
        {
          organizationId: this.props.match.params.organizationID,
          deviceName: name,
        },
        (resp) => {
          resolve(Number(resp?.totalCount) === 1);
        },
        (error) => {
          console.error("Error fetching device list:", error);
        }
      );
    });
  }

  async onSubmit(device) {
    let dev = device;
    if (dev.location) {
      dev.location.latitude = dev.location.latitude || 0
      dev.location.longitude = dev.location.longitude || 0
      dev.location.altitude = dev.location.altitude || 0
    }

  
    const exists = await this.deviceExists(dev.name);
    if (exists) {
      this.setState({errorName: true});
      return;
    }

    DeviceStore.create(dev, resp => {
   
          if(resp&&resp.response&&!resp.response.ok){
            return
          }
   
      DeviceProfileStore.get(dev.deviceProfileID, resp => {
        if (resp.deviceProfile.supportsJoin) {
          this.props.history.push(`/organizations/${this.props.match.params.organizationID}/devices/${dev.devEUI}/keys`);
        } else {
          this.props.history.push(`/organizations/${this.props.match.params.organizationID}/devices/${dev.devEUI}/activation`);
        }
      });

    });

  }

  render() {

   

    return (
      <Grid container spacing={4}>
        <Dialog
          open={this.state.dpDialog}
          nClose={this.closeDialog}
        >
          <DialogTitle>Add a device-profile?</DialogTitle>
          <DialogContent>
            <DialogContentText paragraph>
              The selected application does not have access to any device-profiles.
              A device-profile defines the capabilities and boot parameters of a device. You can create multiple
              device-profiles for different kind of devices.
            </DialogContentText>
            <DialogContentText>
              Would you like to create a device-profile?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary"
                    component={Link}
                    to={`/device-profiles/create`}
                    onClick={this.closeDialog}>
              Create
              device-profile
            </Button>
            <Button color="primary" onClick={this.closeDialog}>Dismiss</Button>
          </DialogActions>
        </Dialog>

        <TitleBar>
        <OrganizationBreadcrumps org={this.props.org}>
            <TitleBarTitle title="Devices" to={`/organizations/${this.props.match.params.organizationID}/devices`}/>
            <TitleBarTitle title="/"/>
            <TitleBarTitle title="Create"/>
          </OrganizationBreadcrumps>
        </TitleBar>

        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <DeviceFormApp
                submitLabel="Create device"
                onSubmit={this.onSubmit}
                match={this.props.match}
                errorName={this.state.errorName}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(CreateDeviceApp));

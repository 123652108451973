import React from "react";

import TextField from '@material-ui/core/TextField';

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import InternalStore from "../../stores/InternalStore";
import {CopyHelper} from '../../classes/Helper'
import { IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import ClipboardTextMultipleOutline from "mdi-material-ui/ClipboardTextMultipleOutline";
import history from '../../history'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "../../components/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from '@material-ui/core/Checkbox';
class APIKeyForm extends FormComponent {
  constructor() {
    super();
    this.state = {
      token: null,
      id: null,
    };

    this.copyToClipboard = this.copyToClipboard.bind(this)
  }

  onSubmit = (e) => {
    e.preventDefault();

    let apiKey = this.state.object;

    if(this.props.update){
      InternalStore.updateAPIKey(apiKey, resp => {
        let url = `/api-keys`
          if(this.props.organizationID){
            url = `/organizations/${this.props.organizationID}/api-keys`
          }
           history.push(url);
         });
    }else{
      if(apiKey.isActive===undefined){
        apiKey.isActive=true
      }
      apiKey.isAdmin = this.props.isAdmin || false;
      apiKey.organizationID = this.props.organizationID || 0;
      apiKey.applicationID = this.props.applicationID || 0;

      InternalStore.createAPIKey(apiKey, resp => {
        this.setState({
          token: resp.jwtToken,
          id: resp.id,
        });
      });

   
    }
  

    
  }
  copyToClipboard() {
    CopyHelper({
      target:{innerText: this.state.token}
    });
  }
  render() {
    if (this.state.object === undefined) {
      return null;
    }

    if (this.state.token !== null) {
      return(
        <div>
          <TextField
            id="id"
            label="API key ID"
            value={this.state.id}
            margin="normal"
            disabled
            fullWidth
          />
          <TextField
            id="name"
            label="API key name"
            value={this.state.object.name}
            margin="normal"
            disabled
            fullWidth
          />
          <TextField
            id="jwtToken"
            label="Token"
            value={this.state.token}
            rows={5}
            margin="normal"
            helperText="Use this token when making API request with this API key. This token is provided once."
            fullWidth
            multiline
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Tooltip title="Copy password to clipboard">
                    <IconButton
                      aria-label="Copy password to clipboard"
                      onClick={this.copyToClipboard}
                    >
                      <ClipboardTextMultipleOutline />
                    </IconButton>
                  </Tooltip>
              </InputAdornment>
            }}
          />
        </div>
      );
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
        <TextField
          id="name"
          label="API key name"
          helperText="Name for the API key"
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          required
          fullWidth
        />
         <TextField
          id="description"
          label="API key description"
          helperText="Description for the API key"
          margin="normal"
          value={this.state.object.description || ""}
          onChange={this.onChange}
          required
          fullWidth
        />
        <FormControl label="Permissions">
          <FormGroup>
            <FormControlLabel
              label="Is active"
              control={
                <Checkbox
                  id="isActive"
                  checked={!!this.state.object.isActive||undefined}
                  onChange={this.onChange}
                  color="primary"
                  defaultChecked={(this.props.object===undefined)?true:undefined}
                />
              }
            />
           
           
          </FormGroup>
        </FormControl>
      </Form>
    );
  }
}

export default APIKeyForm;

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";

import {DevicesDataRates,GatewaysActiveInactive,DevicesActiveInactive} from './components/ActiveInactive'
import {DeviceSummaryLog, GatewaySummaryLog, FramesLog, MessageLog} from './components/SummaryLog'

import ListGatewaysMap from './components/ListGatewaysMap'

const styles = {
  chart: {
    minHeight: 300,
  },
  chartBig: {
    minHeight: 400,
  },
  graphActionContainer:{
    display:'inline-flex',
    alignItems:'center'
  },
  doughtnutChart: {
    maxWidth: "350px",
    padding: 0,
    margin: "auto",
    display: "block",
  },  
};





class Dashboard extends Component {

  componentDidMount(){
    const anchor = ( document).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' });
    }
  }
  render() {

    return(
      <Grid container spacing={4}>
        {!this.props.match.params.organizationID&&<TitleBar>
          <TitleBarTitle title="Dashboard" />
        </TitleBar>}

        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <DevicesActiveInactive  />
            </Grid>

            <Grid item xs={4}>
              <GatewaysActiveInactive/>
            </Grid>

            <Grid item xs={4}>
              <DevicesDataRates/>
            </Grid>

            <Grid item xs={12}>
             <DeviceSummaryLog/>
            </Grid>
            <Grid item xs={12}>
              <GatewaySummaryLog/>
            </Grid>
            {!this.props.match.params.organizationID&&<>
            <Grid item xs={12}>
              <FramesLog/>
            </Grid>
            <Grid item xs={12}>
              <MessageLog/>
            </Grid>
            </>}
            <Grid item xs={12}>
              <ListGatewaysMap organizationID={this.props.match.params.organizationID||undefined} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}


export default withStyles(styles)(Dashboard);

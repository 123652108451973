import React, { useState } from 'react';
import { Box, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { CircularProgress, Icon } from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import SessionStore from "../../stores/SessionStore";
import HandyRustyStore from "../../stores/HandyRustyStore";
import moment from 'moment';
import dispatcher from "../../dispatcher";

const FileDownload = require('downloadjs');

function notify(msg,type='success'){
  dispatcher.dispatch({
    type: "CREATE_NOTIFICATION",
    notification: {
      type: type,
      message: msg,
    },
  });
}
let styles = {
    select: {
        width: 150,
        marginRight: 10,
        marginLeft: 10,
      },

      iconCSV: {
        marginLeft: 10,
      },
      buttonWrapper:{
        position:"relative",
        display:'inline-block'
      }
} 

const ExportComponent = (props) => {
  const exportMax = 1000000;
  const [errMsgExport, setErrMsgExport] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportCSVPerc, setExportCSVPerc] = useState(0);
  const [exportManual, setExportManual]  = useState(false);
  const [exportSize, setExportSize] = useState(200);
  let csvData=""
  const [exportTotal,setExportTotal] = useState(0)
  
  const handleRowsChange = (event) => {
    setErrMsgExport(event.target.value > exportMax); //Set error message if value is greater than exportMax
    setExportSize(event.target.value); //Update the state with value from the text fields
  };

  const handleChangeCountSelection = (event) => {
    let exportSize = event.target.value;
    if (exportSize === 'user') {
      setExportManual(true)
    }
    setExportSize(exportSize)
  }


  const onCSVData = (d) =>{
    let currentPercentage = 0;

    if (d.total != null) {
      setExportTotal(d.total)
    
    }

    if (d.chunk != null) {
   

      if (exportTotal !== 0) {
        currentPercentage = Math.round(d.current * 100 / exportTotal);
      }
       csvData = csvData + atob(d.chunk)
      setExportCSVPerc( currentPercentage + '%')
    
    }
  }

  const onCSVSocketClose = () => {
    let time = moment().format('DDMMYYYY_HHmm')
    if (csvData === '') {
      notify("Error: No data available!", "error");
     

      return;
    }
    let dRes = FileDownload(csvData, 'CTT_' + time + '.csv', "text/csv")
    csvData = ""
    setExportCSVPerc(0)
    if(dRes){
      setTimeout(()=>{
      notify("All the data downloaded!");
      },500)
      return
    }else{
      notify("Error: Download error!", "error");
      return
    }
  }

  const exportCSV =  () => {
    setExportLoading(true); //Show the loading message
    
    try {
      let filter = props.filter || {}
      if(!filter.filters){
        filter.filters = {}
      }

     
     
      let load = true
      // code to export csv file
      //setExportCSVPerc(100);
      // fil.start = (fil.start === null||fil.start===undefined||fil.start==="undefined") ? '' : fil.start
      // fil.end = (fil.end === null||fil.end === undefined||fil.end === "undefined") ? '' : fil.end
      // if(fil.organizationId===undefined){
        
      //   if (!SessionStore.isAdmin()) {
      //     fil.organizationId = SessionStore.getOrganizationID() || SessionStore.organizations[0].organizationID
      //   }
      // }

      let query_string = window.location.hash.split('?')[1]

      if(query_string){
        let searchParams = new URLSearchParams(query_string);
        if(searchParams.has("start")){
          filter.start = searchParams.get("start")
        }

        if(searchParams.has("end")){
          filter.end = searchParams.get("end")
        }

        if(searchParams.has("devEUI")){
          filter['filters']["devEUI"]  = searchParams.get("devEUI")
          load = false
          if(!SessionStore.isAdmin() && SessionStore.organizations.filter(el =>  el.isDeviceAdmin ).length > 1  ){
            SessionStore.globalSearch(filter['filters']["devEUI"] ,1,0,(resp)=>{ 
              if(resp.totalCount>0 && resp.result[0].organizationID!==SessionStore.getOrganizationID() ){
                  notify("Device is out of scope current organization or not found.", "error");
             
                }else{
                HandyRustyStore.getDeviceFramesCSV(filter, exportSize, 0, onCSVData, onCSVSocketClose)
                
              }
             
            })
            
          }
        }        
      }

      if(load){
        HandyRustyStore.getDeviceFramesCSV(filter, exportSize, 0, onCSVData, onCSVSocketClose)
      }



    } catch (error) {
      console.error(error); //Display error message if csv file fails to export
    } finally {
      setExportLoading(false); //Hide the loading message
      
    }
  };
  const handleClick = () =>{
    setExportSize(10);
    setExportManual(false)
  }



  return (
    <div style={{width: '220px'}}>
             
    {(exportManual) ?
      <>
        <TextField
          label="rows"
          id="exportSize"
          style={{width: '150px',position:'relative',top:'4px'}}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClick}
                  style={{padding: "0"}}
                  color="default"
                  aria-label="upload picture"
                  component="span"
                >
                  <Icon className="fa fa-times"/>
                </IconButton>
              </InputAdornment>,
          }}
          inputProps={{
            min: 0,
            max: 1000000,
          }}
          variant="outlined"
          size="small"
          type='number'
          error={errMsgExport}
          helperText={(errMsgExport)?("Max "+ exportMax):""}
          onChange={handleRowsChange}
          value={exportSize || ""}
        />
     </> : <>
        <InputLabel shrink id="exportSize">
          rows
        </InputLabel>
        <Select style={{width: "150px"}}
                labelId="exportSize"
                id="exportSize"
                value={exportSize || ""}
                className={props.classes.select}
                onChange={handleChangeCountSelection}
                displayEmpty
        >
          <MenuItem value={10}>
            <em>10</em>
          </MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={200}>200</MenuItem>
          <MenuItem value={500}>500</MenuItem>
          <MenuItem value={'user'}>by user</MenuItem>
        </Select>
      </>}
<div className={props.classes.buttonWrapper}>
    <IconButton
      disabled={exportLoading}
      onClick={exportCSV}
      style={{marginRight: '10px', marginLeft: '10px', position:'relative',top:(exportManual)?'8px':''}}
      size="small"
      variant="text"
      className={props.classes.iconCSV}
    >
      <Icon className={'fas fa-file-csv'}/>
    </IconButton>
    {exportLoading && <Box style={{
      width: '40px',
      position: 'absolute',
      left: '5px',
      top: '0px',
      zIndex: '10'
    }} display="inline-flex">
      <CircularProgress value={exportCSVPerc}
                        style={{width: '100%'}}
                        color={'inherit'}
                        variant={'determinate'}
                        size={'small'}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" style={{fontWeight: 800, fontSize: '1rem'}} component="div"
                    color="textSecondary">{exportCSVPerc}</Typography>
      </Box>
    </Box>}

    </div>
    </div>
  )
}



export default withStyles(styles)(ExportComponent)
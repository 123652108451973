import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import { CardContent } from "@material-ui/core";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import OrganizationForm from "./OrganizationForm";
import OrganizationStore from "../../stores/OrganizationStore";
import SessionStore from "../../stores/SessionStore"

class CreateOrganization extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
    this.fetchedProfile = false
    SessionStore.on("userGot",()=>{
      if(SessionStore.user&&SessionStore.user.isAdmin){
        this.fetchedProfile = true
      }else if (SessionStore.user&&!SessionStore.user.isAdmin){
        this.fetchedProfile = false
        this.props.history.push("/organizations")
      }
      this.forceUpdate();
    })
  }
  

  componentDidMount(){
    
    if(SessionStore.user!=null&&SessionStore.user.isAdmin){
      this.fetchedProfile = true
      this.forceUpdate();
    }else if (SessionStore.user!=null&&!SessionStore.user.isAdmin){
      this.props.history.push("/organizations")
    }
  }
  onSubmit(organization) {
    let org = organization;
    org.maxDeviceCount = 0;
    OrganizationStore.create(org, resp => {
      this.props.history.push("/organizations");
    });
  }

  render() {
    if (!this.fetchedProfile ){return null}
    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title="Organizations" to="/organizations" />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Create" />
        </TitleBar>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <OrganizationForm
                submitLabel="Create organization"
                onSubmit={this.onSubmit}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(CreateOrganization);
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import ServiceProfileStore from "../../stores/ServiceProfileStore";
import ServiceProfileForm from "./ServiceProfileForm";

class UpdateServiceProfile extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(serviceProfile) {
    let sp = serviceProfile;
    sp.minEIRP = (!sp.minEIRP || (sp.minEIRP &&isNaN(sp.minEIRP))) ? -1 : sp.minEIRP;
    sp.maxEIRP = (!sp.maxEIRP || (sp.maxEIRP &&isNaN(sp.maxEIRP)  )) ? -1 : sp.maxEIRP;
    sp.maxNbTrans = (!sp.maxNbTrans || (sp.maxNbTrans &&isNaN(sp.maxNbTrans) )) ? -1 : sp.maxNbTrans;
    sp.ulRate = (!sp.ulRate || (sp.ulRate &&isNaN( sp.ulRate) )) ? -1 : sp.ulRate;
    sp.dlRate = (!sp.dlRate || (sp.dlRate &&isNaN(sp.dlRate) )) ? -1 : sp.dlRate;
    ServiceProfileStore.update(sp, resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/service-profiles`);
    });
  }

  render() {
    const {availableDevicesCount, usedDevicesCount} = this.props;

    const { serviceProfile } = this.props;
    serviceProfile.minEIRP = serviceProfile.minEIRP === -1 ? NaN : serviceProfile.minEIRP;
    serviceProfile.maxEIRP = serviceProfile.maxEIRP === -1 ? NaN : serviceProfile.maxEIRP;
    serviceProfile.maxNbTrans = serviceProfile.maxNbTrans === -1 ? NaN : serviceProfile.maxNbTrans;
    serviceProfile.ulRate = serviceProfile.ulRate === -1 ? NaN : serviceProfile.ulRate;
    serviceProfile.dlRate = serviceProfile.dlRate === -1 ? NaN : serviceProfile.dlRate;

    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <ServiceProfileForm
                submitLabel="Update service-profile"
                object={this.props.serviceProfile}
                onSubmit={this.onSubmit}
                match={this.props.match}
                disabled={!this.props.admin}
                update={true}
                availableDevicesCount={availableDevicesCount}
                usedDevicesCount={usedDevicesCount}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(UpdateServiceProfile);

import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TitleBarTitle from "./TitleBarTitle";
import TitleBarButton from "./TitleBarButton";

const styles = {
  clear: {
    clear: "both",
    paddingTop:'0!important',
    paddingBottom:'0!important'
  },

  left: {
    float: "left",
  },

  right: {
    float: "right",
  },
};


class TitleBar extends Component {
  render() {
    return(
        <Grid item xs className={this.props.classes.clear}>
          <div className={this.props.classes.left}>
          {this.props.children&&Array.isArray(this.props.children)&&this.props.children.map((el,i)=>{
              return <TitleBarTitle
              key={"titleId"+i}
              to={el.props.to} 
              title={el.props.title}
              />
            })}
            {!Array.isArray(this.props.children)&&this.props.children}
          </div>
          <div className={this.props.classes.right}>
         {this.props.buttons&&Array.isArray(this.props.buttons)&&this.props.buttons.map((el,i)=>{
           const props = (el.props.children)?((Array.isArray(el.props.children))? el.props.children[1].props: el.props.children.props) : el.props
                return <TitleBarButton 
                key={"buttonId"+i}
                color={(props)?props.color:"default"}
                to={(props)?props.to:undefined}
                onClick={(props)?props.onClick:undefined}
                label={(props)?props.label:undefined}
                icon={(props)?props.icon:undefined}
                disabled={(props)?props.disabled:undefined}
                />
              })}
            {!Array.isArray(this.props.buttons) && this.props.buttons}
          </div>
        </Grid>
    );
  }
}

export default withStyles(styles)(TitleBar);

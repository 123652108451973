import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Delete from "mdi-material-ui/Delete";
import Account from "mdi-material-ui/Account";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import SessionStore from "../../stores/SessionStore";
import OrganizationStore from "../../stores/OrganizationStore";
import UpdateOrganizationUser from "./UpdateOrganizationUser";
import KeyVariant from "mdi-material-ui/KeyVariant";
import UserStore from "../../stores/UserStore";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


class OrganizationUserLayout extends Component {
  constructor() {
    super();
    this.state = {
      admin: false,
    };
    this.deleteOrganizationUser = this.deleteOrganizationUser.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    OrganizationStore.getUser(this.props.match.params.organizationID, this.props.match.params.userID, resp => {
      this.setState({
        organizationUser: resp,
      });
    });

    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();
  }

  componendWillUnmount() {
    SessionStore.removeListener("on", this.setIsAdmin);
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin(),
    });
  }

  isAdmin() {
    return ((SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(SessionStore.getOrganizationID())))
  }

  deleteOrganizationUser() {
    if (this.state.organizationUser.organizationUser.orgCnt > 1) {
      if (window.confirm("Do you realy want to remove the user from the organization?")) {
      OrganizationStore.deleteUser(this.props.match.params.organizationID, this.props.match.params.userID, resp => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/users`);
      });
      }
      return    
    }

    if (window.confirm("Do you realy want to delete the user?")) {
      UserStore.delete(this.props.match.params.userID, () => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/users`);
      });
    }

  }

  render() {
    if (this.state.organizationUser === undefined) {
      return (<div></div>);
    }

    return (
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <div>
              {SessionStore.isOrganizationAdmin(SessionStore.getOrganizationID()) && <TitleBarButton
                label="Change password"
                icon={<KeyVariant/>}
                to={`/organizations/${this.props.match.params.organizationID}/users/${this.state.organizationUser.organizationUser.userID}/password`}
              />}
              {this.state.admin && <TitleBarButton
                label="Go to user"
                icon={<Account/>}
                to={`/users/${this.state.organizationUser.organizationUser.userID}`}
              />}
              <TitleBarButton
                label="Delete"
                icon={<Delete/>}
                color="secondary"
                disabled={!this.isAdmin()}
                onClick={this.deleteOrganizationUser}
              />
            </div>
          }
        >
        <OrganizationBreadcrumps org={this.props.org}>
              <TitleBarTitle to={`/organizations/${this.props.match.params.organizationID}/users`}
                            title="Organization users"/>
              <TitleBarTitle title="/"/>
              <TitleBarTitle title={this.state.organizationUser.organizationUser.email}/>
        </OrganizationBreadcrumps>
        </TitleBar>

        <Grid item xs={12}>
          <UpdateOrganizationUser organizationUser={this.state.organizationUser}/>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(OrganizationUserLayout);

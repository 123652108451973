import React, {Component} from "react";
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Plus from "mdi-material-ui/Plus";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import DeviceAdmin from "../../components/DeviceAdmin";
import RoutingProfileStore from "../../stores/RoutingProfileStore"
import moment from "moment";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


class ListAsRoutingProfiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orderBy:null,
      order:null
    }

    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    RoutingProfileStore.list(null,limit, offset, this.props.match.params.organizationID, callbackFunc);
  }

  getRow (obj) {
    let createdAt = '';
    if (obj.createdAt) {
      createdAt = moment(obj.createdAt).format("YYYY-MM-DD HH:mm:ss");
    };

    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell>{obj.id}</TableCell>
        <TableCellLink
          to={`/organizations/${this.props.match.params.organizationID}/as-routing-profiles/${obj.id}`}>{obj.name}</TableCellLink>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/devices/?rp=${obj.id}`}>{obj.devCnt}</TableCellLink>
        <TableCell>{createdAt}</TableCell>
      </TableRow>
    );
  }

  render() {

    return (
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <DeviceAdmin organizationID={this.props.match.params.organizationID}>
              <TitleBarButton
                icon={<Plus/>}
                label="Create"
                to={`/organizations/${this.props.match.params.organizationID}/as-routing-profiles/create`}
              />
            </DeviceAdmin>
          }
        >
          <OrganizationBreadcrumps org={this.props.org}>
            <TitleBarTitle title="AS routing profiles"/>
          </OrganizationBreadcrumps>
        </TitleBar>

        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Device Count</TableCell>
                <TableCell>Created at</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ListAsRoutingProfiles;


import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from '@material-ui/core/Button';
import Refresh from "mdi-material-ui/Refresh";
import Delete from "mdi-material-ui/Delete";
import moment from "moment";
import TableCellLink from "../../components/TableCellLink";
import DeviceQueueItemForm from "./DeviceQueueItemForm";
import DeviceQueueStore from "../../stores/DeviceQueueStore";
import SettingsRemoteOutlinedIcon from '@material-ui/icons/SettingsRemoteOutlined';
import {base64ToHex} from "../../components/JSONTree";
import RoutingProfileStore from "../../stores/RoutingProfileStore";
import ServiceProfileStore from "../../stores/ServiceProfileStore";
import Paper from '@material-ui/core/Paper';
import {Map, Marker} from 'react-leaflet';
import MapTileLayer from "../../components/MapTileLayer";
import {checkGps} from '../../classes/Helper'
import Icon from '@material-ui/core/Icon';
import { withStyles } from "@material-ui/core";

const styles = {
  gatewayIcon: {
    verticalAlign: 'bottom', cursor: 'pointer'
  },
  link:{
    color:'unset'
  },
  icon:{
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    fontSize:'11px',
    marginRight:'5px'
  }
}

class DetailsCard extends Component {

  constructor() {
    super();
    this.state = {
      routingProfileName: '',
      serviceProfileName: ''
    };
  }

  componentDidMount() {
    RoutingProfileStore.get(this.props.device.device.routingProfileID, res => {
      this.setState({
        routingProfileName: res.routingProfile.name,
      });
    });

    if (this.props.device.device.serviceProfileID && this.props.device.device.serviceProfileID !== '') {
      ServiceProfileStore.get(this.props.device.device.serviceProfileID, res => {
        this.setState({
          serviceProfileName: res.serviceProfile.name,
        });
      });
    }
  }

  render() {
    let deviceClass = 'Class-A';
    if (this.props.deviceProfile.deviceProfile.supportsClassC || (this.props.deviceProfile.deviceProfile.supportsClassC && this.props.deviceProfile.deviceProfile.supportsClassB)) {
      deviceClass = 'Class-C';
    } else if (this.props.deviceProfile.deviceProfile.supportsClassB) {
      deviceClass = 'Class-B';
    }

    const {firstUplinkAt} = this.props.device;

    return (
      <Card>
        <CardHeader title="Details" action={<Grid container direction="row">
       {((!this.props.deviceProfile.deviceProfile.supportsJoin&&!this.props.activated)||(this.props.deviceProfile.deviceProfile.supportsJoin&&!this.props.otaaKeys)) && <Icon style={{ fontSize: 18 }} className="fa fa-ban"/>}
       
      
        {this.props.device.device.isDisabled && <div style={{color: 'red', marginLeft:'5px'}}>DISABLED</div>}</Grid>}/>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{this.props.device.device.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>DevEUI</TableCell>
                <TableCell>{this.props.device.device.devEUI.toUpperCase()}
                <Link  style={styles.link} to={`/diagnostics?devEUI=${this.props.device.device.devEUI}`}>
                  <SettingsRemoteOutlinedIcon style={styles.gatewayIcon} titleAccess={'Show in Lorawan Logger'}/></Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>{this.props.device.device.description}</TableCell>
              </TableRow>
              {this.props.device.device.location && this.props.device.device.location.latitude !== 0 && this.props.device.device.location.longitude !== 0 &&
              <TableRow>
                <TableCell>Coordinates</TableCell>
                <TableCell>Lat: {checkGps(this.props.device.device.location.latitude)} Lon: {checkGps(this.props.device.device.location.longitude)} Alt: {this.props.device.device.location.altitude} </TableCell>
              </TableRow>}
              <TableRow>
                <TableCell>Device-profile</TableCell>
                <TableCellLink
                  to={`/device-profiles/${this.props.deviceProfile.deviceProfile.id}`}>{this.props.deviceProfile.deviceProfile.name}</TableCellLink>
              </TableRow>
              <TableRow>
                <TableCell>Current device class</TableCell>
                <TableCell>{deviceClass}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>AS routing profile</TableCell>
                <TableCellLink
                  to={`/organizations/${this.props.match.params.organizationID}/as-routing-profiles/${this.props.device.device.routingProfileID}`}>{this.state.routingProfileName}</TableCellLink>
              </TableRow>
              <TableRow>
                <TableCell>Service-profile</TableCell>
                <TableCellLink
                  to={`/organizations/${this.props.match.params.organizationID}/service-profiles/${this.props.device.device.serviceProfileID}`}>{this.state.serviceProfileName}</TableCellLink>
              </TableRow>
              <TableRow>
                <TableCell>Created at</TableCell>
                <TableCell>{moment(this.props.device.createdAt).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
              </TableRow>
              {firstUplinkAt != null && (<TableRow>
                <TableCell>First message at</TableCell>
                <TableCell>{moment(firstUplinkAt).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

DetailsCard = withStyles(styles)(DetailsCard)
class StatusCard extends Component {
  render() {
    let lastSeenAt = "never";
    let state = "enabled";

    if (this.props.device.lastSeenAt !== null) {
      lastSeenAt = moment(this.props.device.lastSeenAt).format("YYYY-MM-DD HH:mm:ss");
    }

    if (this.props.device.device.isDisabled === true) {
      state = "disabled";
    }

    return (
      <Card>
        <CardHeader title="Status"/>
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Last seen at</TableCell>
                <TableCell>{lastSeenAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>State</TableCell>
                <TableCell>{state}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

class EnqueueCard extends Component {
  constructor() {
    super();

    this.state = {
      object: {},
    };
  }

  onSubmit = (queueItem) => {
    let qi = queueItem;
    qi.devEUI = this.props.match.params.devEUI;
    if(!queueItem.ttl){
      queueItem.ttl = 0
    }
   
    DeviceQueueStore.enqueue(qi, resp => {
      this.setState({
        object: {},
      });
    });
  }

  render() {

    return (
      <Card>
        <CardHeader title="Enqueue downlink payload"/>
        <CardContent>
          <DeviceQueueItemForm
            submitLabel="Enqueue payload"
            onSubmit={this.onSubmit}
            object={this.state.object}
            disabled={this.props.disabled}
          />
        </CardContent>
      </Card>
    );
  }
}

EnqueueCard = withRouter(EnqueueCard);

class QueueCardRow extends Component {
  render() {
    let confirmed = "no";
    if (this.props.item.confirmed) {
      confirmed = "yes";
    }

    return (
      <TableRow>
        <TableCell >{moment(this.props.item.createdAt).format("YYYY-MM-DD HH:mm:ss") }</TableCell>
        <TableCell >{moment(this.props.item.timeoutAfter).format("YYYY-MM-DD HH:mm:ss") }</TableCell>
        <TableCell>{this.props.item.fCnt}</TableCell>
        <TableCell>{this.props.item.fPort}</TableCell>
        <TableCell>{confirmed}</TableCell>
        <TableCell>{base64ToHex(this.props.item.data)}</TableCell>
      </TableRow>
    );
  }
}

class QueueCard extends Component {
  constructor() {
    super();

    this.state = {
      deviceQueueItems: [],
    };
  }

  componentDidMount() {
    this.getQueue();
    DeviceQueueStore.on("enqueue", this.getQueue);
  }

  componentWillUnmount() {
    DeviceQueueStore.removeListener("enqueue", this.getQueue);
  }

  getQueue = () => {
    DeviceQueueStore.list(this.props.match.params.devEUI, resp => {
      this.setState({
        deviceQueueItems: resp.deviceQueueItems,
      });
    });
  }

  flushQueue = () => {
    if (window.confirm("Are you sure you want to flush the device queue?")) {
      DeviceQueueStore.flush(this.props.match.params.devEUI, resp => {
        this.getQueue();
      });
    }
  }

  render() {
    const rows = this.state.deviceQueueItems.map((item, i) => <QueueCardRow key={i} item={item}/>);

    return (
      <Card>
        <CardHeader title="Downlink queue" action={
          <div>
            <Button onClick={this.getQueue}><Refresh/></Button>
            <Button onClick={this.flushQueue} color="secondary"><Delete/></Button>
          </div>
        }/>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width="10%">Added at</TableCell>
                <TableCell width="10%">Time expired</TableCell>
                <TableCell width="5%">FCnt</TableCell>
                <TableCell width="5%">FPort</TableCell>
                <TableCell width="5%">Confirmed</TableCell>
                <TableCell>HEX encoded payload</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

QueueCard = withRouter(QueueCard);

class DeviceDetailsApp extends Component {
  constructor() {
    super();

    this.goTo = this.goTo.bind(this);
  
  }

 
  componentDidUpdate(prevProps) {
    if (prevProps.device !== this.props.device) {
   
    }
  }

  goTo() {
    if (this.props.device && this.props.device.device && this.props.device.device.devEUI) {
      this.props.history.push('/diagnostics?devEUI=' + this.props.device.device.devEUI)
    }
  }



  render() {
    const style = {
      height: 400,
    };

    let position = [];
    if (this.props.device.device.location && typeof (this.props.device.device.location.latitude) !== "undefined" && typeof (this.props.device.device.location.longitude !== "undefined")) {
      position = [this.props.device.device.location.latitude, this.props.device.device.location.longitude];
    } else {
      position = [0, 0];
    }

    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>

          <DetailsCard 
           otaaKeys={this.props.otaaKeys}
           activated={this.props.activated}
                        goTo={this.goTo}
                       device={this.props.device}
                       deviceProfile={this.props.deviceProfile}
                       match={this.props.match}/>
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <Map center={position}
                 zoom={15}
                 style={style}
                 animate={true}
                 scrollWheelZoom={false}>
              <MapTileLayer/>
              <Marker position={position}/>
            </Map>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <StatusCard device={this.props.device}/>
        </Grid>

        {this.props.activated && <Grid item xs={12}>
          <EnqueueCard disabled={!this.props.admin}/>
        </Grid>}
        {this.props.activated && <Grid item xs={12}>
          <QueueCard/>
        </Grid>}
      </Grid>
    );
  }
}

export default DeviceDetailsApp;

import React from 'react'
import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";
import { Dictionary } from "../classes/Dictionary";


class OrganizationStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/organization.swagger.json", sessionStore.getClientOpts());
  }

  create(organization, callbackFunc) {
    this.swagger.then(client => {
      client.apis.OrganizationService.OrganizationService_Create({
        body: {
          organization: organization,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.emit("create", organization);
        this.notify("created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  get(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.OrganizationService.OrganizationService_Get({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  update(organization, callbackFunc) {
    this.swagger.then(client => {
      client.apis.OrganizationService.OrganizationService_Update({
        "organization.id": organization.id,
        body: {
          organization: organization,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.emit("change", organization);
        this.notify("updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  delete(id, callbackFunc) {  
    this.swagger.then(client => {
      client.apis.OrganizationService.OrganizationService_Delete({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        this.emit("delete", id);
        this.notify("deleted");
        callbackFunc(resp.obj);
      })
      .catch(this.deleteErrorHandler);
    });
  }

  list(search, limit, offset, callbackFunc, orderBy, order,userId) {
    this.swagger.then((client) => {
      client.apis.OrganizationService.OrganizationService_List({
        search: search,
        limit: limit,
        offset: offset,
        orderBy:orderBy,
        order:order,
        userId:userId
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  addUser(organizationID, user, callbackFunc) {
    this.swagger.then(client => {
      client.apis.OrganizationService.OrganizationService_AddUser({
        "organizationUser.organizationID": organizationID,
        body: {
          organizationUser: user,
        },
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(this.addErrorHandler);
    });
  }

  getUser(organizationID, userID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.OrganizationService.OrganizationService_GetUser({
        organizationID: organizationID,
        userID: userID,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  deleteUser(organizationID, userID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.OrganizationService.OrganizationService_DeleteUser({
        organizationID: organizationID,
        userID: userID,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  updateUser(organizationUser, callbackFunc) {
    this.swagger.then(client => {
      client.apis.OrganizationService.OrganizationService_UpdateUser({
        "organizationUser.organizationID": organizationUser.organizationID,
        "organizationUser.userID": organizationUser.userID,
        body: {
          organizationUser: organizationUser,
        },
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  listUsers(organizationID, limit, offset, orderBy, order, callbackFunc,search) {
    this.swagger.then(client => {
      client.apis.OrganizationService.OrganizationService_ListUsers({
        search:search,
        organizationID: organizationID,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        order:  order
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  addErrorHandler(error){
   
      if( error.response.obj.code===5 ){

        dispatcher.dispatch({
          type: "CREATE_NOTIFICATION",
          notification: {
            type: "error",
            message:<div>User does not exist</div>,
          },
        });
        return
      }else{
        errorHandler(error)
      }
  }
  deleteErrorHandler(error) {
    if (error.response.obj.code !== 2 ) {
      errorHandler(error)
    }
    let splitMsg = error.response.obj.message.split('table ')
    let table = splitMsg[splitMsg.length-1].replaceAll('"','')
   
    let dict = new Dictionary("tables-translation")

   dispatcher.dispatch({
    type: "CREATE_NOTIFICATION",
    notification: {
      type: "error",
      message:<div>Delete error: {dict.map(table)} exist.</div>,
    },
  });

  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "organization has been " + action,
      },
    });
  }
}

const organizationStore = new OrganizationStore();
export default organizationStore;

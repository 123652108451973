import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";

import APIKeyForm from "./APIKeyForm";



const styles = {
  card: {
    overflow: "visible",
  },
};


class UpdateApiKey extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(apiKey) {
  
    console.log(apiKey , this.props.apiKey) 
  
  }

  render() {
    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <APIKeyForm
                submitLabel={`Update ${(this.props.match.params.organizationID)?'organization':''} api-key`}
                object={this.props.apiKey}
                organizationID={this.props.match.params.organizationID}
                match={this.props.match}
                disabled={!this.props.admin}
                update={true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(UpdateApiKey));

import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";


class UserStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/user.swagger.json", sessionStore.getClientOpts());
  }

  create(user, password, organizations, callbackFunc,notify=true) {
    this.swagger.then(client => {
      client.apis.UserService.UserService_Create({
        body: {
          organizations: organizations,
          password: password,
          user: user,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  logs(orgId,search, limit, offset, orderBy, order, callbackFunc){
    this.swagger.then(client => {
      client.apis.UserService.UserService_ListLogs({
        search:search,
        organizationId: orgId,
        limit: limit,
        offset: offset,
        orderBy:orderBy,
        order:order
      })
          .then(checkStatus)
          .then(resp => {
          //  resp.obj.totalCount = resp.obj.result.length
            callbackFunc(resp.obj);
          })
          .catch(errorHandler);
    });
  }

  get(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.UserService.UserService_Get({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  update(user, callbackFunc) {
    this.swagger.then(client => {
      client.apis.UserService.UserService_Update({
        "user.id": user.id,
        body: {
          "user": user,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  delete(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.UserService.UserService_Delete({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("deleted");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  updatePassword(id, password, callbackFunc) {
    this.swagger.then(client => {
      client.apis.UserService.UserService_UpdatePassword({
        "userId": id,
        body: {
          password: password,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  list(search, limit, offset, orderBy, order, callbackFunc) {
    this.swagger.then((client) => {
      client.apis.UserService.UserService_List({
        search: search,
        limit: limit,
        offset: offset,
        orderBy:orderBy,
        order:order
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });

  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "user has been " + action,
      },
    });
  }
}

const userStore = new UserStore();
export default userStore;

import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import FormControlOrig from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import FormControl from "../../components/FormControl";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import NetworkServerStore from "../../stores/NetworkServerStore";
import theme from "../../theme";
import {FormGroup, Radio, RadioGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";

const styles = {
  a: {
    color: theme.palette.primary.main,
  },
  formLabel: {
    fontSize: 12,
  },
  button: {
    padding: 0,
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: 1,
    letterSpacing: "0.00938em",
  }
};

const defaultValues = () =>{ return {
  downlinkTxPower: -1,
  channelsStr: "0,1,2",
  channels:[0,1,2],
}}

class ExtraChannel extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onChange(e) {
    let channel = this.props.channel;
    let field = "";

    if (e.target.id === undefined) {
      field = e.target.name;
    } else {
      field = e.target.id;
    }

    if (field === "spreadingFactorsStr") {
      let sfStr = e.target.value.split(",");
      channel["spreadingFactors"] = sfStr.map((sf, i) => parseInt(sf, 10));
    }

    if (e.target.type === "number") {
      channel[field] = parseInt(e.target.value, 10);
    } else {
      channel[field] = e.target.value;
    }

    this.props.onChange(channel);
  }

  onDelete(e) {
    e.preventDefault();
    this.props.onDelete();
  }

  render() {
    let spreadingFactorsStr = "";
    if (this.props.channel.spreadingFactorsStr !== undefined) {
      spreadingFactorsStr = this.props.channel.spreadingFactorsStr;
    } else if (this.props.channel.spreadingFactors !== undefined) {
      spreadingFactorsStr = this.props.channel.spreadingFactors.join(", ");
    }

    return (
      <FormControl
        label={
          <span>
                Extra channel {this.props.i + 1} (
                  <a href="#delete"
                     onClick={this.onDelete}
                     className={this.props.classes.a}>
                    <IconButton
                      color="primary"
                      className={this.props.classes.button}
                      disableRipple
                    >
                      delete
                    </IconButton>
                  </a>
                )
          </span>
        }
      >
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormControl>
              <InputLabel htmlFor="modulation" required>Modulation</InputLabel>
              <Select
                value={this.props.channel.modulation || ""}
                onChange={this.onChange}
                inputProps={{
                  name: "modulation",
                }}
              >
                <MenuItem value="LORA">LoRa</MenuItem>
                <MenuItem value="FSK">FSK</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <InputLabel htmlFor="bandwidth" required>bandwidth (kHz)</InputLabel>
              <Select
                value={this.props.channel.bandwidth || ""}
                onChange={this.onChange}
                inputProps={{
                  name: "bandwidth",
                }}
              >
                <MenuItem value={125}>125 kHz</MenuItem>
                <MenuItem value={250}>250 kHz</MenuItem>
                <MenuItem value={500}>500 kHz</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="frequency"
              label="Frequency (Hz)"
              type="number"
              margin="normal"
              value={this.props.channel.frequency || ""}
              onChange={this.onChange}
              required
              fullWidth
            />
          </Grid>
          {this.props.channel.modulation === "LORA" && <Grid item xs={6}>
            <TextField
              id="spreadingFactorsStr"
              label="Spreading-factors"
              margin="normal"
              value={spreadingFactorsStr}
              onChange={this.onChange}
              placeholder="7, 8, 9, 10, 11, 12"
              helperText="When defining multiple spreading-factors, the channel will be configured as a multi-SF channel on the gateway."
              inputProps={{
                pattern: "[0-9]+(,[\\s]*[0-9]+)*",
              }}
              required
              fullWidth
            />
          </Grid>}
          {this.props.channel.modulation === "FSK" && <Grid item xs={6}>
            <TextField
              id="bitrate"
              label="Bitrate"
              type="number"
              margin="normal"
              value={this.props.channel.bitrate || ""}
              onChange={this.onChange}
              placeholder="50000"
              required
              fullWidth
            />
          </Grid>}
        </Grid>
      </FormControl>
    );
  }
}

ExtraChannel = withStyles(styles)(ExtraChannel);


class GatewayProfileForm extends FormComponent {
  constructor() {
    super();
    this.state = {
      description: "",
      isChecked: true,
      reference: "Reference Design",
      gps: false,
      secure: false,
      design: "v1.5",
      "downlinkTxPower" : -1,
    }

    this.addExtraChannel = this.addExtraChannel.bind(this);
    this.getNetworkServerOption = this.getNetworkServerOption.bind(this);
    this.getNetworkServerOptions = this.getNetworkServerOptions.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.props.object !== undefined ) {
      let object = this.props.object;
      if(this.props.object.channels !== undefined && this.props.object.channelsStr === undefined){
          object.channelsStr = object.channels.join(", ");
      }
     

      this.setState({
        object: object,
      });
    }else{
      this.setDefaults()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.object !== this.props.object) {
      let object = this.props.object;

      if (object !== undefined && object.channels !== undefined && object.channelsStr === undefined) {
        object.channelsStr = object.channels.join(", ");
      }

      this.setState({
        object: object || {},
      });
    }

      
  }

  setDefaults() {
    this.setState({object: defaultValues()})
  }
  setDefaultFreq = ()=>{
    let object = this.state.object
    object.rx2Freq = 0;
    this.setState({
      object: object,
    });
  }
  onChange(e) {
    super.onChange(e);

   
    let object = this.state.object;

    if (e.target.id === "channelsStr") {
      let channelsStr = e.target.value.split(",");
      object["channels"] = channelsStr.map((c, i) => { 
        return parseInt(c, 10)
      });
    }
   
    this.setState({
      object: object,
    });
  }


  addExtraChannel() {
    let object = this.state.object;
    if (object.extraChannels === undefined) {
      object.extraChannels = [{modulation: "LORA"}];
    } else {
      object.extraChannels.push({modulation: "LORA"});
    }

    this.setState({
      object: object,
    });
  }

  deleteExtraChannel(i) {
    let object = this.state.object;
    object.extraChannels.splice(i, 1);
    this.setState({
      object: object,
    });
  }

  updateExtraChannel(i, ec) {
    let object = this.state.object;
    object.extraChannels[i] = ec;

    this.setState({
      object: object,
    });
  }

  getNetworkServerOption(id, callbackFunc) {
    NetworkServerStore.get(id, resp => {
      callbackFunc({label: resp.networkServer.name, value: resp.networkServer.id});
    });
  }

  getNetworkServerOptions(search, callbackFunc) {
    NetworkServerStore.list(0, 999, 0, resp => {
      const options = resp.result.map((ns, i) => {
        return {label: ns.name, value: ns.id}
      });
      callbackFunc(options);
    });
  }

  onChangeDescription = (e) => {
    let object = this.state.object;
    const {name, value} = e.target;
    object[name] = e.target.value;

    this.setState({
      [name]: value,
      object: object,
    })
  }

  toggleVersionChange = (e) => {
    const {name, value} = e.target;
    let object = this.state.object;
    object[name] = e.target.value;

    this.setState({
      [name]: value,
    })
  }
  setDefaultFreq = ()=>{
    let object = this.state.object
    object.downlinkTxPower = 14;
    this.setState({
      object: object,
    });
  }
  toggleChange = (e) => {
    let object = this.state.object;
    const {name} = e.target
    object[name] = e.target.checked;


    this.setState({
      [name]: e.target.checked,
      object: object,
    });

  }

  render() {
    if (this.state.object === undefined) {
      return (<div></div>);
    }

    let extraChannels = [];

    if (this.state.object.extraChannels !== undefined) {
      extraChannels = this.state.object.extraChannels.map((ec, i) => <ExtraChannel key={i} channel={ec} i={i}
                                                                                   onDelete={() => this.deleteExtraChannel(i)}
                                                                                   onChange={ec => this.updateExtraChannel(i, ec)}/>);
    }
 
    return (
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        extraButtons={<Button onClick={this.addExtraChannel}>Add extra channel</Button>}
      >
        <TextField
          id="name"
          label="Name"
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          helperText="A short name identifying the gateway-profile."
          required
          fullWidth
        />

      
        <TextField
          id="descriptionTop"
          label="Description"
          name="description"
          margin="normal"
          value={this.state.description || this.state.object.description}
          onChange={this.onChangeDescription}
          rows={1}
          multiline
          fullWidth
        />
  <FormLabel className={this.props.classes.formLabel} required>Reference Design</FormLabel>

    <RadioGroup
      name="design"
      onChange={this.toggleVersionChange}
      value={this.state.object.design || "v1.5"}
    >
      <FormControlLabel
        value="v1.5"
        control={<Radio color="primary" required={true}/>}
        label="v1.5"
      />
      <FormControlLabel
        value="v2.0"
        control={<Radio color="primary" required={true}/>}
        label="v2.0"
      />
    </RadioGroup>

        <FormGroup>
          <FormControlLabel
            control={<Checkbox
              onChange={this.toggleChange}
              name="gps"
              value={this.state.gps}
              color="primary"/>
            }
            label="Availability of GNSS"
            checked={!!this.state.object.gps || !!this.state.gps}
          />
          <FormControlLabel
            control={<Checkbox
              onChange={this.toggleChange}
              value={this.state.secure}
              name="secure"
              color="primary"/>
            }
            label="Connection type (secure/non-secure)"
            checked={!!this.state.object.secure || !!this.state.secure}
          />
        </FormGroup>
      
        { (  (this.state.downlinkTxPowerClicked||(this.state.object.downlinkTxPower!==undefined   && this.state.object.downlinkTxPower!=='' && this.state.object.downlinkTxPower!==null && this.state.object.downlinkTxPower>-1 ) )?<TextField
            id="downlinkTxPower"
            label="Downlink TX power (dBm)* If -1 - default value will be in use"
            InputProps={{ inputProps: { min: -1 } }}
            margin="normal"
            type="number"
            value={this.state.object.downlinkTxPower  }
            onBlur={(e)=>{
        
                if(this.state.object.downlinkTxPower===undefined||isNaN( this.state.object.downlinkTxPower)|| this.state.object.downlinkTxPower==='' || this.state.object.downlinkTxPower===-1 ){
                      this.setState({downlinkTxPowerClicked:false})
                }
            }}
            onChange={(e)=>{ 
             
                this.onChange(e)
              }}
            fullWidth
            required
        /> :<TextField
        id="downlinkTxPower"
        label="Downlink TX power (dBm)* If -1 - default value will be in use"
        margin="normal"
        type="text"
        value={"Default" }
        onMouseUp={(e)=>{
          this.setState({downlinkTxPowerClicked:true})
        }}
        onClick={(e)=>{
          this.setState({downlinkTxPowerClicked:true})
        }}
        fullWidth
        required
    /> ) }

      {/* {this.state.object.downlinkTxPower>-1?<TextField
          id="downlinkTxPower"
          label="Downlink TX power (dBm)* If -1 - default value will be in use"
          margin="normal"
          type="number"
          value={this.state.object.downlinkTxPower}
          onChange={this.onChange}
          InputProps={{ inputProps: { min: -1 } }}
          fullWidth
          required
        />:<TextField
        id="downlinkTxPower"
        label="Downlink TX power (dBm)* If -1 - default value will be in use"
        margin="normal"
        type="text"
        disabled
        onClick={this.setDefaultFreq}
        value={"Default"}
        fullWidth
      />} */}
        <TextField
          id="channelsStr"
          label="Enabled channels"
          margin="normal"
          value={this.state.object.channelsStr || ""}
          onChange={this.onChange}
          helperText="The channels active in this gateway-profile as specified in the LoRaWAN Regional Parameters specification. Separate channels by comma, e.g. 0, 1, 2. Extra channels must not be included in this list."
          placeholder="0, 1, 2"
          inputProps={{
            pattern: "[0-9]+(,[\\s]*[0-9]+)*",
          }}
          required
          fullWidth
        />
        {!this.props.update && <FormControlOrig margin="normal" fullWidth>
          <FormLabel className={this.props.classes.formLabel} required>Frequency plan</FormLabel>
          <AutocompleteSelect
            id="networkServerID"
            label="Select frequency plan"
            value={this.state.object.networkServerID || ""}
            onChange={this.onChange}
            getOption={this.getNetworkServerOption}
            getOptions={this.getNetworkServerOptions}
          />
        </FormControlOrig>}
        {extraChannels}
      </Form>
    );
  }
}

export default withStyles(styles)(GatewayProfileForm);

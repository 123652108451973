import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import Plus from "mdi-material-ui/Plus";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import OrganizationStore from "../../stores/OrganizationStore";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { withStyles } from "@material-ui/core/styles";
import { Box, IconButton, InputAdornment, TextField } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { handleSearchChange,clearSearch} from "../../classes/Helper";
import SessionStore from "../../stores/SessionStore";
const styles ={
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  grid: {
    display:'flex',
    alignItems:'center',
    flexWrap:'wrap',
  },
  boxDefault: {
    width: '270px',
  },
};

class ListOrganizations extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);
    this.handleSearchChange = handleSearchChange.bind(this);
    this.clearSearch = clearSearch.bind(this);

    this.state = {
      orderBy: null,
      order: null,
      search: null,
    }
    this.TableRef = React.createRef()
  }

  

  changeSort(event) {
    let state = {
        orderBy:event.currentTarget.dataset.key,
        order:this.state.order
    }

    if(state.orderBy!==this.state.orderBy){
        state.order = null;
    }

    if(state.order === null){
        state.order = 'asc'
    }else if(state.order === 'asc'){
        state.order = 'desc'
    }else if(state.order === 'desc'){
        state.order = null
        state.orderBy = null
    }

    this.setState(state);
  }

  makeSortLabel(keyName,viewName){
  return  <TableSortLabel
        data-key={keyName}
        active={this.state.orderBy === keyName}
        direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
        onClick={this.changeSort}
    >
      {viewName}
        {this.state.orderBy === keyName ? (
            <span className={this.props.classes.visuallyHidden}>
                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
        ) : null}
    </TableSortLabel>
  }

  makeCellSortProps(keyName) {
    return {
      key: keyName,
      align: 'left',
      padding: 'normal',
      sortDirection: this.state.orderBy === keyName ? this.state.order : false,
    }
  }
  getPage(limit, offset, callbackFunc) {
    if(this.timeOut) {
      clearTimeout(this.timeOut)
    }
    this.timeOut =  setTimeout(()=>{
          OrganizationStore.list(this.state.search, limit, offset,(obj)=>{  callbackFunc(obj)} , this.state.orderBy, this.state.order);

    }, 400)
  }

  getRow(obj) {
    let icon = null;

    if (obj.canHaveGateways) {
      icon = <Check />;
    } else {
      icon = <Close />;
    }

    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCellLink to={`/organizations/${obj.id}`}>{obj.name}</TableCellLink>
        <TableCell>{obj.displayName}</TableCell>
        <TableCell>{obj.deviceCount}</TableCell>
        <TableCell>{obj.gatewayCount}</TableCell>
        <TableCell>{icon}</TableCell>
      </TableRow>
    );
  }

  render() {

    let titleParams = (SessionStore.isAdmin())? {
      buttons:[
        <TitleBarButton
        label="Create"
        icon={<Plus />}
        to={`/organizations/create`}
      />,
        ]
    }:[]

    return(
      <Grid container spacing={4}>
        <TitleBar
         {...titleParams}
        >
          <TitleBarTitle title="Organizations" />
        </TitleBar>

        <Grid item xs={12} style={styles.grid}>
          <Box component="div" mr={2}>
            <TextField
              className={this.props.classes.boxDefault}
              label={"Search"}
              id="Search"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={this.handleSearchChange}
              value={this.state.search || ""}
              InputProps={{
                endAdornment:
                this.state.search && <InputAdornment position="end">
                     <IconButton
                      onClick={this.clearSearch}
                      style={{padding: "0"}}
                      color="default"
                      component="span"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>,
              }}
              variant="outlined"
              size="small"
            />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <DataTable
          orderBy={this.state.orderBy}
           ref={this.TableRef}
            header={
              <TableRow>
                <TableCell {...this.makeCellSortProps('name')}>{this.makeSortLabel('name','Name')}</TableCell>
                <TableCell {...this.makeCellSortProps('displayName')}>{this.makeSortLabel('displayName','Display name')}</TableCell>
                <TableCell {...this.makeCellSortProps('dev_count')}>{this.makeSortLabel('dev_count','Devices')}</TableCell>
                <TableCell {...this.makeCellSortProps('gw_count')}>{this.makeSortLabel('gw_count','Gateways')}</TableCell>
                <TableCell {...this.makeCellSortProps('canHaveGateways')}>{this.makeSortLabel('canHaveGateways','Can have gateways')}</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListOrganizations);

import React, { Component } from "react";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


class AutocompleteSelect extends Component {
  constructor() {
    super();

    this.state = {
      options: [],
      setSelectedOption: null,
    };
  }

  componentDidMount() {
    this.setInitialOptions(this.setSelectedOption);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value === this.props.value && prevProps.triggerReload === this.props.triggerReload) {
      return;
    }

    this.setInitialOptions(this.setSelectedOption);
  }

  setInitialOptions = (callbackFunc) => {
    this.props.getOptions("", (options, totalCount) => {
      const total = this.props.extraOptionsCount ? (+totalCount + this.props.extraOptionsCount) : totalCount;
      this.setState({
        options:(totalCount>0)?[{label: `Showing ${options.length} of ${total}`, value: "__DISABLED__"},...options] : options,
        totalCount: totalCount,
      }, callbackFunc);
    });
  }

  setSelectedOption = () => {
    if (this.props.getOption !== undefined) {
      if (this.props.value !== undefined && this.props.value !== "" && this.props.value !== null) {
        this.props.getOption(this.props.value, resp => {
          this.setState({
            selectedOption: resp,
          });
        });
      } else {
        this.setState({
          selectedOption: "",
        });
      }
    } else {
      if (this.props.value !== undefined && this.props.value !== "" && this.props.value !== null) {
        for (const opt of this.state.options) {
          if (this.props.value === opt.value) {
            this.setState({
              selectedOption: opt,
            });
          }
        }
      } else {
        this.setState({
          selectedOption: "",
        });
      }
    }
  }

  getAutocompleteOption(option) {
    if (!option) {
      return '';
    }
    if (this.props.availableDeviceCount) {
      return `${option.label} (${option.availableDevicesCount})` || '';
    } else {
      return option.label || '';
    }

  }

  render() {

    return(
      <Autocomplete
        id={this.props.id}
        openOnFocus={this.props.openOnFocus || true}
        options={this.state.options}
        getOptionLabel={(option) => this.getAutocompleteOption(option)}
        onOpen={() => this.setInitialOptions(null)}
        value={this.state.selectedOption || ""}
        onChange={this.onChange}
        getOptionSelected={this.props.getOptionSelected}
        onInputChange={this.onInputChange}
        className={this.props.className}
        getOptionDisabled={t => (t.value === "__DISABLED__") || (t.availableDevicesCount === 0)}
        renderInput={(params) => <TextField error={this.props.error} helperText={this.props.errorHelperText} required={!!this.props.required} placeholder={this.props.label} {...params} /> }
        disableClearable={!this.props.clearable}
        disabled={this.props.disabled}
        classes={this.props.classes}
      />
    );
  }

  onChange = (e, v, r) => {
    let value = null;
    if (v !== null) {
      value = v.value;
    }

    this.setState({
      selectedOption: v,
    });

    this.props.onChange({
      target: {
        id: this.props.id,
        value: value,
      },
    });
  }

  onInputChange = (e, v, r) => {
    this.props.getOptions(v, (options, totalCount) => {
      this.setState({
        options: options,
        totalCount: undefined,
      });
    });
  }
}

export default AutocompleteSelect;

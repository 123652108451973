import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import theme from "../theme";

const styles = {
  footer: {
    paddingBottom: theme.spacing(1),
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
};

class Footer extends Component {
  
  render() {
    if (this.props.footer === null) {
      return(null);
    }

    return(
      <footer className={this.props.classes.footer}>
        <Typography align="center" dangerouslySetInnerHTML={{__html: this.props.footer}}></Typography>
      </footer>
    );
  }
}

export default withStyles(styles)(Footer);

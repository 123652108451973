import React, {Component} from "react";
import {withRouter} from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import HandyRustyStore from '../../stores/HandyRustyStore'
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {CircularProgress, FormControlLabel, Switch} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import dispatcher from "../../dispatcher";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from "@material-ui/core/IconButton";
import Popover from '@material-ui/core/Popover';
import Download from "mdi-material-ui/Download";
import GatewayStore from "../../stores/GatewayStore";
import DataStreamStore from "../../stores/DataStreamStore";
import moment from "moment";
import InfoIcon from '@material-ui/icons/Info';

const FileDownload = require('downloadjs');

let styles = {
  list: {
    listStyleType: 'none'
  },
  listEl: {
    marginBottom: '20px'
  },
  propgress: {
    position: 'absolute'
  },
  msgReboot: {
    marginLeft: '40px',
  },
  msgReverseSSH: {
    marginLeft: '40px',
  },
  buttons: {
    width: '250px'
  },
  border: {
    border: '1px solid black'
  },
  popoverContent: {
    padding: '10px'
  }
}

class Administration extends Component {
  constructor() {
    super();
    this.changeRadioSwitch = this.changeRadioSwitch.bind(this);
    this.execCmd = this.execCmd.bind(this);
    this.restartRadioButton = this.restartRadioButton.bind(this);
    this.startLoad = this.startLoad.bind(this);
    this.finishLoad = this.finishLoad.bind(this);
    this.reboot = this.reboot.bind(this);
    this.execScript = this.execScript.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);
    this.onTXTData = this.onTXTData.bind(this);
    this.onTXTSocketClose = this.onTXTSocketClose.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.showErrorInfo = this.showErrorInfo.bind(this);
    this.handleOkCloseScanTheRadioDialog = this.handleOkCloseScanTheRadioDialog.bind(this);

    this.state = {
      checkedRadio: false,
      progressBar: {
        reload: false,
        radio: false,
        radioSwitch: false,
        reverseSSH: false,
        radioScan: false,
        getLogs: false,
      },
      disabling: {
        reload: true,
        radio: true,
        radioSwitch: true,
        reverseSSH: true,
        radioScan: true,
        getLogs: true,
      },
      reverseSSHResponce: null,
      reverseSSHResponceErr: null,
      
      tableRadio:[],
      exportLoading: false,
      exportFilename: null,
      exportValue: '',
      listOfExportValues: [],
      organization: 'all',
      txtData : '',
      exportTotal : 0,
      exportPerc: 0,
      dialogRefresh: false,
      dialogErrorInfo: false,
      showDialogLogs: null,
      showScanTheRadioResponce: null,
      showScanTheRadioResponceErr: null,
      popoverAnchorEl: null
    }
  }

  componentDidMount() {
    this.setState({
      checkedRadio: (this.props.gateway && this.props.gateway.metadata && this.props.gateway.metadata.radio === 'Running')
    });

    if (this.props.rebooting === false) {
      this.status();
    }

    GatewayStore.getTasksResults(this.props.gateway.id, resp => {
      this.setState({
        tableRadio: resp.results,
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.gateway.active === false && this.props.gateway.active === true) {
      this.finishLoad();
    }

    if (prevProps.rebooting === true && this.props.rebooting === false) {
      let state = this.state
      state.progressBar.reload = false;
      this.setState(state);
    }
  }

  execCmd(cmd, cb) {
    const params = {
      "command": cmd,
      "gatewayId": this.props.gateway.id,
    }
    if (cmd === "spectral_scan") {
      params.backgroundTask = true;
      params.timeout = 600;
    } if (cmd === "get_logs") {
      params.backgroundTask = true;
      params.timeout = 60;
    }
    if(cmd==="ping"){
      params.timeout = 5;
    }
    HandyRustyStore.execCommand(params, cb)
  }

  status() {
    this.execCmd('ping', (resp) => {
      if (resp.status === 200) {
        this.finishLoad();
      } else {
        HandyRustyStore.notify('the gateway is unreachable')
      }
    })
  }

  startLoad(type = null) {
    let state = this.state;
    state.disabling.radioSwitch = true
    state.disabling.reload = true
    state.disabling.radio = true
    state.disabling.radioScan = true
    state.disabling.getLogs = true
    if (type !== 'spectral_scan') {
      state.disabling.reverseSSH = true
    }
    this.setState(state)
  }

  finishLoad() {
    let state = this.state

    state.progressBar.radioSwitch = false
    state.progressBar.reload = false
    state.progressBar.radio = false
    state.progressBar.reverseSSH = false
    state.progressBar.radioScan = false
    state.progressBar.getLogs = false

    state.disabling.radioSwitch = false
    state.disabling.reload = false
    state.disabling.radio = false
    state.disabling.reverseSSH = false
    state.disabling.radioScan = false
    state.disabling.getLogs = false

    this.setState(state)
  }

  changeRadioSwitch(e) {
    this.startLoad();

    let state = this.state
    state.checkedRadio = e.target.checked;
    state.progressBar.radioSwitch = true;

    this.setState(state);

    const cb = () => {
      this.finishLoad()
    }
    if (e.target.checked) {
      this.execCmd('radio_start', cb)
    } else {
      this.execCmd('radio_stop', cb)
    }
  }

  reboot() {
    this.startLoad();

    let state = this.state;
    state.progressBar.reload = true;

    this.setState(state, () => {
      this.execCmd('reboot', () => {
        this.props.setRebooting();
        HandyRustyStore.notify('the gateway is rebooting ...')
      })
    })
  }

  restartRadioButton() {
    this.startLoad();

    let state = this.state;
    state.progressBar.radio = true

    this.setState(state, () => {
      this.execCmd('radio_restart', () => {
        this.finishLoad();
      })
    })
  }

  execScript(type) {
    this.startLoad(type === 'spectral_scan' ? 'spectral_scan' : null);
    let state = this.state;

    if (type === "reverse_ssh") {
      state.progressBar.reverseSSH = true;
      this.setState(state, () => {
        this.execCmd('reverse_ssh', resp => {
          if (resp.body.error) {
            dispatcher.dispatch({
              type: "CREATE_NOTIFICATION",
              notification: {
                type: "error",
                message: resp.body.error,
              },
            });
          } else if (resp.body.stdout || resp.body.stderr) {
            this.setState({
              reverseSSHResponce: resp.body.stdout,
              reverseSSHResponceErr: resp.body.stderr,
            })
          }
          this.finishLoad();
        })
      })
    }
    if (type === "spectral_scan") {

      state.showScanTheRadioResponce = true
      state.showScanTheRadioResponceErr = ""
      state.progressBar.radioScan = true

      this.setState(state)
    } if (type === "get_logs") {
      state.progressBar.getLogs = true

      this.setState(state, () => {
        this.execCmd('get_logs', resp => {
          if (resp.body.error) {
            dispatcher.dispatch({
              type: "CREATE_NOTIFICATION",
              notification: {
                type: "error",
                message: resp.body.error,
              },
            });
          } else {
            this.setState({
              showDialogLogs: true,
              showGetLogsResponceErr: resp.body.error,
            });
            setTimeout(() => {
              this.refreshData();
            }, 1500)
          }
          this.finishLoad();
        })
      })
    }
  }

  handleCloseDialog(e) {
   
    this.setState({
      reverseSSHResponce: null,
      reverseSSHResponceErr: null,
      showScanTheRadioResponce: false,
      showScanTheRadioResponceErr: null,
      dialogRefresh: false,
      dialogErrorInfo: false,
      showDialogLogs: false,
      showGetLogsResponceErr: null,
    });
  }

  handleOkCloseScanTheRadioDialog(){

    this.execCmd('spectral_scan', resp => {
      if (resp.body.error) {
        dispatcher.dispatch({
          type: "CREATE_NOTIFICATION",
          notification: {
            type: "error",
            message: resp.body.error,
          },
        });
      } else {
        this.setState({
          showScanTheRadioResponce: false,
          showScanTheRadioResponceErr: null,
        });
        setTimeout(() => {
          this.refreshData();
        }, 1500)
      }
      this.finishLoad();
    })
    this.setState({
      showScanTheRadioResponce: false,
      showScanTheRadioResponceErr: null,
    },()=>{

    });
  }
  refreshData = () => {
    let state = this.state;
    state.dialogRefresh = true;
    this.setState(state);

    GatewayStore.getTasksResults(this.props.gateway.id, resp => {
      setTimeout(() => {
        this.setState({
          tableRadio: resp.results,
          dialogRefresh: false,
        });
      }, 1000);
    });
  }

  showErrorInfo(e) {
    this.setState({
      dialogErrorInfo: true,
      popoverAnchorEl: e.currentTarget,
    });
  }

  notify(msg,type="success") {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: type,
        message: msg,
      },
    });
  }

  exportFile(id, name = null) {
    this.setState({exportLoading: true, exportFilename: name});
    DataStreamStore.getTasksResult(id, this.onTXTData,  this.onTXTSocketClose, () => {this.setState({exportLoading: false});});
  }

  onTXTData(d) {
    let currentPercentage = 0;
    if (d.total) {
      this.setState({
        exportTotal: d.total,
      })
    }

    if (d.chunk != null) {
      let data = this.state.txtData;
      data = data+atob(d.chunk);

      if (this.state.exportTotal !== 0) {
        currentPercentage = Math.round(d.current * 100 / this.state.exportTotal);
      }

      this.setState({
        txtData: data,
        exportPerc: currentPercentage,
      });
    }
  }

  onTXTSocketClose(cb, filter) {

    //let selectedObject = this.state.tableRadio.find((el) => el.id === filter)

    //let time = moment().format('DDMMYYYY_HHmm');

    if (this.state.txtData === '') {
      this.notify("Error: No data available!", "error");
      this.setState({
        exportFilename: null
      });
      cb();
      return;
    }
    FileDownload(this.state.txtData, this.state.exportFilename);
    this.notify("All the data downloaded!");
    this.setState({
      txtData: '',
      exportPerc: 0,
      exportFilename: null,
    }, () => {
      cb();
    });
  }

  getCommandName = (name) => {

    switch (name) {
      case "get_logs":
        return "Logs";
      case "spectral_scan":
        return "Spectral scan";
      default:
        return name.replace('_', ' ') || '';
    }
  }

  render() {
    const {tableRadio} = this.state
    const existErrors = tableRadio.some(row => (row.error !== '') || (row.stderr !== null));

    const radioTable = tableRadio.map(({cmd, id, stdoutLen, updatedAt, error, stderr, name}) => {
      let updatedAtTime = '';
      if (updatedAt) {
        updatedAtTime = moment(updatedAt).format("YYYY-MM-DD HH:mm:ss");
      }

      return(
        <Grid container={true} key={id}>
          <Grid item xs={existErrors ? 3 : 4}>
            <Typography
              gutterBottom
              variant="subtitle1"
              align="center"
            >
              {this.getCommandName(cmd)}
            </Typography>
          </Grid>
          <Grid item xs={existErrors ? 3 : 4}>
            <Typography
              gutterBottom
              align="center"
            >
              {updatedAtTime}
            </Typography>
          </Grid>
          <Grid item xs={existErrors ? 3 : 4}>
            <Typography
              gutterBottom
              align="center"
            >
              <IconButton
                variant="outlined"
                className={this.props.classes.button}
                onClick={()=>this.exportFile(id, name)}
                disabled={stdoutLen === 0}
              >
                <Download className={this.props.classes.icon}/>
              </IconButton>
            </Typography>
          </Grid>
          {existErrors && <Grid item xs={3}>
            <Typography
              gutterBottom
              align="center"
            >
              {(error !== '' || stderr !== null) &&
              <IconButton
                variant="outlined"
                className={this.props.classes.button}
                onClick={this.showErrorInfo}
              >
                <InfoIcon className={this.props.classes.icon}/>
              </IconButton>}
              <Popover
                id={`popover-${id}`}
                open={this.state.dialogErrorInfo}
                anchorEl={this.state.popoverAnchorEl}
                onClose={this.handleCloseDialog}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography className={this.props.classes.popoverContent}>
                  <div>error: {error}</div>
                  <div>stderr: {stderr}</div>
                </Typography>
              </Popover>
            </Typography>
          </Grid>}
        </Grid>
      )
    })

    return (
      <Grid container spacing={4}>
        <Grid item xl={4} md={6} xs={12}>
          <Card>
            <CardHeader
              title="Management"
              action={
                (this.state.progressBar.radioSwitch
                  || this.state.progressBar.radio
                  || this.state.progressBar.reload
                  || this.state.progressBar.reverseSSH
                  || this.state.progressBar.radioScan
                  || this.state.progressBar.getLogs) &&
              <CircularProgress size={20}/>}/>
            <CardContent>
              <ul className={this.props.classes.list}>
                <li className={this.props.classes.listEl}>
                  <Button className={this.props.classes.buttons} variant="contained" color="primary"
                          onClick={this.reboot} disabled={this.state.disabling.reload} disableElevation>
                    reboot gateway

                  </Button>
                  {this.props.rebooting &&
                  <span className={this.props.classes.msgReboot}>Gateway is rebooting...</span>}
                </li>
                <li className={this.props.classes.listEl}>
                  <Button className={this.props.classes.buttons} variant="contained" color="primary"
                          onClick={this.restartRadioButton} disabled={this.state.disabling.radio} disableElevation>
                    restart radio
                  </Button>
                </li>

                <li className={this.props.classes.listEl}>
                  <Button
                    className={this.props.classes.buttons}
                    variant="contained"
                    color="primary"
                    onClick={() => this.execScript("reverse_ssh")}
                    disabled={this.state.disabling.reverseSSH}
                    disableElevation
                  >
                    Reverse SSH
                  </Button>
                  {(this.state.reverseSSHResponce || this.state.reverseSSHResponceErr) &&
                  <Dialog onClose={this.handleCloseDialog}
                          open={this.state.reverseSSHResponce || this.state.reverseSSHResponceErr}>
                    <DialogContent>
                      {this.state.reverseSSHResponceErr ? `Error: ${atob(this.state.reverseSSHResponceErr)}` : atob(this.state.reverseSSHResponce)}
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={this.handleCloseDialog} color="primary">OK</Button>
                    </DialogActions>
                  </Dialog>
                  }
                </li>

                <li className={this.props.classes.listEl}>
                  <Button
                    className={this.props.classes.buttons}
                    variant="contained" color="primary"
                    onClick={() => this.execScript("spectral_scan")}
                    disabled={this.state.disabling.radioScan}
                    disableElevation
                  >
                    Scan the radio
                  </Button>

                  {this.state.showScanTheRadioResponceErr === "" && (<Dialog onClose={this.handleCloseDialog} open={this.state.showScanTheRadioResponce || this.state.showScanTheRadioResponceErr}>
                    <DialogContent>
                       Depending on the gateway reference design, LoRaWAN packets may NOT BE RECEIVED OR TRANSMITTED during the scanning process. Are you sure you want to run?
                      {/* Background job has been started. An answer will be in results soon.. */}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        autoFocus
                        onClick={this.handleOkCloseScanTheRadioDialog}
                        color="primary">
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>)}
                </li>

                <li className={this.props.classes.listEl}>
                  <Button className={this.props.classes.buttons}
                    variant="contained"
                    color="primary"
                    onClick={() => this.execScript("get_logs")}
                    disabled={this.state.disabling.getLogs}
                    disableElevation
                  >
                    Download logs
                  </Button>
                  {this.state.showGetLogsResponceErr === "" && (<Dialog onClose={this.handleCloseDialog} open={this.state.showDialogLogs}>
                    <DialogContent>
                      Background job has been started. An answer will be in results soon.
                    </DialogContent>
                    <DialogActions>
                      <Button
                        autoFocus
                        onClick={this.handleCloseDialog}
                        color="primary">
                        OK
                      </Button>
                    </DialogActions>
                  </Dialog>)}
                </li>

                <li className={this.props.classes.listEl}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={this.state.disabling.radioSwitch}
                        checked={this.state.checkedRadio}
                        onChange={this.changeRadioSwitch}
                        name="radio"
                        color="primary"
                      />
                    }
                    label={"Radio " + ((this.state.checkedRadio) ? 'on' : 'off')}
                  />
                </li>
              </ul>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={4} md={6} xs={12}>
          <Card>
            <CardContent>
              <Grid container={true}>
                <Grid container
                      alignItems="center"
                >
                  <Grid item md={12}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      alignItems="left"
                    >
                      Results
                      <IconButton
                        title={'refresh data'}
                        style={{marginRight: '10px'}}
                        size="small"
                        variant="text"
                        onClick={this.refreshData}
                      >
                        <RefreshIcon/>
                      </IconButton>
                      {this.state.dialogRefresh && <CircularProgress size={20}/>}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container={true}>
                  <Grid item xs={existErrors ? 3 : 4}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                    >
                      Command
                    </Typography>
                  </Grid>
                  <Grid item xs={existErrors ? 3 : 4}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                    >
                      Created at
                    </Typography>
                  </Grid>
                  <Grid item xs={existErrors ? 3 : 4}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                    >
                      Download
                    </Typography>
                  </Grid>
                  {existErrors &&<Grid item xs={3}>
                    <Typography
                      gutterBottom
                      variant="h6"
                      align="center"
                    >
                    </Typography>
                  </Grid>}
                </Grid>
                {radioTable}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(Administration));

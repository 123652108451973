import React, { Component } from "react";
import { matchPath } from "react-router";
import {Doughnut} from 'react-chartjs-2';
import InternalStore from "../../../stores/InternalStore";
import theme from "../../../theme";
import {Dictionary} from "../../../classes/Dictionary";
import Card from '@material-ui/core/Card';
import MLink from '@material-ui/core/Link';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from "@material-ui/core/styles";
import SessionStore from "../../../stores/SessionStore";

const styles = {
  
    doughtnutChart: {
      maxWidth: "350px",
      padding: 0,
      margin: "auto",
      display: "block",
    },  
    card:{
        height:'100%'
    }
  };
function orgMatch(){
  return matchPath(window.location.hash, {
    path: "#/organizations/:id",
    strict: false
  });
}
function makeCardTitle(  classCaller ,title){
  const match = orgMatch()
  if(match!==null){
    return <MLink href={`#/organizations/${match.params.id}/${classCaller.toLowerCase()}`}>{title}</MLink>  
  }else{
    return title
  }
}  
const backgroundColor = [
  'rgb(250,166,36)',//theme.palette.warning.main, //neverseen
  'rgb(255,85,0)',//theme.palette.error.main,  //inactive
 theme.palette.success.main, //active
]
class DevicesActiveInactive extends Component {
  constructor(){
    super();
    this.state = {
      summary:null
    }

    this.load = this.load.bind(this)
  }

  load(){
    const match = orgMatch()
    let id  = (match!==null&&match.params) ? match.params.id : 0
  InternalStore.getDevicesSummary(id, resp => {
      this.setState({
          summary:resp,
      });
    });  
  }
    componentDidMount(){
      this.load()

      SessionStore.on("organization.change", this.load);
    
    }
    componentWillUnmount(){
      SessionStore.removeListener("organization.change", this.load);
    }
    render() {
      let data = null;
  
      if (this.state.summary !== null && (this.state.summary.activeCount !== 0 || this.state.summary.inactiveCount !== 0 || this.state.summary.neverSeenCount !== 0 )) {
        data = {
          labels: ["Never seen ("+this.state.summary.neverSeenCount+')', "Inactive ("+this.state.summary.inactiveCount+')', "Active ("+this.state.summary.activeCount+')'],
          datasets: [
            {
              data: [this.state.summary.neverSeenCount, this.state.summary.inactiveCount, this.state.summary.activeCount],
              backgroundColor: backgroundColor ,
            },
          ],
        };
      }
  
      const options = {
        animation: false,
      };
      const classCaller = 'devices'
      const title =  "Devices: "+ ((this.state.summary)?this.state.summary.activeCount + this.state.summary.neverSeenCount + this.state.summary.inactiveCount  :'')
     
      return(
        <Card  className={this.props.classes.card}>
          <CardHeader title={makeCardTitle(classCaller, title )} />
          <CardContent>
            {data && <Doughnut data={data} options={options} className={this.props.classes.doughtnutChart} />}
            {!data && <div>No data available.</div>}
          </CardContent>
        </Card>
      );
    }
  }
  
  DevicesActiveInactive = withStyles(styles)(DevicesActiveInactive);
  
  
  class GatewaysActiveInactive extends Component {
    constructor(){
      super();
      this.state = {
        summary:null
      }
      this.load = this.load.bind(this)
    }
    load(){
      const match = orgMatch()
      let id  = (match!==null&&match.params) ? match.params.id : 0
    InternalStore.getGatewaysSummary(id, resp => {
        this.setState({
            summary: resp,
        });
      });
    }
    componentDidMount(){
      this.load();

      SessionStore.on("organization.change",  this.load);
    }
    componentWillUnmount(){
      SessionStore.removeListener("organization.change", this.load);
    }
    render() {
      let data = null;
  
      if (this.state.summary !== null && (this.state.summary.activeCount !== 0 || this.state.summary.inactiveCount !== 0  || this.state.summary.neverSeenCount !== 0 )) {
        data = {
          labels: ["Never seen ("+this.state.summary.neverSeenCount+')', "Inactive ("+this.state.summary.inactiveCount+')', "Active ("+this.state.summary.activeCount+')'],
          datasets: [
            {
              data: [this.state.summary.neverSeenCount, this.state.summary.inactiveCount, this.state.summary.activeCount],
              backgroundColor: backgroundColor,
            },
          ],
        };
      }
  
      const options = {
        animation: false,
      };
      const classCaller = 'gateways'
      const title = "Gateways: "+ ((this.state.summary)?this.state.summary.activeCount + this.state.summary.neverSeenCount + this.state.summary.inactiveCount:'')
      return(
        <Card className={this.props.classes.card}>
          <CardHeader title={makeCardTitle(classCaller,title)} />
          <CardContent>
            {data && <Doughnut data={data} options={options} className={this.props.classes.doughtnutChart} />}
            {!data && <div>No data available.</div>}
          </CardContent>
        </Card>
      );
    }
  }
  
  GatewaysActiveInactive = withStyles(styles)(GatewaysActiveInactive);
  
  
  class DevicesDataRates extends Component {
    constructor(){
      super();
      this.state = {
        summary:null
      }
      this.load = this.load.bind(this)
    }
    load(){
      const match = orgMatch()
      let id  = (match!==null&&match.params) ? match.params.id : 0
      InternalStore.getDevicesSummary(id, resp => {
          this.setState({
              summary:resp,
          });
        });  
    }
    componentDidMount(){
     this.load()
     SessionStore.on("organization.change",this.load);
    }

    componentWillUnmount(){
      SessionStore.removeListener("organization.change", this.load);
    }
    getColor = (dr) => {
      return ['rgb(255,85,0)', 'rgb(17,105,178)', 'rgb(91,155,212)', 'rgb(74,174,198)', 'rgb(188,214,237)',  '#4caf50', '#009688', '#00bcd4', '#03a9f4', '#2196f3', '#3f51b5', '#673ab7', '#9c27b0', '#e91e63'][dr];
    }
  
    render() {
  
      let dict = new Dictionary('data-rate-map');
  
      let data = null;
  
      if (this.state.summary !== null && Object.keys(this.state.summary.drCount).length !== 0) {
        data = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: [],
            },
          ],
        };
  
        for (let dr in this.state.summary.drCount) {
          data.labels.push(`SF${dict.map(dr)}`);
          data.datasets[0].data.push(this.state.summary.drCount[dr]);
          data.datasets[0].backgroundColor.push(this.getColor(dr));
        }
      }
      
      const options = {
        animation: false,
      };
  
      return(
        <Card className={this.props.classes.card}>
          <CardHeader title="Device SF usage" />
          <CardContent>
            {data && <Doughnut data={data} options={options} className={this.props.classes.doughtnutChart} />}
            {!data && <div>No data available.</div>}
          </CardContent>
        </Card>
      );
    }
  }
  
  DevicesDataRates = withStyles(styles)(DevicesDataRates);

  export {DevicesDataRates,GatewaysActiveInactive,DevicesActiveInactive}
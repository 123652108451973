import React, {Component} from "react";
import {withRouter} from "react-router-dom";

import Grid from '@material-ui/core/Grid';

import Delete from "mdi-material-ui/Delete";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import UpdateAsRoutingProfile from "./UpdateAsRoutingProfile";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {Card} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import RoutingProfileStore from "../../stores/RoutingProfileStore";
import SessionStore from "../../stores/SessionStore";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


class AsRoutingProfileLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialog:false,
      tab: 0,
    }

    this.deleteAsRoutingProfile = this.deleteAsRoutingProfile.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    RoutingProfileStore.get(this.props.match.params.id, resp => {
      this.setState({
        routingProfile: resp,
      });
    });
    SessionStore.on("change",()=>{
      this.setIsAdmin();
    }) 
    this.setIsAdmin();
  }

  UNSAFE_componentWillUpdate() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  deleteAsRoutingProfile() {
    if (window.confirm("Are you sure you want to delete this AS routing-profiles?")) {
      RoutingProfileStore.delete(this.props.match.params.id, resp => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/as-routing-profiles`);
      });
    }
  }

  onChangeTab = (e, v) => {
    this.setState({
      tab: v,
    });
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationDeviceAdmin(this.props.match.params.organizationID),
    });
  }

  render() {

    if (this.state.routingProfile === undefined) {
      return(<div></div>);
    }
    const {pathname} = this.props.location;
    const {match} = this.props;

    let buttons = [];
    if (this.state.admin) {
      buttons = [
        <TitleBarButton
          label="Delete"
          icon={<Delete/>}
          color="secondary"
          onClick={this.deleteAsRoutingProfile}
        />,
      ];
    }

    return (
      <Grid container spacing={4}>
        <TitleBar
          buttons={buttons}
        >
          <OrganizationBreadcrumps org={this.props.org}>
            <TitleBarTitle to={`/organizations/${this.props.match.params.organizationID}/as-routing-profiles/`}
                          title="AS routing profiles"/>
            <TitleBarTitle title="/"/>
            <TitleBarTitle title={this.state.routingProfile.routingProfile.name}/>
          </OrganizationBreadcrumps>
          
        </TitleBar>
        <Grid item xs={12}>
          <Card>
            <CardContent elevation={0}>
              <Tabs
                value={this.state.tab}
                onChange={this.onChangeTab}
                indicatorColor="primary"
              >
                <Tab label="General"/>
                {pathname && (<Tab label="Logs"/>)}
              </Tabs>
              <UpdateAsRoutingProfile
                routingProfile={this.state.routingProfile.routingProfile}
                pathname={pathname}
                match={match}
                tab={this.state.tab}
                admin={this.state.admin}
              />
              
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
};

export default withRouter (AsRoutingProfileLayout);

import dispatcher from "../dispatcher";
import moment from "moment";
import Papa from "papaparse";
let FileDownload = require('downloadjs');

function ConvertDLSettings(fromBit,toBit,param){
  if(param===undefined || fromBit===undefined || toBit===undefined) return ""
  return parseInt(hexToBinary(param).split('').reverse().slice(fromBit,toBit).reverse().join(''),2)
}
function CheckHex (val){

    if(val.length%2!==0){
        return false
    }else if(!val.match(/[A-Fa-f0-9]{2}/g)){
        return false
    }else if(val.length!==val.match(/[A-Fa-f0-9]{2}/g).join("").length){
        return false
    }else{
        return true
    }
}
class DateHelper{
  

    setMask(mask){
        this.mask = mask
        return this
    }
    parseDate(string){
        this.date = new Date(string);
        return this
    }
    setDate(dateObj){
        this.date = dateObj;
        return this;
    }
    getDate(){
        let d = this.date.getDate();
        if(d.toString().length===1){
            d= '0'+d
        }
        let m = this.date.getMonth()+1;
        if(m.toString().length===1){
            m= '0'+m
        }
        let y = this.date.getFullYear();

        let h = this.date.getHours()
        if(h.toString().length===1){
            h= '0'+h
        }
        let i = this.date.getMinutes()
        if(i.toString().length===1){
            i= '0'+i
        }
        let s = this.date.getSeconds();
        if(s.toString().length===1){
            s= '0'+s
        }
        let ms = this.date.getUTCMilliseconds();
        return  this.mask.replace('y', y).replace('m',m).replace('d',d).replace('h',h).replace('i',i).replace('s',s).replace('ms',ms)
    }
    getUTCDate(){
        let d = this.date.getUTCDate();
        if(d.toString().length===1){
            d= '0'+d
        }
        let m = this.date.getUTCMonth()+1;
        if(m.toString().length===1){
            m= '0'+m
        }
        let y = this.date.getUTCFullYear();

        let h = this.date.getUTCHours()
        if(h.toString().length===1){
            h= '0'+h
        }
        let i = this.date.getUTCMinutes()
        if(i.toString().length===1){
            i= '0'+i
        }
        let s = this.date.getUTCSeconds();
        if(s.toString().length===1){
            s= '0'+s
        }
        let ms = this.date.getUTCMilliseconds();
       return  this.mask.replace('y', y).replace('m',m).replace('d',d).replace('h',h).replace('i',i).replace('s',s).replace('ms',ms)

    }

    toIsoString () {
        var tzo = -this.date.getTimezoneOffset(),
            dif = tzo >= 0 ? '+' : '-',
            pad = function(num) {
                var norm = Math.floor(Math.abs(num));
                return (norm < 10 ? '0' : '') + norm;
            };
        return this.date.getFullYear() +
            '-' + pad(this.date.getMonth() + 1) +
            '-' + pad(this.date.getDate()) +
            'T' +( (this.date.getHours().toString().length===1)?('0'+this.date.getHours()):this.date.getHours()) +
            ':' + ( (this.date.getMinutes().toString().length===1)?('0'+this.date.getMinutes()):this.date.getMinutes())  +
            ':' + ( (this.date.getSeconds().toString().length===1)?('0'+this.date.getSeconds()):this.date.getSeconds()) +
            // ".000Z"
         dif + pad(tzo / 60) +
         ':' + pad(tzo % 60);
    }


}

function notify(msg,type='success') {
    dispatcher.dispatch({
        type: "CREATE_NOTIFICATION",
        notification: {
            type: type,
            message: msg,
        },
    });
}
function CopyHelper(event){
    let text = event.target.innerText
    const fallbackCopyTextToClipboard = (text)=> {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
             document.execCommand('copy');
           
          //  console.log('Fallback: Copying text command was ' + msg);
            notify('Copied to clipboard')
        } catch (err) {
          //  console.error('Fallback: Oops, unable to copy', err);
            notify('Copy error' ,'error')
        }

        document.body.removeChild(textArea);
    }

    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function() {
        //console.log('Async: Copying to clipboard was successful!');
        notify('Copied to clipboard')

    }, function(err) {
       // console.error('Async: Could not copy text: ', err);
        notify('Copy error' ,'error')

    });
}

function CheckLastDuration(arr){



    var last = moment(arr[arr.length-1].createdAt*1000);
    var now = moment();

    if(now.diff(last,'hours') > 3){
        let lastEl = arr[arr.length-1];
        lastEl.createdAt = now.valueOf()/1000
        arr.push(lastEl)
    }
    return arr
}
function base64ToHex(str) {
    const raw = atob(str);
    let result = '';
    for (let i = 0; i < raw.length; i++) {
        const hex = raw.charCodeAt(i).toString(16);
        result += (hex.length === 2 ? hex : '0' + hex);
    }
    return result.toUpperCase();
}

function TableRowToggleEvent(ev,funcTrue,funcFalse) {
    let rowClassname = '.'+ev.currentTarget.id;
    let iconSelector = '#'+ev.currentTarget.id+' .fa';
    let plusIconClass = 'fa-plus-circle';
    let minusIconClass = 'fa-minus-circle';

    let rowEl = document.querySelector(rowClassname);
    let iconEl = document.querySelector(iconSelector);

    if(!rowEl||!iconEl){
        return;
    }

    if(rowEl.style.display==='none'){
        rowEl.style.display='table-row';

        iconEl.className = iconEl.className.replace(plusIconClass,minusIconClass)

        if(funcTrue!==undefined && funcTrue!==null){
            funcTrue()
        }
    }else{
        rowEl.style.display='none';

        iconEl.className = iconEl.className.replace(minusIconClass, plusIconClass)

        if(funcFalse!==undefined && funcFalse!==null){
            funcFalse()
        }
    }
}

function ExpandRows (expand,cb){
    let elems = document.querySelectorAll('#extended');
    if(elems.length===0){
        if(cb){cb()}
    }else{
        elems.forEach((el)=>{
            let icon = el.previousElementSibling.firstElementChild.firstElementChild
            let display = ['none','table-row']
            let replace = ['fa-minus-circle','fa-plus-circle']
            if(expand){
                replace.reverse()
                display.reverse()
            }

            icon.className = icon.className.replace(...replace);
            el.style.display=display[0]
        });
        if(cb){cb()}
    }
}

const S4 = () => ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
const getUuid = () =>
  `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`.toLowerCase();

function checkGps(cord){
    let str = cord.toString();

    let splitted = str.split('.');

    if (!splitted[1]) {
      splitted[1] = new Array(6).fill('0').join('');
    } else if (splitted[1] && splitted[1].length === 6) {
      return cord;
    } else if (splitted[1] && splitted[1].length < 6) {
      let zeroes = 6 - splitted[1].length;
      splitted[1] = splitted[1] + new Array(zeroes).fill('0').join('');
    } else {
      splitted[1] = splitted[1].substring(0,6);
    }

    return splitted.join('.')
}

export function ExportDevicesToCSV(data) {
   return new Promise((resolve, reject) => {
      const csv = Papa.unparse(
        data.map((item) => {
          const { 
            devEUI, 
            name, 
            deviceProfileName, 
            serviceProfileName, 
            routingProfileName, 
            lastSeenAt, 
            isDisabled, 
            deviceStatusBatteryLevel, 
            deviceStatusExternalPowerSource,
            deviceStatusBatteryLevelUnavailable
        } = item
  
          return {
            "Device name": name,
            "Device EUI" : devEUI,
            "Device profile": deviceProfileName,
            "AS routing profile": routingProfileName,
            "Service profile": serviceProfileName,
            "Last seen": lastSeenAt && moment(lastSeenAt).format('YYYY-MM-DD HH:mm:ss.SSS ZZ'),
            "Battery": deviceStatusBatteryLevelUnavailable ? 'N\\A' : deviceStatusExternalPowerSource ? "EXT" : deviceStatusBatteryLevel,
            "Enable": !isDisabled,
          }
        })
      )
  
      const fileName = `Devices_${moment().format("DD-MM-YY--h-mm-ss")}.csv`
      FileDownload(csv, fileName, "text/csv")
      resolve()
    })
  }
  
  
  
function ExportJSONtoCSV(params){


    let dataSet = params.dataset;
    let timeColumn = ''

    let json = dataSet.map((item)=>{
       
        let obj = {}

        for (let i in params.columns){
            if (params.columns[i].csv==='skip') continue;

            let val = item[params.columns[i].objName]
           
            obj[params.columns[i].csv] = val

            if ( params.parse && params.columns[i].time){
                timeColumn = params.columns[i]
                if(params.parse.multiplier){
                    obj[params.columns[i].csv]  *= params.parse.multiplier
                }
                if(params.parse.utc){
                    obj[params.columns[i].csv]  = moment.utc( val ).format(params.parse.format)
                }else{
                    obj[params.columns[i].csv]  = moment( val ).format(params.parse.format)
                }
            }

            if (params.parse&&params.parse.int && !params.columns[i].time){
                obj[params.columns[i].csv]  =  parseInt(val)
            }


        }

        return obj
    })

    if(params.reverse===true){
           json.reverse()
    }

    let csv = Papa.unparse(json);
    //console.log(csv,json);
    FileDownload(
        csv,
        params.filename + ' '
    + json[0][timeColumn.csv]+' - '
    + json[json.length-1][timeColumn.csv]
    +'.csv','text/csv' );

}



var hexToBinarylookup = {
    '0': '0000',
    '1': '0001',
    '2': '0010',
    '3': '0011',
    '4': '0100',
    '5': '0101',
    '6': '0110',
    '7': '0111',
    '8': '1000',
    '9': '1001',
    'a': '1010',
    'b': '1011',
    'c': '1100',
    'd': '1101',
    'e': '1110',
    'f': '1111',
    'A': '1010',
    'B': '1011',
    'C': '1100',
    'D': '1101',
    'E': '1110',
    'F': '1111'
  };
function hexToBinary(s) {

    var ret = '';
    for (var i = 0, len = s.length; i < len; i++) {
        ret += hexToBinarylookup[s[i]];
    }
    return ret;
}
function GetOptionSelected(option,value){
    return  (value.value)?option.value===value.value:true
}
 function GetFromQueryString(arr,nullFlag){
    let query_string = window.location.hash.split('?')[1]

    if(!query_string||!arr||arr.length===0){
            return {}
    }

    let obj = {};
    let searchParams = new URLSearchParams(query_string);

    for (let i=0; i<arr.length;i++){
        let name = arr[i];

        if (searchParams.has(name)){
            obj[name] = searchParams.get(name)
        }else if (nullFlag){
            obj[name] = null
        }
    }

    return obj
 }



 function handleSearchChange(event) {
    let val = event.target.value;
    this.setState({search: val.trim()},()=>{
        clearTimeout( this.searchTimeOut )
        this.searchTimeOut = setTimeout(this.TableRef.current.updateTable, 150);
    });
  }

  function clearSearch() {
    this.setState({search: null},()=>{
        clearTimeout( this.searchTimeOut )
        this.searchTimeOut = setTimeout(this.TableRef.current.updateTable, 5);
    });
  }

  function decodeFromHex(messageToDecode,parseArr) {
    var data = {
        latitude: null,
        longitude: null,       
        RSSI: null,
        SNR: null,
        DlCount: null,
        UlCount: null,
      };
      const bytes = Buffer.from(messageToDecode, 'hex');
      var presenceBits = bytes[0];
      var hasRssiSnr = (presenceBits & 0x01)  && (parseArr.indexOf('snr')>-1 && parseArr.indexOf('rssi')>-1);
      var hasBattery = (presenceBits & 0x02) && parseArr.indexOf('bat')>-1;
      var hasDownlinkCount = (presenceBits & 0x04) &&parseArr.indexOf('dLCount')>-1
      var hasUplinkCount = (presenceBits & 0x08) &&parseArr.indexOf('uLCount')>-1
      var hasNavigation = (presenceBits & 0x10 )&&parseArr.indexOf('nav')>-1;
     // var hasButton = presenceBits & 0x20;
    //  var hasTemperature = presenceBits & 0x80;
    
      if (hasNavigation) {
        // Извлечение latitude data
        var latitudeBytes = bytes.slice(1, 4);
        var latitude = (
          ((latitudeBytes[0] >> 4) & 0x0F) * 10 +
          (latitudeBytes[0] & 0x0F) +
          ((latitudeBytes[1] >> 4) & 0x0F) / 6 +
          (latitudeBytes[1] & 0x0F) / 60 +
          ((latitudeBytes[2] >> 4) & 0x0F) / 600 +
          ((latitudeBytes[2] & 0x0F) / 6000) +
          ((latitudeBytes[3] >> 4) & 0x0F) / 60000 +
          ((latitudeBytes[3] & 0x0F) / 600000) +
          ((latitudeBytes[4] >> 4) & 0x0F) / 6000000
        );
        if ((latitudeBytes[4] & 0x01) === 1) {
          latitude *= -1;
        }
        data.latitude = latitude.toFixed(5);
    
        // Извлечение longitude data
        var longitudeBytes = bytes.slice(5, 9);
        var longitude = (
          ((longitudeBytes[0] >> 4) & 0x0F) * 100 +
          (longitudeBytes[0] & 0x0F) * 10 +
          ((longitudeBytes[1] >> 4) & 0x0F) +
          (longitudeBytes[1] & 0x0F) / 6 +
          ((longitudeBytes[2] >> 4) & 0x0F) / 60 +
          (longitudeBytes[2] & 0x0F) / 600 +
          ((longitudeBytes[3] >> 4) & 0x0F) / 6000 +
          ((longitudeBytes[3] & 0x0F) / 60000) +
          ((longitudeBytes[4] >> 4) & 0x0F) / 600000
        );
        if ((longitudeBytes[4] & 0x10) === 0) {
          longitude *= 1;
        }
        data.longitude = longitude.toFixed(5);
      }
    
      // Extract RSSI and SNR 
      if (hasRssiSnr) {
        data.RSSI = - bytes[13];
        data.SNR = bytes[14];
      }
    
      // Извлечение downlink and uplink count
      if (hasDownlinkCount) {
        data.DlCount = bytes[10];
      }
    
      if (hasUplinkCount) {
        data.UlCount = bytes[9];
  
        // Extract battery voltage data
      if (hasBattery) {
        var voltageBytes = bytes.slice(11, 13);  // Извлечение 11-го и 12-го байтов
        var voltage = (voltageBytes[0] << 8) + voltageBytes[1];  // Объединение байтов
        var batteryLevel = voltage / 1000;  // Расчет уровня заряда
        data.batteryLevel = batteryLevel.toFixed(2) + ' B';
  }
  
    
    }
  
        // Извлечение RSSI and SNR если нет GPS
        if ((presenceBits & 0x10 ) === 0) {
          // отсутствуют навигационные данные
          
          if (hasRssiSnr) {
            data.RSSI = - bytes[5];
            data.SNR = bytes[6];
            
          }
        
          // Извлечение downlink and uplink count
          if (hasDownlinkCount) {
            data.DlCount = bytes[2];
          }
        
          if (hasUplinkCount) {
            data.UlCount = bytes[1];
        
        }
          // Извлечение данных о напряжении аккумулятора
          if (hasBattery) {
          //  var voltageBytes = bytes.slice(3, 5);  // Извлечение 3-го и 4-го байта
          //  var voltage = (voltageBytes[0] << 8) + voltageBytes[1];  // Объединение байтов
         //   var batteryLevel = voltage / 1000;  // Расчет уровня заряда
            data.batteryLevel = batteryLevel.toFixed(2) + ' В';
  
  }
  
        }
        
       
    return data;
  }




export {
    GetOptionSelected,
    GetFromQueryString,
    DateHelper,
    CopyHelper,
    CheckLastDuration,
    base64ToHex,
    TableRowToggleEvent,
    ExpandRows,
    getUuid,
    checkGps,
    CheckHex,
    ExportJSONtoCSV,
    ConvertDLSettings,
    handleSearchChange,
    clearSearch,
    decodeFromHex 
}

import React, {Component} from "react";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import gatewayStore from "../../stores/GatewayStore";
import SessionStore from '../../stores/SessionStore'
import classNames from "classnames";
import moment from "moment";
import images from './BsCkeckImages.json';
const queryString = require('query-string');

const styles = {
    w100:{
        width:'100%'
    },
    imgFlexContainer:{
        display:'flex',
        flexWrap:'wrap',
        width:'320px'
    },
    cardContainer:{
        width:'320px'
    },
    card:{
        display:'flex',
        alignItems:'center',
        flexDirection:'column'
    },
    divider:{
        width:'320px'
    },
    textFieldContainer:{
            paddingTop:'20px',
            paddingBottom:'20px'
    },
    searchButton:{
        marginBottom:'20px'
    },
    resultContainer:{
        marginTop:'20px',
        marginBottom:'20px'
    },
    result:{
         fontSize:'18px'   
    },
    status:{
        margin:0,
        fontSize:'25px'    
    }
}

class BsCheck extends Component {

    constructor() {
        super()

        this.search = this.search.bind(this)

        this.state={
            bsid:'',
            result:null,
            loading:false,
        }
    }

    componentDidMount(){
        let search = queryString.parse (this.props.history.location.search)
       if(search.bsid){       
        this.setState({ bsid: search.bsid},()=>{
            this.search()
        })
       }
    }
    componentWillUnmount(){
        SessionStore.setMode(false);
    }
    search(){
        this.setState({loading:true,result:null})
        gatewayStore.getBs(this.state.bsid,(res)=>{
                let el = <div></div>
                if(res.response&&res.response.status!==200){
                   if(res.response.status===404){
                    el = <div>
                            <p className={this.props.classes.status}><span style={{color:'red'}}>NOT FOUND</span></p> 
                        </div>
                   }else{
                    el = <div>  
                            <p style={{color:'red',fontSize:'20px'}}>{res.response.status}</p> 
                            <p style={{color:'#ff7961'}}>{res.response.obj.message}</p> 
                        </div>
                   }
                   
                }else if(res.id){
                    let status = 'Unknown';
                    let color = ''
                    if(res.active){
                        color = 'green'
                        status = 'Connected'
                    }else{
                        color = 'red'
                        status = 'Disconnected'
                    }
                    el = <div className={classNames(this.props.classes.card, this.props.classes.result)}> 
                    <p className={this.props.classes.status}><span style={{color:color}}>{status}</span> </p> 
                    {status==='Disconnected'&&<p className={this.props.classes.card}><span>Last Seen at :</span>  <span style={{color:'#1769aa'}}>{ (res.lastSeenAt)?moment.utc(res.lastSeenAt).local().format('YYYY-MM-DD HH:mm:ss'):'Never seen'}</span></p> }
                    
                    </div>
                }
                
                this.setState({loading:false,result:el})
        })
    }


    render() {
        let disabled =( this.state.bsid!=='' && this.state.bsid.match(/(^[0-9a-fA-F]{8}$)|(^[0-9a-fA-F]{16}$)/ig)===null )
            return(
                <Grid container spacing={4} >
                    <TitleBar>
                        <TitleBarTitle title="Base Stations checker" />
                    </TitleBar>
                    <Grid item xs={12} >
                    <Card className={this.props.classes.cardContainer}>
                            <CardContent className={this.props.classes.card}>
                               
                                <div className={this.props.classes.textFieldContainer}>
                                <TextField 
                                       label={'Use all 16 or last 8 symbols'}
                                       id="bsId"
                                       InputLabelProps={{
                                           shrink: true,
                                       }}
                                       onChange={(event)=> {
                                           let val  = event.target.value.trim()
                                           if (val.length > 16) return
                                           this.setState({bsid:val.toLowerCase()})
                                        
                                       }}
                                       error={disabled}
                                       value={this.state.bsid || ""}
                                       InputProps={{
                                           endAdornment:
                                               <InputAdornment position="end" >

                                                      <IconButton
                                                           onClick={()=>{this.setState({bsid:'',result:null})}}
                                                           style={{padding:"0"}} color="default" component="span" >
                                                           <Icon className="fa fa-times" />
                                                       </IconButton>

                                               </InputAdornment>,
                                       }}
                                       variant="outlined"
                                       size="small"
                            />
                            </div>

                            <Button disabled={disabled||!this.state.bsid} onClick={this.search} className={this.props.classes.searchButton} variant="contained" color="primary">
                                Search
                            </Button>

                                <Divider className={this.props.classes.divider} />

                                <div className={classNames( this.props.classes.w100 ,this.props.classes.card ,this.props.classes.resultContainer )}>

                                    {this.state.loading && <CircularProgress />}
                                    {this.state.result}   
                                </div>

                                {this.state.result&&<Divider className={this.props.classes.divider} />}
                                <p> BS ID examples on device</p>
                                <div className={this.props.classes.w100}>
                                    <img className={this.props.classes.w100} alt="" src={images.img1} />
                                    <img className={this.props.classes.w100} alt="" src={images.img2}/>
                                </div>
                            </CardContent>
                        </Card>
                     </Grid>
                    
                </Grid>
            );
        }
}


export default withStyles(styles)(BsCheck);
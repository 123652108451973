import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import { Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import theme from "../../../theme";
import classNames from "classnames";
import moment from 'moment';
import HandyRustyStore from "../../../stores/HandyRustyStore";
import { withRouter } from 'react-router-dom';
import CardHeader from '@material-ui/core/CardHeader';
import {CircularProgress} from "@material-ui/core";





class Statistic extends Component {
    constructor(){
        super();

        this.state = {
            last24Hours: {},
            loader:false
        }
    }

    componentDidMount(){
      let created = moment.utc(this.props.createdAt)
      let current = moment.utc()
      let duration = moment.duration(current.diff(created))
     
      if(this.props.createdAt&&duration.isValid()&&duration.asDays()>1){
        this.loadStats()
      }
    
     
    }
    loadStats(){
      this.setState({loader:true});
       
      HandyRustyStore.getDeviceStats(this.props.match.params.devEUI, resp => {
          this.setState({ last24Hours: resp && resp.obj ? resp.obj : {} , loader:false});
      });
    }

    render(){
        const {last24Hours} = this.state;

        const lastRx = last24Hours && last24Hours.rxLast ? moment(last24Hours.rxLast).format("DD.MM.YYYY HH:mm:ss") : '';
        const lastTx = last24Hours && last24Hours.txLast ? moment(last24Hours.txLast).format("DD.MM.YYYY HH:mm:ss") : '';
        const lastJoin = last24Hours && last24Hours.joinLast ? moment(last24Hours.joinLast).format("DD.MM.YYYY HH:mm:ss") : '';

        return (
        <Card className={this.props.classes.root}>
        <CardHeader title={'Statistics'} action={this.state.loader&& <CircularProgress size={'20px'} /> }/>

        {Object.keys(this.state.last24Hours).length>0 && 
        <CardContent className={this.props.classes.infoCard}>
        <div className={classNames([this.props.classes.infoContainer])}>
          <div className={this.props.classes.infoContainer}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Last RSSI: </TableCell>
                  <TableCell>{`${Math.round(last24Hours.rssiLast)} dBm` || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last SNR: </TableCell>
                  <TableCell>{`${last24Hours.snrLast.toFixed(1)} dB` || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last ESP: </TableCell>
                  <TableCell>{`${last24Hours.espLast.toFixed(1)} dBm` || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Instant PER (20 packets): </TableCell>
                  <TableCell>{Math.round(last24Hours.perLast)  + " %" || last24Hours.perLast === 0 ? Math.round(last24Hours.perLast)  + " %" : ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          <div className={classNames([this.props.classes.infoContainer,this.props.classes.infoContainerMarginTop]) }>
            <Table size="small">
              <TableBody>
              <TableRow>
                  <TableCell>Last uplink frame: </TableCell>
                  <TableCell>{lastRx}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last downlink frame: </TableCell>
                  <TableCell>{lastTx}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last success join: </TableCell>
                  <TableCell>{lastJoin}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last spreading factor: </TableCell>
                  <TableCell>{last24Hours.sfLast || last24Hours.sfLast === 0 ? last24Hours.sfLast : ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          </div>
          <div className={this.props.classes.infoContainer}>
          <div className={this.props.classes.infoContainer}>
            <Table size="small">
              <TableBody>
              <TableRow>
                  <TableCell>Average RSSI (24h): </TableCell>
                  <TableCell>{`${Math.round(last24Hours.rssiAvg)} dBm` || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Average SNR (24h): </TableCell>
                  <TableCell>{`${last24Hours.snrAvg.toFixed(1)} dB` || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Average ESP (24h): </TableCell>
                  <TableCell>{`${last24Hours.espAvg.toFixed(1)} dBm` || ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Average PER (24h): </TableCell>
                  <TableCell>{Math.round(last24Hours.perAvg) + " %"|| ''}</TableCell>
                </TableRow>
               </TableBody>
            </Table>
          </div>
         
          <div className={classNames([this.props.classes.infoContainer,this.props.classes.infoContainerMarginTop])}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>UL/DL messages (24h):</TableCell>
                  <TableCell>{last24Hours.packetCnt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          </div>
          </CardContent>
           } 
      </Card>)
    }

}

const styles = {
    // link: {
    //   color: theme.palette.primary.main,
    // },
    root: {
      marginBottom: 50,
    },
    infoContainer: {
      marginRight: '50px'
    },
    infoContainerMarginTop:{
      marginTop: '50px'
    },
    infoCard: {
      display: 'flex',
    },
    cardMarginBottom: {
      marginBottom: '20px',
    },
    marginTop: {
      marginTop: '20px',
    },
    chart: {
      minHeight: 300,
    },
    chartBig: {
      minHeight: 400,
    },
    graphActionContainer:{
      display:'inline-flex',
      alignItems:'center'
    },
    tabs: {
      borderBottom: "1px solid " + theme.palette.divider,
      height: "49px",
    },
  };
Statistic = withStyles(styles)(Statistic)
Statistic = withRouter(Statistic)
export default Statistic
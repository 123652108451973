import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";
import moment from 'moment'

class GatewayProfileStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/gatewayProfile.swagger.json", sessionStore.getClientOpts());
  }

  create(gatewayProfile, callbackFunc) {
    this.swagger.then(client => {
      client.apis.GatewayProfileService.GatewayProfileService_Create({
        body: {
          gatewayProfile: gatewayProfile,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  get(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.GatewayProfileService.GatewayProfileService_Get({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  update(gatewayProfile, callbackFunc) {
    this.swagger.then(client => {
      client.apis.GatewayProfileService.GatewayProfileService_Update({
        "gatewayProfile.id": gatewayProfile.id,
        body: {
          gatewayProfile: gatewayProfile,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  delete(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.GatewayProfileService.GatewayProfileService_Delete({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("deleted");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  list(networkServerID, limit, offset, callbackFunc,orderBy,order) {
    this.swagger.then((client) => {
      client.apis.GatewayProfileService.GatewayProfileService_List({
        networkServerID: networkServerID,
        limit: limit,
        offset: offset,
        orderBy: orderBy,
        order:order
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  updateStorageGatewayCounts(callbackFunc){ 
      this.swagger.then((client)=>{
        client.apis.GatewayProfileService.GatewayProfileService_CountGateways()
        .then(checkStatus).then(resp => {
          if(resp.obj&&resp.obj.data){
            resp.obj.date = moment().valueOf()
          }
          localStorage.setItem("gatewayCount", JSON.stringify(resp.obj));
          callbackFunc(resp.obj,true)
        })
        .catch(errorHandler);
      })
  }


  getStorageGatewayCounts(callbackFunc){ 
    const strData = localStorage.getItem("gatewayCount");
    if (strData === "" || strData === null){
        this.updateStorageGatewayCounts(callbackFunc)
    }else{
      let json = JSON.parse(strData);
      let a = moment( json.date )
      let b = moment(  ) 
      if (a.diff(b, 'hours')>1){
        this.updateStorageGatewayCounts(callbackFunc)
      }else{
        callbackFunc(json,false)
      }
    }
   
   
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "gateway-profile has been " + action,
      },
    });
  }
}

const gatewayProfileStore = new GatewayProfileStore();
export default gatewayProfileStore;

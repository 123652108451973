import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import TableCellLink from "../../components/TableCellLink";
import DataTable from "../../components/DataTable";
import GatewayStore from "../../stores/GatewayStore";
import theme from "../../theme";
import Brightness1RoundedIcon from '@material-ui/icons/Brightness1Rounded';
import TableSortLabel from "@material-ui/core/TableSortLabel";
import SettingsRemoteOutlinedIcon from '@material-ui/icons/SettingsRemoteOutlined';

const styles = {
  chart: {
    width: 190,
  },
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  gatewayIcon: {
    verticalAlign: 'bottom', cursor: 'pointer'
  },
  noWrap: {
    whiteSpace: 'nowrap'
  }
};


function ConnectionStatus({status, opStatusCode}) {

  let color;
  let text;
  if (status === true) {
    color = 'green';
    switch (opStatusCode)
    {
      case '5': 
      text = 'online\u00A0(susp)'
      color = '#748b19'
      break
      default: text = 'online'
    }
  } else if (status === false) {
    color = 'red';
    text = 'offline'
  } else {
    color = 'grey';
    text = 'unknown'
  }

  return <div style={{color: color}}>  {text.toUpperCase()}  </div>
}

function RadioStatus(props) {
  let color;

  if (props.status === 'Running') {
    color = 'green';
  } else if (props.status === 'Disabled' || props.status === 'Stopped') {
    color = 'red';
  } else {
    color = 'grey';
  }

  return <Brightness1RoundedIcon htmlColor={color}/>
}

class GatewayRow extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const start = moment().subtract(14, "days").toISOString();
    const end = moment().toISOString();

    GatewayStore.getStats(this.props.gateway.id, start, end, resp => {
      let stats = {
        labels: [],
        datasets: [
          {
            data: [],
            fillColor: "rgba(33, 150, 243, 1)",
          },
        ],
      };

      for (const row of resp.result) {
        stats.labels.push(row.timestamp);
        stats.datasets[0].data.push(row.rxPacketsReceivedOK + row.txPacketsEmitted);
      }

      this.setState({
        stats: stats,
      });
    });
  }

  render() {
    const options = {
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      plugins: {
        tooltips: {
          enabled: false,
        },
        legend: {
          display: false,
        },
      },
      responsive: false,
      animation: false,
    };

    let lastseen = "Never";
    if (this.props.gateway.lastSeenAt !== null) {
      lastseen = moment(this.props.gateway.lastSeenAt).fromNow();
    }

    return (
      <TableRow hover>
        <TableCellLink
          to={`/organizations/${this.props.gateway.organizationID}/gateways/${this.props.gateway.id}`}>
          {this.props.gateway.name}
        </TableCellLink>
        <TableCell style={{...styles.noWrap}}>
          {this.props.gateway.id.toUpperCase()}
          <SettingsRemoteOutlinedIcon
          titleAccess={'Show in Lorawan Logger'}
          onClick={() => this.props.history.push('/diagnostics?gw=' + this.props.gateway.id)}
          style={styles.gatewayIcon}
        />
        </TableCell>
        <TableCellLink
          to={`/gateway-profiles/${this.props.gateway.gatewayProfileId}`}>
          {this.props.gateway.gatewayProfileName}
        </TableCellLink>
        <TableCell>
          {lastseen}
        </TableCell>
        <TableCell>
          <ConnectionStatus status={this.props.gateway.active} opStatusCode={this.props.gateway.opStatusCode}/>
        </TableCell>
        <TableCell>
          <RadioStatus status={this.props.gateway.radio}/>
        </TableCell>
        <TableCell>
          {this.props.gateway.opStatusLabel}
        </TableCell>
        <TableCell>
          {this.state.stats && <Bar
            width={190}
            height={23}
            data={this.state.stats}
            options={options}
          />}
        </TableCell>
        <TableCell>{this.props.gateway.networkServerName}</TableCell>
      </TableRow>
    );
  }
}


class ListGateways extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);

    this.state = {
      orderBy: null,
      order: null
    }
  }

  getPage = (limit, offset, callbackFunc) => {
    GatewayStore.list("", this.props.match.params.organizationID, limit, offset, callbackFunc, this.state.orderBy, this.state.order);
  }

  getRow = (obj) => {
    return (
      <GatewayRow history={this.props.history} key={obj.id} gateway={obj}/>
    );
  }

  changeSort(event) {
    let state = {
      orderBy: event.currentTarget.dataset.key,
      order: this.state.order
    }

    if (state.orderBy !== this.state.orderBy) {
      state.order = null;
    }

    if (state.order === null) {
      state.order = 'asc'
    } else if (state.order === 'asc') {
      state.order = 'desc'
    } else if (state.order === 'desc') {
      state.order = null
      state.orderBy = null
    }

    this.setState(state);
  }

  makeSortLabel(keyName, viewName) {
    return <TableSortLabel
      data-key={keyName}
      active={this.state.orderBy === keyName}
      direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
      onClick={this.changeSort}
    >
      {viewName}
      {this.state.orderBy === keyName ? (
        <span className={this.props.classes.visuallyHidden}>
                  {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
      ) : null}
    </TableSortLabel>
  }

  makeCellSortProps(keyName) {
    return {
      key: keyName,
      align: 'left',
      padding: 'normal',
      sortDirection: this.state.orderBy === keyName ? this.state.order : false
    }
  }

  render() {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataTable
          orderBy={this.state.orderBy}
            header={
              <TableRow>
                <TableCell {...this.makeCellSortProps('name')}>{this.makeSortLabel('name','Name')} </TableCell>
                <TableCell {...this.makeCellSortProps('gatewayId')}>{this.makeSortLabel('gatewayId','Gateway ID')} </TableCell>
                <TableCell {...this.makeCellSortProps('gpName')}>{this.makeSortLabel('gpName','Gateway Profile')} </TableCell>
                <TableCell {...this.makeCellSortProps('lastSeen')}>{this.makeSortLabel('lastSeen','Last seen')} </TableCell>
                <TableCell {...this.makeCellSortProps('status')}>{this.makeSortLabel('status','Status')} </TableCell>
                <TableCell {...this.makeCellSortProps('radio')}>{this.makeSortLabel('radio','Radio')} </TableCell>
                <TableCell {...this.makeCellSortProps('opStatusLabel')}>{this.makeSortLabel('opStatusLabel','Operational status')} </TableCell>
                <TableCell className={this.props.classes.chart}>Gateway activity (14d)</TableCell>
                <TableCell>Frequency plan</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListGateways);
export {ConnectionStatus}

import React, {  useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import Fields from './Fields'




export default (props) =>{

    const [checkedFields, setCheckedFields] = useState([...props.selectedFields]);
    
    return (
      <Dialog onClose={() => {
        props.onClose(checkedFields)
      }} aria-labelledby="simple-dialog-title" open={props.open}>
        <DialogTitle id="simple-dialog-title">Select fields</DialogTitle>
        <Grid xs={12}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              item
        >
          {Fields && Fields.map((f, i) => {
            return (
              <div key={i}
                   style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        paddingLeft: '10px'
                    }}
                   className={'header-name'}>
                <span> {(f.head.selector)?f.head.selector():f.head.render()} </span>
                <Checkbox
                  style={{marginLeft: 'auto'}}
                  checked={(checkedFields.indexOf(f.dataName) > -1)}
                  color="default"
                  inputProps={{'aria-label': 'checkbox with default color'}}
                  name={f.dataName}
                  onChange={(event) => {
                    let checked = [...checkedFields];
                    let name = event.currentTarget.name;
                    let check = event.target.checked;
  
                    if (check) {
                      if (checked.indexOf(name) === -1) {
                        checked.push(name)
                        setCheckedFields(checked);
                      }
                    } else {
                      let index = checked.indexOf(name);
  
                      if (index > -1) {
                        checked.splice(index, 1);
                        setCheckedFields(checked);
                      }
                    }
  
                  }}
                />
              </div>
            )
          })}
        </Grid>
      </Dialog>
    )
  }
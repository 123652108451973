import { EventEmitter } from "events";
import Swagger from "swagger-client";
import RobustWebSocket from "robust-websocket";

import sessionStore from "./SessionStore";
import { checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";
import moment from "moment-timezone"

class HandyRustyStore extends EventEmitter {
  constructor() {
    super();

    this.getDeviceFrames = this.getDeviceFrames.bind(this)
    this.getFrameSpeed = this.getFrameSpeed.bind(this)
    this.execCommand = this.execCommand.bind(this)
    this.getIntegrationReplies = this.getIntegrationReplies.bind(this)

    this.client = null;
    this.swagger = Swagger("/swagger/handyrusty.swagger.json", sessionStore.getClientOpts());
    this.data = {body: {}}
  }

  getFrameCounters(organizationId, start, end, callbackFunc) {
    this.swagger.then(client => {
      this.data.body = {
        organizationId: parseInt(organizationId),
        start: start,
        end: end,
      }

      client.apis.HandyRustyService.HandyRustyService_GetFrameCounters(this.data)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getFrameSpeed(organizationId, start, end, callbackFunc) {
    this.swagger.then(client => {
      this.data.body = {
        organizationId: parseInt(organizationId),
        start: start,
        end: end,
      }

      client.apis.HandyRustyService.HandyRustyService_GetFrameSpeed(this.data)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  execCommand(params, callbackFunc) {
    this.swagger.then(client => {
      this.data.body = params
      client.apis.HandyRustyService.HandyRustyService_ExecCommand(this.data)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp);
        })
        .catch(errorHandler);
    });
  }

  getDeviceStats(devEui, callbackFunc) {

    this.swagger.then(client => {
      client.apis.HandyRustyService.HandyRustyService_GetDeviceStats({devEui})
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp);
        })
        .catch((err) => {
          errorHandler(err)
          callbackFunc()
        });
    });
  }

  getDeviceStatsLastTwoweeks(devEui, callbackFunc) {

    this.swagger.then(client => {
      client.apis.HandyRustyService.HandyRustyService_GetDeviceStatsLastTwoweeks({devEui})
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp);
        })
        .catch((err) => {
          errorHandler(err)
          callbackFunc()
        });
    });
  }

  getDeviceStatsLastTwoweeksAggregated(devEui, callbackFunc) {

    this.swagger.then(client => {
      client.apis.HandyRustyService.HandyRustyService_GetDeviceStatsLastTwoweeksAggregated({devEui})
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp);
        })
        .catch((err) => {
          errorHandler(err)
          callbackFunc()
        });
    });
  }

  GetAveragesForDeviceList(devEuiList, callbackFunc) {
    this.swagger.then(client => {
      this.data.body = {devList: devEuiList};
      client.apis.HandyRustyService.HandyRustyService_GetAveragesForDeviceList(this.data)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp);
        })
        .catch((err) => {
          errorHandler(err)
          callbackFunc()
        });
    });
  }

  getDeviceFrames(filter, limit, offset, callbackFunc) {

    filter.limit = limit;
    filter.offset = offset;

    this.swagger.then(client => {
      this.data.body = filter
     
      client.apis.HandyRustyService.HandyRustyService_GetDeviceFrames(this.data)
        .then(checkStatus)
        .then(resp => {
          let dataObj = resp.obj;
          let obj = {};
          obj.totalCount = (dataObj && dataObj.itemsTotal) ? dataObj.itemsTotal : 0;
          obj.result = (dataObj && dataObj.data) ? dataObj.data : [];
          callbackFunc(obj);
        })
        .catch((err) => {
          errorHandler(err)
          let obj = {};
          obj.totalCount = 0;
          obj.result =[];
          callbackFunc(obj)
        });
    });

    /*  fetch('http://80.244.228.34:4444/logs/device-frames',{
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        body:JSON.stringify(filter)

      })
          .then(response => {
            response.json().then((data)=>{
              let obj = {};
              obj.totalCount =(data&&data.ItemsTotal)?data.ItemsTotal : 0
              obj.result = (data&&data.Data)?data.Data:[];
              callbackFunc(obj)
            })

          })*/
  }

  getDeviceFramesCSV(filter, limit, offset, onData, onClose, cb) {
    filter.limit = limit;
    filter.offset = offset;
    filter.timeZone = moment.tz.guess(true)

    let serialize = function (obj, prefix) {
      var str = [],
        p;
      for (p in obj) {
        if (obj.hasOwnProperty(p)) {
          var k = prefix ? prefix + "[" + p + "]" : p,
            v = obj[p];
          str.push((v !== null && typeof v === "object") ?
            serialize(v, k) :
            encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
      }
      return str.join("&");
    }
    let queryString = serialize(filter)
    const loc = window.location;
    const wsURL = (() => {
      if (loc.host === "localhost:3000" || loc.host === "localhost:3001") {
        return `ws://localhost:8080/api/handy-rusty/frames/logs/device-frames/csv?` + queryString;
      }

      const wsProtocol = loc.protocol === "https:" ? "wss:" : "ws:";
      return `${wsProtocol}//${loc.host}/api/handy-rusty/frames/logs/device-frames/csv?` + queryString;
    })();

    const conn = new RobustWebSocket(wsURL,
      ["Bearer", sessionStore.getToken()],
      {
        shouldReconnect: function (event, ws) {
          return null
        },
      }
    );

    conn.addEventListener("open", () => {
      console.log('connected to', wsURL);
      this.wsDataStatus = "CONNECTED";
      this.emit("ws.status.change");
    });

    conn.addEventListener("message", (e) => {
      const msg = JSON.parse(e.data); // console.log( e );
      if (msg.error !== undefined) {
        dispatcher.dispatch({
          type: "CREATE_NOTIFICATION",
          notification: {
            type: "error",
            message: msg.error.message,
          },
        });
      } else if (msg.result !== undefined) {
        onData(msg.result);
      }
    });

    conn.addEventListener("close", () => {
      console.log('closing', wsURL);
      this.wsDataStatus = null;
      this.emit("ws.status.change");
      onClose(cb)
    });

    conn.addEventListener("error", () => {
      console.log("error");
      this.wsDataStatus = "ERROR";
      this.emit("ws.status.change");
    });

    return conn;
  }

  notify(msg) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: msg,
      },
    });
  }

  getIntegrationReplies(filter, limit, offset, organisationId, routingProfileId, start, end, callbackFunc) {
    let params = {
      limit: limit,
      offset: offset,
      organisationId: organisationId,
      routingProfileId: routingProfileId,
      start: start,
      end: end,
    };
    if (filter.devEui.length > 0) {
      params.devEui = filter.devEui;
    }
    if (filter.isError) {
      params.isError = filter.isError;
    }
    if (filter.isOk) {
      params.isOk = filter.isOk;
    }
    this.swagger.then((client) => {
      client.apis.HandyRustyService.HandyRustyService_GetIntegrationReplies(params)
        .then(checkStatus)
        .then(resp => {
          const newObj = {result: [...resp.obj.result], totalCount: resp.obj.itemsTotal}
          callbackFunc(newObj);
        })
        .catch(errorHandler);
    });
  }

  getGwStats(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.HandyRustyService.HandyRustyService_GetGwStats({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }
}


const handyRustyStore = new HandyRustyStore();
export default handyRustyStore;

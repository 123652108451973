import { Button, DialogContentText, Dialog, IconButton, TextField } from "@material-ui/core"

import DeleteIcon from "@material-ui/icons/Delete"
import React, { Component } from "react"

import MuiDialogActions from "@material-ui/core/DialogActions"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClear, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      <IconButton className={classes.closeButton} onClick={onClear}>
        <DeleteIcon />
      </IconButton>
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions)

export default class MultipleDeviceSelector extends Component {
  constructor(props) {
    super(props)

    this.onClear = this.props.onClear.bind(this)
    this.multiplySelectDialogClose = this.props.multiplySelectDialogClose.bind(this)
    this.onSubmit = this.props.onSubmit.bind(this)
    this.onChange = this.props.onChange.bind(this)

  }

  render() {
    const arrayDevEUIs = this.props.arrayDevEUIs
    return (
      <Dialog open={this.props.open} onClose={this.multiplySelectDialogClose} fullWidth>
        <DialogTitle onClear={this.onClear}>Multiple input</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter a list of DevEUI (any separator)</DialogContentText>
          <TextField
            value={this.props.devEUIs}
            onChange={this.onChange}
            multiline
            minRows={5}
            autoFocus
            margin="dense"
            type="text"
            fullWidth
          />
          <Typography>{`Unique total items: ${this.props.arrayDevEUIs ? arrayDevEUIs.length : 0} ${arrayDevEUIs?.length > 200 ? " (limit 200)" : ""}`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.multiplySelectDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!arrayDevEUIs?.length || arrayDevEUIs.length > 200}
            onClick={() => {
              this.onSubmit(arrayDevEUIs)
              this.multiplySelectDialogClose()
            }}
            variant="contained"
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

import React from "react";

import TextField from '@material-ui/core/TextField';

import FormComponent from "../../../../classes/FormComponent";
import Form from "../../../../components/Form";


const defaultValues = () =>{ return {
  "topic": "ernet.uplink",
  "marshaler": "LAR_TECH",
  "reader": {
    "enabled": true,
    "marshaler": "LAR_TECH",
    "topic": "ernet.downlink",
  },
  "writers": [
    {
    "event": "up",
    "enabled": true,
    "marshaler": "LAR_TECH",
    "topic": "ernet.uplink"
    },
    {
    "event": "ack",
    "enabled": true,
    "marshaler": "LAR_TECH",
    "topic": "ernet.downlink.status"
    },
    {
    "event": "txack",
    "enabled": true,
    "marshaler": "LAR_TECH",
    "topic": "ernet.downlink.status"
    },
    {
    "event": "consumer-ack",
    "enabled": true,
    "marshaler": "LAR_TECH",
    "topic": "ernet.downlink.status"
    }
  ],
}}

class LPWAN_MeteringIntegrationForm extends FormComponent {

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if(this.state.object.topic === undefined  ||
       this.state.object.reader === undefined ||
       this.state.object.writers === undefined ){
      this.setDefaults()
    }
  }

  setDefaults(){
    this.setState({object:defaultValues()})
  }

  onChange(e) {
    super.onChange(e);

    let object = this.state.object;
    if (e.target.id === "brokersStr") {
      object.brokers = e.target.value.split(",");
    }

    this.setState({
      object: object,
    });
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    let brokersStr = "";
    if (this.state.object.brokersStr !== undefined) {
      brokersStr = this.state.object.brokersStr;
    } else if (this.state.object.brokers !== undefined) {
      brokersStr = this.state.object.brokers.join(", ");
    }

    return(
      <Form submitLabel={this.props.submitLabel} onSubmit={this.onSubmit}>
        <TextField
          id="brokersStr"
          label="Brokers (comma-separated list of ip-address:port pairs)"
          value={brokersStr}
          onChange={this.onChange}
          margin="normal"
          required
          fullWidth
        />
{/*        <FormControl fullWidth margin="normal">
          <FormGroup>
            <FormControlLabel
             label="Tls"
              control={
                <Checkbox
                id="tls"
                checked={!!this.state.object.tls}
                onChange={this.onChange}
                color="primary"
              />
              }
            />
          </FormGroup>
        </FormControl>*/}

         <TextField
          id="owner"
          label="Owner"
          value={this.state.object.owner || ""}
          onChange={this.onChange}
          margin="normal"
          required
          fullWidth
        />

{/*         <TextField
          id="username"
          label="Username"
          
          value={this.state.object.username || ""}
          onChange={this.onChange}
          margin="normal"
          required
          fullWidth
        />
         <TextField
          id="password"
          label="Password"
          type="password" 
          value={this.state.object.password || ""}
          onChange={this.onChange}
          margin="normal"
          required
          fullWidth
        />*/}
      </Form>
    );
  }
}


export default LPWAN_MeteringIntegrationForm;

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";


import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";

import ApplicationForm from "./ApplicationForm";
import ApplicationStore from "../../stores/ApplicationStore";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


const styles = {
  card: {
    overflow: "visible",
  },
};


class CreateApplication extends Component {
  constructor() {
    super();
    this.state = {
      // spDialog: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    // this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
    // ServiceProfileStore.list(this.props.match.params.organizationID, 0, 0, 0, resp => {
    //   if (resp.totalCount === "0") {
    //     this.setState({
    //       spDialog: true,
    //     });
    //   }
    // });
  }

  // closeDialog() {
  //   this.setState({
  //     spDialog: false,
  //   });
  // }

  onSubmit(application) {
    let app = application;
    app.organizationID = this.props.match.params.organizationID;

    ApplicationStore.create(app, resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications`);
    });
  }

  render() {
    const obj = {
      isActive: true,
    }
    return(
      <Grid container spacing={4}>
        {/* <Dialog
          open={this.state.spDialog}
          onClose={this.closeDialog}
        >
          <DialogTitle>Add a service-profile?</DialogTitle>
          <DialogContent>
            <DialogContentText paragraph>
              The selected organization does not have a service-profile yet.
              A service-profile connects an organization to a network-server and defines the features that an organization can use on this network-server.
            </DialogContentText>
            <DialogContentText>
              Would you like to create a service-profile?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" component={Link} to={`/organizations/${this.props.match.params.organizationID}/service-profiles/create`} onClick={this.closeDialog}>Create service-profile</Button>
            <Button color="primary" onClick={this.closeDialog}>Dismiss</Button>
          </DialogActions>
        </Dialog> */}

        <TitleBar>
        <OrganizationBreadcrumps org={this.props.org}>
          <TitleBarTitle title="Application Servers" to={`/organizations/${this.props.match.params.organizationID}/applications`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Create" />
          </OrganizationBreadcrumps>
        </TitleBar>

        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <ApplicationForm
                submitLabel="Create application server"
                onSubmit={this.onSubmit}
                match={this.props.match}
                object={obj}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(CreateApplication));

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  paper: {
    width: 500,
    height: 350,
    overflow: 'auto',
  },
  listItem: {
    padding: '0',
    height: '30px'
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  headList: {
    textAlign: 'center',
    // color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    fontFamily: "Pantone",
    fontWeght: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    paddingBottom: '10px',
  }
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
  const {handleUpdateServerList, appServers, selectedApplicationIds, disabled} = props;
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(appServers);
  const [right, setRight] = React.useState([]);

  React.useEffect(()=>{
    const rightColumn = appServers.filter((item) => {
      return selectedApplicationIds.includes(item.id)
    })

    const leftColumn = appServers.filter(leftColumnItem => {
      return !rightColumn.map(el => el.id).includes(leftColumnItem.id)
    })

    setLeft(leftColumn)
    setRight(rightColumn)
  }, [appServers, selectedApplicationIds])

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
    let arrTransfer = right.concat(left).map((el)=> el.id)
    handleUpdateServerList(arrTransfer)
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    let arrTransfer = right.concat(leftChecked).map((el)=> el.id)
    handleUpdateServerList(arrTransfer)
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    let arrTransfer = not(right, rightChecked).map((el)=> el.id)
    handleUpdateServerList(arrTransfer)
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
    handleUpdateServerList([])
  };

  const customList = (items) => (

    <Paper className={classes.paper}>
      <List component="div" role="list">

        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)} disabled={disabled} className={classes.listItem}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  color='primary'
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} alignItems="center" className={classes.root}>
      <Grid item>
        <div className={classes.headList}>Available</div>
        {customList(left)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0 || disabled}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || disabled}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || disabled}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0 || disabled}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <div className={classes.headList}>Used</div>
        {customList(right)}
      </Grid>
    </Grid>
  );
}

import Papa from 'papaparse';
import _ from 'lodash';

class FileImport{
    msg = [];

    constructor(file,progressFunc) {
        this.file = file
        this.changeProgress = progressFunc
        this.changeProgress(1,'File gotten');
    }

    checkAll(cb){
       if(!this.checkFilename()){
           cb( this.msg ) ;
           return ;
       }



       this.readFile((data)=>{
           if(data.errors.length>0){
               data.errors.forEach(err => {
                this.msg.push('reading file '+ err.message)
               })
           }



          this.makeJson(data.data);

          this.checkIntegrity();
           this.changeProgress(30,'Checking integrity of file Finished');

           cb( this.msg , this.uploadRes ) ;
       })

    }



    checkIntegrity(){
        this.changeProgress(4,'Checking integrity of file');
        this.uploadRes = [];
        for (let i in this.json){

            let res
            res = this.checkDevEUI(this.json[i]["devEUI"],i);

            if(!res) continue;

            //only deveui is needed when delete
            if(this.json[i]["cmd"]!=='DELETE'){

                res = this.checkUnique('DeviceName',this.json[i]["DeviceName"],i);
                if(!res) continue;

                res = this.checkProfileId(this.json[i]["DeviceProfileID"],this.json[i]["devEUI"],'Device')
                if(!res) continue;

                res = this.checkProfileId(this.json[i]["ConectivityPlanId"],this.json[i]["devEUI"],'Service')
                if(!res) continue;

                res = this.checkASRoutingProfile(this.json[i]["AsRoutingProfileId"],this.json[i]["devEUI"])
                if(!res) continue;

                let matchStruct =this.json[i]["cmd"].match(/(OTAA|ABP)/ig);

                if(matchStruct[0]==='OTAA'){  //checking if otaa
                    this.json[i]["cmd"] = 'OTAA'
                 //   res =  this.checkAppKey(this.json[i]["AppKey"],'AppKey');
                    res =  this.checkKey(this.json[i]["AppKey"],32,this.json[i]["devEUI"],'AppKey');
                    if(!res) continue;
                  
                }else if(matchStruct[0]==='ABP'){  //checking if ABP
                    this.json[i]["cmd"] = 'ABP';
                    res = this.checkDevAddr(this.json[i]["devAddr"],i);
                    if(!res) continue;

                    // res = this.NwkSKey(this.json[i]["NwkSKey"]);
                    // if(!res) continue;
                    res =  this.checkKey(this.json[i]["AppSKeys"],32,this.json[i]["devEUI"],'AppKey');
                    if(!res) continue;

                  
                }
            }

            this.uploadRes.push(this.json[i]);

        }
    }
    makeJson(data){
        this.changeProgress(3,'Making Json');
        this.json = [];
        let struct ={
        OTAA: {
            cmd:'',
            devEUI:'',
            devAddr:'',
            DeviceProfileID:'',
            AppEUI:'',
            AppKey:'',
            ConectivityPlanId:'',
            AsRoutingProfileId:'',
            DeviceName:'',
            AdminLat:'',
            AdminLon:'',
            AdminInfo:'',
            SkipFCntCheck:''
        },
        ABP:{
            cmd:'',
            devEUI:'',
            devAddr:'',
            DeviceProfileID:'',
            AppSKeys:'',
            NwkSEncKey:'',
            FNwkSIntKey:'',
            SNwkSIntKey:'',
            FCntUp:'',
            NFCntDown:'',
            AFCntDown:'',
            ConectivityPlanId:'',
            AsRoutingProfileId:'',
            DeviceName:'',
            AdminLat:'',
            AdminLon:'',
            AdminInfo:'',      
            SkipFCntCheck:''   
        },
        DELETE:{
            cmd:'',
            devEUI:'',
        }
        }
        for (let i in data){
            if(data[i][0]===''){
                continue;
            }
            let matchStruct = data[i][0].match(/(OTAA|ABP|DELETE|#)/ig);
            if(data[i][0].match(/(CMD)/ig)){
                continue;    
            }
            if(matchStruct===null){
                this.msg.push('Incorrect input command  (CREATE_OTAA|CREATE_ABP|DELETE) ' );
                continue;
            }
            if(matchStruct[0]==='#'){
                continue;
            }

            let pushObj = Object.assign({},struct[matchStruct[0]]);
            let c = 0
            for(let key in pushObj){
                pushObj[key] = data[i][c]
                c++;
            }
            pushObj.cmd = matchStruct[0]
            this.json.push(pushObj);
        }
    }

    checkGPSmatch(str,field){
      let res = str.match(/^[0-9]{2}.[0-9]+$/ig);
      if(res===null) {
          this.msg.push(field + ' ' + str + ' must be XX.XX+');
          return false;
      }

      return true
    }
    NwkSKey(str){
        //48D16DDB42EDB213BF1E57AFEF2DFA5E
        //534A8B7A65DDAE1AF91A9F86AEAFD231
        let res = str.match(/[0-9A-F]{32}/ig);
        if(res===null){
            this.msg.push('Not valid NwkSKey');
            return false;
        }
        return true;
    }
    checkKey(str,num,devEUI,type){
       
        let errMsg = `${devEUI} not valid ${type}`
        if(str===''){
            this.msg.push(errMsg);
            return false
       }


       if( new Blob([str.replaceAll('-','')]).size !== num){
        this.msg.push(errMsg);
        return false
       }

       return str
    }
    checkAppSKeys(str){
     
       if(str===''){
            return false
       }
       let res = str.match(/[0-9A-Z]{32}/ig);
       if(res===null || new Blob([str.replaceAll('-','')]).size !== 16){
           this.msg.push('Not valid AppKeys');
           return false;
       }
        
       return res;
    }
    checkASRoutingProfile(str,devEUI){ 
        if(str === '' || !Number(str)){
            let errMsg = `${devEUI} not valid AS Routing Profile Id, must be in Bigint`
            this.msg.push(errMsg);
            return false;
        }
        return true
    }
    checkProfileId(str,devEUI,type){
      let res = str.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/ig);
      if(res===null){
          let errMsg = `${devEUI} not valid ${type} Profile Id, must be uuid v1-5`
          this.msg.push(errMsg);

          return false
      }
      return true;
    }
    checkAppKey(str,field){
       return this.matchHexValue(str,0,field)
    }

    checkDevAddr(devAddr,pos){
        let push = true;

        // let res =  this.checkUnique('devAddr',devAddr,pos)
        // if(!res) push = false;
 
        let res = this.matchHexValue(devAddr,8,'devAddr')
        if(!res) push = false;

        return push;
    }
  
    checkDevEUI(devEUI,pos){
      let push = true;

      let res =  this.checkUnique('devEUI',devEUI,pos)
      if(!res) push = false;
      res = this.matchHexValue(devEUI,16,'devEUI')
      if(!res) push = false;

      return push;
    }
    checkUnique(field,val,pos){

        let res =  _.findIndex(this.uploadRes, function(chr) {
            return chr[field] === val;
        });
        if(res>-1){
            this.msg.push(field+' '+this.json[pos][field] + ' already exist');
            return false;
        }
        return true;
    }
    matchHexValue(str,len,field){
        let strMatch = '^[0-9a-fA-F]{'+len+'}$'
        if(len===0){
            strMatch = '^[0-9a-fA-F]+$'
        }
        let match = new RegExp(strMatch,'gi')
        let res = match.exec(str)
       if(res===null){
               this.msg.push(field+' '+str + ' not matching RegExp');
               return false;
       }
       return true
    }
    checkFilename(){
       let name =  this.file.name ;
       let tmp = name.split('.');
       if(tmp.length===1 || tmp[tmp.length-1]!=='csv'){
           this.msg.push('File extension name must be (.csv)');
           return false
       }

       return true;
    }

    readFile(cb){
        this.changeProgress(2,'Reading file');
        Papa.parse(this.file,{
            error:cb,
            complete:cb,
            header:false,
            delimitersToGuess: [',',';'],
            skipEmptyLines: true,
        })
    }
}






export {
    FileImport
}
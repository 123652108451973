import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import OrganizationStore from "../../stores/OrganizationStore";
import UserStore from "../../stores/UserStore";
import  SessionStore from '../../stores/SessionStore'
import {withStyles} from "@material-ui/core/styles";

import Delete from "mdi-material-ui/Delete";
import IconButton from '@material-ui/core/IconButton';
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";
import { handleSearchChange,clearSearch} from "../../classes/Helper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
const styles ={
  visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
  },
};

class ListOrganizationUsers extends Component {
  constructor() {
    super();

    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.handleSearchChange = handleSearchChange.bind(this);
    this.clearSearch = clearSearch.bind(this);
    this.TableRef = React.createRef()
    this.state = {
      orderBy:null,
      order:null,
      search:null
    }
  }
  changeSort(event){
        let state = {
            orderBy:event.currentTarget.dataset.key,
            order:this.state.order
        }

        if(state.orderBy!==this.state.orderBy){
            state.order = null;
        }

        if(state.order === null){
            state.order = 'asc'
        }else if(state.order === 'asc'){
            state.order = 'desc'
        }else if(state.order === 'desc'){
            state.order = null
            state.orderBy = null
        }

        this.setState(state);
  }

  makeSortLabel(keyName,viewName){
    return  <TableSortLabel
          data-key={keyName}
          active={this.state.orderBy === keyName}
          direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
          onClick={this.changeSort}
      >
        {viewName}
          {this.state.orderBy === keyName ? (
              <span className={this.props.classes.visuallyHidden}>
                  {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
          ) : null}
      </TableSortLabel>
  }

  makeCellSortProps(keyName){
      return {
          key:keyName,
          align:'left',
          padding:'normal',
          sortDirection:this.state.orderBy === keyName ? this.state.order : false
      }
  }
  getPage(limit, offset, callbackFunc) {
    if(this.timeOut) {
      clearTimeout(this.timeOut)
    }
    this.timeOut =  setTimeout(()=>{
      OrganizationStore.listUsers(this.props.match.params.organizationID, limit, offset, this.state.orderBy, this.state.order, callbackFunc,this.state.search);
    }, 400)
  }

  onDelete(e, orgCnt){
    
    if (orgCnt > 1) {
      if (window.confirm("Do you realy want to remove the user from the organization?")) {
      OrganizationStore.deleteUser(this.props.match.params.organizationID, e.currentTarget.id, resp => {
        this.TableRef.current.updateTable()
      });
      }
      return    
    }

    if (window.confirm("Do you realy want to delete the user?")) {
      UserStore.delete(e.currentTarget.id, () => {
        this.TableRef.current.updateTable()
      });
    }

  }

  getRow(obj) {
    let orgAdmin = null;
    let gwAdmin = null;
    let devAdmin = null;
    let active = null;

    if (obj.isAdmin) {
      orgAdmin = <Check />
    } else {
      orgAdmin = <Close />
    }

    if (obj.isAdmin || obj.isGatewayAdmin) {
      gwAdmin = <Check />
    } else {
      gwAdmin = <Close />
    }

    if (obj.isAdmin || obj.isDeviceAdmin) {
      devAdmin = <Check />
    } else {
      devAdmin = <Close />
    }

    if (obj.isActive) {
      active = <Check />;
    } else{ 
      active =  <Close />
    }

    return(
      <TableRow
        key={obj.userID}
        hover
      >
        
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/users/${obj.userID}`}>{obj.email}</TableCellLink>
        <TableCell>{obj.surname} {obj.name}</TableCell>
        <TableCell>{obj.company}</TableCell>
        <TableCell>{obj.position}</TableCell>
      
       
        <TableCell>{active}</TableCell>
        <TableCell>{orgAdmin}</TableCell>
        <TableCell>{gwAdmin}</TableCell>
        <TableCell>{devAdmin}</TableCell>
        <TableCell align="right">
                  <IconButton id={obj.userID}  onClick={(event) => this.onDelete(event, obj.orgCnt)} className={'noUDpaddings'} aria-label="delete"><Delete /></IconButton>
         </TableCell>
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={[
            <TitleBarButton
              label={(SessionStore.isAdmin())?"Add":"Create"}
              icon={<Plus />}
              to={`/organizations/${this.props.match.params.organizationID}/users/create`}
            />,
          ]}
        >
        <OrganizationBreadcrumps org={this.props.org}>
          <TitleBarTitle title="Organization users" />
        </OrganizationBreadcrumps>
        </TitleBar>
        <Grid className={'paddingBottomZero'} item xs={12}>
          <TextField
            label={"Search"}
            id="Search"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleSearchChange}
            value={this.state.search || ""}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.clearSearch}
                    style={{padding: "0"}} color="default" component="span">
                    <Icon className="fa fa-times"/>
                  </IconButton>
                </InputAdornment>,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            orderBy={this.state.orderBy}
            ref={this.TableRef}
            header={
              <TableRow>
               
                <TableCell {...this.makeCellSortProps('email')}>{this.makeSortLabel('email','Email')}</TableCell>
                <TableCell {...this.makeCellSortProps('surname')}>{this.makeSortLabel('surname','Name')}</TableCell>
                <TableCell {...this.makeCellSortProps('company')}>{this.makeSortLabel('company','Company')}</TableCell>
                <TableCell {...this.makeCellSortProps('position')}>{this.makeSortLabel('position','Job position')}</TableCell>
               
                <TableCell {...this.makeCellSortProps('isActive')}>{this.makeSortLabel('isActive','Active')}</TableCell>
                <TableCell>Organization admin</TableCell>
                <TableCell>Gateway admin</TableCell>
                <TableCell>Device admin</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListOrganizationUsers);

import React from 'react';
import Link from "@material-ui/core/Link";
import {CopyHelper} from '../../classes/Helper'
import moment from "moment";


const  isInt = (n)=>{
  return Number(n) === n && n % 1 === 0;
}

function getGatewayById(id, rxInfo) {
  if (!id) return false;

  if (rxInfo) {
    let GW = rxInfo.find((item) => {
      return item.gateway_id === id;
    });
    return GW || false;
  }
}

function checkCoordLength(str) {
  let res = str.toString();
  if (res.length > 8) {
    let tmp = res.split('.');
    tmp[1] = tmp[1].slice(0, 5);
    res = tmp.join('.');
  }
  return res;
}
export default [
    {
        dataName: 'dateTime',
        default:true,
        head: {
          render: () => {
            return "UTC Timestamp"
          }
        },
        cell: {
          render: (data) => {
            return moment.utc(data.dateTime).format('YYYY-MM-DD HH:mm:ss.SSS');
          }
        }
      },
      {
        dataName: 'localTime',
        default:true,
        head: {
          render: () => {
            return "Local Timestamp"
          }
        },
        cell: {
          render: (data) => {
            return moment.utc(data.dateTime).local().format('YYYY-MM-DD HH:mm:ss.SSS')
          }
        }
      },
      {
        dataName: 'devEUI',
        default:true,
        head: {
          render: () => {
            return  "DevEUI"
          }
        },
        cell: {
          render: (data) => {
            const copy = (e) =>{
                let devEui = e.target.innerText.match(/[0-9a-fA-F]{16}/ig)
                if(devEui){
                  CopyHelper({target:{innerText:devEui[0]}})
                }
            }
            return (<span onDoubleClick={copy}>{data.devEUI.toUpperCase()} <Link href={`#/redirect?device=${data.devEUI}`}>&gt;&gt;</Link></span>)
          }
        },
        filter: {
          validation: {
            rule: /^[0-9a-fA-F]{16}$/ig   //hex only
          }
        }
      },
      {
        dataName: 'devLAT',
        default:true,
        head: {
          render: () => {
            return  "Dev LAT"
          }
        },
        cell: {
          render: (data) => {
            return data.devLAT
          }
        },
     
      },
      {
        dataName: 'devLONG',
        default:true,
        head: {
          render: () => {
            return  "Dev LONG"
          }
        },
        cell: {
          render: (data) => {
            return data.devLON
          }
        },
      
      },
      {
        dataName: 'fCnt',
        default:true,
        head: {
          render: () => {
            return  "FCnt"
          }
        },
        cell: {
          style: (data,props) => {
            let color = 'unset'
            if(props.fCntFlag){
              color = '#CD5C5C'
            }  
            return {
              background: color
            }
          },
          render: (data) => {
            return (data.FCntUp);

           
          }
        },
     
      },
      {
        dataName: 'rxRssi',
        default:true,
        head: {
          render: () => {
            return  "RSSI"
          }
        },
        cell: {
          render: (data) => {
            return data.rxRssi
          }
        },
      
      },
      {
        dataName: 'rxSnr',
        default: true,
        head: {
          render: () => {
            return "SNR";
          }
        },
        cell: {
          render: (data) => {
            return ((data.rxSnr === 0 && data.direction === 'TX') ? '' : (isInt(data.rxSnr)?data.rxSnr:data.rxSnr.toFixed(1) ))
          }
        },
      
      },
      {
        dataName: 'sf',
        default: true,
        head: {
          render: () => {
            return "SF";
          }
        },
        cell: {

          render: (data) => {
            if (data && data.txInfo && data.txInfo.ModulationInfo.LoraModulationInfo && data.txInfo.ModulationInfo.LoraModulationInfo && data.txInfo.ModulationInfo.LoraModulationInfo.spreading_factor) {
              return data.txInfo.ModulationInfo.LoraModulationInfo.spreading_factor;
            }
            return (null)
          }
        }
     
      },
      {
        dataName: 'gwid',
        default: true,
        head: {
          render: () => {
            return "GW Id";
          }
        },
        cell: {
          render: (data) => {
            const copy = (e) =>{
              let gw = e.target.innerText.match(/[0-9a-fA-F]{16}/ig)
              if(gw){
                CopyHelper({target:{innerText:gw[0]}})
              }
          } 
           return (<span onDoubleClick={copy}>{data.gw.toUpperCase()} <Link href={`#/redirect?gateway=${data.gw}`}>&gt;&gt;</Link></span>)
          }
        },
       
      },
      {
        dataName: 'gwlat',
        default: true,
        head: {
          render: () => {
            return "GW Lat";
          }
        },
        cell: {
          render: (data) => {
            const gw = getGatewayById(data.gw, data.RxInfo)
            if (!!gw && gw.location !== null && Object.keys(gw.location).length > 0) {
              return checkCoordLength(gw.location.latitude);
            }
            return (null)
          }
        },
       
      },
      {
        dataName: 'gwlong',
        default: true,
        head: {
          render: () => {
            return "GW Long";
          }
        },
        cell: {
          render: (data) => {
            const gw = getGatewayById(data.gw, data.RxInfo)
            if (!!gw && gw.location !== null && Object.keys(gw.location).length > 0) {
              return checkCoordLength(gw.location.longitude);
            }
            return (null)
          }
        },
      
      },
      {
        dataName: 'gwcount',
        default: true,
        head: {
          render: () => {
            return "GW Count";
          }
        },
        cell: {
          render: (data) => {
            if (data.RxInfo && data.RxInfo.length > 0) {
              return data.RxInfo.length
            } else {
              if (data.gw && data.gw !== '' && data.direction !== 'TX') {
                return 1
              } else {
                return ''
              }
            }
          }
        },
     
      },
      
      {
        dataName: 'distance',
        default: false,
        head: {
          render: () => {
            return "Distance";
          }
        },
        cell: {
          render: (data) => {
            return data.distance;
          }
        },
      
      },
      {
        dataName: 'payload',
        default: false,
        head: {
          render: () => {
            return "Payload";
          }
        },
        cell: {
          render: (data) => {
            return data.payload;
          }
        }
      }
]
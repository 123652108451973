import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import {CopyHelper} from "../../classes/Helper";
import DataTable from "../../components/DataTable";
import SessionStore from "../../stores/SessionStore";
import internalStore from "../../stores/InternalStore";
import { withStyles } from "@material-ui/core/styles";

const styles ={
  tableGrid:{
    maxWidth:'361px',
    position:'relative'
  },
  actionArea:{
    position:'absolute',
    top:0,
    right:0,
    padding:0
  },
  copyIcon:{
    width:'15px',
    height:'15px',
    cursor:'pointer'
  }
}

class AboutPage extends Component {
  constructor() {
    super();

    this.getPage = this.getPage.bind(this)
    this.getRow  = this.getRow.bind(this)
    this.copyToClipBoard  = this.copyToClipBoard.bind(this)
    this.tableRef = React.createRef();
  }

  getPage(limit, offset, callbackFunc){
    let orgId = (SessionStore.isAdmin())?0:SessionStore.getOrganizationID()
    internalStore.getStackInfo(orgId,(obj)=>{
      let keys = Object.keys(obj)
      
      let result = keys.map((item,c)=>{
        return {
          id:c,
          name:item,
          details:obj[item].replace('\n',"")
        }
      })
      callbackFunc({result:result})

    })
    
  }

  copyToClipBoard(event){
    CopyHelper({
      target:{ 
        innerText: event.currentTarget.parentNode.nextSibling.lastChild.innerText
      }
    })
  }

  getRow(obj){
      return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell>{obj.name}</TableCell>
        <TableCell>{obj.details}</TableCell>
      </TableRow>
    );
  }

  render() {
   
    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title="About" />
        </TitleBar>

        <Grid  item xs={12}>
          <Card className={this.props.classes.tableGrid}>
          <CardActions className={this.props.classes.actionArea}> <FileCopyIcon onClick={this.copyToClipBoard} className={this.props.classes.copyIcon} titleAccess={'copy to clipboard'}/> </CardActions> 
          <DataTable
            ref={this.tableRef}
            pagination={false}
            getPage={this.getPage}
            getRow={this.getRow}
          />
          </Card>
       
        </Grid>
      </Grid>
    );
  }
}

export default withRouter( withStyles(styles)(AboutPage)  );

import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {CardContent} from "@material-ui/core";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import AsRoutingForm from "./AsRoutingForm";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import RoutingProfileStore from "../../stores/RoutingProfileStore"
import ApplicationStore from "../../stores/ApplicationStore";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
class CreateAsRoutingProfile extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.createDialog = this.createDialog.bind(this);
    this.dismisDialog = this.dismisDialog.bind(this);

    this.state = {
      tab: 0,
      dialog:false,
      dialogOk:false,
      appServers: []
    }
  }

  onSubmit(routingProfile) {

  if(!this.state.dialogOk && (!routingProfile.applications||(routingProfile.applications&&routingProfile.applications.length===0))){
    this.setState({dialog:true})
    return
  }

    routingProfile.organizationID = +this.props.match.params.organizationID;
    RoutingProfileStore.create(routingProfile, resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/as-routing-profiles`);
    });
  }


  componentDidMount() {
    ApplicationStore.list("", this.props.match.params.organizationID, 999, 0, resp => {
      this.setState({
        appServers: resp.result
      })
    })
  }


  onChangeTab = (e, v) => {
    this.setState({
      tab: v,
    });
  }
  dismisDialog(){
      this.setState({dialog:false})
  }

  createDialog(){
    this.setState({dialog:false,dialogOk:true})
  }
  render() {
    const {match} = this.props;

    return (
      <Grid container spacing={4}>
        <TitleBar>
        <OrganizationBreadcrumps org={this.props.org}>
            <TitleBarTitle title="AS routing profiles" to={`/organizations/${match.params.organizationID}/as-routing-profiles`}/>
            <TitleBarTitle title="/"/>
            <TitleBarTitle title="Create"/>
          </OrganizationBreadcrumps> 
          
        </TitleBar>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Tabs
                value={this.state.tab}
                onChange={this.onChangeTab}
                indicatorColor="primary"
              >
                <Tab label="General"/>
              </Tabs>
              <AsRoutingForm
                submitLabel="Create AS routing profile"
                onSubmit={this.onSubmit}
                tab={this.state.tab}
                match={match}
                appServers={this.state.appServers}
              />

                <Dialog
                          open={this.state.dialog}
                          onClose={this.dismisDialog}
                        >
                          <DialogTitle>Create AS Routing Profile?</DialogTitle>
                          <DialogContent>
                            <DialogContentText paragraph>
                              You did not used any application server, create AS routing profile anyway?
                            </DialogContentText>
                            <DialogContentText>
                              
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                          <Button color="primary" onClick={this.dismisDialog}>Dismiss</Button>
                            <Button color="primary"  onClick={this.createDialog}>Create AS routing profile</Button>
                            
                          </DialogActions>
                        </Dialog>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
};

export default withRouter (CreateAsRoutingProfile);

import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';


function validateDevEUIValue(value) {
  const rule = /^[0-9a-fA-F]{16}$/ig;
  return value.search(rule) !== -1 ? false : true;
}

class DevEUITextField extends Component {
  constructor() {
    super();

    this.state = {
      value: '',
      error: false,
    };

    this.handleClearField = this.handleClearField.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    });
  }

  handleClearField() {
    let state = this.state;
    state.value = '';
    state.error = false;
    this.setState(state);
    this.props.handleDevEUIChange('');
  }

  onChange(e) {
    let state = this.state;
    state.value = e.target.value.toLowerCase().trim();
    state.error = (state.value && state.value.length > 0) ? validateDevEUIValue(state.value) : false;
    // this.setState(state);

    if (state.value && state.value.length > 0 && !validateDevEUIValue(state.value)) {
      this.props.handleDevEUIChange(state.value);
    } else if (!state.value || state.value === '') {
      this.props.handleDevEUIChange('');
    }
    this.setState(state);
  }

  render() {

    return(
      <TextField
        label={this.state.error ? 'HEX, 16 chars only' : 'DevEUI'}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment:
            <InputAdornment position="end" >
              {this.state.value && this.state.value.length > 0 && <IconButton
                onClick={this.handleClearField}
                style={{padding:"0"}} color="default" component="span" >
                <ClearIcon />
              </IconButton>}
            </InputAdornment>
        }}
        error={this.state.error}
        
        onChange={this.onChange}
        value={this.state.value}
        disabled={this.props.disabled}
        variant="outlined"
        size="small"
      />
    );
  }
}


export default DevEUITextField;
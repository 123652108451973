import { Component } from "react";
import SessionStore from "../stores/SessionStore";


class GatewayAdmin extends Component {
  constructor() {
    super();
    this.state = {
      admin: false,
    };

    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    this.setIsAdmin();
  }

setIsAdmin() {
    if (SessionStore.isAdmin()) {
        this.setState({
            admin: true
        });
        return
    }

    if (this.props.organizationID !== undefined) {
        if (!SessionStore.organizationCanHaveGateways(this.props.organizationID)) {
            return
        }

        this.setState({
                admin: SessionStore.isOrganizationGatewayAdmin(this.props.organizationID),
        });
    }
}

  render() {
    if (this.state.admin) {
      return(this.props.children);
    }

    return(null);
  }
}

export default GatewayAdmin;

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import OrganizationStore from "../../stores/OrganizationStore";
import OrganizationUserForm from "./OrganizationUserForm";
import SessionStore from "../../stores/SessionStore";
import UserForm from "../users/UserForm";
import UserStore from "../../stores/UserStore";
import moment from 'moment'
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


class CreateOrganizationUser extends Component {
  constructor() {
    super();

    this.onAssignUser = this.onAssignUser.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      exist:false
    }
  }

  onAssignUser(user) {
    if (user && user.email && user.email.length) {
      user.email = user.email.toLowerCase();
    }
    OrganizationStore.addUser(this.props.match.params.organizationID, user, resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/users`);
    });
  };
  onSubmit(user) {
    if (user && user.email && user.email.length) {
      user.email = user.email.toLowerCase();
    }
    if(user.phone){ user.phone = user.phone.toString() }
    if (user.isActive===undefined){
      user.isActive = true;
    }
    UserStore.create(user, user.password, [
      {
        "isAdmin": user.isAdmin,
        "isDeviceAdmin": user.isDeviceAdmin,
        "isGatewayAdmin": user.isGatewayAdmin,
        "organizationID": SessionStore.getOrganizationID()
      }
    ], resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/users`);
    });
  }

  render() {
    let dates = {}

    dates.createdAt = moment().format('DD-MM-YYYY HH:mm:ss')
    dates.loginAt = 'Never'
    return(
      <Grid container spacing={4}>
        <TitleBar>
        <OrganizationBreadcrumps org={this.props.org}>
          <TitleBarTitle title="Organization users" to={`/organizations/${this.props.match.params.organizationID}/users`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title={(SessionStore.isAdmin())?"Add":"Create"} />
        </OrganizationBreadcrumps>  
        </TitleBar>

        {(SessionStore.isAdmin() || this.state.exist) &&
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <h3>Add existing user</h3>
              <OrganizationUserForm
                  submitLabel="Add user"
                  onSubmit={this.onAssignUser}
                  update={false}
              />
            </CardContent>
          </Card>
        </Grid>}

        {SessionStore.isOrganizationAdmin( SessionStore.getOrganizationID() )  && 
         <Grid item xs={12}>
          <Card>
            <CardContent>   
              <h3>Create new user</h3>    
              <UserForm
                  dates={dates}
                  submitLabel="Create user"
                  onSubmit={this.onSubmit}
              />
            </CardContent>
          </Card>
        </Grid>}
      </Grid>
    );
  }
}

export default withRouter(CreateOrganizationUser);

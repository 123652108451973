import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";


class ApplicationStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/application.swagger.json", sessionStore.getClientOpts());
  }

  create(application, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_Create({
        body: {
          application: application,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  get(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_Get({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  update(application, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_Update({
        "application.id": application.id,
        body: {
          application: application,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  delete(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_Delete({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("deleted");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  list(search, organizationID, limit, offset, callbackFunc, orderBy, order) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_List({
        limit: limit,
        offset: offset,
        organizationID: organizationID,
        search: search,
        orderBy:orderBy,
        order:order
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  listIntegrations(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_ListIntegrations({
        application_id: applicationID,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  createHTTPIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateHTTPIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("http", "created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  getHTTPIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetHTTPIntegration({
        application_id: applicationID,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  updateHTTPIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateHTTPIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("http", "updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  deleteHTTPIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteHTTPIntegration({
        application_id: applicationID,
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("http", "deleted");
        this.emit("integration.delete");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  createInfluxDBIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateInfluxDBIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("InfluxDB", "created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  getInfluxDBIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetInfluxDBIntegration({
        application_id: applicationID,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  updateInfluxDBIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateInfluxDBIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("InfluxDB", "updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  deleteInfluxDBIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteInfluxDBIntegration({
        application_id: applicationID,
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("InfluxDB", "deleted");
        this.emit("integration.delete");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  createThingsBoardIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateThingsBoardIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp =>  {
          this.integrationNotification("ThingsBoard.io", "created");
          callbackFunc(resp.obj);
        })
      .catch(errorHandler);
    });
  }

  getThingsBoardIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetThingsBoardIntegration({
        application_id: applicationID, 
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
      .catch(errorHandler);
    });
  }

  updateThingsBoardIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateThingsBoardIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("ThingsBoard.io", "updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  deleteThingsBoardIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteThingsBoardIntegration({
        application_id: applicationID,
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("ThingsBoard.io", "deleted");
        this.emit("integration.delete");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  createKafkaIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateKafkaIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp =>  {
          this.integrationNotification("LPWAN.Metering", "created");
          callbackFunc(resp.obj);
        })
      .catch(errorHandler);
    });
  }

  getKafkaIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetKafkaIntegration({
        application_id: applicationID, 
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
      .catch(errorHandler);
    });
  }

  updateKafkaIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateKafkaIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("LPWAN.Metering", "updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  
  deleteKafkaIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteKafkaIntegration({
        application_id: applicationID,
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("LPWAN.Metering", "deleted");
        this.emit("integration.delete");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  createMyDevicesIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateMyDevicesIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp =>  {
          this.integrationNotification("myDevices", "created");
          callbackFunc(resp.obj);
        })
      .catch(errorHandler);
    });
  }

  getMyDevicesIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetMyDevicesIntegration({
        application_id: applicationID, 
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
      .catch(errorHandler);
    });
  }

  updateMyDevicesIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateMyDevicesIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("myDevices", "updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  deleteMyDevicesIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteMyDevicesIntegration({
        application_id: applicationID,
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("myDevices", "deleted");
        this.emit("integration.delete");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  createLoRaCloudIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateLoRaCloudIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("LoRa Cloud", "created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getLoRaCloudIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetLoRaCloudIntegration({
        application_id: applicationID, 
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
      .catch(errorHandler);
    });
  }

  updateLoRaCloudIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateLoRaCloudIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("LoRa Cloud", "updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  deleteLoRaCloudIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteLoRaCloudIntegration({
        application_id: applicationID,
      })
      .then(checkStatus)
      .then(resp => {
        this.integrationNotification("LoRa Cloud", "deleted");
        this.emit("integration.delete");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "application server has been " + action,
      },
    });
  }

  createGCPPubSubIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateGCPPubSubIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("GCP Pub/Sub", "created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getGCPPubSubIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetGCPPubSubIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  updateGCPPubSubIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateGCPPubSubIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("GCP Pub/Sub", "updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  deleteGCPPubSubIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteGCPPubSubIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("GCP Pub/Sbu", "deleted");
          this.emit("integration.delete");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  createAWSSNSIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateAWSSNSIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("AWS SNS", "created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getAWSSNSIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetAWSSNSIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  updateAWSSNSIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateAWSSNSIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("AWS SNS", "updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  deleteAWSSNSIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteAWSSNSIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("AWS SNS", "deleted");
          this.emit("integration.delete");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  createAzureServiceBusIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateAzureServiceBusIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("Azure Service-Bus", "created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getAzureServiceBusIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetAzureServiceBusIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  updateAzureServiceBusIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateAzureServiceBusIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("Azure Service-Bus", "updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  deleteAzureServiceBusIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteAzureServiceBusIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("Azure Service-Bus", "deleted");
          this.emit("integration.delete");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  createPilotThingsIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreatePilotThingsIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("Pilot Things", "created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getPilotThingsIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetPilotThingsIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  updatePilotThingsIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdatePilotThingsIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("Pilot Things", "updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  deletePilotThingsIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeletePilotThingsIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("Pilot Things", "deleted");
          this.emit("integration.delete");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  generateMQTTIntegrationClientCertificate(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GenerateMQTTIntegrationClientCertificate({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }
  createMQTTIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_CreateMQTTIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("MQTT", "created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  updateMQTTIntegration(integration, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_UpdateMQTTIntegration({
        "integration.applicationID": integration.applicationID,
        body: {
          integration: integration,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("MQTT", "updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getMQTTIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_GetMQTTIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }



  deleteMQTTIntegration(applicationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.ApplicationService.ApplicationService_DeleteMQTTIntegration({
        application_id: applicationID,
      })
        .then(checkStatus)
        .then(resp => {
          this.integrationNotification("MQTT", "deleted");
          this.emit("integration.delete");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }


  integrationNotification(kind, action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: kind + " integration has been " + action,
      },
    });
  }
}

const applicationStore = new ApplicationStore();
export default applicationStore;

import React, { Component } from "react";

import { unparse } from "uuid-parse";
import {JSONTree as JSONTreeOriginal} from "react-json-tree";


class JSONTree extends Component {
  render() {
    const theme = {
      scheme: 'google',
      author: 'seth wright (http://sethawright.com)',
      base00: '#000000',
      base01: '#282a2e',
      base02: '#373b41',
      base03: '#969896',
      base04: '#b4b7b4',
      base05: '#c5c8c6',
      base06: '#e0e0e0',
      base07: '#ffffff',
      base08: '#CC342B',
      base09: '#F96A38',
      base0A: '#FBA922',
      base0B: '#198844',
      base0C: '#3971ED',
      base0D: '#3971ED',
      base0E: '#A36AC7',
      base0F: '#3971ED',
    }

    // :(
    let data = JSON.parse(JSON.stringify(this.props.data));
   

    if ("rxInfo" in data && data.rxInfo !== null) {
      for (let i = 0; i < data.rxInfo.length; i++) {

        if ("uplinkID" in data.rxInfo[i] && data.rxInfo[i].uplinkID !== null) {
          const id = Buffer.from(data.rxInfo[i].uplinkID, 'base64');
          data.rxInfo[i].uplinkID = unparse(id);
        }
      }
    }

    if ("uplinkIDs" in data && data.uplinkIDs !== null) {
      for (let i = 0; i < data.uplinkIDs.length; i++) {
        const id = Buffer.from(data.uplinkIDs[i], 'base64');
        data.uplinkIDs[i] = unparse(id);
      }
    }

    if ("objectJSON" in data && data.objectJSON !== null && data.objectJSON !== "") {
      data.objectJSON = JSON.parse(data.objectJSON);
    }

    return(
      <JSONTreeOriginal
        labelRenderer={this.props.labelRenderer}
        valueRenderer={this.props.valueRenderer}
        data={data}
        theme={theme}
        hideRoot={true}
        shouldExpandNode={this.props.shouldExpandNode||(() => { return true })}
      />
    );
  }
}

function base64ToHex(str) {
  return (str)?Buffer.from(str, 'base64').toString('hex'):"";
}
if (!window.atob) {
  var tableStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  var table = tableStr.split("");

  window.atob = function (base64) {
    if (/(=[^=]+|={3,})$/.test(base64)) throw new Error("String contains an invalid character");
    base64 = base64.replace(/=/g, "");
    var n = base64.length & 3;
    if (n === 1) throw new Error("String contains an invalid character");
    for (var i = 0, j = 0, len = base64.length / 4, bin = []; i < len; ++i) {
      var a = tableStr.indexOf(base64[j++] || "A"), b = tableStr.indexOf(base64[j++] || "A");
      var c = tableStr.indexOf(base64[j++] || "A"), d = tableStr.indexOf(base64[j++] || "A");
      if ((a | b | c | d) < 0) throw new Error("String contains an invalid character");
      bin[bin.length] = ((a << 2) | (b >> 4)) & 255;
      bin[bin.length] = ((b << 4) | (c >> 2)) & 255;
      bin[bin.length] = ((c << 6) | d) & 255;
    };
    return String.fromCharCode.apply(null, bin).substr(0, bin.length + n - 4);
  };

  window.btoa = function (bin) {
    for (var i = 0, j = 0, len = bin.length / 3, base64 = []; i < len; ++i) {
      var a = bin.charCodeAt(j++), b = bin.charCodeAt(j++), c = bin.charCodeAt(j++);
      if ((a | b | c) > 255) throw new Error("String contains an invalid character");
      base64[base64.length] = table[a >> 2] + table[((a << 4) & 63) | (b >> 4)] +
          (isNaN(b) ? "=" : table[((b << 2) & 63) | (c >> 6)]) +
          (isNaN(b + c) ? "=" : table[c & 63]);
    }
    return base64.join("");
  };

}
function hexToBase(str) {
  return btoa(String.fromCharCode.apply(null,
      str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
  );
}
export default JSONTree;
export {base64ToHex , hexToBase}
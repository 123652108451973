import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import SessionStore from "../../stores/SessionStore";
import L from "leaflet";
import { Map, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import MapTileLayer from "../../components/MapTileLayer";
import GatewayStore from "../../stores/GatewayStore";
import HandyRustyStore from "../../stores/HandyRustyStore";

import CardHeader from "@material-ui/core/CardHeader";

import { decodeFromHex } from "../../classes/Helper";
const styles = {};

class CttMap extends Component {
  constructor() {
    super();
    this.loadMap = this.loadMap.bind(this);
    this.state = { loading: true, noData: false };
  }

  componentDidMount() {
    let query = SessionStore.getQueryString();
    if (query?.devEUI) {
      let filter = {
        start: query?.start,
        end: query?.end,
        filters: {
          direction: "RX",
          devEUI: query?.devEUI,
        },
      };
      this.loadMap(filter);
    } else {
      this.setState({ loading: false, noData: true });
    }
  }
  async loadMap(filter) {
    const getAllGateways = () => {
      return new Promise((resolve, reject) => {
        GatewayStore.list(null, null, 99999, 0, (obj) => {
          resolve(obj.result);
        });
      });
    };

    const fetchAllFrames = () => {
      return new Promise((resolve, reject) => {
        HandyRustyStore.getDeviceFrames(filter, 2000, 0, (obj) => {
          resolve(obj.result);
        });
      });
    };

    const allGateways = await getAllGateways();
    const deviceFrames = await fetchAllFrames();
    const filteredGatewaysInFrames = [
      ...new Set(deviceFrames.map((obj) => obj.gw)),
    ];
    const filteredArray = allGateways.filter((obj) =>
      filteredGatewaysInFrames.includes(obj.id)
    );

    if (filteredArray.length > 0 && deviceFrames.length > 0) {
      this.bounds = [];
      this.gatewayMarkers = [];
      this.deviceMarkers = [];
      const greenMarker = L.AwesomeMarkers.icon({
        icon: "wifi",
        prefix: "fa",
        markerColor: "green",
      });
      const orangeMarker = L.AwesomeMarkers.icon({
        icon: "wifi",
        prefix: "fa",
        markerColor: "orange",
      });

      for (const item of filteredArray) {
        if (item.location.latitude && item.location.longitude) {
          const position = [item.location.latitude, item.location.longitude];
          this.bounds.push(position);
          this.gatewayMarkers.push(
            <Marker
              position={position}
              key={`gw-${item.id}`}
              icon={orangeMarker}
            >
              <Popup>{item.id}</Popup>
            </Marker>
          );
        }
      }


      let uniqCoordinatesArray = [];
      
      for (const item of deviceFrames) {
        let decoded = decodeFromHex(item.frmPayload, ["nav"]);
        let latitude = parseFloat(decoded.latitude);
        let longitude = parseFloat(decoded.longitude);
        if (latitude && longitude) {


          if (!uniqCoordinatesArray.includes([latitude, longitude].join())) {
            uniqCoordinatesArray.push([latitude, longitude].join());

            const position = [latitude, longitude];
            this.bounds.push(position);
            this.deviceMarkers.push(
              <Marker position={position} icon={greenMarker}>
                <Popup>{item.devEUI}</Popup>
              </Marker>
            );
          }
        }
      }
    } else {
      this.setState({ noData: true });
    }
    this.setState({ loading: false });
  }

  render() {
    const styleMap = {
      height: 600,
    };

    if (this.state.loading) {
      return <LinearProgress />;
    } else {
      if (!this.state.noData) {
        return (
          <Map
            bounds={this.bounds}
            maxZoom={19}
            style={styleMap}
            animate={true}
            scrollWheelZoom={false}
          >
            <MapTileLayer />
            <MarkerClusterGroup>{this.gatewayMarkers}</MarkerClusterGroup>
            {this.deviceMarkers}
          </Map>
        );
      } else return <CardHeader title={"No data"}></CardHeader>;
    }
  }
}

export default withStyles(styles)(CttMap);

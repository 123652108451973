import React, { Component } from "react";
import moment from "moment";

import InternalStore from '../../../stores/InternalStore'
import HandyRustyStore from '../../../stores/HandyRustyStore'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import {CircularProgress} from "@material-ui/core";
import {Line, Chart, Bar} from 'react-chartjs-2';
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import zoomPlugin from 'chartjs-plugin-zoom';
import {ExportJSONtoCSV} from '../../../classes/Helper'
import { matchPath } from "react-router";
import { LTTB } from 'downsample';
import SessionStore from "../../../stores/SessionStore";


const styles = {
  chart: {
    minHeight: 300,
  },
  chartBig: {
    minHeight: 400,
  },
  graphActionContainer:{
    display:'inline-flex',
    alignItems:'center'
  },
  doughtnutChart: {
    maxWidth: "350px",
    padding: 0,
    margin: "auto",
    display: "block",
  },  
};

class SummaryLog extends Component{

    constructor(){
        super();
        this.load = this.load.bind(this)
        this.preLoad = this.preLoad.bind(this)
        this.setTimeUnit = this.setTimeUnit.bind(this)
        this.exportClickHandle = this.exportClickHandle.bind(this)
        this.paddingDatasetInterval = 0.1
        Chart.register(zoomPlugin)
        
        this.date = {
          start: moment().subtract(31, 'days').toISOString(),
          end:   moment().toISOString()
        }
        let that = this
        this.state ={
            loader:false,
            counters:[],
           
            options:{
              
              maintainAspectRatio: false,
              follow: true,
              animation: false,
              plugins: {
                tooltip:{
                    callbacks:{
                      label:function(context){
                        let label = context.dataset.label || '';
                        if (label) {
                          label += ': ';
                      }
                      if (context.parsed.y !== null) {
                          label += parseInt(context.parsed.y)
                      }
                     
                        return label
                      }
                    }
                },
                zoom:{
                  pan: {
                    enabled:true,
                    mode: 'x',
                    onPanComplete : function({chart}) {
                      that.date.start = moment(chart.scales.x.min).toISOString()
                      that.date.end = moment(chart.scales.x.max).toISOString()
                      that.load()
                    }
                  },
                }
              },
            
              scales: {
                x: {
                  bounds:'data',
                  type: 'time',
                  ticks:{
                    maxTicksLimit:31,
                    minTicksLimit:10,
                    autoSkip:false,
                    maxRotation:0,
                  },
                  time: {
                    displayFormats:{
                      minute:'HH:mm',
                      hour:'HH:00',
                      day:'MMM D',
                      week:"MMM D",
                      month:"MMM D",
                      quarter:"MMM D",
                      year:"MMM YYYY",
                    },
                    round:true,
                    unit:'day',
                    tooltipFormat: 'DD-MM-YYYY HH:mm',
                  }
                },
                y:{
                  beginAtZero: true,
                  display:true,
                  ticks:{
                    maxTicksLimit:10,
                    stepSize:1,
                    min: 2,
                  }
                },
                
              },
            }
        }
       
        const match = matchPath(window.location.hash, {
          path: "#/organizations/:id",
          strict: false
        });
        if(match!==null){
          this.orId = match.params.id
        }

      this.datasetsParams =  [{label:'total',color: "rgb(17,105,178)",objName:'total',csv:'total'}
                              ,{label:'active',color:"rgb(96,243,33)",objName:'activeCount',csv:'activeCount'}
                              ,{label:'inactive',color: "rgb(255,85,0)",objName:'inactiveCount',csv:'inactiveCount'}
                              ,{label:'neverseen',color:"rgb(250,166,36)",objName:'neverSeenCount',csv:'neverSeenCount'}]
      
      
    }

    setCounters(counters,cb){
      if(counters.length>0){
        counters = counters.map((el)=>{
          el.total = el.activeCount + el.neverSeenCount + el.inactiveCount
          
           if(el.activeCount&&el.activeCount===el.inactiveCount){
              el.activeCount+=this.paddingDatasetInterval
            }
            if(el.inactiveCount&&el.inactiveCount===el.neverSeenCount){
              el.inactiveCount+=this.paddingDatasetInterval
            }
            if(el.neverSeenCount&&el.activeCount===el.neverSeenCount){
              el.activeCount+=this.paddingDatasetInterval
            }

          el.y = moment.utc(el.createdAt * 1000).valueOf()

          return el
        })
        this.exportJson = {
          filename:this.title
          ,dataset:counters
          ,columns:[{objName:'y',csv:'Created at',time:true}, ...this.datasetsParams]
          ,parse:{
              int:true,
              utc:false,
              format:'DD-MM-YYYY'
          }
          ,reverse:true
          
        }

       }
        
          this.setState({loader:false,counters:counters||[]},cb)
    }
    preLoad(){
      this.orId = parseInt(SessionStore.getOrganizationID())
      this.load()
    }
    componentDidMount(){
      this.load()
      SessionStore.on("organization.change", this.preLoad);
    }
    componentWillUnmount(){
      SessionStore.removeListener("organization.change", this.preLoad);
    }
    exportClickHandle(e){
      ExportJSONtoCSV(this.exportJson)
    }
    
    setTimeUnit(unit){
      let opts = this.state.options
      opts.scales.x.time.unit  = unit
      this.setState({options:opts})
    }
    getDatasetEl(el){
        return {
          borderWidth:1,
          radius:1,
          stepped:false,
          label: el.label.charAt(0).toUpperCase() + el.label.slice(1),
          data: this.state.counters||[],
          parsing: {
            xAxisKey: 'y',
            yAxisKey:el.objName
          },
          borderColor:el.color,
          backgroundColor: el.color,
          lineTension: 0,
          pointBackgroundColor: el.color,
        }
    }
    render(){
     
      let datasets = this.datasetsParams.map((el)=>{
          return   this.getDatasetEl(el)
      })

      return (
      <Card>
        <CardHeader title={this.title} action={
        <div className={this.props.classes.graphActionContainer}>
            {this.state.loader&& <CircularProgress size={'20px'} />} 
            <IconButton aria-label="delete" size="small" onClick={this.exportClickHandle}>
                <ArrowDownwardIcon titleAccess={'Download in CSV'} fontSize="inherit" />
            </IconButton>
           
         </div>}/>
        <CardContent className={this.props.classes.chart} >
            <Line  id={this.canvasId} data={{datasets: datasets}}  options={this.state.options}/>
        </CardContent>
      </Card>
     )
    }

}


class Devices extends SummaryLog{
    constructor(){
      super();

      this.title = 'Devices count'
      this.canvasId = 'deviceSummaryLog'
      
    }


    load(cb){
      this.setState({loader:true})
      InternalStore.getDevicesSummaryLog(this.orId||0,this.date.start,this.date.end,this.state.options.scales.x.time.unit.toUpperCase(),(resp)=>{
        
        this.setCounters(resp.counters , cb)
      })
  }
}

class Gateways extends SummaryLog{
  constructor(){
    super();

    this.title = 'Gateways count'
    this.canvasId = 'gatewaySummaryLog'
  
    
  }


  load(cb){
    this.setState({loader:true})
    InternalStore.getGatewaysSummaryLog(this.orId||0,this.date.start,this.date.end,this.state.options.scales.x.time.unit.toUpperCase(),(resp)=>{
    
      this.setCounters(resp.counters , cb)
    })
  }
}



class Frames extends SummaryLog{

  constructor(){
      super();
   
      this.title = 'Messages per day'
      this.canvasId = 'messagesPerDay'
      

      this.datasetsParams =  [
                           //  {label:'Total',color:"rgb(17,105,178)",objName:'totalCnt',csv:'total'},
                              {label:'Confirmed Data Down',color:"rgb(243,131,33)",objName:'confirmedDataDown',csv:'confirmedDataDown'},
                              {label:'Confirmed Data Up',color: "rgb(44,128,217)",objName:'confirmedDataUp',csv:'confirmedDataUp'},
                              {label:'Join Accept',color: "rgb(227,217,23)",objName:'joinAccept',csv:'joinAccept'},
                              {label:'Join Request',color: "rgb(100,100,100)",objName:'joinRequest' ,csv:'joinRequest'},
                              {label:'Proprietary',color: "rgb(0,0,0)" ,objName:'proprietary',csv:'proprietary'}, 
                              {label:'Rejoin Request',color: "rgb(255,0,0)",objName:'rejoinRequest',csv:'rejoinRequest'},
                              {label:'Unconfirmed Data Down',color: "rgb(255,202,182)",objName:'unconfirmedDataDown',csv:'unconfirmedDataDown'},
                              {label:'Unconfirmed Data Up',color: "rgb(50,236,200)",objName:'unconfirmedDataUp',csv:'unconfirmedDataUp'},
                              {label:'Unknown Type',color: " rgb(117,110,124)",objName:'unknownType',csv:'unknownType'},
                            ]
     this.state.options.scales.x.stacked=true;
     this.state.options.scales.y.stacked=true;
     this.state.options.tooltip = {
          intersect: true
        }
     this.state.options.scales.x.gridLines = {
              offsetGridLines: true
            }

      this.state.options.plugins.legend = {
              position: 'right'
            }

            this.state.options.plugins.tooltip.callbacks =  {
              afterBody: function(context,data){ 
            
                let total = context[0].raw.totalCnt

                return 'Total: '+ total
              }
            }  
           

  }

  setCounters(counters,cb){
    if(counters.length>0){
      counters = counters.map((el)=>{
          el.y = moment.utc(el.date * 1000).valueOf()
        return el
      })
//{objName:'totalCnt',csv:'total'}
      this.exportJson = {
        filename:this.title
        ,dataset:counters
        ,columns:[{objName:'y',csv:'Created at',time:true},...this.datasetsParams]
        ,parse:{
            int:true,
            utc:false,
            format:'DD-MM-YYYY'
        }
        ,reverse:true
        
      }
     }
     
        this.setState({loader:false,counters:counters||[]},cb)
  }

  load(cb){
    this.setState({loader:true})
    HandyRustyStore.getFrameCounters(this.orId||0,this.date.start,this.date.end,resp => {
      this.setCounters(resp.counters , cb)
    })
  }
  exportClickHandle(e){
   
    this.exportJson.columns.push({label:'Total',color:"rgb(17,105,178)",objName:'totalCnt',csv:'total'})
  
    ExportJSONtoCSV(this.exportJson)
  }

  render(){
     
    let datasets = this.datasetsParams.map((el)=>{
     return {
        label: el.label,
      
        
        data: this.state.counters||[],
        borderColor: el.color,
        backgroundColor: el.color,
        pointBackgroundColor: el.color,
        parsing: {
          xAxisKey: 'y',
          yAxisKey:el.objName
        },
      }
      
    })

    return (
    <Card>
      <CardHeader title={this.title} action={
      <div className={this.props.classes.graphActionContainer}>
          {this.state.loader&& <CircularProgress size={'20px'} />} 
          <IconButton aria-label="delete" size="small" onClick={this.exportClickHandle}>
              <ArrowDownwardIcon titleAccess={'Download in CSV'} fontSize="inherit" />
          </IconButton>
         
       </div>}/>
      <CardContent className={this.props.classes.chartBig} >
          <Bar  id={this.canvasId} data={{datasets: datasets}}  options={this.state.options}/>
      </CardContent>
    </Card>
   )
  }

}

class Message extends SummaryLog{
  constructor(){
    super();

    this.title = 'Messages per minute'
    this.canvasId = 'messagesPerMinute'
    this.datasetsParams =  [{label: 'Messages',color: "rgb(221,136,75)",objName:'y',csv:'Count'}]
    this.state.options.plugins.legend = {
      display : false
    }
  }
  getDatasetEl(el){
    return{
      showLines: false,
      label: el.label.charAt(0).toUpperCase() + el.label.slice(1),
      borderWidth:0.5,
      radius:1,
      data: this.state.counters ||[],
      borderColor: el.color,
      backgroundColor: el.color,
      lineTension: 0,
      pointBackgroundColor: el.color,
      parsing: {
        xAxisKey: 'x',
        yAxisKey:el.objName
      }
    }
}
  setCounters(counters,cb){
    if(counters.length>0){
      counters = counters.map((el)=>{
      
        let time = moment.utc(parseInt(el.t) * 1000).valueOf()
       
        return {
          y:el.y,
          x:time,
          t:time,
        }
      })


      if(counters.length>500){
        let chartWidth = 500;
        counters =  LTTB(counters, chartWidth) 
      }

      this.exportJson = {
        filename:this.title
        ,dataset:counters
        ,columns:[{objName:'t',csv:'Created at',time:true}, ...this.datasetsParams]
        ,parse:{
          
            utc:false,
            format:'DD-MM-YYYY HH:mm'
        }
        ,reverse:true
        
      }

     }
      
        this.setState({loader:false,counters:counters||[]},cb)
  }
  load(cb){
    this.setState({loader:true})
    HandyRustyStore.getFrameSpeed(this.orId||0,this.date.start,this.date.end,resp => {
      this.setCounters(resp.counters , cb)
    })
  }



}




const DeviceSummaryLog  = withStyles(styles)(Devices)
const GatewaySummaryLog = withStyles(styles)(Gateways)
const FramesLog = withStyles(styles)(Frames)
const MessageLog = withStyles(styles)(Message)


export { DeviceSummaryLog , GatewaySummaryLog, FramesLog, MessageLog}

import React, { Component } from "react";


import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';


import DataTable from "../../components/DataTable";

import OrganizationStore from "../../stores/OrganizationStore";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {makeStyles} from "@material-ui/core/styles";
import TableCellLink from "../../components/TableCellLink";
import Delete from "mdi-material-ui/Delete";
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
},
});


class UserOrganizationsList extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.state = {
        orderBy:null,
        order:null
    }

    this.TableRef = React.createRef()
  }
 
 
  changeSort(event){
    let state = {
        orderBy:event.currentTarget.dataset.key,
        order:this.state.order
    }

    if(state.orderBy!==this.state.orderBy){
        state.order = null;
    }

    if(state.order === null){
        state.order = 'asc'
    }else if(state.order === 'asc'){
        state.order = 'desc'
    }else if(state.order === 'desc'){
        state.order = null
        state.orderBy = null
    }

    this.setState(state);
  }

  makeSortLabel(keyName,viewName){
    function MakeSortLabelHook() {
      const classes = useStyles();
      return<TableSortLabel
      data-key={keyName}
      active={this.state.orderBy === keyName}
      direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
      onClick={this.changeSort}
  >
    {viewName}
      {this.state.orderBy === keyName ? (
          <span className={classes.visuallyHidden}>
              {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
            </span>
      ) : null}
  </TableSortLabel>
    }
  return  MakeSortLabelHook
  }

  makeCellSortProps(keyName){
    return {
        key:keyName,
        align:'left',
        padding:'normal',
        sortDirection:this.state.orderBy === keyName ? this.state.order : false
    }
  }
  getPage(limit, offset, callbackFunc) {
    OrganizationStore.list("", limit, offset,  callbackFunc,this.state.orderBy, this.state.order,(this.props.user&&this.props.user.id)?this.props.user.id:undefined);
  }

  getRow(obj) {
    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell >{obj.id}</TableCell>
        <TableCellLink to={`/organizations/${obj.id}`}>{obj.name}</TableCellLink>
        <TableCell>{obj.displayName}</TableCell>
        <TableCell align="right">
                  <IconButton id={obj.id}  onClick={this.onDelete} className={'noUDpaddings'} aria-label="delete"><Delete /></IconButton>
         </TableCell>
       
      </TableRow>
    );
  }
  onDelete(e){
    if (window.confirm("Are you sure you want to remove the user from this organization? This does not remove the user itself.")) {
      OrganizationStore.deleteUser(e.currentTarget.id, this.props.user.id, resp => {
        this.TableRef.current.updateTable()
      });
    }
  }
  
  render() {
    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataTable
            ref={this.TableRef}
            header={
              <TableRow>
                <TableCell {...this.makeCellSortProps('id')}>{this.makeSortLabel('id','Id')}</TableCell>
                <TableCell {...this.makeCellSortProps('name')}>{this.makeSortLabel('name','Name')}</TableCell>
                <TableCell {...this.makeCellSortProps('displayName')}>{this.makeSortLabel('displayName','Display name')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default UserOrganizationsList;

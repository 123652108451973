import React from "react";
import TextField from '@material-ui/core/TextField';
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import NetworkServerStore from "../../stores/NetworkServerStore";
import { Dictionary } from "../../classes/Dictionary";

const styles = {
  formLabel: {
    fontSize: 12,
  },
  errorAutocomplete: {
    border: '2px solid red',
    borderRadius: '3px',
  },
  errorAutocompleteHelper: {
    color: 'red',
  }
};

class ServiceProfileForm extends FormComponent {
  constructor() {
    super();

    this.state = {
      SFOptions: [],
      TxOptions:[],
      NbOptions:[],
      errorDrMin: false,
      errorDrMax: false,
      errorTxMax:false,
      errorTxMin:false,
      errorNbMin:false,
      errorNbMax:false,
    }

    this.getNetworkServerOption = this.getNetworkServerOption.bind(this);
    this.getNetworkServerOptions = this.getNetworkServerOptions.bind(this);
    this.generateSFOptions = this.generateSFOptions.bind(this);
    this.getSFOptions = this.getSFOptions.bind(this);
    this.getTxOptions = this.getTxOptions.bind(this);
    this.getNbOptions = this.getNbOptions.bind(this);
    this.getNbOption = this.getNbOption.bind(this);
    this.getTxOption = this.getTxOption.bind(this);
    this.onChangeSF = this.onChangeSF.bind(this);
    this.onChangeTx = this.onChangeTx.bind(this);
    this.onChangeNb = this.onChangeNb.bind(this);
    this.onChangeADR = this.onChangeADR.bind(this);

  }

  getNetworkServerOption(id, callbackFunc) {
    NetworkServerStore.get(id, resp => {
      callbackFunc({label: resp.networkServer.name, value: resp.networkServer.id});
    });
  }

  getNetworkServerOptions(search, callbackFunc) {
    NetworkServerStore.list(0, 999, 0, resp => {
      const options = resp.result.map((ns, i) => {
        return {label: ns.name, value: ns.id}
      });
      callbackFunc(options);
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.generateSFOptions();
    this.generateTxOptions();
    this.generateNbOptions();
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (this.state.object.devStatusReqFreq === undefined || this.state.object.drMax === undefined) {
      this.setDefaults()
    }
  }

  setDefaults() {
    this.setState({
      object: {
        dlQueueMaxSize:500,
        reportDevStatusBattery: true,
        reportDevStatusMargin: true,
        devStatusReqFreq: 1,
        uplinksOnly: true,
        sendToApps: true,
        drMax: 5,
        drMin: 0,
        ulRatePolicy: 'MARK',
        dlRatePolicy: 'MARK',
        ulRate: 999,
        dlRate: 999,
        ulRateUnit: 'Day',
        dlRateUnit: 'Day',
        minNbTrans: -1,
        maxNbTrans: -1,
        minTXPowerIndex: -1,
        maxTXPowerIndex: -1,
        name: 'Default_SP',
        adrAlgorithmID: 'default',
        allowTxConfirmed: true,
        allowTxUnconfirmed: true,
        allowRxConfirmed: true,
      }
    })
  }
  generateTxOptions(){

    const options = [
      {label:'Default' , value:-1},
      {label:'0' , value:0},
      {label:'1' , value:1},
      {label:'2' , value:2},
      {label:'3' , value:3},
      {label:'4' , value:4},
      {label:'5' , value:5},
      {label:'6' , value:6},
      {label:'7' , value:7},
    ]

    this.setState({
      TxOptions: options,
    });
  }
  generateNbOptions(){

    const options = [
      {label:'Default' , value:-1},
    
      {label:'1' , value:1},
      {label:'2' , value:2},
      {label:'3' , value:3},
    ]

    this.setState({
      NbOptions: options,
    });
  }
  generateSFOptions() {
    const values = [0, 1, 2, 3, 4, 5];
    const dict = new Dictionary('data-rate-map');
    let options = [];

    for (let dr in values) {
      options.push({label: `SF${dict.map(dr)}`, value: dr});
    }

    this.setState({
      SFOptions: options,
    });
  }

  getSFOptions(any, callbackFunc) {
    callbackFunc(this.state.SFOptions);
  }

  getSFOption(id, callbackFunc) {
    const dict = new Dictionary('data-rate-map');
    callbackFunc({label: `SF${dict.map(id)}`, value: id});
  }
  getTxOptions(any, callbackFunc) {
    callbackFunc(this.state.TxOptions);
  }
  getNbOptions(any, callbackFunc) {
    callbackFunc(this.state.NbOptions);
  }
  getTxOption(id, callbackFunc) {
    this.getTxOptions(null,(options)=>{
        options.forEach(element => {
          if(element.value===id){
            callbackFunc({label:element.label,value:element.value})
          }
        });
    })
  }
  getNbOption(id, callbackFunc) {
    this.getNbOptions(null,(options)=>{
        options.forEach(element => {
          if(element.value===id){
            callbackFunc({label:element.label,value:element.value})
          }
        });
    })
  }
  getRegulatorOptions(any, callbackFunc) {
    callbackFunc([
      {value: 'DROP', label: 'Drop'},
      {value: 'MARK', label: 'Mark'}
    ]);
  }

  getRateOptions(any, callbackFunc) {
    callbackFunc([
      {value: 'Hour', label: 'Packet / Hour'},
      {value: 'Day', label: 'Packet / Day'},
      {value: 'Week', label: 'Packet / Week'},
      {value: 'Month', label: 'Packet / Month'},
      {value: 'Year', label: 'Packet / Year'},
    ]);
  }

  getADRAlgorithmsOptions = (search, callbackFunc) => {
    if (this.state.object.networkServerID === undefined) {
      callbackFunc([
        {value: 'default', label: 'Default ADR algorithm'},
        {value: 'fixed', label: 'Fixed Data Rate'},
      ]);
    } else {
      NetworkServerStore.getADRAlgorithms(this.state.object.networkServerID, resp => {
        let options = resp.adrAlgorithms.map((adr, i) => {return {value: adr.id, label: adr.name}});
        options.splice(1, 0, {value: 'fixed', label: 'Fixed Data Rate'}); // default must be first
        callbackFunc(options);
      })
    }
  }

  onChangeSF(e) {
    if (e.target.id === 'drMin') {
      if (+e.target.value > +this.state.object.drMax) {
        this.setState({errorDrMin: true, errorDrMax: false});
      } else {
        this.setState({errorDrMin: false, errorDrMax: false});
      }
    } else if (e.target.id === 'drMax') {
      if (+e.target.value < +this.state.object.drMin) {
        this.setState({errorDrMax: true, errorDrMin: false});
      } else {
        this.setState({errorDrMax: false, errorDrMin: false});
      }
    }
    this.onChange(e);
  }

  onChangeADR(e) {
    let object = this.state.object;
    if (e.target.value === "fixed") {
      object.drMax = 0;
      object.maxTXPowerIndex = 0;
      object.minNbTrans = 1;
    } else {
      object.drMax = 5;
      object.minTXPowerIndex = 0;
      object.maxTXPowerIndex = 7;
      object.minNbTrans = 1;
      object.maxNbTrans = 3;
    }
    object.drMin = 0;
    this.setState(object);
    this.onChange(e);
  }
  onChangeTx(e){
    let maxId = "maxTXPowerIndex"
    let minId = "minTXPowerIndex"
   
    let {errorTxMin , errorTxMax} = false
    if(this.state.object.adrAlgorithmID&&this.state.object.adrAlgorithmID!=='fixed'){
      if(e.target.id === maxId  && (this.state.object.minTXPowerIndex>-1&&e.target.value>-1) && e.target.value<this.state.object.minTXPowerIndex){
        errorTxMax = true
      
      }else if(e.target.id === minId &&   (e.target.value>-1&&this.state.object.maxTXPowerIndex>-1) && e.target.value>this.state.object.maxTXPowerIndex){
        errorTxMin = true
     
      }
    }
    this.setState({errorTxMax:errorTxMax,errorTxMin:errorTxMin})
   this.onChange(e)
  }
  onChangeNb(e){
    let maxId = "maxNbTrans"
    let minId = "minNbTrans"
   
    let {errorNbMin , errorNbMax} = false
    if(this.state.object.adrAlgorithmID&&this.state.object.adrAlgorithmID!=='fixed'){
      if(e.target.id === maxId  && (this.state.object.minNbTrans>-1&&e.target.value>-1) && e.target.value<this.state.object.minNbTrans){
        errorNbMax = true
      }else if(e.target.id === minId &&   (e.target.value>-1&&this.state.object.maxNbTrans>-1) && e.target.value>this.state.object.maxNbTrans){
        errorNbMin = true
      }
    }
    this.setState({errorNbMax:errorNbMax,errorNbMin:errorNbMin})
   this.onChange(e)
  }
  render() {
    if (this.state.object === undefined) {
      return (<div></div>);
    }

    const {errorDrMax, errorDrMin,errorTxMax,errorTxMin, errorNbMin, errorNbMax} = this.state;
    const {availableDevicesCount, usedDevicesCount} = this.props;

    return (
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={(this.props.disabled || errorDrMax || errorDrMin ||errorTxMax||errorTxMin || errorNbMin || errorNbMax)}
      >
        <TextField
          id="name"
          label="Service-profile name"
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          helperText="A name to identify the service-profile."
          disabled={this.props.disabled}
          required
          fullWidth
        />
        <TextField
          id="description"
          label="Description"
          margin="normal"
          value={this.state.object.description || ""}
          onChange={this.onChange}
          helperText="A description to identify the service-profile."
          disabled={this.props.disabled}
          fullWidth
        />
        {!this.props.update && <FormControl fullWidth margin="normal">
          <FormLabel required>Frequency plan</FormLabel>
          <AutocompleteSelect
            id="networkServerID"
            label="Frequency plan"
            value={this.state.object.networkServerID || null}
            onChange={this.onChange}
            getOption={this.getNetworkServerOption}
            getOptions={this.getNetworkServerOptions}
            required
          />
          <FormHelperText>
            The frequency plan on which this service-profile will be provisioned. After creating the service-profile,
            this value can't be changed.
          </FormHelperText>
        </FormControl>}
        <FormControl fullWidth margin="normal">
          <FormControlLabel
            label="Enable network geolocation"
            control={
              <Checkbox
                id="nwkGeoLoc"
                checked={!!this.state.object.nwkGeoLoc}
                onChange={this.onChange}
                disabled={this.props.disabled}
                color="primary"
              />
            }
          />
          <FormHelperText>
            When enabled, the network-server will try to resolve the location of the devices under this service-profile.
            Please note that you need to have gateways supporting the fine-timestamp feature and that the network-server
            needs to be configured in order to provide geolocation support.
          </FormHelperText>
        </FormControl>
        <TextField
          id="devStatusReqFreq"
          label="Device-status request frequency"
          margin="normal"
          type="number"
          value={this.state.object.devStatusReqFreq}
          onChange={this.onChange}
          helperText="Frequency to initiate an End-Device status request (request/day). Set to 0 to disable."
          disabled={this.props.disabled}
          required
          fullWidth
        />
        <FormControl fullWidth margin="normal">
          <FormGroup>
            <FormControlLabel
              label="Report device battery level to application-server"
              control={
                <Checkbox
                  id="reportDevStatusBattery"
                  checked={!!this.state.object.reportDevStatusBattery}
                  onChange={this.onChange}
                  disabled={this.props.disabled}
                  color="primary"
                />
              }
            />
            <FormControlLabel
              label="Report device link margin to application-server"
              control={
                <Checkbox
                  id="reportDevStatusMargin"
                  checked={!!this.state.object.reportDevStatusMargin}
                  onChange={this.onChange}
                  disabled={this.props.disabled}
                  color="primary"
                />
              }
            />
          </FormGroup>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>ADR algorithm</FormLabel>
          <AutocompleteSelect
            id="adrAlgorithmID"
            label="Select ADR algorithm"
            value={this.state.object.adrAlgorithmID || "default"}
            onChange={this.onChangeADR}
            getOptions={this.getADRAlgorithmsOptions}
            triggerReload={this.state.object.networkServerID || ""}
            disabled={this.props.disabled}
          />
          <FormHelperText>
              the algorithm controlls device-parameters such as data-rate, TX power, number of Retransmissions.
          </FormHelperText>
        </FormControl>


      {this.state.object.adrAlgorithmID && (this.state.object.adrAlgorithmID !== "fixed" || this.state.object.adrAlgorithmID === "fixed" ) && (<FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>{(this.state.object.adrAlgorithmID === "fixed")?"Number of transmissions":"Minimum number of transmissions"}</FormLabel>
          <AutocompleteSelect
            id="minNbTrans"
            label={(this.state.object.adrAlgorithmID === "fixed")?"Number of transmissions":"Minimum number of transmissions"}
            value={this.state.object.minNbTrans || 0}
            onChange={this.onChangeNb}
            getOptions={this.getNbOptions}
            getOption={this.getNbOption}
            className={this.state.errorNbMin ? this.props.classes.errorAutocomplete : null}
            disabled={this.props.disabled}
          />
          <FormHelperText className={this.state.errorNbMin ? this.props.classes.errorAutocompleteHelper : null}>
            {this.state.errorNbMin ? 'The value must not be less than the "Maximum number of transmissions' : ((this.state.object.adrAlgorithmID === "fixed")?"Number of transmissions":"Minimum number of transmissions")+ " from 0 to 3"}
          </FormHelperText>
        </FormControl>)}
          {this.state.object.adrAlgorithmID && this.state.object.adrAlgorithmID !== "fixed" &&  (<FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Maximum number of transmissions</FormLabel>
          <AutocompleteSelect
            id="maxNbTrans"
            label="Maximum number of transmissions"
            value={this.state.object.maxNbTrans || 0}
            onChange={this.onChangeNb}
            getOptions={this.getNbOptions}
            getOption={this.getNbOption}
            className={this.state.errorNbMax ? this.props.classes.errorAutocomplete : null}
            disabled={this.props.disabled}
          />
          <FormHelperText className={this.state.errorNbMax ? this.props.classes.errorAutocompleteHelper : null}>
            {this.state.errorNbMax ? 'The value must not be less than the "Minimum number of transmissions' : "Maximum number of transmissions from 0 to 3"}
          </FormHelperText>
        </FormControl>)}
       {this.state.object.adrAlgorithmID && this.state.object.adrAlgorithmID !== "fixed" &&  (<FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Minimum TX power index</FormLabel>
          <AutocompleteSelect
            id="minTXPowerIndex"
            label="Minimum TX power index"
            value={this.state.object.minTXPowerIndex || 0}
            onChange={this.onChangeTx}
            getOptions={this.getTxOptions}
            getOption={this.getTxOption}
            className={this.state.errorTxMin ? this.props.classes.errorAutocomplete : null}
            disabled={this.props.disabled}
          />
          <FormHelperText className={this.state.errorTxMin ? this.props.classes.errorAutocompleteHelper : null}>
            {this.state.errorTxMin ? 'The value must not be more than the "Maximum TX power index' : "Minimum TX power index from 0 to 7"}
          </FormHelperText>
        </FormControl>)}
      {this.state.object.adrAlgorithmID && (this.state.object.adrAlgorithmID !== "fixed" || this.state.object.adrAlgorithmID === "fixed" ) && (<FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>{(this.state.object.adrAlgorithmID === "fixed")?"TX power index":"Maximum TX power index"}</FormLabel>
          <AutocompleteSelect
            id="maxTXPowerIndex"
            label={(this.state.object.adrAlgorithmID === "fixed")?"TX power index":"Maximum TX power index"}
            value={this.state.object.maxTXPowerIndex || 0}
            onChange={this.onChangeTx}
            getOptions={this.getTxOptions}
            getOption={this.getTxOption}
            className={this.state.errorTxMax ? this.props.classes.errorAutocomplete : null}
            disabled={this.props.disabled}
          />
          <FormHelperText className={this.state.errorTxMax ? this.props.classes.errorAutocompleteHelper : null}>
            {this.state.errorTxMax ? 'The value must not be less than the "Minimum TX power index' : ((this.state.object.adrAlgorithmID === "fixed")?"TX power index":"Maximum TX power index")+ " from 0 to 7"}
          </FormHelperText>
        </FormControl>)}

        {this.state.object.adrAlgorithmID && this.state.object.adrAlgorithmID !== "fixed" && (<FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Minimum allowed spreading factors</FormLabel>
          <AutocompleteSelect
            id="drMin"
            label="Minimum allowed spreading factors"
            value={this.state.object.drMin || 0}
            onChange={this.onChangeSF}
            getOptions={this.getSFOptions}
            getOption={this.getSFOption}
            className={this.state.errorDrMin ? this.props.classes.errorAutocomplete : null}
            disabled={this.props.disabled}
          />
          <FormHelperText className={this.state.errorDrMin ? this.props.classes.errorAutocompleteHelper : null}>
            {this.state.errorDrMin ? 'The value must not be more than the "Maximum allowed spreading factors" value' : 'Minimum allowed spreading factor. SF12-SF7'}
          </FormHelperText>
        </FormControl>)}

        {this.state.object.adrAlgorithmID && this.state.object.adrAlgorithmID !== "fixed" && (<FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Maximum allowed spreading factors</FormLabel>
          <AutocompleteSelect
            id="drMax"
            label="Maximum allowed spreading factors"
            value={this.state.object.drMax || 0}
            onChange={this.onChangeSF}
            getOptions={this.getSFOptions}
            getOption={this.getSFOption}
            className={this.state.errorDrMax ? this.props.classes.errorAutocomplete : null}
            disabled={this.props.disabled}
          />
          <FormHelperText className={this.state.errorDrMax ? this.props.classes.errorAutocompleteHelper : null}>
            {this.state.errorDrMax ? 'The value must not be less than the "Minimum allowed spreading factors" value' : 'Maximum allowed spreading factor. SF12-SF7'}
          </FormHelperText>
        </FormControl>)}

        {this.state.object.adrAlgorithmID && this.state.object.adrAlgorithmID === "fixed" && (<FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Allowed spreading factors</FormLabel> 
          <AutocompleteSelect
            id="drMax"
            label="Allowed spreading factors"
            value={this.state.object.drMax || 0}
            onChange={this.onChangeSF}
            getOptions={this.getSFOptions}
            getOption={this.getSFOption}
            disabled={this.props.disabled}
          />
        </FormControl>)}
        
        <TextField
          id="deviceCountLimit"
          label="Maximum number of devices"
          margin="normal"
          type="number"
          onChange={this.onChange}
          disabled={this.props.disabled}
          value={this.state.object.deviceCountLimit || 0}
          fullWidth
          inputProps={{
            min: 0,
            max: 100000,
          }}
        />
        <TextField
          id="usedDevicesCount"
          label="The used number of devices"
          margin="normal"
          type="number"
          value={usedDevicesCount || 0}
          disabled
          fullWidth
        />
        <TextField
          id="availableDevicesCount"
          label="Available number of devices"
          margin="normal"
          type="number"
          value={availableDevicesCount || 0}
          disabled
          fullWidth
        />
        <TextField
          id="dlQueueMaxSize"
          label="Downlink queue maximum size (per device)"
          margin="normal"
          type="number"
          value={this.state.object.dlQueueMaxSize || 0}
          onChange={this.onChange}
          fullWidth
          disabled
        />
        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Uplink regulator policy</FormLabel>
          <AutocompleteSelect
            id="ulRatePolicy"
            label="Uplink regulator policy"
            value={this.state.object.ulRatePolicy}
            onChange={this.onChange}
            getOptions={this.getRegulatorOptions}
            getOption={this.getRegulatorOption}
            disabled={this.props.disabled}
          />
        </FormControl>

        <TextField
          id="ulRate"
          label="Uplink rate"
          margin="normal"
          type="number"
          value={this.state.object.ulRate}
          onChange={this.onChange}
          InputProps={{ inputProps: { min: -1 } }}
          fullWidth
          disabled={this.props.disabled}
          // required
        />

        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Uplink rate unit</FormLabel>
          <AutocompleteSelect
            id="ulRateUnit"
            label="Uplink rate unit"
            value={this.state.object.ulRateUnit}
            onChange={this.onChange}
            getOptions={this.getRateOptions}
            disabled={this.props.disabled}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Downlink regulator policy</FormLabel>
          <AutocompleteSelect
            id="dlRatePolicy"
            label="Downlink regulator policy"
            value={this.state.object.dlRatePolicy}
            onChange={this.onChange}
            getOptions={this.getRegulatorOptions}
            getOption={this.getRegulatorOption}
            disabled={this.props.disabled}
          />
        </FormControl>

        <TextField
          id="dlRate"
          label="Downlink rate"
          margin="normal"
          type="number"
          value={this.state.object.dlRate}
          onChange={this.onChange}
          InputProps={{ inputProps: { min: -1 } }}
          // required
          fullWidth
          disabled={this.props.disabled}
        />

        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Downlink rate unit</FormLabel>
          <AutocompleteSelect
            id="dlRateUnit"
            label="Downlink rate unit"
            value={this.state.object.dlRateUnit}
            onChange={this.onChange}
            getOptions={this.getRateOptions}
            disabled={this.props.disabled}
          />
        </FormControl>

        <FormControlLabel
          label="Confirmed uplink support"
          control={
            <Checkbox
              id="allowRxConfirmed"
              checked={!!this.state.object.allowRxConfirmed}
              onChange={this.onChange}
              disabled={this.props.disabled}
              color="primary"
            />
          }
        />
        <FormControl fullWidth >
          <FormGroup>
            <FormControlLabel
              label="Confirmed downlink support"
              control={
                <Checkbox
                  id="allowTxConfirmed"
                  checked={!!this.state.object.allowTxConfirmed}
                  onChange={this.onChange}
                  disabled={this.props.disabled}
                  color="primary"
                />
              }
            />
            <FormControlLabel
              label="Unconfirmed downlink support"
              control={
                <Checkbox
                  id="allowTxUnconfirmed"
                  checked={!!this.state.object.allowTxUnconfirmed}
                  onChange={this.onChange}
                  disabled={this.props.disabled}
                  color="primary"
                />
              }
            />
          </FormGroup>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormControlLabel
            label="Private gateways"
            control={
              <Checkbox
                id="gwsPrivate"
                checked={!!this.state.object.gwsPrivate}
                onChange={this.onChange}
                disabled={this.props.disabled}
                color="primary"
              />
            }
          />
          <FormHelperText>
            Gateways under this service-profile are private. This means that these gateways can only be used by devices
            under the same service-profile.
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormControlLabel
            label="Pass uplinks only"
            control={
              <Checkbox
                id="uplinksOnly"
                checked={!!this.state.object.uplinksOnly}
                onChange={this.onChange}
                disabled={this.props.disabled}
                color="primary"
              />
            }
          />
          <FormHelperText>
            Pass only uplink-events to all applications of the client. Other events won't be transmitted
            (such as ack,errors,device-location, etc.)
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormControlLabel
            label="Pass data to apps"
            control={
              <Checkbox
                id="sendToApps"
                checked={!!this.state.object.sendToApps}
                onChange={this.onChange}
                disabled={this.props.disabled}
                color="primary"
              />
            }
          />
          <FormHelperText>
            Allow passing data to all applications
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormGroup>
            <FormControlLabel
              label="Is disabled"
              control={
                <Checkbox
                  id="isDisabled"
                  checked={!!this.state.object.isDisabled}
                  onChange={this.onChange}
                  color="primary"
                  disabled={this.props.disabled}
                />
              }
            />
          </FormGroup>
        </FormControl>
      </Form>
    );
  }
}

export default withStyles(styles)(ServiceProfileForm);

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';

import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import SessionStore from "../../stores/SessionStore";
import UpdateApiKey from "./UpdateApiKey";
import internalStore from "../../stores/InternalStore";


class AdminApiKeyLayout extends Component {
  constructor() {
    super();
    this.state = {
      admin: false,
    };
    this.deleteApiKey = this.deleteApiKey.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
  }

  componentDidMount() {
    internalStore.getAPIKey(this.props.match.params.apiKey, resp => {

      this.setState({
        apiKey: resp.apiKey,
      });
    });

    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin(),
    });
  }

  deleteApiKey() {
    if (window.confirm("Are you sure you want to delete this api-key?")) {
      internalStore.deleteAPIKey(this.props.match.params.apiKey, resp => {
        if(this.props.match.params.organizationID){
          this.props.history.push(`/organizations/${this.props.match.params.organizationID}/api-keys`);
        }else{
          this.props.history.push(`/api-keys`);

        }
      });
    }
  }

  render() {
    if (this.state.apiKey === undefined) {
      return(<div></div>);
    }

    let buttons = [];
    if (this.state.admin) {
      buttons = [
          <TitleBarButton
            label="Delete"
            icon={<Delete />}
            color="secondary"
            onClick={this.deleteApiKey}
          />,
      ];
    }

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={buttons}
        >
        {(this.props.match.params.organizationID)?<TitleBarTitle title="Organization API keys" to={`/organizations/${this.props.match.params.organizationID}/api-keys`} />:<TitleBarTitle title="Global API keys" to="/api-keys" />}
          <TitleBarTitle title="/" />
          <TitleBarTitle title={this.state.apiKey.name} />
         
        </TitleBar>

        <Grid item xs={12}>
          <UpdateApiKey  apiKey={this.state.apiKey} admin={this.state.admin} />
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(AdminApiKeyLayout);

import { createTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
let important = '!important'
let topNavColor = window.localStorage.getItem("topNavColor")
let styleObj = {
  palette: {
    primary: blue,
  },
  overrides: {
   

    MuiSwitch:{
      switchBase: {
       
       
        '&$checked + $track': {
          backgroundColor: '#ccc'+important,
        },
      },
    },
    
  },
}


if(topNavColor){
  styleObj.overrides.MuiTabs = {
    indicator:{
      backgroundColor : topNavColor+important
    }
  } 

  styleObj.overrides.MuiButton = {
    containedPrimary:{
      backgroundColor : topNavColor
    }
  }
  styleObj.overrides.MuiSwitch = {

    switchBase: {
       
      '&$checked': {
        color: topNavColor+important
      }
    }

  
  }
}
const theme = createTheme(styleObj);
  
export default theme;

import React, { Component } from "react";

import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core/styles';

import SessionStore from "../stores/SessionStore";

import Paper from "./Paper";
import IconButton from "@material-ui/core/IconButton";
import {FirstPage,LastPage, KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = {
  pagination:{
    inputNumber:{width:'65px', textAlign:'center'}
  },
  progeressShow:{
    opacity:1,
    transition:'opacity',
    '& > * ':{
      willChange:'transform,animation'
    }
  },
  progeressHidden:{
    opacity:0,
    transition:'opacity'
  },
  table:{
   
    position:'relative',
    '& td':{
      paddingTop:'0!important',
      height:'30px',
      paddingBottom:'0!important',
    }
  },
  stickyHead:{
    '& th':  {
      position:'sticky!important',
      zIndex:999999,
      top:0
  }
  }
  

};


// function Sticky(props){
//   const { children, window } = props;
//  // const classes = useStyles();
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//     disableHysteresis: true,
//     threshold: 200,
//   });

//   if (!trigger){
//     return null
//   }

//   return children
// }



class DataTable extends Component {
  constructor() {
    super();
    this.mounted = false;

    this.state = {
      loading:true,
      inputPage:0,
      count: 0,
      rowsPerPage: SessionStore.getRowsPerPage(),
      page: 0,
      loaded: {
        rows: false,
      },
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.resetTable = this.resetTable.bind(this);
    this.onPageInputChange = this.onPageInputChange.bind(this);
    this.arrowButtonsClick = this.arrowButtonsClick.bind(this);

    this.refTablePagination = React.createRef();
    this.timeoutInput = null;
  }

  componentDidMount() {
    this.mounted = true;
    this.onChangePage(null, 0);
  }

  componentDidUpdate(prevProps) { 
    if (this.props === prevProps) {
      return;
    }
    let page = 0;
    if(this.props.orderBy||prevProps.orderBy || ( this.props.fields && this.props.fields.length!==prevProps.fields.length)  ){
      page = this.state.page;
       this.onChangePage(null, page);
    }

   
  }
  componentWillUnmount() {
    this.mounted = false;
  }

  getCount(){
    return this.state.count
  }

  updateTable(cb=null){
    this.setState({loading:true})
    this.props.getPage(this.state.rowsPerPage, (this.state.page) * this.state.rowsPerPage, (result) => {
      if(this.mounted){
        this.setState({
          page: this.state.page,
          inputPage: this.state.page+1,
          loading:false,
          count: parseInt(result.totalCount, 10),
          rows: result.result.map((row, i,arr) => this.props.getRow(row,i,arr)),
        },cb);

      }
    });
  }

  resetTable(cb=null){
    this.setState({loading:true})
    this.props.getPage(this.state.rowsPerPage, 0, (result) => {
      if(this.mounted){
        this.setState({
          page: 0,
          inputPage: 1,
          loading: false,
          count: parseInt(result.totalCount, 10),
          rows: result.result.map((row, i,arr) => this.props.getRow(row,i,arr)),
        },cb);

      }
    });
  }

  onChangePage(event, page,cb=null) {
    if(this.props.changePageEvent){
      this.props.changePageEvent()
    }

    page = parseInt(page)
    this.setState({loading:true})
   
    this.props.getPage(this.state.rowsPerPage, page * this.state.rowsPerPage, (result) => {
      if(this.mounted&&result){
        let state = {
          page: page,
          loading: false,
          count: parseInt(result.totalCount, 10),
          rows: result.result.map((row, i,arr) => this.props.getRow(row,i,arr)),
        }
        if(this.state.inputPage-1 !== page){
          state.inputPage = page+1
        }
        this.setState(state,cb);
        const anchor = document.querySelector('#back-to-top-anchor');
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth' });
        }
      }

    });
  }

  onPageInputChange(event){
    if(this.timeoutInput){
      clearTimeout(this.timeoutInput);
    }

    let match =  event.target.value.match(/[0-9]/ig);
    let val = (match)?match.join(''):'';

    if(val){
      if(val<1){
        val = 1
      }else if(val>Math.ceil(this.state.count/this.state.rowsPerPage)){
        val = Math.ceil(this.state.count/this.state.rowsPerPage)
      }
    }

    this.setState({inputPage:val},()=>{
        if(val&&this.state.page!==this.state.inputPage-1){
          this.timeoutInput = setTimeout(()=>{
            this.onChangePage(event,val-1,()=>{
              document.getElementById('outlined-number').focus();
            })
          },1500)
        }
    })


  }

  onChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value,
      loading:true
    });

    SessionStore.setRowsPerPage(event.target.value);
   
    this.props.getPage(event.target.value, 0, (result) => {
      this.setState({
        page: 0,
        loading:false,
        inputPage:1,
        count: result.totalCount,
        rows: result.result.map((row, i,arr) => this.props.getRow(row,i,arr)),
      });
    });
  }

  arrowButtonsClick(event){ 
    let page = event.currentTarget.dataset.page;
    if(!page) return ;
  
    this.onChangePage(event,page)
  }

  render() {
    const {elevation} = this.props;
   
    if (this.state.rows === undefined) {
      return(
          <Paper>
            <LinearProgress variant={'indeterminate'} className={(this.state.loading || this.props.loading)?this.props.classes.progeressShow:this.props.classes.progeressHidden}/>
          </Paper>);
    }

    return(
      <Paper classes={{root:'noUDpaddings'}}
      {...{elevation: elevation}}
      >
        <LinearProgress variant={'indeterminate'} className={(this.state.loading || this.props.loading)?this.props.classes.progeressShow:this.props.classes.progeressHidden}/>
        <div style={{overflowX:"auto"}}>
          <Table className={this.props.classes.table}>
            {/* <Sticky>
                <TableHead className={this.props.classes.stickyHead}>
                  {this.props.header}
                </TableHead>
            </Sticky> */}
            <TableHead>
              {this.props.header}
            </TableHead>
          
            <TableBody>
              {this.state.rows}
            </TableBody>
          </Table>
        </div>
        {(this.props.pagination===undefined || this.props.pagination===true) && <div style={{display:'flex',justifyContent:'flex-end',marginTop:'16px'}}>
         { (this.props.paginationTools===undefined || (this.props.paginationTools!==undefined && this.props.paginationTools.indexOf('paginator')!==-1 ))  && <TablePagination
              component="div"
              count={this.state.count}
              rowsPerPage={this.state.rowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100, 200]}
              page={this.state.page}
              onPageChange={this.onChangePage}
              onRowsPerPageChange={this.onChangeRowsPerPage}
              ActionsComponent={()=>(null)}
          />}

          <div className="MuiTablePagination-custom-controls" style={{display:'flex',alignItems:'center' , marginLeft:'32px'}}>
           {(this.props.paginationTools===undefined || (this.props.paginationTools!==undefined && this.props.paginationTools.indexOf('pageTotal')!==-1 ))  && <div  style={{display:'flex',alignItems:'center' , marginRight:'20px'}}>
              <span>Page: {this.state.page+1}  {this.props.maxpage!==false&&('of '+(this.props.maxpage||Math.ceil(this.state.count / this.state.rowsPerPage)))}</span>
            </div>}
            {(this.props.paginationTools===undefined || (this.props.paginationTools!==undefined && this.props.paginationTools.indexOf('first')!==-1 ))  &&<IconButton data-page={0} onClick={(e)=>{
          this.arrowButtonsClick(e)  
          if(this.props.onPaginationNextPrev){
            this.props.onPaginationNextPrev('first')
          }
          }} disabled={this.state.page === 0} aria-label="previous page">
              <FirstPage />
            </IconButton>}
         {(this.props.paginationTools===undefined || (this.props.paginationTools!==undefined && this.props.paginationTools.indexOf('backward')!==-1 ))  && <IconButton data-page={this.state.page - 1} onClick={(e)=>{
          this.arrowButtonsClick(e)  
          if(this.props.onPaginationNextPrev){
            this.props.onPaginationNextPrev('backward')
          }
          }} disabled={this.state.page === 0} aria-label="previous page">
              <KeyboardArrowLeft />
            </IconButton>}
            {(this.props.paginationTools===undefined||(this.props.paginationTools!==undefined&&this.props.paginationTools.indexOf('pageNumber')!==-1))&&  <TextField
                style={styles.pagination.inputNumber}
                disabled={this.state.loading}
                onChange={this.onPageInputChange}
                id="outlined-number"
                type="text"
                size='small'
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={this.state.inputPage}
            />}
         {(this.props.paginationTools===undefined||(this.props.paginationTools!==undefined&&this.props.paginationTools.indexOf('forward')!==-1))&& <IconButton data-page={this.state.page + 1} onClick={(e)=>{  this.arrowButtonsClick(e)  
          if(this.props.onPaginationNextPrev){
            this.props.onPaginationNextPrev('forward')
          }}} disabled={(this.state.page >= Math.ceil(this.state.count / this.state.rowsPerPage) - 1)||(this.props.maxpage!==undefined&&this.page>=this.props.maxpage)} aria-label="next page">
              <KeyboardArrowRight />
            </IconButton>}
          {(this.props.paginationTools===undefined||(this.props.paginationTools!==undefined&&this.props.paginationTools.indexOf('last')!==-1))&&  <IconButton data-page={(this.props.maxpage!==undefined&&this.props.maxpage-1)||Math.ceil(this.state.count / this.state.rowsPerPage) - 1} onClick={this.arrowButtonsClick} disabled={(this.state.page >= Math.ceil(this.state.count / this.state.rowsPerPage) - 1)||(this.props.maxpage!==undefined&&this.page>=this.props.maxpage)} aria-label="next page">
              <LastPage />
            </IconButton>}
          </div>

        </div>}
        {this.state.rows.length>30&&<LinearProgress variant={'indeterminate'} className={(this.state.loading)?this.props.classes.progeressShow:this.props.classes.progeressHidden}/>}
      </Paper>
    );
  }
}

export default withStyles(styles)(DataTable);

import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import GatewayProfileStore from "../../stores/GatewayProfileStore";
import { withStyles } from "@material-ui/core/styles";

const styles ={

    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
};

class ListGatewayProfiles extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);
    this.getCount = this.getCount.bind(this);
    this.tableRef = React.createRef()
    this.state = {
        orderBy:null,
        order:null,
        dialogOpen: false,
        loading:true
    }
   
    
  }
  
  
  getCount(id){
      if(!this.gatewayCounts){
        return ""
      }
    if(this.gatewayCounts&&  this.gatewayCounts[id]!==undefined ){
      return this.gatewayCounts[id]
    }else{
      return 0
    }
  }
  changeSort(event){
    let state = {
        orderBy:event.currentTarget.dataset.key,
        order:this.state.order
    }

    if(state.orderBy!==this.state.orderBy){
        state.order = null;
    }

    if(state.order === null){
        state.order = 'asc'
    }else if(state.order === 'asc'){
        state.order = 'desc'
    }else if(state.order === 'desc'){
        state.order = null
        state.orderBy = null
    }

    this.setState(state);
  }

  makeSortLabel(keyName,viewName){
  return  <TableSortLabel
        data-key={keyName}
        active={this.state.orderBy === keyName}
        direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
        onClick={this.changeSort}
    >
      {viewName}
        {this.state.orderBy === keyName ? (
            <span className={this.props.classes.visuallyHidden}>
                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
        ) : null}
    </TableSortLabel>
  }

  makeCellSortProps(keyName){
    return {
        key:keyName,
        align:'left',
        padding:'normal',
        sortDirection:this.state.orderBy === keyName ? this.state.order : false
    }
  }
  getPage(limit, offset, callbackFunc) {
    GatewayProfileStore.list(0, limit, offset, callbackFunc, this.state.orderBy, this.state.order);
  }

  getRow(obj) {
    return(
      <TableRow
        id={obj.id}
        hover
      >
        <TableCellLink to={`/gateway-profiles/${obj.id}`}>{obj.name}</TableCellLink>
        <TableCell>{this.getCount(obj.id)}</TableCell>
        <TableCellLink to={`/network-servers/${obj.networkServerID}`}>{obj.networkServerName}</TableCellLink>
      </TableRow>
    );
  }

  toggleHelpDialog = () => {
    this.setState({
      dialogOpen: !this.state.dialogOpen,
    });
  }
  componentDidMount(){
    GatewayProfileStore.getStorageGatewayCounts((data,loaded)=>{
      this.gatewayCounts = data.data
     
      this.setState({loading:undefined})
     if(loaded){
      this.tableRef.current.updateTable()
     }
  }) 
  }
  render() {
    return(
      <Grid container spacing={4}>
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.toggleHelpDialog}
          aria-labelledby="help-dialog-title"
          aria-describedby="help-dialog-description"
        >
          <DialogTitle id="help-dialog-title">Gateway Profile help</DialogTitle>
          <DialogContent>
            <DialogContentText id="help-dialog-description">
              The only purpose of a Gateway Profile is to (re)configure one or multiple gateways with the
              configuration properties that are set by the Gateway Profile.<br /><br />

              When the Network Server detects that the configuration of a gateway is out-of-sync
              with its Gateway Profile, it will push a configuration command to the gateway in order to
              update its configuration.<br /><br />

              Please note that this feature is optional and only works in combination with the
              Concentratord component.<br /><br />

              Also note that the Gateway Profile does not change the way how devices are behaving.
              To configure the channel-plan that must be used by devices, update the
              Network Server configuration.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleHelpDialog} color="primary">Close</Button>
          </DialogActions>
        </Dialog>

        <TitleBar
          buttons={[
            <TitleBarButton
              label="Create"
              icon={<Plus />}
              to={`/gateway-profiles/create`}
            />,
           
          ]}
        >
          <TitleBarTitle title="Gateway-profiles" />
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
          orderBy={this.state.orderBy}
          ref={this.tableRef}
          loading={this.state.loading}
            header={
              <TableRow>
                <TableCell {...this.makeCellSortProps('name')}>{this.makeSortLabel('name','Name')}</TableCell>
                <TableCell>Gw Count</TableCell>
                <TableCell {...this.makeCellSortProps('networkServerName')}>{this.makeSortLabel('networkServerName','Frequency plan')}</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListGatewayProfiles);

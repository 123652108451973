import React, { Component } from "react";
import { Route, Switch, Link, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Delete from "mdi-material-ui/Delete";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import GatewayAdmin from "../../components/GatewayAdmin";
import GatewayStore from "../../stores/GatewayStore";
import SessionStore from "../../stores/SessionStore";
import GatewayDetails from "./GatewayDetails";
import UpdateGateway from "./UpdateGateway";
import GatewayFrames from "./GatewayFrames";
import GatewayEvents from "./GatewayEvents";

import theme from "../../theme";
import Administration from "./Administration";
import AppBar from '@material-ui/core/AppBar';

import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";

const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
    
    color:'#000',
  },
  root: {
    minWidth: '140px',
  },
};


class GatewayLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
      rebooting:false,
    };
    this.deleteGateway = this.deleteGateway.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.locationToTab = this.locationToTab.bind(this);
    this.setIsAdmin = this.setIsAdmin.bind(this);
    this.updateGatewayData = this.updateGatewayData.bind(this);
    this.detailsMounted = this.detailsMounted.bind(this);
    this.setRebooting = this.setRebooting.bind(this);
  }

  componentDidMount() {
    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();
    this.locationToTab();
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) {
      return;
    }

    this.locationToTab();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
    if(this.gatewayInterval) clearInterval(this.gatewayInterval)
  }

  setIsAdmin() {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationGatewayAdmin(this.props.match.params.organizationID),
    });
  }

  updateGatewayData(){
    GatewayStore.get(this.props.match.params.gatewayID, resp => {
      if (resp.response&&resp.response.obj.code === 16 ) {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/gateways`);
        return
      }
      this.setState({
        gateway: resp,
      });
    });
  }

  detailsMounted(){
    if(this.state.rebooting===false) {
      //this.updateGatewayData();
    }
  }

  setRebooting(){
    let state = this.state;
    state.rebooting = true;
    state.gateway.gateway.active = false;

    setTimeout(()=>{
      this.setState({rebooting:false})
    },40*1000)
    this.setState(state)
  }

  deleteGateway() {
    if (window.confirm("Are you sure you want to delete this gateway?")) {
      GatewayStore.delete(this.props.match.params.gatewayID, () => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/gateways`);
      });
    }
  }

  locationToTab() {
    clearInterval(this.gatewayInterval);
    let tab = 0;

    if (window.location.href.endsWith("/administration")) {
      tab = 1;
    }
    else if (window.location.href.endsWith("/edit")) {
      tab = 2;
    } else if (window.location.href.endsWith("/frames")) {
      tab = 3;
    } else if (window.location.href.endsWith("/events")) {
      tab = 4;
    }


    if (tab > 0 && !this.state.admin) {
      tab = tab - 1;
    }

    if(tab!==2){
      this.updateGatewayData();
      this.gatewayInterval = setInterval( ()=>{
        if(this.state.rebooting===false){
          this.updateGatewayData();
        }
      } ,60*1000 );
    }
    this.setState({
      tab: tab,
    });
  }

  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }

  render() {
    
    if (this.state.gateway === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <GatewayAdmin organizationID={this.props.match.params.organizationID}>
              <TitleBarButton
               
                label="Delete"
                icon={<Delete />}
                color="secondary"
                onClick={this.deleteGateway}
              />
            </GatewayAdmin>
          }
        >
          <OrganizationBreadcrumps org={this.props.org}>
              <TitleBarTitle to={`/organizations/${this.props.match.params.organizationID}/gateways`} title="Gateways" />
              <TitleBarTitle title="/" />
              <TitleBarTitle title={this.state.gateway.gateway.name} />
          </OrganizationBreadcrumps>
          
        </TitleBar>

        <Grid className={'noUDpaddings'} item xs={12}>
        <AppBar position="static">


                <Tabs
                  value={this.state.tab}
                  onChange={this.onChangeTab}
                  indicatorColor="primary"
                  className={this.props.classes.tabs}
                  style={{background:this.props.colors.tabsColor}}
                >
                  <Tab label="Details" component={Link} to={`/organizations/${this.props.match.params.organizationID}/gateways/${this.props.match.params.gatewayID}`} className={this.props.classes.root} />
                  {this.state.admin && <Tab label="Administration" component={Link} to={`/organizations/${this.props.match.params.organizationID}/gateways/${this.props.match.params.gatewayID}/administration`} className={this.props.classes.root} />}
                  {this.state.admin && <Tab label="Configuration" component={Link} to={`/organizations/${this.props.match.params.organizationID}/gateways/${this.props.match.params.gatewayID}/edit`} className={this.props.classes.root} />}
                  <Tab
                    label="Live frames"
                    component={Link}
                    to={`/organizations/${this.props.match.params.organizationID}/gateways/${this.props.match.params.gatewayID}/frames`}
                    className={this.props.classes.root}
                  />
                  <Tab label="Events" component={Link} to={`/organizations/${this.props.match.params.organizationID}/gateways/${this.props.match.params.gatewayID}/events`} className={this.props.classes.root} />
                </Tabs>
          </AppBar>
        </Grid>
        
        <Grid item xs={12}>
        <Switch>
          <Route exact path={`${this.props.match.path}`} render={props => <GatewayDetails GPSSeenAt={this.state.gateway.GPSSeenAt} gateway={this.state.gateway.gateway} createdAt={this.state.gateway.createdAt} lastSeenAt={this.state.gateway.lastSeenAt} {...props} />} />
          <Route exact path={`${this.props.match.path}/administration`} render={props => <Administration rebooting={this.state.rebooting} setRebooting={this.setRebooting} gateway={this.state.gateway.gateway} {...props} />} />
          <Route exact path={`${this.props.match.path}/edit`} render={props => <UpdateGateway gateway={this.state.gateway.gateway} {...props} />} />
          <Route exact path={`${this.props.match.path}/frames`} render={props => <GatewayFrames gateway={this.state.gateway.gateway} {...props} />} />
          <Route exact path={`${this.props.match.path}/events`} render={props => <GatewayEvents gateway={this.state.gateway.gateway} {...props} />} />
        </Switch>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(GatewayLayout));

import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {CardContent} from "@material-ui/core";
import AsRoutingForm from "./AsRoutingForm";
import RoutingProfileStore from "../../stores/RoutingProfileStore"
import ApplicationStore from "../../stores/ApplicationStore";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";

  class UpdateAsRoutingProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0,
      appServers: [],
      dialog:false,
      dialogOk:false,
    }
    this.createDialog = this.createDialog.bind(this);
    this.dismisDialog = this.dismisDialog.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    ApplicationStore.list("", this.props.match.params.organizationID, 999, 0, resp => {
      this.setState({
        appServers: resp.result
      })
    })
  }
      dismisDialog(){
        this.setState({dialog:false})
    }

    createDialog(){
      this.setState({dialog:false,dialogOk:true})
    }
  onSubmit(routingProfile){ 

    if(!this.state.dialogOk && (!routingProfile.applications||(routingProfile.applications&&routingProfile.applications.length===0))){
      this.setState({dialog:true})
      return
    }


    RoutingProfileStore.update(routingProfile, resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/as-routing-profiles`);
    });
  }

  render() {
    const {routingProfile} = this.props
    const {appServers} = this.state

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card elevation={0}>
            <CardContent elevation={0}>
              <AsRoutingForm
                submitLabel="Update AS routing profile"
                object={routingProfile}
                onSubmit={this.onSubmit}
                update={true}
                tab={this.props.tab}
                appServers={appServers}
                disabled={!this.props.admin}
              />
                  <Dialog
                          open={this.state.dialog}
                          onClose={this.dismisDialog}
                        >
                          <DialogTitle>Update AS Routing Profile?</DialogTitle>
                          <DialogContent>
                            <DialogContentText paragraph>
                              You did not used any application server, update AS routing profile anyway?
                            </DialogContentText>
                            <DialogContentText>
                              
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                          <Button color="primary" onClick={this.dismisDialog}>Dismiss</Button>
                            <Button color="primary"  onClick={this.createDialog}>Update AS routing profile</Button>
                            
                          </DialogActions>
                        </Dialog>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
};

export default withRouter(UpdateAsRoutingProfile);

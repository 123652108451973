import React, { Component } from "react";
import {Router} from "react-router-dom";
import { Route, Switch } from 'react-router-dom';
import classNames from "classnames";
import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';

import history from "./history";
import theme from "./theme";

import TopNav from "./components/TopNav";
import SideNav from "./components/SideNav";
import Footer from "./components/Footer";
import Notifications from "./components/Notifications";
import SessionStore from "./stores/SessionStore";
import Dashboard from "./views/dashboard/Dashboard";
import InternalStore from "./stores/InternalStore";

// network-server
import ListNetworkServers from "./views/network-servers/ListNetworkServers";
import CreateNetworkServer from "./views/network-servers/CreateNetworkServer";
import NetworkServerLayout from "./views/network-servers/NetworkServerLayout";

// gateway profiles
import ListGatewayProfiles from "./views/gateway-profiles/ListGatewayProfiles";
import CreateGatewayProfile from "./views/gateway-profiles/CreateGatewayProfile";
import GatewayProfileLayout from "./views/gateway-profiles/GatewayProfileLayout";

// organization
import ListOrganizations from "./views/organizations/ListOrganizations";
import CreateOrganization from "./views/organizations/CreateOrganization";
import OrganizationLayout from "./views/organizations/OrganizationLayout";
import ListOrganizationUsers from "./views/organizations/ListOrganizationUsers";
import OrganizationUserLayout from "./views/organizations/OrganizationUserLayout";
import CreateOrganizationUser from "./views/organizations/CreateOrganizationUser";
import OrganizationRedirect from "./views/organizations/OrganizationRedirect";

// user
import Login from "./views/users/Login";
import ListUsers from "./views/users/ListUsers";
import CreateUser from "./views/users/CreateUser";
import UserLayout from "./views/users/UserLayout";
import ChangeUserPassword from "./views/users/ChangeUserPassword";

// service-profile
import ListServiceProfiles from "./views/service-profiles/ListServiceProfiles";
import CreateServiceProfile from "./views/service-profiles/CreateServiceProfile";
import ServiceProfileLayout from "./views/service-profiles/ServiceProfileLayout";

// device-profile
import ListDeviceProfiles from "./views/device-profiles/ListDeviceProfiles";
import CreateDeviceProfile from "./views/device-profiles/CreateDeviceProfile";
import DeviceProfileLayout from "./views/device-profiles/DeviceProfileLayout";

// gateways
import GatewaysListLayout from "./views/gateways/GatewaysListLayout";
import GatewayLayout from "./views/gateways/GatewayLayout";
import CreateGateway from "./views/gateways/CreateGateway";

// applications
import ListApplications from "./views/applications/ListApplications";
import CreateApplication from "./views/applications/CreateApplication";
import ApplicationLayout from "./views/applications/ApplicationLayout";

// device
import CreateDeviceApp from "./views/devices-applications/CreateDeviceApp";
import DeviceLayoutApp from "./views/devices-applications/DeviceLayoutApp";
import ListDevicesApp from "./views/devices-applications/DeviceListLayout";

// multicast
import ListMulticastGroups from "./views/multicast-groups/ListMulticastGroups";
import CreateMulticastGroup from "./views/multicast-groups/CreateMulticastGroup";
import MulticastGroupLayout from "./views/multicast-groups/MulticastGroupLayout";

// search
import Search from "./views/search/Search";

// API Keys
import ListAdminAPIKeys from "./views/api-keys/ListAdminAPIKeys";
import CreateAdminAPIKey from "./views/api-keys/CreateAdminAPIKey";
import AdminAPIKeyLayout from './views/api-keys/APIKeyLayout'
import ListOrganizationAPIKeys from "./views/api-keys/ListOrganizationAPIKeys";
import CreateOrganizationAPIKey from "./views/api-keys/CreateOrganizationAPIKey";
import OrganizationAPIKeyLayout from './views/api-keys/APIKeyLayout'
import Diagnostic from "./views/diagnostics/Diagnostic";
import ImportDevice from "./views/devices-applications/ImportDevice";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
//Logs
import Logs from './views/logs/LogsLayout';

//Tools
import Tools from './views/tools/ToolsLayout';

//Unauthorized
import BsCheck from './views/unauthorized/BsCheck';
import AboutPage from "./views/about/AboutPage";
import ListAsRoutingProfiles from "./views/as-routing-profile/ListAsRoutingProfiles";
import CreateAsRoutingProfile from "./views/as-routing-profile/CreateAsRoutingProfile";
import AsRoutingProfileLayout from "./views/as-routing-profile/AsRoutingProfileLayout"

import Redirect from './components/Redirect'
import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import OrganizationStore from "./stores/OrganizationStore";
import { errorHandler } from "./stores/helpers";
const drawerWidth = 60;
const drawerWidthFullSize = 270;
const useStyles = makeStyles((theme) => ({
  root: {
    zIndex:9999,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));


function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
     const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}


const styles = {
  root: {
    flexGrow: 1,
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  main: {
    width: "100%",
    padding: 2 * 24,
    paddingTop: 115,
    flex: 1,
  },

  mainDrawerOpen: {
    paddingLeft: drawerWidth + (2 * 24),
  },
  fullSizeDrawerOpen:{
    paddingLeft: drawerWidthFullSize + (2 * 24),
  },
  footerDrawerOpen: {
    paddingLeft: drawerWidth,
  },
};


class App extends Component {
  constructor() {
    super();
    document.body.style.visibility = 'hidden'
    if (document.fonts.onloadingdone){
      document.fonts.onloadingdone = () => {
        document.body.style.visibility = 'visible'
      };
    }else{
      document.fonts.ready.then(() => {
        document.body.style.visibility = 'visible'
      });
    }
  
    this.state = {
      mode:null,
      user: null,
      drawerOpen: false,
      drawerSize:false,
      organization:null,
      settings:{
        openidConnect:{},
        branding:{topNavColor:'transparent'}
      }
    };
  
    this.setDrawerOpen = this.setDrawerOpen.bind(this);
    this.setOrganization = this.setOrganization.bind(this);
    this.organizationStoreInit = this.organizationStoreInit.bind(this);
    this.getOrganizationFromLocation = this.getOrganizationFromLocation.bind(this)
    let meta = document.createElement('meta')
    meta.setAttribute('name', 'description')
    meta.setAttribute('content', localStorage.getItem("metaDescription") || "")
    document.querySelector('head').prepend(meta)
    document.querySelector('title').text = localStorage.getItem("metaTitle") || ""

    SessionStore.on("userGot", () => {
      this.organizationStoreInit()
    })
  }

  componentDidMount() {

    SessionStore.on("change", () => {
      this.setState({
        mode:SessionStore.getMode(),
        user: SessionStore.getUser(),
        drawerOpen: SessionStore.getUser() != null && !(SessionStore.getMode()==='noauth'),
      });
    });

    this.setState({
      mode:SessionStore.getMode()||null,
      user: SessionStore.getUser(),
      drawerOpen: SessionStore.getUser() != null || SessionStore.getMode() != null,
      settings:{
        branding:{
          topNavColor:localStorage.getItem("topNavColor") || 'rgb(42, 106, 178)',
          tabsColor : localStorage.getItem("tabsColor") || 'rgb(138 ,181 ,225)',
      },
        openidConnect:this.state.settings.openidConnect
      }
    },()=>{
      if(window.location.href.match(/noauth/ig)!=null){
        SessionStore.setMode(true)
      }else{
        SessionStore.setMode(false)
      }
    });

    InternalStore.settings(resp => {

      let settings = this.state.settings
      settings.branding.topNavColor = resp.branding.topNavColor || 'rgb(42, 106, 178)'
      settings.branding.tabsColor = resp.branding.tabsColor || 'rgb(138 ,181 ,225)'
      //settings.branding.mode = resp.branding.mode
      settings.toolsEnabled = resp.toolsEnabled
      this.setState({
       settings:settings
      });
      localStorage.setItem("serverName", resp.serverName);
      localStorage.setItem("topNavColor", settings.branding.topNavColor);
      localStorage.setItem("tabsColor", settings.branding.tabsColor);
      localStorage.setItem("metaDescription", resp.branding.metaDescription||'');
      localStorage.setItem("metaTitle", resp.branding.metaTitle||'');
    })
   
   
  }
  onSwitchMode(){
    let mode = SessionStore.getMode();
   // let user = SessionStore.getUser();

    if(mode!=null){
      SessionStore.setMode(false);
      history.push("/");
    }else{
      SessionStore.setMode(true);
      history.push("/noauth/bscheck");
    }
  }
  setDrawerOpen(bool) {
    let state = this.state
    if(!bool && !this.state.drawerSize){
      state.drawerOpen = true
      state.drawerSize = 'full'
    }else if(!bool && this.state.drawerSize==='full'){
      state.drawerOpen = false
      state.drawerSize = false
    }else if (bool){
      state.drawerOpen = true
      state.drawerSize = false
    }

    this.setState(state);
  }

  getOrganizationFromLocation() {
    const organizationRe = /\/organizations\/(\d+)/g;
    const match = organizationRe.exec(window.location.hash);
    
    if (match !== null && (this.state.organization === null || this.state.organization.id !== match[1])) {
      SessionStore.setOrganizationID(match[1]);
    }else{
      return false
    }
  }
  setOrganization(org){
    this.setState({organization:org})
  }
  organizationStoreInit(){
    this.getOrganizationFromLocation()
    if (SessionStore.getOrganizationID() !== null && this.state.mode!=='noauth') {
      OrganizationStore.get(SessionStore.getOrganizationID(), resp => {
           this.setOrganization(resp.organization)
         });
     }else if(SessionStore.getOrganizationID()==null  && this.state.mode!=='noauth'){

      if(SessionStore.organizations.length>0){
        SessionStore.setOrganizationID(SessionStore.organizations[0].organizationID) 
      }else{
        OrganizationStore.list("", 999, 0, resp => {
          const options = resp.result.map((o, i) => {
            return {label: o.name, value: o.id}
          });
          if(!options.length){
            errorHandler({message:'No organizations available'})
          }else{
            OrganizationStore.get(options[0].value, resp => {
              this.setOrganization(resp.organization)
             
            });
            SessionStore.setOrganizationID(options[0].value)
          }
      
        });
      }
     
    
     } 
     SessionStore.on("organization.change", () => {
      OrganizationStore.get(SessionStore.getOrganizationID(), resp => {
        this.setOrganization(resp.organization)
        
      });
    });

     OrganizationStore.on("delete", id => {
      if (this.state.organization !== null && this.state.organization.id === id) {
        OrganizationStore.list('', 1, 0, resp => {
          this.setOrganization(resp.result[0])
          SessionStore.setOrganizationID(resp.result[0].id)
        });
       
      }
     
    });

    OrganizationStore.on("change", (org) => {
      if (this.state.organization !== null && this.state.organization.id === org.id) {
        this.setOrganization(org) 
      }
    });
    history.listen((location,action)=>{
     if(location.pathname === "/login"){
        this.setOrganization(null)
     }
      
        
     this.getOrganizationFromLocation()
    })
  }
  render() {
    let topNav = null;
    let sideNav = null;
    if (this.state.user !== null || this.state.mode==='noauth' ) {
      topNav = <TopNav toolsEnabled={this.state.settings.toolsEnabled} colors={{topNavColor:this.state.settings.branding.topNavColor}} serverName={this.state.settings.serverName} logoutURL={this.state.settings.openidConnect.logoutURL} oidcEnabled={this.state.settings.openidConnect.enabled} size={this.state.drawerSize} onSwitchMode={this.onSwitchMode} setDrawerOpen={this.setDrawerOpen} drawerOpen={this.state.drawerOpen} mode={this.state.mode} user={this.state.user} />;
      sideNav = <SideNav organization={this.state.organization}  open={this.state.drawerOpen} size={this.state.drawerSize} mode={this.state.mode} user={this.state.user} />
    } 
  
    return (
      <Router history={history}>
        <React.Fragment>
          <CssBaseline />
          <MuiThemeProvider theme={theme}>
            <div id="back-to-top-anchor" className={this.props.classes.root}>
              {topNav}
              {sideNav}
              <div  className={classNames(this.props.classes.main, this.state.drawerOpen &&( (this.state.drawerSize==='full' && this.state.drawerOpen)?this.props.classes.fullSizeDrawerOpen:this.props.classes.mainDrawerOpen ))}>
                <Grid  container spacing={4}>
                  <Switch>
                    <Route exact path="/" render={routeProps => (
                          <OrganizationRedirect org={this.state.organization} {...routeProps}/>  )} />
                    <Route exact path="/login"   render={routeProps => (
                      <Login toolsEnabled={this.state.settings.toolsEnabled} {...routeProps}/>
                    )}/>

                    <Route exact path="/noauth/bscheck" component={BsCheck} />

                    <Route exact path="/about" component={AboutPage} />

                    <Route exact path="/users" component={ListUsers} />
                    <Route exact path="/users/create" component={CreateUser} />
                    <Route exact path="/users/:userID(\d+)" component={UserLayout} />
                    <Route exact path="/users/:userID(\d+)/organizations" component={UserLayout} />
                    <Route exact path="/users/:userID(\d+)/password" component={ChangeUserPassword} />
                    <Route exact path="/dashboard" component={Dashboard} />

                    {/* <Route exact path="/organizations/:organizationID(\d+)/application-server" component={ListApplicationServers} />
                    <Route exact path="/organizations/:organizationID(\d+)/application-server/create" component={CreateApplicationServer} />
                    <Route path="/organizations/:organizationID(\d+)/application-server/:applicationServerID(\d+)" component={ApplicationServerLayout} /> */}

                    <Route exact path="/network-servers" component={ListNetworkServers} />
                    <Route exact path="/network-servers/create" component={CreateNetworkServer} />
                    <Route path="/network-servers/:networkServerID" component={NetworkServerLayout} />

                    <Route exact path="/gateway-profiles" component={ListGatewayProfiles} />
                    <Route exact path="/gateway-profiles/create" component={CreateGatewayProfile} />
                    <Route path="/gateway-profiles/:gatewayProfileID([\w-]{36})" component={GatewayProfileLayout} />

                    <Route exact path="/api-keys" component={ListAdminAPIKeys} />
                    <Route exact path="/api-keys/create" component={CreateAdminAPIKey} />
                    <Route exact path="/api-keys/:apiKey([\w-]{36})" component={AdminAPIKeyLayout} />

                    <Route exact path="/logs" component={Logs} />
                    <Route exact path="/organizations/:organizationID(\d+)/logs" render={routeProps => (
                          <Logs org={this.state.organization} {...routeProps}/>  )}  />   

                    <Route path="/tools"  render={routeProps => (
                    
                    <Tools toolsEnabled={this.state.settings.toolsEnabled} {...routeProps}/>  )}  /> 

{/* Api keys*/}
                    <Route exact path="/organizations/:organizationID(\d+)/api-keys" render={routeProps => (
                          <ListOrganizationAPIKeys org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route exact path="/organizations/:organizationID(\d+)/api-keys/create" render={routeProps => (
                          <CreateOrganizationAPIKey org={this.state.organization} {...routeProps}/>  )}  />  
                    <Route exact path="/organizations/:organizationID(\d+)/api-keys/:apiKey([\w-]{36})" render={routeProps => (
                          <OrganizationAPIKeyLayout org={this.state.organization} {...routeProps}/>  )}  />  
{/* Service Profiles */}
                    <Route exact path="/organizations/:organizationID(\d+)/service-profiles" render={routeProps => (
                          <ListServiceProfiles org={this.state.organization} {...routeProps}/>
                        )}  />
                    <Route exact path="/organizations/:organizationID(\d+)/service-profiles/create" render={routeProps => (
                        <CreateServiceProfile org={this.state.organization} {...routeProps}/>
                    )}  />
                    <Route path="/organizations/:organizationID(\d+)/service-profiles/:serviceProfileID([\w-]{36})" render={routeProps => (
                       <ServiceProfileLayout org={this.state.organization} {...routeProps}/>
                       )} />
              

                    <Route exact path="/device-profiles" component={ListDeviceProfiles} />
                    <Route exact path="/device-profiles/create" component={CreateDeviceProfile} />
                    <Route path="/device-profiles/:deviceProfileID([\w-]{36})" component={DeviceProfileLayout} />

{/* AsRouting Profiles */}
                    <Route exact path="/organizations/:organizationID(\d+)/as-routing-profiles"  render={routeProps => (
                      <ListAsRoutingProfiles org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route exact path="/organizations/:organizationID(\d+)/as-routing-profiles/create" render={routeProps => (
                      <CreateAsRoutingProfile org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route exact path="/organizations/:organizationID(\d+)/as-routing-profiles/:id(\d+)" render={routeProps => (
                      <AsRoutingProfileLayout org={this.state.organization} {...routeProps}/>  )}  /> 

{/* Gateway */}
                    <Route exact path="/organizations/:organizationID(\d+)/gateways/create" render={routeProps => (
                       <CreateGateway org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route path="/organizations/:organizationID(\d+)/gateways/:gatewayID([\w]{16})" render={routeProps => (
                       <GatewayLayout colors={{topNavColor:this.state.settings.branding.topNavColor,tabsColor:this.state.settings.branding.tabsColor}} org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route path="/organizations/:organizationID(\d+)/gateways" render={routeProps => (
                       <GatewaysListLayout org={this.state.organization} {...routeProps}/>  )}  /> 

{/* Multicast Group */}
                    <Route exact path="/organizations/:organizationID(\d+)/multicast-groups/create" render={routeProps => (
                       <CreateMulticastGroup org={this.state.organization} {...routeProps}/>  )}  />  
                    <Route path="/organizations/:organizationID(\d+)/multicast-groups/:multicastGroupID([\w-]{36})"  render={routeProps => (
                       <MulticastGroupLayout org={this.state.organization} {...routeProps}/>  )}  />  
                    <Route exact path="/organizations/:organizationID(\d+)/multicast-groups" render={routeProps => (
                       <ListMulticastGroups org={this.state.organization} {...routeProps}/>  )}  /> 
                  
{/* Applications */}
                    <Route exact path="/organizations/:organizationID(\d+)/applications" render={routeProps => (
                       <ListApplications org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route exact path="/organizations/:organizationID(\d+)/applications/create" render={routeProps => (
                       <CreateApplication org={this.state.organization} {...routeProps}/>  )}  /> 
                    {/* <Route exact path="/organizations/:organizationID(\d+)/applications/:applicationID(\d+)/devices/create" component={CreateDevice} /> */}
                    {/* <Route exact path="/organizations/:organizationID(\d+)/applications/:applicationID(\d+)/devices/import" component={ImportDevice} /> */}
                    {/* <Route path="/organizations/:organizationID(\d+)/applications/:applicationID(\d+)/devices/:devEUI([\w]{16})" component={DeviceLayout} /> */}
                    <Route path="/organizations/:organizationID(\d+)/applications/:applicationID(\d+)"  render={routeProps => (
                       <ApplicationLayout org={this.state.organization} {...routeProps}/>  )}  />  

                    {/*<Route exact path="/organizations/:organizationID(\d+)/devices-applications" component={ListDevicesApp} />*/}
                    {/*<Route exact path="/organizations/:organizationID(\d+)/devices-applications/create" component={CreateDeviceApp} />*/}
                    {/* <Route exact path="/organizations/:organizationID(\d+)/devices-applications/:devEUI([\w]{16}" component={DeviceLayoutApp} /> */}
{/* Devcies */}                    
                    <Route exact path="/organizations/:organizationID(\d+)/devices/import" render={routeProps => (
                       <ImportDevice org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route exact path="/organizations/:organizationID(\d+)/devices/create"  render={routeProps => (
                       <CreateDeviceApp org={this.state.organization} {...routeProps}/>  )}  />
                    <Route path="/organizations/:organizationID(\d+)/devices/:devEUI([\w]{16})" render={routeProps => (
                       <DeviceLayoutApp  colors={{topNavColor:this.state.settings.branding.topNavColor,tabsColor:this.state.settings.branding.tabsColor}} org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route path="/organizations/:organizationID(\d+)/devices" render={routeProps => (
                       <ListDevicesApp org={this.state.organization} {...routeProps}/>  )}  />  



                    <Route exact path="/organizations" component={ListOrganizations} />
                    <Route exact path="/organizations/create" component={CreateOrganization} />

{/* Organization Users */}                      
                    <Route exact path="/organizations/:organizationID(\d+)/users" render={routeProps => (
                       <ListOrganizationUsers org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route exact path="/organizations/:organizationID(\d+)/users/create" render={routeProps => (
                       <CreateOrganizationUser org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route exact path="/organizations/:organizationID(\d+)/users/:userID(\d+)/password" render={routeProps => (
                       <ChangeUserPassword org={this.state.organization} {...routeProps}/>  )}  /> 
                    <Route exact path="/organizations/:organizationID(\d+)/users/:userID(\d+)" render={routeProps => (
                       <OrganizationUserLayout org={this.state.organization} {...routeProps}/>  )}  /> 
 {/*  */}                     
                    <Route path="/organizations/:organizationID(\d+)" render={routeProps => (
                       <OrganizationLayout getOrgFromLocation={this.getOrganizationFromLocation} organization={this.state.organization} {...routeProps}/>  )}  />

                    <Route exact path="/search" component={Search} />
                    <Route exact path="/redirect" component={Redirect} />




                    <Route exact path="/diagnostics" render={routeProps => (
                       <Diagnostic org={this.state.organization} {...routeProps}/>  )}  />  
                        {/* <Route exact path="/ctt" render={routeProps => (
                       <CTTLayout org={this.state.organization} {...routeProps}/>  )}  />   */}
                  </Switch>
                </Grid>
              </div>
              <div className={this.state.drawerOpen ? this.props.classes.footerDrawerOpen : ""}>
                <Footer footer={this.state.settings.branding.footer}/>
              </div>
            </div>
            <Notifications />
          </MuiThemeProvider>
          <ScrollTop >
        <Fab  color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon  />
        </Fab>
      </ScrollTop>
        </React.Fragment>
      </Router>
    );
  }
}

export default withStyles(styles)(App);

import { EventEmitter } from "events";
import Swagger from "swagger-client";
import sessionStore from "./SessionStore";
import {checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";

class RoutingProfileStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/routingProfile.swagger.json", sessionStore.getClientOpts());
  }

  create(routingProfile, callbackFunc) {
    this.swagger.then(client => {
      client.apis.RoutingProfileService.RoutingProfileService_Create({
        body: {
          routingProfile: routingProfile,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notifiy("created");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  get(id, callbackFunc) {
    this.swagger.then((client) => {
      client.apis.RoutingProfileService.RoutingProfileService_Get({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  update(routingProfile, callbackFunc) {
    this.swagger.then(client => {
      client.apis.RoutingProfileService.RoutingProfileService_Update({
        "routingProfile.id": routingProfile.id,
        body: {
          routingProfile: routingProfile,
        },
      })
        .then(checkStatus)
        .then(resp => {
          this.notifiy("updated");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  notifiy(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "routing Profile has been " + action,
      },
    });
  }

  delete(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.RoutingProfileService.RoutingProfileService_Delete({
        id: id,
      })
        .then(checkStatus)
        .then(resp => {
          this.notifiy("deleted");
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  list(search,limit, offset, organizationID, callbackFunc) {
    this.swagger.then((client) => {
      client.apis.RoutingProfileService.RoutingProfileService_List({
        limit: limit,
        offset: offset,
        organizationID: organizationID,
        search:search,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }
}

const routingProfileStore = new RoutingProfileStore();
export default routingProfileStore;
window.test = routingProfileStore;

import React, { Component } from "react";
import { withRouter,Link , Route, Switch,} from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Grid from '@material-ui/core/Grid';

import Delete from "mdi-material-ui/Delete";
import KeyVariant from "mdi-material-ui/KeyVariant";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import UserStore from "../../stores/UserStore";
import UpdateUser from "./UpdateUser";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import theme from "../../theme";
import UserOrganizationsList from "./UserOrganizationsList";

const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class UserLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 1,
    };
    this.onChangeTab = this.onChangeTab.bind(this);
    this.locationToTab = this.locationToTab.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    UserStore.get(this.props.match.params.userID, resp => {
      resp.user.sessionTTL = resp.user.sessionTTL / 60
      this.setState({
        user: resp,
      });
    });
    this.locationToTab();
  }
  componentDidUpdate(prevProps) {
    if (this.props === prevProps) {
      return;
    }

    this.locationToTab();
  }
  deleteUser() {
    if (window.confirm("Do you realy want to delete the user?")) {
      UserStore.delete(this.props.match.params.userID, () => {
        this.props.history.push("/users");
      });
    }
  }
  locationToTab() {
    let tab = 0;
  
    if(window.location.href.endsWith("/organizations")){
      tab = 1;
    }
    this.setState({
      tab: tab,
    });
  }
  onChangeTab(e, v) {
    this.setState({
      tab: v,
    });
  }
  render() {
    if (this.state.user === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={[

             <TitleBarButton
              label="Change password"
              icon={<KeyVariant />}
              to={`/users/${this.props.match.params.userID}/password`}
              disabled={this.state.user.user.loginWithLdap}
            />,
            <TitleBarButton
              label="Delete"
              icon={<Delete />}
              color="secondary"
              onClick={this.deleteUser}
            />,
          ]}
        >
          <TitleBarTitle to="/users" title="Users" />
          <TitleBarTitle title="/" />
          <TitleBarTitle title={this.state.user.user.email} />
        </TitleBar>
        <Grid className={'noUDpaddings'} item xs={12}>
          <Tabs
            value={this.state.tab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab label="Details" component={Link} to={`/users/${this.props.match.params.userID}/`} />
            <Tab label="Organizations" component={Link} to={`/users/${this.props.match.params.userID}/organizations`} />
          </Tabs>
        </Grid>
        <Grid item xs={12}>
        <Switch>
          <Route exact path={`/users/${this.props.match.params.userID}/`} render={props => <UpdateUser user={this.state.user} />} />
          <Route exact path={`/users/${this.props.match.params.userID}/organizations`} render={props => <UserOrganizationsList {...props} user={this.state.user.user} />} />
        </Switch>
          
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(UserLayout));

import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";



import Form from "../../components/Form";
import FormComponent from "../../classes/FormComponent";
import SessionStore from "../../stores/SessionStore";
import InternalStore from "../../stores/InternalStore";
import theme from "../../theme";
import LinearProgress from '@material-ui/core/LinearProgress';
import { logoHandle } from "../../components/TopNav"
import history from '../../history'

const styles = {
  loginImage:{width:'200px',position:'relative',opacity:0},
  loginHead:{
      width:'100%' , 
      display:'flex',
      justifyContent:'center',
      height:'50px',
      background:'rgb(42, 106, 178)'

},
loginFooter:{
  width:'100%' , 
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  height:'30px',
  background:'rgb(42, 106, 178)'
},
footerItem:{
  color:"#fff",
  fontFamily: "Pantone",
  fontWeight: 400,
  marginRight:'10px'
},
  loginWrap:{
    width:'100%',
    height:'100%',
    position:'absolute',
    top:0,
    left:0,
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  textField: {
    width: "100%",
  },
  link: {
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  mrAuto: {
    marginRight:"auto"
  },
  videoWrapper:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    overflow: 'hidden',
    zIndex: -1,
    top: 0,
    left: 0,
    "& video":{
      position:'absolute',
      top:0,
      left:0,
      width:'100%',
      height:'100%',
      objectFit:'cover'
    }
  }
};


class LoginForm extends FormComponent {
  render() {
    if (this.state.object === undefined) {
      return null;
    }


    return(
      <Form
        extraButtons={this.props.toolsEnabled&&this.props.toolsEnabled.bsChecker===true&&<Button disabled={this.props.spinner} color="primary" onClick={this.props.onNoAuth} className={this.props.classes.mrAuto} type={"button"} >BS Checker</Button>}
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.spinner}
      >
        <TextField
          id="email"
          label="Email"
          margin="normal"
          value={this.state.object.email || ""}
          onChange={this.onChange}
          fullWidth
          required
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          margin="normal"
          value={this.state.object.password || ""}
          onChange={this.onChange}
          fullWidth
          required
        />
      </Form>
    );
  }
}

class OpenIDConnectLogin extends Component {
  render() {
    return(
      <div>
        <a href={this.props.loginUrl}><Button variant="outlined">{this.props.loginLabel}</Button></a>
      </div>
    );
  }
}


class Login extends Component {
  constructor() {
    super();

    this.state = {
      loaded: false,
      showOIDC:false,
      registration: "",
      oidcEnabled: false,
      oidcLoginlabel: "",
      oidcLoginUrl: "",
      bg:null
    };
    
    this.onSubmit = this.onSubmit.bind(this);
    this.onNoAuth = this.onNoAuth.bind(this);
  }

componentDidMount() {
    SessionStore.logout(true, () => {});

    const params = new URLSearchParams(this.props.location.search);

    // try to login on callback from openid provider
    if (params.has('code') && params.has('state')) {
        SessionStore.openidConnectLogin(params.get('code'), params.get('state'), () => {
            this.props.history.push("/");
        });
        return;
    }

    InternalStore.settings(resp => {
        const oidcParam = params.get('oidc');
        const oidcEnabled = resp.openidConnect.enabled && (oidcParam !== 'false');

        // redirecto to OpenID Connect in case it is enabled in cfg and no oidc=false in url presented (oidc back button)
        if (oidcEnabled) {
            window.location.href = `${window.location.origin}/${resp.openidConnect.loginURL}`;
        }

        this.setState({
            loaded: true,
            registration: resp.branding.registration,
            oidcEnabled: oidcEnabled,
            oidcLoginUrl: resp.openidConnect.loginURL,
            oidcLoginLabel: resp.openidConnect.loginLabel,
        });
    });

    this.setState({ bg: '/public/background.mp4' });
}

  onNoAuth(){
    SessionStore.setMode(true)
    this.props.history.push("/noauth/bscheck");
  }

  onSubmit(login) {
    this.setState({spinner:true})
    SessionStore.login(login, () => {
      this.setState({spinner:false})
      let page = "/"
      let prevPage = window.sessionStorage.getItem("prevPage")
      if(prevPage){
          page = prevPage
      }
      if((prevPage)&&prevPage.match(/^!/)!==null){
        window.location.href =  window.location.origin + prevPage.replace('!','')
      }else{
        history.push(page);
      }
      
    },this);
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }
  
    return(
      <div className={this.props.classes.loginWrap}>
        <Grid item xs={6} lg={4} >
          <Card>
          {this.state.spinner&&<LinearProgress />}
            <div className={this.props.classes.loginHead} >
              <img alt="" onLoad={logoHandle} className={this.props.classes.loginImage} src="/public/logo.svg"  />
            </div>

            <CardContent>
              {!this.state.oidcEnabled && <LoginForm
                toolsEnabled={this.props.toolsEnabled}
                classes={this.props.classes}
                submitLabel="Login"
                onNoAuth={this.onNoAuth}
                onSubmit={this.onSubmit}
                spinner={this.state.spinner}
              />}

              {this.state.oidcEnabled && this.state.showOIDC&& <OpenIDConnectLogin
                loginUrl={this.state.oidcLoginUrl}
                loginLabel={this.state.oidcLoginLabel}
              />}
            </CardContent>
            <div className={this.props.classes.loginFooter} >
                <span className={this.props.classes.footerItem}>Поддержка:</span>
                <a className={this.props.classes.footerItem} href={"mailto:helpdesk_iot@ertelecom.ru"}>helpdesk_iot@ertelecom.ru</a>
                <span className={this.props.classes.footerItem}>|</span>
                <span className={this.props.classes.footerItem}>8 (800) 234-08-80</span>
            </div>
            {this.state.registration !== "" && <CardContent>
              <Typography className={this.props.classes.link} dangerouslySetInnerHTML={{__html: this.state.registration}}></Typography>
             </CardContent>}
          </Card>
        </Grid>

        <div className={this.props.classes.videoWrapper}>
            <video src={this.state.bg}  loop autoPlay  preload="auto" muted >
            
            </video>
          </div>
       
        
    
      </div>
    );
  }
}

export default withStyles(styles)(withRouter(Login));

import { EventEmitter } from "events";


import Swagger from "swagger-client";
import sessionStore from "./SessionStore";

import {checkStatus, errorHandler } from "./helpers";

// dic_type s:  operational_status | ...
class DictionaryStore extends EventEmitter {
    constructor() {
        super();
        this.swagger = new Swagger("/swagger/dictionary.swagger.json", sessionStore.getClientOpts());
      }
    
    getDictionary(type, store, callbackFunc) {
      const params = {
        dic_type: type,
      };
      if (store) {
        params.store = store;
      }
        this.swagger.then(client => {
            client.apis.DictionaryService.DictionaryService_GetDictionary(params)
            .then(checkStatus)
            .then(resp => {
              callbackFunc(resp.obj);
            })
            .catch(errorHandler);
          });
    }  
}

const dictionaryStore = new DictionaryStore();
export default dictionaryStore;
import React, {Component} from 'react'


import {Line} from 'react-chartjs-2';
import GatewayStore from "../../stores/GatewayStore";

import _ from 'lodash'


const load = (gw,filter,organizationID,cb) =>{
    GatewayStore.logs(organizationID, gw, filter, undefined, undefined,undefined, undefined, cb);  
}



class HeatmapEvents extends Component{
    constructor(){
        super()

        this.getDatasetsFromDict = this.getDatasetsFromDict.bind(this)
        this.getLabelsFromData = this.getLabelsFromData.bind(this)
        this.proccessLogs = this.proccessLogs.bind(this)
        this.update = this.update.bind(this)

        this.state = {
            log:[]
        }


        

        this.options = {
            maintainAspectRatio: false,
            follow: true,
            animation: false,
            plugins: {
              tooltip:{
                callbacks:{
                  afterLabel:(context)=>{
                   if(context.raw.cause!==undefined ){
                    return context.raw.cause
                   }
                   
                  }
                }
              }
              // tooltip:{
              //     callbacks:{
              //       label:function(context){
              //         let label = context.dataset.label || '';
              //         if (label) {
              //           label += ': ';
              //       }
              //       console.log(context)
              //       if (context.parsed.y !== null) {
              //          // label += context.parsed.y
              //       }
                   
              //         return label
              //       }
              //     }
              // }
            },
            scales: {
              x: {
                bounds:'data',
                type: 'time',
                ticks:{
                  maxTicksLimit:31,
                  minTicksLimit:10,
                  autoSkip:false,
                  maxRotation:0,
                },
                time: {
                  displayFormats:{
                    minute:'HH:mm',
                    hour:'HH:00',
                    day:'MMM D',
                    week:"MMM D",
                    month:"MMM D",
                    quarter:"MMM D",
                    year:"MMM YYYY",
                  },
                  round:true,
                  unit:'day',
                  tooltipFormat: 'DD-MM-YYYY HH:mm',
                }
              },
              y:{
                type: 'category',
                labels: ['ON', 'OFF'],
              },
              
            },
  }
    }


proccessLogs(raw){
    
   
   
    let log = raw.result.map((el,i)=>{
     
        let code = el.code
        let found = _.findKey(this.props.dict, ['code', code]);
        let dictObj = this.props.dict[found]
        let split = dictObj.label.split(" ");

        let switchEr 


        let On = ['on','up','online']
        let Off = ['off','down','offline']
        if(split[split.length-1]){
            let word = split[split.length-1].toLowerCase()
            let word2 = split[split.length-2].toLowerCase()
            let word3 = (split.length>3) ?split[split.length-3].toLowerCase() : ""
            
            if(On.indexOf(word)>-1  || On.indexOf(word2)>-1 || On.indexOf(word3)>-1){
                switchEr = 'ON'
            }else if(Off.indexOf(word)>-1 || Off.indexOf(word2)>-1 || Off.indexOf(word3)>-1){
                switchEr ='OFF'
            }
        }


        let obj = {
          createdAt:el.createdAt,
        }
        obj[split[0]] = switchEr

        if(split[0]==="Gateway" && switchEr==='ON'){
          obj.cause = split.splice(2,2).join(' ')
        }
       
        return obj
    });



    this.setState({log:log})
}
getLabelsFromData(){
  return this.state.log.map((el)=>{
   
    return el.createdAt
  })
}

getDatasetsFromDict(){
 const dict  = this.props.dict

let colors = ['#b39ddb','#9fa8da','#90caf9','#80deea','#80cbc4','#a5d6a7','#fff59d']
let arr = []
for(let i in dict){
    if(arr.indexOf(dict[i].label.split(' ')[0])>-1){
        continue
    }else{
        arr.push(dict[i].label.split(' ')[0])
    }
}

let dataset = []
for(let c in arr){

    dataset.push({
        showLines: false,
        label:arr[c],
        borderWidth:0.5,
        radius:1,
        data: this.state.log,
        borderColor: colors[parseInt(c)],
        backgroundColor:colors[parseInt(c)],
       // lineTension: 1,
        pointBackgroundColor: colors[parseInt(c)],
        stepped:  'middle',
        parsing: {
          xAxisKey: 'createdAt',
          yAxisKey:arr[c]
        }
    })
}

return dataset
    
}

update(){
    load(this.props.gwId,this.props.filter,this.props.orgId||0,(res)=>{
        this.proccessLogs(res)
        this.defaults = this.props
    })
}
componentDidMount(){
  this.update()
}

// shouldComponentUpdate(nextProps,nextState){

// console.log(nextProps,  this.props, _.isEqual(this.props.filter, nextProps.filter) )
//     if(nextProps.filter.code!==this.props.filter.code){
//         return true
//     }

//     if(nextProps.filter.startTimestamp!==this.props.filter.startTimestamp){
//         return true
//     }

//     if(nextProps.filter.endTimestamp!==this.props.filter.endTimestamp){
//         return true
//     }

//     if(nextProps.gwId!==this.props.gwId){
//         return true
//     }

//     if(nextProps.orgId!==this.props.orgId){
//         return true
//     }
//     return false

// }


    render(){
      
        let data  ={
         //   labels:this.getLabelsFromData(),
            datasets:this.getDatasetsFromDict()
        }

        return (
            <Line id={"GatewayEvents"} data={data} options={this.options} />
          )
    }
}

export default HeatmapEvents
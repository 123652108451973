import React, { Component } from "react";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import SessionStore from "../../stores/SessionStore";
import theme from "../../theme";
import UserLogs from "./UserLogs";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};


class LogsLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };
  }

  componentDidMount() {
    this.locationToTab();
    SessionStore.on("change", this.setIsAdmin);
    this.setIsAdmin();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    this.locationToTab();
  }

  setIsAdmin = () => {
    this.setState({
      admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
    });
  }

  locationToTab = () => {
    let tab = 0;

    /*if (window.location.href.endsWith("/map")) {
        tab = 1;
    }*/

    this.setState({
      tab: tab,
    });
  }

  render() {
    let userLogsLink = (this.props.match.params.organizationID) ? `/organizations/${this.props.match.params.organizationID}/logs` : '/logs'

    return (
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            null
          }
        >
        <OrganizationBreadcrumps org={this.props.org}>
             <TitleBarTitle title="Logs"/>
          </OrganizationBreadcrumps>
        </TitleBar>

        <Grid item className={'noUDpaddings'} xs={12}>
          <Tabs
            value={this.state.tab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab label="User Logs" component={Link} to={userLogsLink}/>
            {/* <Tab label="Map" component={Link} to={`/organizations/${this.props.match.params.organizationID}/gateways/map`} />*/}
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Switch>
            <Route exact path={this.props.match.path} render={props => <UserLogs {...props} />}/>
            {/*<Route exact path={`${this.props.match.path}/map`} render={props => <ListGatewaysMap organizationID={this.props.match.params.organizationID}/>} />
*/}
          </Switch>
        </Grid>
      </Grid>
    );
  }
}


export default withStyles(styles)(withRouter(LogsLayout));

import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import DeviceAdmin from "../../components/DeviceAdmin";
import ApplicationStore from "../../stores/ApplicationStore";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {withStyles} from "@material-ui/core/styles";
import moment from "moment";
import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


const styles ={
    root: {
        width: '100%',
    },

    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
};


class ListApplications extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);

    this.state = {
        orderBy:null,
        order:null
    }
  }

  changeSort(event){
        let state = {
            orderBy:event.currentTarget.dataset.key,
            order:this.state.order
        }

        if(state.orderBy!==this.state.orderBy){
            state.order = null;
        }

        if(state.order === null){
            state.order = 'asc'
        }else if(state.order === 'asc'){
            state.order = 'desc'
        }else if(state.order === 'desc'){
            state.order = null
            state.orderBy = null
        }

        this.setState(state);
  }

  makeSortLabel(keyName,viewName){
    return  <TableSortLabel
          data-key={keyName}
          active={this.state.orderBy === keyName}
          direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
          onClick={this.changeSort}
      >
        {viewName}
          {this.state.orderBy === keyName ? (
              <span className={this.props.classes.visuallyHidden}>
                  {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
          ) : null}
      </TableSortLabel>
  }

  makeCellSortProps(keyName){
      return {
          key:keyName,
          align:'left',
          padding:'normal',
          sortDirection:this.state.orderBy === keyName ? this.state.order : false
      }
  }

  getPage(limit, offset, callbackFunc) {
    ApplicationStore.list("", this.props.match.params.organizationID, limit, offset, callbackFunc,this.state.orderBy,this.state.order);
  }

  getRow(obj) {
    let createdAt = ''
    if(obj.createdAt){
      createdAt = moment(obj.createdAt).format("YYYY-MM-DD HH:mm:ss")
    }
    let icon = null;

    if (obj.isActive) {
      icon = <Check />;
    } else {
      icon = <Close />;
    }
    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell>{obj.id}</TableCell>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/applications/${obj.id}/edit`}>{obj.name}</TableCellLink>
        {/* <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/service-profiles/${obj.serviceProfileID}`}>{obj.serviceProfileName}</TableCellLink> */}
        {/* <TableCell>{obj.devCnt}</TableCell>
        <TableCell>{obj.description}</TableCell> */}
        <TableCell>{createdAt}</TableCell>
        <TableCell>{icon}</TableCell>
      </TableRow>
    );
  }

  render() {

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <DeviceAdmin organizationID={this.props.match.params.organizationID}>
              <TitleBarButton
                label="Create"
                icon={<Plus />}
                to={`/organizations/${this.props.match.params.organizationID}/applications/create`}
              />
            </DeviceAdmin>
          }
        >
          <OrganizationBreadcrumps org={this.props.org}>
           <TitleBarTitle title="Application Servers" />
          </OrganizationBreadcrumps>
          
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
          orderBy={this.state.orderBy}
            header={
              <TableRow>
                <TableCell {...this.makeCellSortProps('id')}>{this.makeSortLabel('id','ID')}</TableCell>
                <TableCell {...this.makeCellSortProps('name')}>{this.makeSortLabel('name','Name')}</TableCell>
                {/* <TableCell {...this.makeCellSortProps('service-profile')}>{this.makeSortLabel('service-profile','Service-profile')}</TableCell>
                <TableCell {...this.makeCellSortProps('devices')}>{this.makeSortLabel('devices','Devices')}</TableCell>
                <TableCell {...this.makeCellSortProps('description')}>{this.makeSortLabel('description','Description')}</TableCell> */}
                <TableCell {...this.makeCellSortProps('createdAt')}>{this.makeSortLabel('createdAt','Created at')}</TableCell>
                <TableCell {...this.makeCellSortProps('isActive')}>{this.makeSortLabel('isActive','Active')}</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListApplications);

import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";

import NetworkServerStore from "../../stores/NetworkServerStore";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { withStyles } from "@material-ui/core/styles";

const styles ={

    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
};

class ListNetworkServers extends Component {
  constructor() {
    super();

    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);

    this.state = {
        orderBy:null,
        order:null
    }
  }
  changeSort(event){
    let state = {
        orderBy:event.currentTarget.dataset.key,
        order:this.state.order
    }

    if(state.orderBy!==this.state.orderBy){
        state.order = null;
    }

    if(state.order === null){
        state.order = 'asc'
    }else if(state.order === 'asc'){
        state.order = 'desc'
    }else if(state.order === 'desc'){
        state.order = null
        state.orderBy = null
    }

    this.setState(state);
  }

  makeSortLabel(keyName,viewName){
  return  <TableSortLabel
        data-key={keyName}
        active={this.state.orderBy === keyName}
        direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
        onClick={this.changeSort}
    >
      {viewName}
        {this.state.orderBy === keyName ? (
            <span className={this.props.classes.visuallyHidden}>
                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
        ) : null}
    </TableSortLabel>
  }

  makeCellSortProps(keyName){
    return {
        key:keyName,
        align:'left',
        padding:'normal',
        sortDirection:this.state.orderBy === keyName ? this.state.order : false
    }
  }
  getPage(limit, offset, callbackFunc) {
    NetworkServerStore.list(0, limit, offset, callbackFunc,this.state.order,this.state.orderBy);
  }

  getRow(obj) {
    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCellLink to={`/network-servers/${obj.id}`}>{obj.name}</TableCellLink>
        <TableCell>{obj.server}</TableCell>
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={[
            <TitleBarButton
              icon={<Plus />}
              label="Add"
              to={`/network-servers/create`}
            />,
          ]}
        >
          <TitleBarTitle title="Frequency plans" />
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            orderBy={this.state.orderBy}
            header={
              <TableRow>
                <TableCell {...this.makeCellSortProps('name')}> {this.makeSortLabel('name','Name')}</TableCell>
                <TableCell {...this.makeCellSortProps('server')}>{this.makeSortLabel('networkServerName','Server')} </TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListNetworkServers);

import { Component } from "react";
import DeviceStore from "../stores/DeviceStore";
import RoutingProfileStore from "../stores/RoutingProfileStore";
import GatewayStore from "../stores/GatewayStore";
import history from '../history'
class Redirect extends Component {
    constructor() {
      super();
      let query_string = window.location.hash.split('?')[1]

      if(query_string){
        let searchParams = new URLSearchParams(query_string);
        if(searchParams.has("device")){
            this.toDevice(searchParams.get("device"))
        }else if (searchParams.has("gateway")){
            this.toGateway(searchParams.get("gateway"))
        }
      }else{
        history.replace('/')
      }
    }

    toDevice(devEUI){
        DeviceStore.get(devEUI, (resp) => { 
            if (resp && resp.device) {
              RoutingProfileStore.get(resp.device.routingProfileID,(resp)=>{
                if (resp && resp.routingProfile) {
                  let link = '/organizations/' + resp.routingProfile.organizationID + '/devices/' + devEUI
                  history.replace(link)
                }
              })
              
            }else{
              history.goBack()
            }
          },false)
    }
    
    toGateway(gwId){
        GatewayStore.get(gwId, (resp) => {
            if (resp && resp.gateway) {
              let link = '/organizations/' + resp.gateway.organizationID + '/gateways/' +gwId
              history.replace(link)
            }
          })
    }

    render (){
        return null
    }
}  

export default Redirect
import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import UserStore from "../../stores/UserStore";
import {withStyles} from "@material-ui/core/styles";
import { handleSearchChange,clearSearch} from "../../classes/Helper";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';

const styles ={

  visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
  },
};

class ListUsers extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);
    this.handleSearchChange = handleSearchChange.bind(this);
    this.clearSearch = clearSearch.bind(this);
    this.TableRef = React.createRef()
    this.state = {
        orderBy:null,
        order:null,
        search:null
    }
  }
    changeSort(event){
      let state = {
          orderBy:event.currentTarget.dataset.key,
          order:this.state.order
      }

      if(state.orderBy!==this.state.orderBy){
          state.order = null;
      }

      if(state.order === null){
          state.order = 'asc'
      }else if(state.order === 'asc'){
          state.order = 'desc'
      }else if(state.order === 'desc'){
          state.order = null
          state.orderBy = null
      }

      this.setState(state);
  }

  makeSortLabel(keyName,viewName){
  return  <TableSortLabel
        data-key={keyName}
        active={this.state.orderBy === keyName}
        direction={this.state.orderBy === keyName ? this.state.order : 'asc'}
        onClick={this.changeSort}
    >
      {viewName}
        {this.state.orderBy === keyName ? (
            <span className={this.props.classes.visuallyHidden}>
                {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
        ) : null}
    </TableSortLabel>
  }

  makeCellSortProps(keyName){
    return {
        key:keyName,
        align:'left',
        padding:'normal',
        sortDirection:this.state.orderBy === keyName ? this.state.order : false
    }
  }
  
  getPage(limit, offset, callbackFunc) {
    if(this.timeOut) {
      clearTimeout(this.timeOut)
    }
    this.timeOut =  setTimeout(()=>{
      UserStore.list(this.state.search, limit, offset, this.state.orderBy,this.state.order, callbackFunc);
    }, 400)
  }

  getRow(obj) {
    let active = null;
    let admin = null;

    if (obj.isAdmin) {
      admin = <Check />;
    } else {
      admin = <Close />;
    }

    if (obj.isActive) {
      active = <Check />;
    } else {
      active = <Close />;
    }

    return(
      <TableRow
        key={obj.id}
        hover
      >
        <TableCellLink to={`/users/${obj.id}`}>{obj.email}</TableCellLink>
        <TableCell>{obj.surname} {obj.name}</TableCell>
        <TableCell>{obj.company}</TableCell>
        <TableCell>{obj.position}</TableCell>
        <TableCell>{obj.orgCnt}</TableCell>
        <TableCell>{active}</TableCell>
        <TableCell>{admin}</TableCell>
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={4}>
        <TitleBar
          title="Users"
          buttons={[
            <TitleBarButton
              label="Create"
              icon={<Plus />}
              to={`/users/create`}
            />,
          ]}
        >
          <TitleBarTitle title="Users" />
        </TitleBar>
        <Grid className={'paddingBottomZero'} item xs={12}>
          <TextField
            label={"Search"}
            id="Search"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={this.handleSearchChange}
            value={this.state.search || ""}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.clearSearch}
                    style={{padding: "0"}} color="default" component="span">
                    <Icon className="fa fa-times"/>
                  </IconButton>
                </InputAdornment>,
            }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12}>
          <DataTable
          orderBy={this.state.orderBy}
          ref={this.TableRef}
            header={
              <TableRow>
                <TableCell {...this.makeCellSortProps('email')}>{this.makeSortLabel('email','Email')}</TableCell>
                <TableCell {...this.makeCellSortProps('surname')}>{this.makeSortLabel('surname','Name')}</TableCell>
                <TableCell {...this.makeCellSortProps('company')}>{this.makeSortLabel('company','Company')}</TableCell>
                <TableCell {...this.makeCellSortProps('position')}>{this.makeSortLabel('position','Job position')}</TableCell>
                <TableCell {...this.makeCellSortProps('orgCnt')}>{this.makeSortLabel('orgCnt','Orgs')}</TableCell>
                <TableCell {...this.makeCellSortProps('isActive')}>{this.makeSortLabel('isActive','Active')}</TableCell>
                <TableCell {...this.makeCellSortProps('isAdmin')}>{this.makeSortLabel('isAdmin','Admin')}</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListUsers);

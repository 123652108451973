import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardHeader from '@material-ui/core/CardHeader';
import {CircularProgress} from "@material-ui/core";
import theme from "../../../theme";
import {  Grid, Tab,  Tabs } from '@material-ui/core';
import { Switch } from 'react-router-dom';
import { Scatter} from 'react-chartjs-2';
import moment from 'moment';
import HandyRustyStore from "../../../stores/HandyRustyStore";
import { withRouter } from 'react-router-dom';


class Packet extends Component{

    constructor(){
        super()
        this.state ={
            loader:false,
            tab: 0,
        }
        this.onChangeTab = this.onChangeTab.bind(this);
    }
    onChangeTab(e, v) {
        this.setState({
          tab: v,
        });
      }

      componentDidMount(){
        this.setState({loader:true});
        HandyRustyStore.getDeviceStatsLastTwoweeks(this.props.match.params.devEUI, resp => {
            let last14Days = resp && resp.obj ? resp.obj.stats : [];
            const last14DaysFlat = last14Days.flat();
            last14Days = last14DaysFlat.map((item) => {
              if(moment(item.createdAt).valueOf() > moment( this.props.createdAt).valueOf() ){
                return {
                  createdAt:moment(item.createdAt).format("DD.MM.YYYY HH:mm"), 
                  date:item.createdAt,
                  per: (item.per!==undefined)?Math.round(item.per):NaN,
                  esp: item.esp.toFixed(1) || NaN,
                  rssi: (item.rssi!==undefined)?Math.round(item.rssi):NaN || NaN,
                  snr:  item.snr.toFixed(1)  || NaN,
                  sf:   item.sf   || NaN,
              }
              } else {
                return null
              }
            });

            this.setState({ last14Days:  last14Days, loader:false});
          });
    }  
    render (){
      const optionsScatterPer = {
        tooltips: {
        
        },
        scales: {
          y:{
            min:0
          },
          x:{
            ticks: {
              maxTicksLimit: 14,
              maxRotation:0,
              callback: function(val, index) {
                return   this.getLabelForValue(val).split(' ')[0] || '';
              },
            },
            
            type: 'category',
          }
        },
        maintainAspectRatio: false,
        plugins:{
          legend:{
            display:false
          }
        }
      };
        const optionsScatter = {
            tooltips: {
            
            },
            scales: {
              x:{
                ticks: {
                  maxTicksLimit: 14,
                  maxRotation:0,
                  callback: function(val, index) {
                    return   this.getLabelForValue(val).split(' ')[0] || '';
                  },
                },
                
                type: 'category',
              }
            },
            maintainAspectRatio: false,
            plugins:{
              legend:{
                display:false
              }
            }
          };

        return (
            <Card className={this.props.classes.root}>
                <CardHeader title={'Packet (hourly)'} action={this.state.loader&& <CircularProgress size={'20px'} /> }/>
                {this.state.last14Days&&<CardContent className={this.props.classes.infoCard}>
                <Grid item xs={12}>
        <Tabs
          value={this.state.tab}
          onChange={this.onChangeTab}
          indicatorColor="primary"
          className={this.props.classes.tabs}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="PER"/>
          <Tab label="ESP"/>
          <Tab label="RSSI"/>
          <Tab label="SNR"/>
          <Tab label="SF"/>
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <Switch>
        {this.state.tab === 0 &&
            <Scatter
              data={{
                datasets: [
                  {
                    label: 'PER',
                    radius: 1,
                    data: this.state.last14Days || [],
                    parsing: {
                        xAxisKey: 'createdAt',
                        yAxisKey:'per'
                      },
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    pointBackgroundColor: "rgb(243, 32, 148)",
                    unit: '%',
                  },
                ]
              }}
              options = {optionsScatterPer}
              height={400}
            />
          }
          {this.state.tab === 1 &&
            <Scatter
              ref={this.last14DaysScaterRef}
              data={{
                datasets: [
                  {
                    label: 'ESP',
                    radius: 1,
                    data: this.state.last14Days || [],
                    parsing: {
                        xAxisKey: 'createdAt',
                        yAxisKey:'esp'
                      },
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    pointBackgroundColor: "rgb(243, 32, 148)",
                  },
                ]
              }}
              options = {optionsScatter}
              height={400}
            />
          }
          {this.state.tab === 2 &&
            <Scatter
              ref={this.last14DaysScaterRef}
              data={{
                datasets: [
                  {
                    label: 'RSSI',
                    unit: 'dBm',
                    radius: 1,
                    data: this.state.last14Days || [],
                    parsing: {
                        xAxisKey: 'createdAt',
                        yAxisKey:'rssi'
                      },
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    pointBackgroundColor: "rgb(243, 32, 148)",
                  },
                ]
              }}
              options = {optionsScatter}
              height={400}
            />
          }
          {this.state.tab === 3 &&
            <Scatter
              ref={this.last14DaysScaterRef}
              data={{
                datasets: [
                  {
                    label: 'SNR',
                    radius: 1,
                    unit: 'dB',
                    data: this.state.last14Days || [],
                    parsing: {
                        xAxisKey: 'createdAt',
                        yAxisKey:'snr'
                      },
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    pointBackgroundColor: "rgb(243, 32, 148)",
                  },
                ]
              }}
              options = {optionsScatter}
              height={400}
            />
          }
          {this.state.tab === 4 &&
            <Scatter
              ref={this.last14DaysScaterRef}
              data={{
                datasets: [
                  {
                    label: 'SF',
                    radius: 1,
                    data: this.state.last14Days || [],
                    parsing: {
                        xAxisKey: 'createdAt',
                        yAxisKey:'sf'
                      },
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    pointBackgroundColor: "rgb(243, 32, 148)",
                  },
                ]
              }}
              options = {optionsScatter}
              height={400}
            />
          }
        </Switch>
      </Grid>
                </CardContent>}
            </Card>
        )
    }
}

const styles = {
    // link: {
    //   color: theme.palette.primary.main,
    // },
    root: {
      marginBottom: 50,
    },
    infoContainer: {
      marginRight: '50px'
    },
    infoContainerMarginTop:{
      marginTop: '50px'
    },
    infoCard: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    cardMarginBottom: {
      marginBottom: '20px',
    },
    marginTop: {
      marginTop: '20px',
    },
    chart: {
      minHeight: 300,
    },
    chartBig: {
      minHeight: 400,
    },
    graphActionContainer:{
      display:'inline-flex',
      alignItems:'center'
    },
    tabs: {
      borderBottom: "1px solid " + theme.palette.divider,
      height: "49px",
    },
  };

Packet =  withStyles(styles)(Packet)
Packet =  withRouter(Packet)


export default Packet
import React, {Component} from "react";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import theme from "../../theme";
import DeviceAdmin from "../../components/DeviceAdmin";
import Plus from "mdi-material-ui/Plus";
import ListDevicesApp from "./ListDevicesApp";
import ListDevicesMap from "./DeviceMap";
import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  importIcon: {fontSize: '20px', marginRight: '8px'},
};


class DevicesListLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };
  }
    componentDidUpdate(prevProps) {
    if (prevProps === this.props) {

      return;
    }
    this.locationToTab();
  }

//   setIsAdmin = () => {
//     this.setState({
//       admin: SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID),
//     });
//   }

  locationToTab = () => {
    let tab = 0;

    if (window.location.href.endsWith("/map")) {
      tab = 1;
    }

    this.setState({
      tab: tab,
    });
  }

  render() {

    return (
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <DeviceAdmin organizationID={this.props.match.params.organizationID}>
              <Grid item xs={12} className={this.props.classes.buttons}>
                <Button variant="outlined"
                        className={this.props.classes.button}
                        component={Link}
                        to={`/organizations/${this.props.match.params.organizationID}/devices/import`}>
                  <Icon className={'fas fa-file-csv ' + this.props.classes.importIcon}/>
                  Import
                </Button>
                <Button variant="outlined"
                        className={this.props.classes.button}
                        component={Link}
                        to={`/organizations/${this.props.match.params.organizationID}/devices/create`}>
                  <Plus className={this.props.classes.icon}/>
                  Create
                </Button>
              </Grid>
            </DeviceAdmin>
          }
        >
        <OrganizationBreadcrumps org={this.props.org}>
            <TitleBarTitle title="Devices"/>
        </OrganizationBreadcrumps>
        </TitleBar>

        <Grid className={'noUDpaddings'} item xs={12}>
          <Tabs
            value={this.state.tab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab label="List"
                 component={Link}
                 to={`/organizations/${this.props.match.params.organizationID}/devices`}/>
            <Tab label="Map"
                 component={Link}
                 to={`/organizations/${this.props.match.params.organizationID}/devices/map`}/>
          </Tabs>
        </Grid>

        <Grid item xs={12}>
          <Switch>
            <Route exact path={this.props.match.path}
                   render={props => <ListDevicesApp rpOptions={this.state.rpOptions} spOptions={this.state.spOptions} {...props} />}/>
            <Route exact path={`${this.props.match.path}/map`}
                   render={props => <ListDevicesMap organizationID={this.props.match.params.organizationID}/>}/>

          </Switch>
        </Grid>
      </Grid>
    );
  }
}


export default withStyles(styles)(withRouter(DevicesListLayout));

import React, {Component} from "react";
import { withStyles} from "@material-ui/core/styles";
import Form from "../../components/Form";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";
import RefreshIcon from '@material-ui/icons/Refresh';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Fields from './Fields'
import DatePicker from "../../components/FilterDatePickerComponent"
import Export from './ExportDeviceFramesCSV'
import SessionStore from '../../stores/SessionStore'
import moment from 'moment'
import _ from 'lodash'
import history from '../../history'

const styles ={
    grid: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
      boxCustom: {
        display: "inline-flex",
        alignItems: 'center',
        paddingBottom: '15px'
      },
      boxField: {
        display: "inline-block",
        paddingTop: ' 7px',
        paddingBottom: '15px'
      },
}

function validateFilterInputValue(value, field, errors) {
    if (errors[field]) {
      delete errors[field];
    }
    let rule = undefined;
  
    let Field = Fields.filter((item) => {
      return item.dataName === field
    });
    if (Field[0] && Field[0].filter && Field[0].filter.validation && Field[0].filter.validation.rule) {
      rule = Field[0].filter.validation.rule;
    }
  
    if (!rule || !value) return errors;
  
    if (!value.match(rule)) {
      errors[field] = true
    }
  
    return errors;
  }


class Filter extends Component {


    constructor() {
        super();
    
        this.clearField = this.clearField.bind(this)
        this.clearAll = this.clearAll.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleFieldChange = this.handleFieldChange.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)
        this.autoReloadOnChange = this.autoReloadOnChange.bind(this)
        this.setAutoReload = this.setAutoReload.bind(this)
        this.refreshTable = this.refreshTable.bind(this)
        let query = SessionStore.getQueryString() || []
        this.state = {
            exportLoading: false,
            exportManual: false,
            exportSize: 200,
            autoreload: false,
            errors: {},
            start_val: null,
            end_val: null,

            filter: {
                "start":query['start'] || moment().subtract(1,'month').toISOString(),
                "end": query['end'] || "",
                "filters": {
                  "direction": "RX",
                  "devEUI": query["devEUI"] || "",
                 
                }
            }
        }

        this.timeout = null;
        this.datePickerRef = React.createRef()
       
      
    
    }    
    clearAll () {
     let state = {
        exportLoading: false,
        exportManual: false,
        exportSize: 200,
        autoreload: false,
        errors: {},
        start_val: null,
        end_val: null,

        filter: {
            "start": null,
            "end": null,
            "filters": {
              "direction": "RX",
              "devEUI": "",
             
            }
        }
    }
      this.setState(state, () => {
        this.handleChange()
        this.datePickerRef.current.clear({})
        this.setAutoReload(false)
      })
    }

    handleDateChange(obj){
      let state = this.state;
      Object.assign( state.filter , obj) 
     
      this.setState(state, () => {
        this.handleChange()
      })
  
    }

    handleFieldChange(value, field) {
      let state = this.state;
  
    
      if (!value) {
        delete state.filter['filters'][field];
     
      } else {
        if (!state.filter['filters'][field]) {
          state.filter['filters'][field] = ""
        }
        state.filter['filters'][field] = value
      }
      state.errors = validateFilterInputValue(value, field, state.errors)
      this.setState(state, () => {
        if (state.errors[field]) return;
        this.handleChange()
      })
    }

    filterToQuery(){
      let except = ["direction"];
      function getObjectValues(obj) {
        const result = {};
        
        _.forIn(obj, (value, key) => {
          if (_.isObject(value)) {
            Object.assign(result, getObjectValues(value));
          } else {
            if (except.indexOf(key) === -1 && value) {
              result[key] = value;
            }
          }
        });
        
        return result;
      }
     
      let filterValues = getObjectValues( this.state.filter )
      
      return new URLSearchParams(filterValues).toString()
    }

    handleChange(){ 
      let u = this.filterToQuery()
      history.replace('?'+u)
      this.props.updateTable()
   
    }

    refreshTable() {
        this.setAutoReload(false);
        this.props.resetTable();
        this.setAutoReload(this.state.autoreload, false);
    }

    clearField(field) {
        this.handleFieldChange(false, field)
    }

    autoReloadOnChange(event) {
      let state = this.state
      state.autoreload = event.target.value
  
      this.setState(state, () => {
        this.setAutoReload(state.autoreload)
      })
    }
  
    setAutoReload(autoreload, fastTrigger = true) {
      clearInterval(this.autoreloadInterval)
  
      if (autoreload) {
        if (fastTrigger) {
          this.props.resetTable();
        }
  
        this.autoreloadInterval = setInterval(() => {
          this.props.resetTable();
        }, autoreload * 1000)
      }
    }

    componentWillUnmount(){
      clearInterval(this.autoreloadInterval)
    }

  componentDidMount(){
    this.datePickerRef.current.setState({ date:'Last month', selectedOption:'Month' });
  }

    render() {
        return (
            <Form>
                <Grid item xs={12} style={styles.grid}>
                <Box className={this.props.classes.boxCustom} component="div" mr={2}>
            <IconButton onClick={this.clearAll}
                        title={'Clean filters'}
                        style={{width: '30px', marginRight: '10px'}}
                        size="small" variant="text">
              <Icon className={'fas fa-trash-alt'}/>
            </IconButton>
            <IconButton title={'choose fields here'}
                        style={{marginRight: '10px'}}
                        size="small"
                        variant="text"
                        onClick={this.props.clickOpenDialog}>
              <Icon className={'fas fa-columns'}/>
            </IconButton>

            <div style={{display: 'inline-flex', alignItems: 'center'}}>
              <IconButton title={'refresh data'}
                          style={{marginRight: '10px'}}
                          size="small"
                          variant="text"
                          onClick={this.refreshTable}>
                <RefreshIcon/>
              </IconButton>
              <div>

                <InputLabel shrink id="autoReload">
                  Refresh
                </InputLabel>
                <Select style={{width: "60px", margin: "0"}}
                        labelId="autoReload"
                        id="autoReload"
                        value={this.state.autoreload || ""}
                        onChange={this.autoReloadOnChange}
                        displayEmpty
                        className={""}
                >
                  <MenuItem value="">
                    <em>off</em>
                  </MenuItem>
                  <MenuItem value={5}>5 sec</MenuItem>
                  <MenuItem value={10}>10 sec</MenuItem>
                  <MenuItem value={15}>15 sec</MenuItem>
                  <MenuItem value={30}>30 sec</MenuItem>
                  <MenuItem value={60}>60 sec</MenuItem>
                  <MenuItem value={60 * 2}>2 min</MenuItem>
                  <MenuItem value={60 * 5}>5 min</MenuItem>
                </Select>
              </div>
            </div>
           

          </Box>
    <Box className={this.props.classes.boxField} component="div" mr={2}>
            <TextField
              label={(this.state.errors["devEUI"]) ? 'HEX, 16 chars only' : "DevEUI"}
              id="devEUI-Filtering"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                this.handleFieldChange(event.target.value.toLowerCase().trim(), 'devEUI')
              }}
              error={this.state.errors["devEUI"]}
              value={this.state.filter['filters']['devEUI'] || ""}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        this.clearField('devEUI')
                      }}
                      style={{padding: "0"}} color="default" component="span">
                      <Icon className="fa fa-times"/>
                    </IconButton>
                  </InputAdornment>,
              }}
              variant="outlined"
              size="small"
            />
          </Box>
          <Box className={this.props.classes.boxField} component="div" mr={2}>
                <DatePicker filter={this.state.filter} ref={this.datePickerRef} handleChange={this.handleDateChange} />
          </Box>
          <Box className={this.props.classes.boxCustom} component="div" mr={2}>
                <Export filter={this.state.filter} allowed={['devEUI']}/>
          </Box>
                </Grid>

            </Form>
        )
    }
}

export default withStyles(styles)(Filter);
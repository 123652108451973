import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardHeader from '@material-ui/core/CardHeader';
import {CircularProgress} from "@material-ui/core";
import theme from "../../../theme";
import {  Grid, Tab,  Tabs } from '@material-ui/core';
import { Switch } from 'react-router-dom';
import { Line, Bar, Scatter} from 'react-chartjs-2';
import moment from 'moment';
import HandyRustyStore from "../../../stores/HandyRustyStore";
import { withRouter } from 'react-router-dom';

class Aggregated extends Component{

    constructor(){
        super()
        this.state ={
            loader:false,
            tab: 0,
        }
        this.onChangeTab = this.onChangeTab.bind(this);
    }
    onChangeTab(e, v) {
        this.setState({
          tab: v,
        });
      }
    componentDidMount(){
        this.setState({loader:true});
           HandyRustyStore.getDeviceStatsLastTwoweeksAggregated(this.props.match.params.devEUI, resp => {
               let last14DaysAggregated = resp && resp.obj ? resp.obj.stats : [];

               last14DaysAggregated = last14DaysAggregated.map((el)=>{
                if(moment(el.createdAt).valueOf() > moment( this.props.createdAt).valueOf() ){
                  return {
                    createdAt: moment(el.createdAt).format("DD.MM.YYYY"),
                    per:       (el.per!==undefined)?Math.round(el.per):NaN,
                    esp:       (el.esp!==undefined)?el.esp.toFixed(1):NaN, 
                    rssi:      (el.rssi!==undefined)?Math.round(el.rssi):NaN,
                    snr:       (el.snr!==undefined)?el.snr.toFixed(1):NaN,
                    sf:        el.sf,
                    txCnt:     el.txCnt,
                    rxCnt:     el.rxCnt
                }
                } else {
                  return null
                }
                    
               })

            this.setState({ last14DaysAggregated: last14DaysAggregated , loader:false});
          });
    }

    
    render(){

    const  optionsLinePer = {
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const datasetIndex = tooltipItem.datasetIndex;
            const itemIndex = tooltipItem.index;
            const unit = data.datasets[datasetIndex].unit || '';
            let dataLabel = data.datasets[datasetIndex].data[itemIndex];
            if (typeof(dataLabel) == 'number' && !Number.isInteger(dataLabel)) {
              dataLabel = dataLabel.toFixed(3);
            }
            dataLabel = `${dataLabel}${unit}`;
            return dataLabel;
          }
        }
      },
      maintainAspectRatio: false,
      scales:{
        y:{
          min:0
        }
      },
      plugins:{
        legend:{
          display:false
        }
      }
    }
    const optionsLine = {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              
              const datasetIndex = tooltipItem.datasetIndex;
              const itemIndex = tooltipItem.index;
              const unit = data.datasets[datasetIndex].unit || '';
              let dataLabel = data.datasets[datasetIndex].data[itemIndex];
              if (typeof(dataLabel) == 'number' && !Number.isInteger(dataLabel)) {
                dataLabel = dataLabel.toFixed(3);
              }
              dataLabel = `${dataLabel}${unit}`;
              return dataLabel;
            }
          }
        },
        maintainAspectRatio: false,
      
        plugins:{
          legend:{
            display:false
          }
        }
      };
  
      const optionsBar = {
        maintainAspectRatio: false,
        plugins:{
          legend:{
            display:false
          }
        },
        scales:{
          y:{
            min:0
          }
        },
      };
  
    
    const optionsScatterAgregated = {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const datasetIndex = tooltipItem.datasetIndex;
              const itemIndex = tooltipItem.index;
              const unit = data.datasets[datasetIndex].data[itemIndex].unit || '';
              let value = tooltipItem.value;
              let dataLabel = data.datasets[datasetIndex].data[itemIndex].date ? moment(data.datasets[datasetIndex].data[itemIndex].date).format("DD.MM.YYYY HH:mm:ss") : '';
              if ((typeof(parseFloat(value)) == 'number' && !Number.isInteger(value)) && value !== 0 && (unit === 'dB' || unit === 'dBm')) {
                const checkLengthAfterComma = x => ( (x.toString().includes('.')) ? (x.toString().split('.').pop().length) : (0) );
                if (checkLengthAfterComma(value) > 2) {
                  value = parseFloat(value);
                  value = value.toFixed(3);
                }
              }
              dataLabel = `${dataLabel}: ${value}${unit}`;
              return dataLabel;
            }
          }
        },
        scales: {
          x:{
            bounds:'data',
            ticks: {
              maxTicksLimit: 14,
            },
            type: 'category',
          },
          y:{
            min:0
          }
         
        },
        maintainAspectRatio: false,
        plugins:{
          legend:{
            display:false
          }
        }
      };


        return (
            <Card className={this.props.classes.root}>
                    <CardHeader title={'Aggregated'} action={this.state.loader&& <CircularProgress size={'20px'} /> }/>
                        {this.state.last14DaysAggregated&&<CardContent className={this.props.classes.infoCard}>
                        

                            <Grid item xs={12}>
                                <Tabs
                                value={this.state.tab}
                                onChange={this.onChangeTab}
                                indicatorColor="primary"
                                className={this.props.classes.tabs}
                                variant="scrollable"
                                scrollButtons="auto"
                                >
                                <Tab label="PER"/>
                                <Tab label="ESP"/>
                                <Tab label="RSSI"/>
                                <Tab label="SNR"/>
                                <Tab label="SF"/>
                                <Tab label="UL/DL packets"/>
                                </Tabs>
                            </Grid>
                            <Grid item xs={12} className={this.props.classes.root}>
                                <Switch>
                                {this.state.tab === 0 &&
                                    <Line
                                   
                                    data={{
                                        datasets: [
                                        {
                                            data:this.state.last14DaysAggregated||[],
                                            parsing: {
                                              xAxisKey: 'createdAt',
                                              yAxisKey:'per'
                                            },
                                            borderWidth: 1,
                                            radius: 1,
                                            label: 'PER',
                                            borderColor: "rgb(33,150,243)",
                                            backgroundColor: "rgba(0, 0, 0, 0)",
                                            pointBackgroundColor: "rgb(33,150,243)",
                                            unit: '%',
                                        },
                                        ]
                                    }}
                                    options={optionsLinePer}
                                    height={400}
                                    />
                                }
                                {this.state.tab === 1 &&
                                    <Line
                                   
                                    data={{
                                        datasets: [
                                        {
                                            borderWidth: 1,
                                            radius: 1,
                                            label: 'ESP',
                                            data:this.state.last14DaysAggregated||[],
                                            parsing: {
                                              xAxisKey: 'createdAt',
                                              yAxisKey:'esp'
                                            },
                                            borderColor: "rgb(33,150,243)",
                                            backgroundColor: "rgba(0, 0, 0, 0)",
                                            pointBackgroundColor: "rgb(33,150,243)",
                                            unit: 'dBm',
                                        },
                                        ]
                                    }}
                                    options={optionsLine}
                                    height={400}
                                    />
                                }
                                {this.state.tab === 2 &&
                                    <Line
                                   
                                    data={{
                                        datasets: [
                                        {
                                            borderWidth: 1,
                                            radius: 1,
                                            label: 'RSSI',
                                            data:this.state.last14DaysAggregated||[],
                                            parsing: {
                                                xAxisKey: 'createdAt',
                                                yAxisKey:'rssi'
                                              },
                                            borderColor: "rgb(33,150,243)",
                                            backgroundColor: "rgba(0, 0, 0, 0)",
                                            pointBackgroundColor: "rgb(33,150,243)",
                                            unit: 'dBm',
                                        },
                                        ]
                                    }}
                                    options={optionsLine}
                                    height={400}
                                    />
                                }
                                {this.state.tab === 3 &&
                                    <Line
                                   
                                    data={{
                                        datasets: [
                                        {
                                            borderWidth: 1,
                                            radius: 1,
                                            label: 'SNR',
                                            data:this.state.last14DaysAggregated||[],
                                            parsing: {
                                                xAxisKey: 'createdAt',
                                                yAxisKey:'snr'
                                              },
                                            borderColor: "rgb(33,150,243)",
                                            backgroundColor: "rgba(0, 0, 0, 0)",
                                            pointBackgroundColor: "rgb(33,150,243)",
                                            unit: 'dB',
                                        },
                                        ]
                                    }}
                                    options={optionsLine}
                                    height={400}
                                    />
                                }
                                {this.state.tab === 4 &&
                                    
                                    <Scatter
                                   
                                    data={{
                                    datasets: [
                                        {
                                        borderWidth: 1,
                                        label: 'SF',
                                        radius: 1,
                                        data:this.state.last14DaysAggregated||[],
                                            parsing: {
                                                xAxisKey: 'createdAt',
                                                yAxisKey:'sf'
                                              },
                                        backgroundColor: "rgba(0, 0, 0, 0)",
                                        pointBackgroundColor: "rgb(33,150,243)",
                                        },
                                    ]
                                    }}
                                    options = {optionsScatterAgregated}
                                    height={400}
                                />
                                }
                                {this.state.tab === 5 &&
                                    <Bar
                                  
                                    data={{
                                       
                                        datasets: [
                                        {
                                            label: 'Uplink packets',
                                            data:this.state.last14DaysAggregated||[],
                                            parsing: {
                                                xAxisKey: 'createdAt',
                                                yAxisKey:'rxCnt'
                                               
                                              },
                                            borderColor: "rgb(243,131,33)",
                                            backgroundColor: "rgb(243,131,33)",
                                            pointBackgroundColor: "rgb(243,131,33)",
                                        },
                                        {
                                            label: 'Downlink packets',
                                            data:this.state.last14DaysAggregated||[],
                                            parsing: {
                                                xAxisKey: 'createdAt',
                                                yAxisKey:'txCnt'
                                              },
                                            borderColor: "rgb(44,128,217)",
                                            backgroundColor: "rgb(44,128,217)",
                                            pointBackgroundColor: "rgb(44,128,217)",
                                        },
                                        ]
                                    }}
                                    options={optionsBar}
                                    height={400}
                                    />
                                }
                                </Switch>
                            </Grid>
                        
                        </CardContent>}
          
             </Card>
        )
    }

}



const styles = {
    // link: {
    //   color: theme.palette.primary.main,
    // },
    root: {
      marginBottom: 50,
    },
    infoContainer: {
      marginRight: '50px'
    },
    infoContainerMarginTop:{
      marginTop: '50px'
    },
    infoCard: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    cardMarginBottom: {
      marginBottom: '20px',
    },
    marginTop: {
      marginTop: '20px',
    },
    chart: {
      minHeight: 300,
    },
    chartBig: {
      minHeight: 400,
    },
    graphActionContainer:{
      display:'inline-flex',
      alignItems:'center'
    },
    tabs: {
      borderBottom: "1px solid " + theme.palette.divider,
      height: "49px",
    },
  };
Aggregated = withStyles(styles)(Aggregated)
Aggregated = withRouter(Aggregated)
export default Aggregated
import React, { Component } from "react";
import { Route, Switch, Link, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import SessionStore from "../../stores/SessionStore";
import theme from "../../theme";
import Export from "./Export";
import { Grid, Tab, Tabs } from "@material-ui/core";
import history from "../../history";
import Paper from '@material-ui/core/Paper';
import CttListLayout from "../ctt/CttListLayout";
const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
  paper:{
    padding:'15px'
  }
};


class ToolsLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      admin: false,
    };
  }

  componentDidMount() {
 

    this.locationToTab();
    SessionStore.on("change",()=>{
      this.setIsAdmin(true)
     
    });
    this.setIsAdmin();
  }

  componentWillUnmount() {
    SessionStore.removeListener("change", this.setIsAdmin);
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    this.locationToTab();
  }

  setIsAdmin = (check) => {
    let admin = SessionStore.isAdmin() || SessionStore.isOrganizationAdmin(this.props.match.params.organizationID)
    this.setState({
      admin: admin,
    });


    if(admin!==undefined && check!==undefined && check && !admin){
     history.push('/');
    }
    
  }

  locationToTab = () => {
    let tab = 0;
        if (window.location.href.endsWith("/export")) {
          tab = 0;
        }else if(window.location.href.includes("/ctt")){
          tab = 1;
        }


    
    this.setState({
      tab: tab,
    });
  }

  render() {

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            null
          }
        >
          <TitleBarTitle title="Tools" />
        </TitleBar>
      
        <Grid item className={'noUDpaddings'} xs={12}>
          <Tabs 
            value={this.state.tab}
            indicatorColor="primary"
            style={{background:localStorage.getItem("tabsColor")}}
            className={this.props.classes.tabs}
          >
            <Tab label="Export" component={Link} to={'/tools/export'} />
           {this.props.toolsEnabled && this.props.toolsEnabled.CTT===true  && <Tab label="Ctt" component={Link} to={'/tools/ctt'} />}
            {/* <Tab label="Map" component={Link} to={'/tools/map'} /> */}
          </Tabs>
        </Grid>   
    
        <Grid item xs={12}>
        
          <Switch>
              <Route exact path={`${this.props.match.path}/export`} render={props =>  <Paper className={this.props.classes.paper}><Export {...props} /> </Paper>} />
             {this.props.toolsEnabled && this.props.toolsEnabled.CTT===true  && <Route  path={`${this.props.match.path}/ctt`} render={props => <CttListLayout {...props} />} />}
          </Switch>
         
        </Grid>

      </Grid>
    );
  }
}


export default withStyles(styles)(withRouter(ToolsLayout));

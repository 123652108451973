import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import theme from "../../theme";
import DatePicker from "../../components/FilterDatePickerComponent"
import Form from "../../components/Form";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from "@material-ui/core/CircularProgress";
import Fields from "./WirelessLoggerFieldset";
import Typography from '@material-ui/core/Typography';
import AutocompleteSelect from "../../components/AutocompleteSelect";
import OrganizationStore from "../../stores/OrganizationStore";
import SessionStore from "../../stores/SessionStore";
import moment from 'moment'
import {GetOptionSelected} from '../../classes/Helper'


const styles = {
  chart: {
    width: 380,
  },
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
  boxField: {
    display: "inline-block",
    paddingTop: ' 7px',
    paddingBottom: '15px'
  },
  boxDefault: {
    width: '261px',
  },
  boxCustom: {
    display: "inline-flex",
    alignItems: 'center',
    paddingBottom: '15px'
  },
  button: {
    marginLeft: "10px"
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  selectOrganization: {
    width: '100%'
  },
  select: {
    width: 150,
    marginRight: 10,
    marginLeft: 10,
  },
  iconCSV: {
    marginLeft: 10,
  }
};


function validateFilterInputValue(value, field, errors) {
  if (errors[field]) {
    delete errors[field];
  }
  let rule = undefined;

  let Field = Fields.filter((item) => {
    return item.dataName === field
  });
  if (Field[0] && Field[0].filter && Field[0].filter.validation && Field[0].filter.validation.rule) {
    rule = Field[0].filter.validation.rule;
  }

  if (!rule || !value) return errors;

  if (!value.match(rule)) {
    errors[field] = true
  }

  return errors;
}


class FilterWirelessLogger extends Component {

  constructor() {
    super();

    this.setAutoReload = this.setAutoReload.bind(this)
    this.autoReloadOnChange = this.autoReloadOnChange.bind(this)
    this.exportCSV = this.exportCSV.bind(this)
    this.refreshTable = this.refreshTable.bind(this)
    this.clearField = this.clearField.bind(this)
    this.handleFieldChange = this.handleFieldChange.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.clearAll = this.clearAll.bind(this)
  

    this.onChangeOrganizationList = this.onChangeOrganizationList.bind(this)
    this.getOrganizationOption = this.getOrganizationOption.bind(this)
    this.getOrganizationOptions = this.getOrganizationOptions.bind(this)

    let query = SessionStore.getQueryString() || []


    this.state = {
      pickerOpen:false,
      anchorPicker:null,
      organization: "0",
      cacheCounter: 0,
      exportLoading: false,
      autoreload: false,
      exportManual: false,
      exportSize: 200,
      start_val: null,
      end_val: null,
    
      errors: {},
      filter: {
        "start":query['start'] || moment().subtract(1,'month').toISOString(),
        "end": query['end'] || "",
        "filters": {
          "direction": "",
          "devEUI": "",
          "devAddr": "",
          "gw": "",
          "mType": ""
        }
      }
    }
    this.datePickerRef = React.createRef()
  };
 
  clearAll() {
    let state = {
      anchorPicker:null,
      pickerOpen:false,
    
      organization: "0",
      cacheCounter: 0,
      exportLoading: false,
      autoreload: false,
      exportManual: false,
      exportSize: 200,
      errMsgExport:false,
      start_val: null,
      end_val: null,
      errors: {},
      filter: {
        organizationId: undefined,
        "start": "",
        "end": "",
        "filters": {
          "direction": "",
          "devEUI": "",
          "devAddr": "",
          "gw": "",
          "mType": ""
        }
      }
    }

    this.setState(state, () => {
      this.props.handleChange(state.filter, 0)
      this.datePickerRef.current.clear({})
      this.setAutoReload(false)
    })
  }

  clearField(field) {
    this.handleFieldChange(false, field)
  }

  handleFieldChange(value, field) {
    let state = this.state;
    
    let timeout = undefined;
    if (value===false ) {
        
      delete state.filter.filters[field];
      timeout = 0
    
    } else {
      if (!state.filter['filters'][field]) {
        state.filter['filters'][field] = ""
      }
      state.filter['filters'][field] = value
 
    }
    state.errors = validateFilterInputValue(value, field, state.errors)
    this.setState(state, () => {
      if (state.errors[field]) return;
      this.props.handleChange(state.filter, timeout,field==='devEUI',value)
      
     
    })
  }


  componentWillUnmount() {
    clearInterval(this.autoreloadInterval)
  }
  componentDidMount(){
    let query_string = window.location.hash.split('?')[1]
    let state = this.state

    if(query_string){
      let searchParams = new URLSearchParams(query_string);

      if(searchParams.has("organizationId")){
        state.organization = searchParams.get("organizationId")
      }
      if(searchParams.has("gw")){
        state.filter['filters']["gw"] = searchParams.get("gw")
      }
      if(searchParams.has("devEUI")){
        state.filter['filters']["devEUI"] = searchParams.get("devEUI")
      }
      if(searchParams.has("devAddr")){
        state.filter['filters']["devAddr"] = searchParams.get("devAddr")
      }
      if(searchParams.has("mType")){
        state.filter['filters']["mType"] = searchParams.get("mType")
      }
      if(searchParams.has("direction")){
        state.filter['filters']["direction"] = searchParams.get("direction")
      }

      let d = '...';
      if(searchParams.has("start")){
        const val = searchParams.get("start");
        state["start_val"] = moment(val);
        state.filter.start = val;
        d = moment(val).format('D.MM.YY');
      }
      if(searchParams.has("end")){
        const val = searchParams.get("end");
        state["end_val"] = moment(val);
        state.filter.end = val;
        d = d + "-" + moment(val).format('D.MM.YY');
      }
     
      if (d !== '...') {
        this.datePickerRef.current.setState({ date: d, selectedOption: 'Interval'}) ;
      }

    } else {
      this.datePickerRef.current.setState({ date:'Last month', selectedOption:'Month' });
    }
   
    this.setState(state)
  }


  autoReloadOnChange(event) {
    let state = this.state
    state.autoreload = event.target.value

    this.setState(state, () => {
      this.setAutoReload(state.autoreload)
    })
  }

  setAutoReload(autoreload, fastTrigger = true) {
    clearInterval(this.autoreloadInterval)

    if (autoreload) {
      if (fastTrigger) {
        this.props.resetTable();
      }

      this.autoreloadInterval = setInterval(() => {
        this.props.resetTable();
      }, autoreload * 1000)
    }
  }

  refreshTable() {
    this.setAutoReload(false);
    this.props.resetTable();
    this.setAutoReload(this.state.autoreload, false);
  }

  exportCSV() {
    this.setState({exportLoading: true})
    let filter = this.state.filter;
    this.props.exportCSV(filter, this.state.exportSize, () => {
      this.setState({exportLoading: false});
    });
  }

  onChangeOrganizationList(e) {
    let state = this.state;
    let val = parseInt(e.target.value);
    state.organization = (val > 0) ? val : null;
    state.filter.organizationId = (val > 0) ? val : undefined;
    this.setState(state);
    this.props.handleChange(state.filter);
  }


  getOrganizationOption(id, callbackFunc) {
    OrganizationStore.get(id, resp => {
      callbackFunc({label: resp.organization.name, value: resp.organization.id});
    });
  }

  getOrganizationOptions(search, callbackFunc) {
    OrganizationStore.list(search, 999, 0, resp => {
      const options = resp.result.map((o, i) => {
        return {label: o.name, value: o.id}
      });
      callbackFunc([{label: 'All Organizations', value: "0"}, ...options], undefined);
    });
  }

  handleRowsChange = (event) => {
      let errMsgExport = false
      let value = parseInt(event.target.value < 0 ? event.target.value = 0 : event.target.value )
      
      let exportMax = (SessionStore.isAdmin() ) ? 1000000 : 10000
      
      if(value > exportMax){
        value = exportMax;
        errMsgExport = true;
      }
    
      this.setState({exportSize:value,errMsgExport:errMsgExport})
  }
  handleDateChange(obj){
    let state = this.state;
    Object.assign( state.filter , obj)    
    this.props.handleChange(state.filter)
  }
  
  render() {
    let organizationID = null;
    if (this.state.organization !== null) {
      organizationID = this.state.organization;
    }
   
    let exportMax = (SessionStore.isAdmin() ) ? 1000000 : 10000

    return (
      <Form>
        <Grid item xs={12} style={styles.grid}>
          <Box className={this.props.classes.boxCustom} component="div" mr={2}>
            <IconButton onClick={this.clearAll}
                        title={'Clean filters'}
                        style={{width: '30px', marginRight: '10px'}}
                        size="small" variant="text">
              <Icon className={'fas fa-trash-alt'}/>
            </IconButton>
            <IconButton title={'choose fields here'}
                        style={{marginRight: '10px'}}
                        size="small"
                        variant="text"
                        onClick={this.props.clickOpenDialog}>
              <Icon className={'fas fa-columns'}/>
            </IconButton>

            <div style={{display: 'inline-flex', alignItems: 'center'}}>
              <IconButton title={'refresh data'}
                          style={{marginRight: '10px'}}
                          size="small"
                          variant="text"
                          onClick={this.refreshTable}>
                <RefreshIcon/>
              </IconButton>
              <div>

                <InputLabel shrink id="autoReload">
                  Refresh
                </InputLabel>
                <Select style={{width: "60px", margin: "0"}}
                        labelId="autoReload"
                        id="autoReload"
                        value={this.state.autoreload || ""}
                        onChange={this.autoReloadOnChange}
                        displayEmpty
                        className={""}
                >
                  <MenuItem value="">
                    <em>off</em>
                  </MenuItem>
                  <MenuItem value={5}>5 sec</MenuItem>
                  <MenuItem value={10}>10 sec</MenuItem>
                  <MenuItem value={15}>15 sec</MenuItem>
                  <MenuItem value={30}>30 sec</MenuItem>
                  <MenuItem value={60}>60 sec</MenuItem>
                  <MenuItem value={60 * 2}>2 min</MenuItem>
                  <MenuItem value={60 * 5}>5 min</MenuItem>
                </Select>
              </div>
            </div>
            <div style={{display: 'inline'}}>
              <InputLabel shrink id="directionLabel">
                Direction
              </InputLabel>
              <Select style={{width: "auto"}}
                      labelId="Direction of messages"
                      id="direction"
                      value={this.state.filter['filters'].direction || ""}
                      onChange={(event) => {
                        this.handleFieldChange(event.target.value, 'direction')
                      }}
                      displayEmpty
                      className={""}
              >

                <MenuItem value="">
                  <em>Both</em>
                </MenuItem>
                <MenuItem value={'RX'}>UL</MenuItem>
                <MenuItem value={'TX'}>DL</MenuItem>
              </Select>
            </div>

          </Box>
          <Box className={this.props.classes.boxField} component="div" mr={2}>
            <TextField
              label={(this.state.errors["devEUI"]) ? 'HEX, 16 chars only' : "DevEUI"}
              id="devEUI-Filtering"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
               
                this.handleFieldChange(event.target.value.toLowerCase().trim(), 'devEUI')
              }}
              onKeyUp={(event)=>{
               if(event.key==='Enter'){
                this.refreshTable()
               }
              }}
              error={this.state.errors["devEUI"]}
              value={this.state.filter['filters']['devEUI'] || ""}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        this.clearField('devEUI')
                      }}
                      style={{padding: "0"}} color="default" component="span">
                      <Icon className="fa fa-times"/>
                    </IconButton>
                  </InputAdornment>,
              }}
              variant="outlined"
              size="small"
            />
          </Box>

          <Box className={this.props.classes.boxField} component="div" mr={2}>
            <TextField
              label={(this.state.errors["devAddr"]) ? 'HEX, 8 chars only' : "DevAddr"}
              id="devAddr-Filtering"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">

                    <IconButton
                      onClick={() => {
                        this.clearField('devAddr')
                      }}
                      style={{padding: "0"}} color="default" aria-label="upload picture" component="span">
                      <Icon className="fa fa-times"/>
                    </IconButton>
                  </InputAdornment>,
              }}
              variant="outlined"
              size="small"
              onChange={(event) => {
                this.handleFieldChange(event.target.value.toLowerCase().trim(), 'devAddr')
              }}
              onKeyUp={(event)=>{
                if(event.key==='Enter'){
                 this.refreshTable()
                }
               }}
              error={this.state.errors["devAddr"]}
              value={this.state.filter['filters']["devAddr"] || ""}
            />
          </Box>

          <Box className={this.props.classes.boxField} component="div" mr={2}>
            <TextField
              id="gw"
              label={(this.state.errors["gw"]) ? 'HEX, 16 chars only' : "GW"}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">

                    <IconButton
                      onClick={() => {
                        this.clearField('gw')
                      }}
                      style={{padding: "0"}}
                      color="default"
                      aria-label="upload picture"
                      component="span">
                      <Icon className="fa fa-times"
                      />
                    </IconButton>
                  </InputAdornment>,
              }}
              variant="outlined"
              size="small"
              onChange={(event) => {
                this.handleFieldChange(event.target.value.toLowerCase().trim(), 'gw')
              }}
              onKeyUp={(event)=>{
                if(event.key==='Enter'){
                 this.refreshTable()
                }
               }}
              error={this.state.errors["gw"]}
              value={this.state.filter['filters']["gw"] || ""}
            />
          </Box>

          <Box className={this.props.classes.boxField} component="div" mr={2}>
                <DatePicker filter={this.state.filter} ref={this.datePickerRef} handleChange={this.handleDateChange} />
          </Box>

          <Box className={this.props.classes.boxCustom} component="div" mr={2}>
            <div style={{width: '100px'}}>
              <InputLabel shrink id="mTypeLabel">
                Frame type
              </InputLabel>
              <Select
                style={{width: "100px"}}
                labelId="select type of messages:"
                id="mType"
                value={this.state.filter['filters']["mType"] || ""}
                onChange={(event) => {
                  this.handleFieldChange(event.target.value, 'mType')
                }}
                displayEmpty
                className={""}
              >

                <MenuItem value="">
                  <em>all</em>
                </MenuItem>
                <MenuItem value={'JoinRequest'}>JoinRequest</MenuItem>
                <MenuItem value={'RejoinRequest'}>RejoinRequest</MenuItem>
                <MenuItem value={'JoinAccept'}>JoinAccept</MenuItem>
                <MenuItem value={'UnconfirmedDataUp'}>UnconfirmedDataUp</MenuItem>
                <MenuItem value={'UnconfirmedDataDown'}>UnconfirmedDataDown</MenuItem>
                <MenuItem value={'ConfirmedDataUp'}>ConfirmedDataUp</MenuItem>
                <MenuItem value={'ConfirmedDataDown'}>ConfirmedDataDown</MenuItem>
                <MenuItem value={'Downlink Multicast'}>Downlink Multicast</MenuItem>
                <MenuItem value={'Proprietary'}>Proprietary</MenuItem>
              </Select>
            </div>
             </Box>   
               
            <Box className={this.props.classes.boxCustom} component="div" mr={2}>
            <div style={{width: '220px',position:'relative'}}>
             
              {(this.state.exportManual) ?
                <>
                  <TextField
                    label="rows"
                    id="exportSize"
                    style={{width: '150px',position:'relative',top:'4px'}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => this.setState({exportSize: 10, exportManual: false})}
                            style={{padding: "0"}}
                            color="default"
                            aria-label="upload picture"
                            component="span"
                          >
                            <Icon className="fa fa-times"/>
                          </IconButton>
                        </InputAdornment>,
                    }}
                    inputProps={{
                      min: 0,
                      max: 1000000,
                    }}
                    variant="outlined"
                    size="small"
                    type='number'
                    error={this.state.errMsgExport}
                    helperText={(this.state.errMsgExport)?("Max "+ exportMax):""}
                    onChange={this.handleRowsChange}
                    value={this.state.exportSize || ""}
                  />
               </> : <>
                  <InputLabel shrink id="exportSize">
                    rows
                  </InputLabel>
                  <Select style={{width: "150px"}}
                          labelId="exportSize"
                          id="exportSize"
                          value={this.state.exportSize || ""}
                          className={this.props.classes.select}
                          onChange={(event) => {
                            let state = this.state
                            state.exportSize = event.target.value;
                            if (state.exportSize === 'user') {
                              state.exportManual = true;
                            }
                            this.setState(state)
                          }}
                          displayEmpty
                  >
                    <MenuItem value={10}>
                      <em>10</em>
                    </MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                    <MenuItem value={'user'}>by user</MenuItem>
                  </Select>
                </>}

              <IconButton
                disabled={this.state.exportLoading}
                onClick={this.exportCSV}
                style={{marginRight: '10px', marginLeft: '10px', position:'relative',top:(this.state.exportManual)?'8px':''}}
                size="small"
                variant="text"
                className={this.props.classes.iconCSV}
              >
                <Icon className={'fas fa-file-csv'}/>
              </IconButton>
              {this.state.exportLoading && <Box style={{
                width: '40px',
                position: 'absolute',
                right: '10px',
                top: '3px',
                zIndex: '10'
              }} display="inline-flex">
                <CircularProgress value={this.props.exportCSVPerc}
                                  style={{width: '100%'}}
                                  color={'inherit'}
                                  variant={'determinate'}
                                  size={'small'}
                />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography variant="caption" style={{fontWeight: 800, fontSize: '1rem'}} component="div"
                              color="textSecondary">{this.props.exportCSVPerc}</Typography>
                </Box>
              </Box>}
              </div>
              </Box>
             
          {SessionStore.isAdmin() && <Box className={this.props.classes.boxDefault} component="div" mr={2}>
            <AutocompleteSelect
              id="organizationIDWL"
              margin="none"
              value={organizationID}
              onChange={this.onChangeOrganizationList}
              getOptions={this.getOrganizationOptions}
              getOptionSelected={GetOptionSelected}
              className={this.props.classes.selectOrganization}
            />
          </Box>}
        </Grid>
      </Form>
    )
  }
}

export default withStyles(styles)(FilterWirelessLogger);

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import CttMap from "./CttMap";
import SessionStore from "../../stores/SessionStore";
import ColumnSelector from "./ColumnSelector";
import Fields from "./Fields";
import Filter from "./Filter";
import Table from "./Table";
import history from "../../history";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const styles = {};

const defaultChecked = Fields.filter((el) => {
  return el.default === true;
}).map((el) => {
  return el.dataName;
});

class CttListLayout extends Component {
  constructor() {
    super();

    // this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.resetTable = this.resetTable.bind(this);
    this.onTabChange = this.onTabChange.bind(this);

    this.TableRef = React.createRef();

    let storageFields = localStorage.getItem("CttFields");

    let parsed = storageFields ? JSON.parse(storageFields) : null;
    let checked = defaultChecked;
    if (parsed && parsed.length > 0) {
      checked = parsed;
    }
    this.state = {
      csvData: "",
      exportTotal: 0,
      exportPerc: 0,
      tab: 0,
      fields: Fields.filter((field) => checked.indexOf(field.dataName) > -1),
      checkedFields: checked,
      openFieldsDialog: false,
      filterChangeTimeOut: undefined,
      page: SessionStore.getPage(),
      filter: {
        offset: 0,
        limit: SessionStore.getRowsPerPage(),
      },
    };
  }

  componentDidMount() {
    let that = this;
    if (SessionStore.user === null) {
      SessionStore.on("userGot", () => {
        const userStore = SessionStore.getUser();
        if (userStore === null || userStore === undefined) {
          window.sessionStorage.setItem("prevPage", "/ctt");
          history.replace("/login");
        } else {
          that.updateTable();
        }
      });

      if (!SessionStore.fetchingProfile && SessionStore.getToken() === null) {
        SessionStore.fetchProfile(() => {});
      }
    }
  }
  //selector dialog functions
  handleClickOpen() {
    this.setState({
      openFieldsDialog: true,
    });
  }

  handleClickClose(checkedFields) {
    let checked = checkedFields.length > 0 ? checkedFields : defaultChecked;

    localStorage.setItem("CttFields", JSON.stringify(checked));
    this.setState({
      openFieldsDialog: false,
      checkedFields: checked,
      fields: Fields.filter((field) => checked.indexOf(field.dataName) > -1),
      limit: "limit",
    });
  }

  handleFilterChange(data, timeout = 1500) {
    let filter = this.state.filter;
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    let state = { filter: { ...filter, ...data } };
    this.timeout = setTimeout(() => {
      let Obj = {};
      for (let i in state.filter) {
        if (i === "offset" || i === "limit") {
          continue;
        }

        if (i === "filters") {
          for (let f in state.filter[i]) {
            if (
              state.filter[i][f] !== null &&
              state.filter[i][f] !== "" &&
              state.filter[i][f] !== undefined
            ) {
              if (f === "direction") {
                continue;
              }
              Obj[f] = state.filter[i][f];
            }
          }
        } else {
          if (
            state.filter[i] !== "" &&
            state.filter[i] !== null &&
            state.filter[i] !== undefined
          ) {
            Obj[i] = state.filter[i];
          }
        }
      }

      const u = new URLSearchParams(Obj).toString();

      this.props.history.replace("?" + u);
      this.setState(state, () => {
        this.updateTable();
      });
    }, timeout);
  }

  onTabChange = (event, value) => {
    this.setState({ tab: value });
  };

  updateTable() {
    if (
      this.TableRef &&
      this.TableRef.current &&
      this.TableRef.current.updateTable
    ) {
      this.TableRef.current.updateTable();
    }
  }
  resetTable() {
    if (
      this.TableRef &&
      this.TableRef.current &&
      this.TableRef.current.resetTable
    ) {
      this.TableRef.current.resetTable();
    }
  }
  render() {
    return (
        <Grid container spacing={4}>
          <Grid item xs={12} style={{ paddingBottom: 0 }} id={"diagnostics"}>
          <Grid item style={{ paddingBottom: 24 }}>
            <Tabs
              value={this.state.tab}
              onChange={this.onTabChange}
              indicatorColor="primary"
            >
              <Tab label="Logs" value={0} />
              <Tab label="Map" value={1} />
            </Tabs>
            </Grid>
            {this.state.tab === 0 && (
              <Grid item xs={12}>
              <Filter
              exportCSVPerc={this.state.exportPerc}
              clickOpenDialog={this.handleClickOpen}
              resetTable={this.resetTable}
              updateTable={this.updateTable}
            />
              <Table
                fields={this.state.fields}
                limit={this.state.limit}
                tableRef={this.TableRef}
                disableAutoUpdate={this.disableAutoUpdate}
                changePageEvent={this.changePageEvent}
                page={this.state.page}
                filter={this.state.filter}
              />
              </Grid>
            )}
            {this.state.tab === 1 && <Card><CardContent><CttMap /></CardContent></Card>}
          </Grid>

          <ColumnSelector
            selectedFields={this.state.checkedFields}
            open={this.state.openFieldsDialog}
            onClose={this.handleClickClose}
          />
        </Grid>
    );
  }
}

export default withStyles(styles)(CttListLayout);

import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles,alpha, makeStyles  } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import blue from "@material-ui/core/colors/blue";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

import MenuIcon from "mdi-material-ui/Menu";
import Backburger from "mdi-material-ui/Backburger";
import AccountCircle from "mdi-material-ui/AccountCircle";


import SessionStore from "../stores/SessionStore";
import theme from "../theme";


import SearchIcon from '@material-ui/icons/Search';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import InputBase from '@material-ui/core/InputBase';
import history from '../history'

function Search(props){
  let {val,onChange} = props
  let width = '12ch'
  if(val&& val.length>13){
    width = '20ch'
  }


  let styleObj = {
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      marginRight: 15,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: width,
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }

 

  const classes = makeStyles((theme) => (styleObj))();

  return (
       <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              value={val}
              onChange={onChange}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
  )
}

const styles = {
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  //  background:'rgb(42, 106, 178)'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10,
  },
  hidden: {
    display: "none",
  },
  flex: {
    flex: 1,
  },
  logo: {
    height: 55,
    width:249,
    opacity:0,
    transition:'opacity 100ms'
  },
  search: {
    marginRight: 3 * theme.spacing(1),
    color: theme.palette.common.white,
    background: blue[400],
    padding: 5,
    borderRadius: 3,
    width: '12ch',
  
   
  },
  chip: {
    background: 'rgb(0, 67 ,136)',
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
    "&:hover": {
      background:'rgb(138, 181 ,225)',
    },
    "&:active": {
      background:'rgb(0, 67 ,136)',
    },
  },
  iconButton: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
};
const logoHandle = (e) =>{
  e.currentTarget.style.opacity = 1
}


class TopNav extends Component {
  constructor() {
    super();

    this.state = {
      menuAnchor: null,
      search: "",
   
    };
  }

  onMenuOpen = (e) => {
    this.setState({
      menuAnchor: e.currentTarget,
    });
  }
 
  onMenuClose = () => {
    this.setState({
      menuAnchor: null,
    });
  }
  componentDidMount(){
    const query = new URLSearchParams(history.location.search);
    const search = (query.get("search") === null) ? "" : query.get("search");

    if(search){
      this.setState({search:search})
    }
  }
  onLogout = () => {
    if (this.props.oidcEnabled === true) {
      if (this.props.logoutURL !== "") {
        SessionStore.logout(false, () => {
          window.location.assign(this.props.logoutURL);
        });
      } else {
        SessionStore.logout(true, () => {
            this.props.history.push("/login");
        });
      }
    } else {
      SessionStore.logout(true, () => {
        this.props.history.push("/login");
      });
    }
  }

  handleDrawerToggle = () => {
    this.props.setDrawerOpen(!this.props.drawerOpen);
  }

  onSearchChange = (e) => {
      this.setState({
        search: e.target.value.replaceAll(' ',''),
      }); 
    }

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.props.history.push(`/search?search=${encodeURIComponent(this.state.search)}`);
  }



  render() {
   
    let drawerIcon;
    if (!this.props.drawerOpen) {
      drawerIcon = <MenuIcon />;
    } else if(this.props.size==='full'&&this.props.drawerOpen){
      drawerIcon = <Backburger />;
    }else if(this.props.drawerOpen){
      drawerIcon = <Backburger style={{transform:"rotate(180deg)"}} />;
    }

    const open = Boolean(this.state.menuAnchor);
    
   // let logoUrl = "/public/logo.svg";
    // if (this.props.serverName === 'dev') {
    //  // logoUrl = "/logo/logo-dev.svg";
    //   logoUrl = "/logo/login_logo_white.svg";
    // }
    // if (this.props.serverName === 'enterprise') {
    //   logoUrl = "/logo/logo-ent.svg";
    // }
    // if (this.props.serverName === 'preprod') {
    //   logoUrl = "/logo/logo-preprod.svg";
    // }
   
    return(
      <AppBar style={
        {background:this.props.colors.topNavColor}
      } className={this.props.classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="toggle drawer"
            onClick={this.handleDrawerToggle}
            className={this.props.classes.menuButton}
          >
            {drawerIcon}
          </IconButton>

         <div className={this.props.classes.flex}>
         <img alt="" className={this.props.classes.logo} onLoad={logoHandle} src="/public/logo.svg"  />
        
          </div>

          {this.props.user&&this.props.mode!=='noauth'&& <form onSubmit={this.onSearchSubmit}> <Search onChange={this.onSearchChange} val={this.state.search}/></form> }

          { this.props.toolsEnabled && this.props.toolsEnabled.help===true && <a href="/help" target="_blank">
             <IconButton className={this.props.classes.iconButton}>
               <LiveHelpOutlinedIcon fontSize="large"/>
               
             </IconButton>
           </a>}

          <Chip
            avatar={
              <Avatar>
                <AccountCircle />
              </Avatar>
            }
            label={(this.props.user)?this.props.user.email:'Unauthorized' }
            onClick={this.onMenuOpen}
            color="primary"
            classes={{
              root: this.props.classes.chip,
            }}
          />
          <Menu
            id="menu-appbar"
            anchorEl={this.state.menuAnchor}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={this.onMenuClose}
          >
            {this.props.toolsEnabled && this.props.toolsEnabled.bsChecker===true &&<MenuItem onClick={()=>{this.props.onSwitchMode();this.onMenuClose()}} >{this.props.mode==='noauth'?'Main platform':'BS Checker'}</MenuItem>}
            {this.props.toolsEnabled && this.props.toolsEnabled.loraMaps===true && <MenuItem onClick={this.onMenuClose} component={'a'} href="/maps" target="_blank">LoRaWAN Maps</MenuItem>}
            {this.props.toolsEnabled && this.props.toolsEnabled.spectrumAnalyzer===true && <MenuItem onClick={this.onMenuClose} component={'a'} href="/spectrum" target="_blank">Spectral scan</MenuItem>}
            {!this.props.oidcEnabled && this.props.user && <MenuItem onClick={this.onMenuClose} component={Link} to={`/users/${this.props.user.id}/password`}>Change password</MenuItem>}
            {this.props.user && (this.props.user.isAdmin || SessionStore.isOrganizationAdmin(SessionStore.getOrganizationID())) && <MenuItem onClick={this.onMenuClose} component={Link} to={`/about`}>About</MenuItem>}
            {this.props.user && <MenuItem onClick={this.onLogout}>Logout</MenuItem>}
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withRouter(TopNav));
export { logoHandle }

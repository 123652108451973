import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ShuffleIcon from '@material-ui/icons/Shuffle';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';
import Domain from "mdi-material-ui/Domain";
import Home from "mdi-material-ui/Home";
import Account from "mdi-material-ui/Account";
import Server from "mdi-material-ui/Server";
import Apps from "mdi-material-ui/Apps";
import RadioTower from "mdi-material-ui/RadioTower";
import Rss from "mdi-material-ui/Rss";
import Tune from "mdi-material-ui/Tune";
import AccountDetails from "mdi-material-ui/AccountDetails";
import KeyVariant from "mdi-material-ui/KeyVariant";
import SettingsRemoteOutlinedIcon from '@material-ui/icons/SettingsRemoteOutlined';


//import AutocompleteSelect from "./AutocompleteSelect";
import SessionStore from "../stores/SessionStore";
import OrganizationStore from "../stores/OrganizationStore";
import Admin from "./Admin";

import theme from "../theme";
import DescriptionIcon from '@material-ui/icons/Description';
import { Build, DeviceHub } from "@material-ui/icons";

const styles = {
  drawerPaper: {
    overflowX: 'hidden',
    overflowY: 'auto',
    position: "fixed",
    width: 60,
 
  //  willChange:'width',
    paddingTop: theme.spacing(9),
  },
  drawerPaperFull: {
   
   // willChange:'width',
    position: "fixed",
    width: 270,
    paddingTop: theme.spacing(9),
  },
  select: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
   // position:"absolute"
  },
 
  listItem:{
    '& > a':{
      paddingTop:0,
      paddingBottom:0,
    }
  },
  listItemSmall:{
    '& > a':{
      paddingTop:4,
      paddingBottom:4
    },
  }
};

class SideNav extends Component {
  constructor() {
    super();

    this.state = {
      open: true,
     // organization: null,
      cacheCounter: 0,
      isGlobalAdmin: false,
    };


    this.onChange = this.onChange.bind(this);
    this.getOrganizationOption = this.getOrganizationOption.bind(this);
    this.getOrganizationOptions = this.getOrganizationOptions.bind(this);
    this.getOrganizationFromLocation = this.getOrganizationFromLocation.bind(this);
    this.getOptionSelected = this.getOptionSelected.bind(this);
  }

  componentDidMount() {
    this.setState({
      isGlobalAdmin: SessionStore.isAdmin(),
    });

  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) {
      return;
    }
  
  
  }

  onChange(e) {
    this.props.history.push(`/organizations/${e.target.value}`);
  }

  getOrganizationFromLocation() {
    const organizationRe = /\/organizations\/(\d+)/g;
    const match = organizationRe.exec(this.props.history.location.pathname);

    if (match !== null && (this.props.organization === null || this.props.organization.id !== match[1])) {
      SessionStore.setOrganizationID(match[1]);
    }
  }

  getOrganizationOption(id, callbackFunc) {
    OrganizationStore.get(id, resp => {
      callbackFunc({label: resp.organization.name, value: resp.organization.id});
    });
  }

  getOrganizationOptions(search, callbackFunc) {
    OrganizationStore.list(search, 999, 0, resp => {
      const options = resp.result.map((o, i) => {
        return {label: o.name, value: o.id}
      });
      callbackFunc(options, resp.totalCount);
    });
  }
  getOptionSelected(option, value){
    if (!value){return true}
    return option.value === value.value
  }
  render() {
    return(
      <Drawer
        variant="persistent"
        anchor="left"
        open={this.props.open}
        classes={{paper:(this.props.size==='full')?this.props.classes.drawerPaperFull:this.props.classes.drawerPaper}}
      >
        {this.props.mode==='noauth'&&<List>
          <ListItem button component={Link} to={'/noauth/bscheck'}>
            <ListItemIcon>
              <Home titleAccess={(this.props.size==="full")?"":"BS Check"}/>
            </ListItemIcon>
            <ListItemText primary="BS Check" />
          </ListItem>
          </List>}
       {this.props.mode!=='noauth'&& <Admin>
          <List className={(this.props.size==='full')?this.props.classes.listItem:this.props.classes.listItemSmall}>
            <ListItem button key={"Dashboard"} component={Link} to="/dashboard">
              <ListItemIcon>
                <Home titleAccess={(this.props.size==="full")?"":"Dashboard"}/>
              </ListItemIcon>
             {this.props.size==="full" && <ListItemText  primary="Dashboard" />}
            </ListItem>
            <ListItem button component={Link} to="/network-servers">
              <ListItemIcon>
                <Server  titleAccess={(this.props.size==="full")?"":"Frequency plans"}/>
              </ListItemIcon>
              {this.props.size==="full" &&<ListItemText primary="Frequency plans" />}
            </ListItem>
            <ListItem button component={Link} to="/gateway-profiles">
              <ListItemIcon>
                <RadioTower titleAccess={(this.props.size==="full")?"":"Gateway-profiles"} />
              </ListItemIcon>
              {this.props.size==="full" &&<ListItemText primary="Gateway-profiles" />}
            </ListItem>
            <ListItem button component={Link} to={`/device-profiles`}>
              <ListItemIcon>
                <Tune titleAccess={(this.props.size==="full")?"":"Device-profiles"} />
              </ListItemIcon>
              {this.props.size==="full" &&<ListItemText primary="Device-profiles" />}
            </ListItem>
            <ListItem button component={Link} to="/organizations">
              <ListItemIcon>
                <Domain titleAccess={(this.props.size==="full")?"":"Organizations"}/>
              </ListItemIcon>
             {this.props.size==="full" && <ListItemText primary="Organizations" />}
            </ListItem>
            <ListItem button component={Link} to="/users">
              <ListItemIcon>
                <Account titleAccess={(this.props.size==="full")?"":"All users"} />
              </ListItemIcon>
             {this.props.size==="full" && <ListItemText primary="All users" />}
            </ListItem>
            <ListItem button component={Link} to="/api-keys">
              <ListItemIcon>
                <KeyVariant titleAccess={(this.props.size==="full")?"":"API keys"} />
              </ListItemIcon>
             {this.props.size==="full" && <ListItemText primary="API keys" />}
            </ListItem>
            <ListItem button component={Link} to="/diagnostics">
              <ListItemIcon>
                <SettingsRemoteOutlinedIcon titleAccess={(this.props.size==="full")?"":"LoRaWAN Logger"} />
              </ListItemIcon>
             {this.props.size==="full" && <ListItemText primary="LoRaWAN Logger" />}
            </ListItem>
            <ListItem button component={Link} to="/logs">
              <ListItemIcon>
                <DescriptionIcon titleAccess={(this.props.size==="full")?"":"Logs"} />
              </ListItemIcon>
             {this.props.size==="full" && <ListItemText primary="Logs" />}
            </ListItem>
            <ListItem button component={Link} to="/tools/export">
              <ListItemIcon>
                <Build titleAccess={(this.props.size==="full")?"":"Tools"} />
              </ListItemIcon>
             {this.props.size==="full" && <ListItemText primary="Tools" />}
            </ListItem>
          </List>
          <Divider />
        </Admin>}

        {/* {this.props.mode!=='noauth'&&this.props.size==='full'&&<div >
          <AutocompleteSelect
            id="organizationID"
            margin="none"
            value={organizationID}
            onChange={this.onChange}
            getOptions={this.getOrganizationOptions}
            className={this.props.classes.select}
            triggerReload={this.state.cacheCounter}
            getOptionSelected={this.getOptionSelected}
          />
        </div>} */}

        {/*org section*/}

        {this.props.organization && this.props.mode !== 'noauth' && <List className={(this.props.size==='full')?this.props.classes.listItem:this.props.classes.listItemSmall}>
          {!SessionStore.isAdmin() && (SessionStore.organizations && SessionStore.organizations.length > 1) &&
          <ListItem button component={Link} to="/organizations">
            <ListItemIcon>
                <Domain titleAccess={(this.props.size==="full")?"":"Organizations"}/>
              </ListItemIcon>
              {this.props.size==="full"&& <ListItemText primary="Organizations" />}
            </ListItem>}
          <ListItem button component={Link} to={`/organizations/${this.props.organization.id}`}>
            <ListItemIcon>
              <Home titleAccess={(this.props.size==="full")?"":"Dashboard"}/>
            </ListItemIcon>
            {this.props.size==="full"&& <ListItemText primary="Dashboard" />}
          </ListItem>
          {!SessionStore.isAdmin()&&<ListItem button component={Link} to="/diagnostics">
            <ListItemIcon>
              <SettingsRemoteOutlinedIcon titleAccess={(this.props.size==="full")?"":"LoRaWAN Logger"}/>
            </ListItemIcon>
           {this.props.size==="full"&&  <ListItemText primary="LoRaWAN Logger"/>}
          </ListItem>}
         
          <ListItem button component={Link} to={`/organizations/${this.props.organization.id}/service-profiles`}>
            <ListItemIcon>
              <AccountDetails titleAccess={(this.props.size==="full")?"":"Service-profiles"}/>
            </ListItemIcon>
            {this.props.size==="full"&& <ListItemText primary="Service-profiles" />}
          </ListItem>
          <ListItem button component={Link} to={`/organizations/${this.props.organization.id}/applications`}>
            <ListItemIcon>
              <Apps titleAccess={(this.props.size==="full")?"":"Application Servers"}/>
            </ListItemIcon>
          {this.props.size==="full"&&   <ListItemText primary="Application Servers" />}
          </ListItem>
          <ListItem button component={Link} to={`/organizations/${this.props.organization.id}/as-routing-profiles`}>
            <ListItemIcon>
              <ShuffleIcon titleAccess={(this.props.size==="full")?"":"AS routing profiles"}/>
            </ListItemIcon>
            {this.props.size==="full"&& <ListItemText primary="AS routing profiles"/>}
          </ListItem>
          {(this.props.mode !=='noauth' && !this.state.isGlobalAdmin) &&
          <ListItem button component={Link} to={`/device-profiles`}>
            <ListItemIcon>
              <Tune titleAccess={(this.props.size==="full")?"":"Device-profiles"}/>
            </ListItemIcon>
           {this.props.size==="full"&&  <ListItemText primary="Device-profiles" />}
          </ListItem>
          }
         
          


          <ListItem button component={Link} to={`/organizations/${this.props.organization.id}/devices`}>
            <ListItemIcon>
              <DeviceHub titleAccess={(this.props.size==="full")?"":"Devices"}/>
            </ListItemIcon>
            {this.props.size==="full"&& <ListItemText primary="Devices" />}
          </ListItem>

       
          {this.props.organization.canHaveGateways && <ListItem button component={Link} to={`/organizations/${this.props.organization.id}/gateways`}>
            <ListItemIcon>
              <RadioTower titleAccess={(this.props.size==="full")?"":"Gateways"}/>
            </ListItemIcon>
           {this.props.size==="full"&&  <ListItemText primary="Gateways" />}
          </ListItem>}
          <ListItem button component={Link} to={`/organizations/${this.props.organization.id}/multicast-groups`}>
            <ListItemIcon>
              <Rss titleAccess={(this.props.size==="full")?"":"Multicast-groups"}/>
            </ListItemIcon>
            {this.props.size==="full"&& <ListItemText primary="Multicast-groups" />}
          </ListItem>
          <Admin organizationID={this.props.organization.id}>
          
            <ListItem button component={Link} to={`/organizations/${this.props.organization.id}/api-keys`}>
              <ListItemIcon>
                <KeyVariant titleAccess={(this.props.size==="full")?"":"API keys"}/>
              </ListItemIcon>
            {this.props.size==="full"&&   <ListItemText primary="API keys" />}
            </ListItem>
            <ListItem button component={Link} to={`/organizations/${this.props.organization.id}/users`}>
              <ListItemIcon>
                <Account titleAccess={(this.props.size==="full")?"":"Users"}/>
              </ListItemIcon>
             {this.props.size==="full"&&  <ListItemText primary="Users" />}
            </ListItem>
          </Admin>
         
          {(SessionStore.isAdmin()||SessionStore.isOrganizationAdmin(this.props.organization.id)) && <ListItem button component={Link} to={`/organizations/${this.props.organization.id}/logs`}>
            <ListItemIcon>
              <DescriptionIcon titleAccess={(this.props.size==="full")?"":"Logs"}/>
            </ListItemIcon>
            {this.props.size==="full"&& <ListItemText primary="Logs" />}
          </ListItem>}
        </List>}
      </Drawer>
    );
  }
}

export default withRouter(withStyles(styles)(SideNav));

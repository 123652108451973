import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";


import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";

import GatewayForm from "./GatewayForm";
import GatewayStore from "../../stores/GatewayStore";

import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";


const styles = {
  card: {
    overflow: "visible",
  },
};


class CreateGateway extends Component {
  constructor() {
    super();

 
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(gateway) {
    let gw = gateway;
    gw.organizationID = this.props.match.params.organizationID;

    if (gw.address){
      gw.address = gw.address.replace('\n',' ') 
    }

    if(!gateway.location){
      gateway.location = {}
    }else{
      gateway.location.latitude =  gateway.location.latitude || 0 
      gateway.location.longitude = gateway.location.longitude || 0
      gateway.location.altitude =  gateway.location.altitude  || 0
     
    }
    GatewayStore.create(gateway, resp => {
      this.props.history.push(`/organizations/${this.props.match.params.organizationID}/gateways`);
    });
  }

  render() {
    return(
      <Grid container spacing={4}>
        <TitleBar>
        <OrganizationBreadcrumps org={this.props.org}>
          <TitleBarTitle title="Gateways" to={`/organizations/${this.props.match.params.organizationID}/gateways`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Create" />
        </OrganizationBreadcrumps>
          
        </TitleBar>
        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <GatewayForm
                match={this.props.match}
                submitLabel="Create gateway"
                onSubmit={this.onSubmit}
           
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(CreateGateway));

import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import { checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";


class InternalStore extends EventEmitter {
  constructor() {
    super();
    this.client = null;
    this.swagger = Swagger("/swagger/internal.swagger.json", sessionStore.getClientOpts());
  }

  listAPIKeys(filters, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.InternalService_ListAPIKeys(filters)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  deleteAPIKey(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.InternalService_DeleteAPIKey({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("api key has been deleted");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  createAPIKey(obj, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.InternalService_CreateAPIKey({
        body: {
          apiKey: obj,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("api key has been created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  updateAPIKey(obj, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.InternalService_UpdateAPIKey({
        body: {
          apiKey: obj,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("api key has been updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  getAPIKey(id, callbackFunc){
    this.swagger.then(client => {
      client.apis.InternalService.InternalService_GetAPIKey({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
       
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });

  }


  settings(callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.InternalService_Settings({})
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getDevicesSummaryLog(organizationId, start, end, aggregation, callbackFunc) {
    this.swagger.then(client => {
      let data = {
        organizationId: parseInt(organizationId),
        start: start,
        end: end,
        aggregation:aggregation
      }

      client.apis.InternalService.InternalService_GetDevicesSummaryLog(data)
          .then(checkStatus)
          .then(resp => {
            callbackFunc(resp.obj);
          })
          .catch(errorHandler);
    });
  }

  getGatewaysSummaryLog(organizationId, start, end, aggregation, callbackFunc) {
    this.swagger.then(client => {
      let data = {
        organizationId: parseInt(organizationId),
        start: start,
        end: end,
        aggregation:aggregation
      }

      client.apis.InternalService.InternalService_GetGatewaysSummaryLog(data)
          .then(checkStatus)
          .then(resp => {
            callbackFunc(resp.obj);
          })
          .catch(errorHandler);
    });
  }

  getStackInfo(organizationID,callbackFunc){
    this.swagger.then(client => { 
      let data = {
        organizationId: parseInt(organizationID),
      }
      client.apis.InternalService.InternalService_GetInfo (data)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getDevicesSummary(organizationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.InternalService_GetDevicesSummary({
        organizationID: organizationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getGatewaysSummary(organizationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.InternalService_GetGatewaysSummary({
        organizationID: organizationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  notify(msg) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: msg,
      },
    });
  }
}

const internalStore = new InternalStore();
export default internalStore;

import DiagTableHead from '../views/diagnostics/diag-table-head.json'
import DataRateMap from './data-rate-map.json';
import TablesTranslations from './tables-translation.json';

const dict_obj = {
  "diagnostics-table-head": DiagTableHead,
  "data-rate-map": DataRateMap,
  "tables-translation":TablesTranslations
}

class Dictionary {

  constructor(dict) {

    if (!dict_obj[dict]) {
      throw new Error('wrong Dictionary')
    }
    this.dict = dict_obj[dict];
  }

  map(str) {
    return ((this.dict[str]) ? this.dict[str] : str)
  }
}

export {
  Dictionary
}

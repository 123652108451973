import React, { Component } from "react";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import L from "leaflet";
import "leaflet.awesome-markers";
import moment from "moment";
import { Map, Marker, Popup } from 'react-leaflet';
import {withStyles} from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import MapTileLayer from "../../components/MapTileLayer";
import MarkerClusterGroup from "react-leaflet-markercluster";
import DeviceStore from "../../stores/DeviceStore";
import 'bootstrap-icons/font/bootstrap-icons.css';



function NoDataAvailable(){
  return(
    <Card>
    
        <CardContent>
          No data available.
        </CardContent>
    </Card>
  );
}

const styles = {
    iconsize:{
      fontSize:'20px!important',
      marginTop:'5px!important'
    }
  };
  
  class ListDevicesMap extends Component {
    constructor() {
      super();
  
      this.state = {
        items: null,
      };
    }
  
    componentDidMount() {
      this.loadData();
    }
  
    componentDidUpdate(prevProps) {
      if (prevProps === this.props) {
        return;
      }
  
      this.loadData();
    }
  
    loadData = () => {
      DeviceStore.list({organizationId:this.props.organizationID,limit:9999}, resp => {
        this.setState({
          items: resp.result,
        });
      });
    }
  
    render() {
      if (this.state.items === null || this.state.items.length === 0) {
        return(
          <NoDataAvailable/>
        );
      }
  
      const style = {
        height: 600,
      };
  
  
      let bounds = [];
      let markers = [];
  
      const greenMarker = L.AwesomeMarkers.icon({
        icon: "phone-vibrate",
        prefix: "bi",
        markerColor: "green",
        extraClasses:this.props.classes.iconsize
      });
  
      // const grayMarker = L.AwesomeMarkers.icon({
      //   icon: "phone-vibrate",
      //   prefix: "bi",
      //   markerColor: "gray",
      // });
  
      // const redMarker = L.AwesomeMarkers.icon({
      //   icon: "phone-vibrate",
      //   prefix: "bi",
      //   markerColor: "red",
      // });
  
      // const orangeMarker = L.AwesomeMarkers.icon({
      //   icon: "phone-vibrate",
      //   prefix: "bi",
      //   markerColor: "orange",
      // });
  
      for (const item of this.state.items) {

       if(!item.location || !item.location.latitude || !item.location.longitude){
         continue;
       }

        const position = [item.location.latitude, item.location.longitude];
  
        bounds.push(position);
  
        let marker = greenMarker;
        let lastSeen = "";
  
        if (item.lastSeenAt === undefined || item.lastSeenAt === null) {
          lastSeen = "Never seen online";
        } else {
          const ts = moment(item.lastSeenAt);  
          lastSeen = ts.fromNow();
        }
  
        markers.push(
          <Marker position={position} key={`d-${item.id}`} icon={marker}>
            <Popup>
              <Link to={`/organizations/${this.props.organizationID}/devices/${item.devEUI}`}>{item.name}</Link><br />
              {item.devEUI.toUpperCase()}<br /><br />
              {lastSeen}
            </Popup>
          </Marker>
        );
      }
      if(!markers.length){
          return <NoDataAvailable/>
      }
      return(
        <Card>
        
            <CardContent>
              <Map bounds={bounds} maxZoom={19} style={style} animate={true} scrollWheelZoom={false}>
                <MapTileLayer />
                <MarkerClusterGroup>
                  {markers}
                </MarkerClusterGroup>
              </Map>
            </CardContent>
        </Card>
      );
    }
  }

  export default  withStyles(styles)(ListDevicesMap)
import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import DataTable from "../../components/DataTable";
import {Box, Grid, TableCell, TableRow, TableSortLabel} from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';
import moment from "moment";
import GatewayStore from "../../stores/GatewayStore";
import { DateHelper} from "../../classes/Helper"; //todo change this to moment js and remove from helper
import 'date-fns';
import ruLocale from "date-fns/locale/ru";
import DateFnsUtils from '@date-io/date-fns';
import AutocompleteSelect from "../../components/AutocompleteSelect";
import DictionaryStore from "../../stores/DictionaryStore";
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HeatmapEvents from './HeatmapEvents'

const styles = {
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  boxDefault:{
      width:'261px' ,
  },
  boxCustom: {
      display:"inline-flex",
      alignItems:'center',
      width:'261px' ,
      paddingBottom:'15px'
  },
  button:{
      marginLeft:"10px"
  },
  grid:{
      display:'flex',
      alignItems:'center',
      flexWrap:'wrap',
  },
  selectEventType:{
      width:'100%'
  },
  chart: {
    minHeight: 500,
  },
}

class GatewayEvents extends Component {
  constructor() {
    super();
    this.refHeatmap = React.createRef()
    this.refTable = React.createRef()
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.changeSort = this.changeSort.bind(this);
    this.makeSortLabel = this.makeSortLabel.bind(this);
    this.makeCellSortProps = this.makeCellSortProps.bind(this);
    this.onChangeEventType = this.onChangeEventType.bind(this);
    this.getEventLogsOptions = this.getEventLogsOptions.bind(this);
    this.handleDateTimeChange = this.handleDateTimeChange.bind(this);

    this.state = {
      orderBy: null,
      order: null,
      tab: 0,
      filter : {
        code: null,
        startTimestamp: null,
        endTimestamp: null,
      },
      dict: null
    }

    this.canvasId = 'gatewayEvents'
   
  }
  onTabChange = (e, v) => {
    this.setState({
      tab: v,
    });
  }
  onChangeEventType(e) {
    let state = this.state;
    state.filter.code = e.target.value;
    this.setState(state,()=>{
      if( this.refHeatmap.current ){
        this.refHeatmap.current.update();
      }
      if( this.refTable.current ){
        this.refTable.current.updateTable()
      }
    });
  }

  handleDateTimeChange(params, time){
    let state = this.state;
    state.filter[time] = params[1];

    if (!params || params[0] == null || params[0].toString() ==='Invalid Date') {
      if (state.filter[time] == null ) {
        return;
      }
      state.filter[time] = null
    } else {
      state.filter[time] = new DateHelper().setDate(params[0]).toIsoString() || null;
    }

    this.setState(state, () => {
      if( this.refHeatmap.current ){
        this.refHeatmap.current.update()
      }
      if( this.refTable.current ){
        this.refTable.current.updateTable()
      }
    });
  }

  getEventLogsOptions(search, callbackFunc) {
    DictionaryStore.getDictionary('gwlogs_state', null, resp => {
      const options = resp.dict.map((d, i) => {return {label: d.label, value: d.code}});
      this.setState({dict: resp.dict});
      callbackFunc(options);
    });
  }

  getPage(limit, offset, callbackFunc) {
    const orgId = this.props.match.params.organizationID || 0;
    const gwId = this.props.gateway.id;
    GatewayStore.logs(orgId, gwId, this.state.filter, limit, offset, this.state.orderBy, this.state.order,callbackFunc);
  }

  getRow(obj) {
    let dictNameObj = null;
    if (this.state.dict !== null) {
      dictNameObj = this.state.dict.find(elem => +elem.code === +obj.code);
    }
    
    
    
    return(
      <TableRow
        key={obj.id}
        hover
      >
        {/* <TableCell>{obj.code}</TableCell> */}
        <TableCell>{dictNameObj ? dictNameObj.label : ''}</TableCell>
        <TableCell>{moment(obj.createdAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
      </TableRow>
    );
  }

  changeSort(event) {
    let state = {
      orderBy: event.currentTarget.dataset.key,
      order: this.state.order
    }
    if (state.orderBy !== this.state.orderBy) {
      state.order = null;
    }
    if (state.order === null) {
      state.order = 'asc'
    } else if (state.order === 'asc') {
      state.order = 'desc'
    } else if (state.order === 'desc') {
      state.order = null
      state.orderBy = null
    }
    this.setState(state);
  }

  makeSortLabel(keyName,viewName) {
    return (
      <TableSortLabel
        data-key = {keyName}
        active = {this.state.orderBy === keyName}
        direction = {this.state.orderBy === keyName ? this.state.order : 'asc'}
        onClick = {this.changeSort}
      >
        {viewName}
        {this.state.orderBy === keyName ? (
          <span className={this.props.classes.visuallyHidden}>
            {this.state.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
          </span>
        ) : null}
      </TableSortLabel>
    )
  }

  makeCellSortProps(keyName) {
    return {
      key: keyName,
      align: 'left',
      padding: 'normal',
      sortDirection: this.state.orderBy === keyName ? this.state.order : false
    }
  }


  render() {
    return(
      <Grid container spacing={4}>
        <Grid item xs={12} style={styles.grid}>

          <Box className={this.props.classes.boxCustom} component="div" mr={2}>
            <div>
              <MuiPickersUtilsProvider  utils={DateFnsUtils} locale={ruLocale}>
                <KeyboardDateTimePicker
                  variant="inline"
                  ampm={false}
                  label="Date from ..."
                  value={this.state.filter.startTimestamp}
                  onChange={(...all)=>this.handleDateTimeChange(all, 'startTimestamp')}
                  onError={console.log}
                  format="yyyy/MM/dd HH:mm"
                />
              </MuiPickersUtilsProvider>
            </div>
          </Box>

          <Box className={this.props.classes.boxCustom} component="div" mr={2}>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
                <KeyboardDateTimePicker
                  variant="inline"
                  ampm={false}
                  label="Date to ..."
                  value={this.state.filter.endTimestamp}
                  onChange={(...all)=>this.handleDateTimeChange(all,'endTimestamp')}
                  onError={console.log}
                  format="yyyy/MM/dd HH:mm"
                />
              </MuiPickersUtilsProvider>
            </div>
          </Box>

          <Box className={this.props.classes.boxDefault} component="div" mr={2}>
            <AutocompleteSelect
              label="Select event type"
              clearable
              id="eventType"
              margin="none"
              value={this.state.filter.code}
              onChange={this.onChangeEventType}
              getOptions={this.getEventLogsOptions}
              className={this.props.classes.selectEventType}
            />
          </Box>

        </Grid>
        <Grid item xs={12}>
        {/* <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label="Table"/>
          <Tab label="Chart"/>
      
        </Tabs> */}

        {/* {this.state.tab === 0 || true &&} */}

        <div>
          <DataTable
          ref={this.refTable}
            header={
              <TableRow>
                {/* <TableCell {...this.makeCellSortProps('code')}>{this.makeSortLabel('code','Code')}</TableCell> */}
                {/* <TableCell {...this.makeCellSortProps('name')}>{this.makeSortLabel('name','Event name')}</TableCell>
                <TableCell {...this.makeCellSortProps('createdAt')}>{this.makeSortLabel('createdAt','Created at (Local)')}</TableCell> */}
                <TableCell>Event name</TableCell>
                <TableCell>Created at (Local)</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
          </div>
          {this.state.tab === 1  && <div>
            <Card>
       
        <CardContent className={this.props.classes.chart} >
           <HeatmapEvents ref={this.refHeatmap} orgId={this.props.match.params.organizationID || undefined} gwId={this.props.gateway.id} filter={this.state.filter} dict={this.state.dict}/>
        </CardContent>
      </Card>
          </div>}
        </Grid>
      </Grid>
    );
  }
}
GatewayEvents=withStyles(styles)(GatewayEvents);
GatewayEvents=withRouter(GatewayEvents)
export default GatewayEvents;

import React, { Component } from "react";
import moment from "moment";
import MapTileLayer from "../../../components/MapTileLayer";
import GatewayStore from "../../../stores/GatewayStore";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import L from "leaflet";
import { Map, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Link } from "react-router-dom";
import SessionStore from "../../../stores/SessionStore";


class ListGatewaysMap extends Component {
  constructor() {
    super();

    this.state = {
      items: null,
    };
  }

  componentDidMount() {
    this.loadData();
    SessionStore.on("organization.change", this.loadData);
  }
  componentWillUnmount(){
    SessionStore.removeListener("organization.change", this.loadData);
  }
    
  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }
   
  }

  loadData = () => {
    GatewayStore.list("", this.props.organizationID||0, 9999, 0, resp => {
      this.setState({
        items: resp.result,
      });
    });
  }

  render() {
    if (this.state.items === null || this.state.items.length === 0) {
      return(
        <Card>
          <CardHeader title="Gateways" />
            <CardContent>
              No data available.
            </CardContent>
        </Card>
      );
    }

    const style = {
      height: 600,
    };


    let bounds = [];
    let markers = [];

    const greenMarker = L.AwesomeMarkers.icon({
      icon: "wifi",
      prefix: "fa",
      markerColor: "green",
    });

    const grayMarker = L.AwesomeMarkers.icon({
      icon: "wifi",
      prefix: "fa",
      markerColor: "gray",
    });

    const redMarker = L.AwesomeMarkers.icon({
      icon: "wifi",
      prefix: "fa",
      markerColor: "red",
    });

    const orangeMarker = L.AwesomeMarkers.icon({
      icon: "wifi",
      prefix: "fa",
      markerColor: "orange",
    });

    for (const item of this.state.items) {
     
      const position = [item.location.latitude, item.location.longitude];

      bounds.push(position);

      let marker = greenMarker;
      let lastSeen = "";

      if (item.lastSeenAt === undefined || item.lastSeenAt === null) {
        marker = grayMarker;
        lastSeen = "Never seen online";
      } else {
        const ts = moment(item.lastSeenAt);
        if (ts.isBefore(moment().subtract(5, 'minutes'))) {
          marker = redMarker;
        }

        lastSeen = ts.fromNow();
      }

      if((item.radio!=='Running' && item.active===true)){
        marker = orangeMarker;
      }

      markers.push(
        <Marker position={position} key={`gw-${item.id}`} icon={marker}>
          <Popup>
            <Link to={`/organizations/${item.organizationID}/gateways/${item.id}`}>{item.name}</Link><br />
            {item.id}<br /><br />
            {lastSeen}
          </Popup>
        </Marker>
      );
    }

    return(
      <Card>
        <CardHeader title="Gateways" />
          <CardContent>
            <Map bounds={bounds} maxZoom={19} style={style} animate={true} scrollWheelZoom={false}>
              <MapTileLayer />
              <MarkerClusterGroup>
                {markers}
              </MarkerClusterGroup>
            </Map>
          </CardContent>
      </Card>
    );
  }
}

export default ListGatewaysMap
import React, { Component } from 'react';
import Statistics from './statistics/Statistics'
import Aggregated from './statistics/Aggregated'
import Packet from './statistics/Packet';



class DeviceStatistics extends Component {

  render() {
   
    
    return(
      <>
      <Statistics {...this.props}/>
      <Aggregated {...this.props}/>
      <Packet {...this.props}/>
    </>
    );
  }
}
export default DeviceStatistics;

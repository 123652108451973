import React from 'react'
import SessionStore from '../stores/SessionStore'
import TitleBarTitle from './TitleBarTitle'

const  OrganizationBreadcrumps = (props) => { 
  
  let orgTitles = null
  let org = props.org
  if(SessionStore.isAdmin()  && props.globalFalse!==undefined){
    org = undefined
  }
  
  if(org){
    orgTitles = [<TitleBarTitle key="displayName" title={org.displayName} />,<TitleBarTitle key="slash" title="/" />]
  }
  
  return (     <>
                    {org&&orgTitles}
                    {props.children}
                </>
                    
  )
}

export default OrganizationBreadcrumps

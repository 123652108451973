import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import DeviceProfileStore from "../../stores/DeviceProfileStore";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from "@material-ui/core/Box";
import {FileImport} from "../../classes/DeviceImportByFILE";
import theme from "../../theme";
import DeviceStore from "../../stores/DeviceStore";
var Queue=require('js-queue');


const styles = {
  formLabel: {
    fontSize: 12,
  },
  inputResult:{
    border:'1px solid #9ABDD8',
    background:'#BFDEF6',
    position:'relative',
    minHeight:'200px'
  },
  inputResultLabel:{
    position: 'absolute',
    top:'-15px',
    left:'30px',
    background:'#91B9EA',
    color:'#fff',
    padding:'5px 10px',
    width:'150px'
  },
  tabsContainer:{marginBottom:'30px'},
  progressBarContainer:{marginLeft:'auto',display:'flex',alignItems:'center'},
  progressBarMsg:{marginRight:'20px'},
  inputResultLog:{
    display:'flex',
    flexWrap:'wrap',
    marginTop:'20px',
    flexDirection:'column'
  },
  resetButton:{
    marginLeft: 2 * theme.spacing(1),
    width:'100px',
    marginBottom:'25px',
    whiteSpace:'nowrap'
  },
  resultButtonContainer:{
    display:'flex',
  },
  manualContainer:{
    display:'flex',
    flexDirection:'row'
  },
  manualAbp:{
   marginLeft:'80px'
  },
  doneMessage:{
    margin:'50px'
  },
  requiredSpan: {
    color: 'red',
  }
};


function CircularProgressWithLabel(props) {
  return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="static" {...props} />
        <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
              props.value,
          )}%`}</Typography>
        </Box>
      </Box>
  );
}


function ProgressBar(props){


  return (
      <div className={props.classes.progressBarContainer}><div className={props.classes.progressBarMsg}>{props.data.msg}</div> <CircularProgressWithLabel value={props.data.value} /></div>
  )
}


class ImportForm extends FormComponent {
  constructor() {
    super();
    this.getDeviceProfileOption = this.getDeviceProfileOption.bind(this);
    this.getDeviceProfileOptions = this.getDeviceProfileOptions.bind(this);
    this.chooseFile = this.chooseFile.bind(this);
    this.changeProgress = this.changeProgress.bind(this);
    this.resetFileImport = this.resetFileImport.bind(this);
    this.fileInputRemove = this.fileInputRemove.bind(this);
    this.loadValid = this.loadValid.bind(this);

    this.state = {
      tab: 0,
      variables: [],
      tags: [],
      progressBar:{value:0,msg:""},
      resMsg:[],
      validData:null,
      loadedCnt:0,
      loading:false
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.setKVArrays(this.props.object || {});
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.object !== this.props.object) {
      this.setKVArrays(this.props.object || {});
    }
  }

  setKVArrays = (props) => {
    let variables = [];
    let tags = [];

    if (props.variables !== undefined) {
      for (let key in props.variables) {
        variables.push({key: key, value: props.variables[key]});
      }
    }

    if (props.tags !== undefined) {
      for (let key in props.tags) {
        tags.push({key: key, value: props.tags[key]});
      }
    }

    this.setState({
      variables: variables,
      tags: tags,
    });
  }

  getDeviceProfileOption(id, callbackFunc) {
    DeviceProfileStore.get(id, resp => {
      callbackFunc({label: resp.deviceProfile.name, value: resp.deviceProfile.id});
    });
  }

  getDeviceProfileOptions(search, callbackFunc) {
    DeviceProfileStore.list({limit: 999, offset: 0}, resp => {
      const options = resp.result.map((dp, i) => {return {label: dp.name, value: dp.id}});
      callbackFunc(options);
    });
  }

  onTabChange = (e, v) => {
    this.setState({
      tab: v,
    });
  }
  fileInputRemove(){
    document.querySelectorAll('#file_Input').forEach((item)=>{
      item.remove()
    })
  }
  chooseFile(event){
    event.preventDefault();
    let input=document.createElement('input');
    input.type="file";
    input.id='file_Input';
    input.hidden = true;

    input.onchange = (ev) =>{
      this.setState({ tab:0})
      let file = new FileImport(ev.target.files[0],this.changeProgress);
      file.checkAll((err,data=null)=>{
        this.setState({resMsg:err,validData:data},()=>{
          if(err.length===0) this.loadValid();
        });

      })

    }

    document.body.appendChild(input);
    setTimeout(function(){
      input.click();
    },200);

  }

  loadValid(){
    let data  = this.state.validData;
    this.setState({loading:true,resMsg:[],loadedCnt:0})
    const logError = (resp,devui)=>{ 
     
      let err = this.state.resMsg;
      if(err===null) err = [];

      let msg = resp.response.body.message||resp.response.obj.error||resp.message

      err.push(devui+' '+ msg)
      this.setState({resMsg:err})
    }
  this.changeProgress(49,'Processing devices');


    var queue=new Queue();







  let that = this;
 
  const checkIfLast = (i,total)=>{
    if(i===total){
      let dataLen = this.state.validData.length;
      let loadedLen = this.state.loadedCnt;

      let msg = ''

      if(loadedLen>0){
          msg = ((dataLen===loadedLen)?'All':`${loadedLen} of ${dataLen}`)+' done'
      }

   
     
      this.setState({ loading:false,progressBar:{value:100,msg:msg}})
      return true
    }
    return false;
  }


   data.forEach((dev,pos)=>{
 
     let elPos = pos+1
     queue.add(function(){

       if(dev.cmd === 'DELETE'){
         DeviceStore.delete(dev.devEUI, resp => {
          if((resp && resp.message)||(resp.response&&resp.response.obj&&resp.response.obj.error)){
             logError(resp,dev.devEUI);
             that.changeProgress(49 +  (Math.round(elPos*49/data.length)),'Processing devices '+ elPos+' of '+data.length);
           }else{
             that.changeProgress(49 +  (Math.round(elPos*49/data.length)),'Processing devices '+ elPos+' of '+data.length);
             
             that.setState({loadedCnt:that.state.loadedCnt+1},()=>{
              console.log(that.state.loadedCnt)
             })
            }
           checkIfLast(elPos,data.length)
           queue.next();
         },false)



       }else{

         let create  = {};
         create.applicationID = that.props.match.params.applicationID;
         create.devEUI = dev.devEUI;
         create.deviceProfileID = dev.DeviceProfileID;
         create.name = dev.DeviceName;
         create.description = '';
         create.routingProfileID = dev.AsRoutingProfileId;
         create.serviceProfileID = dev.ConectivityPlanId;

         if(dev.AdminLat || dev.AdminLon){
          create.location = {}

          create.location.latitude = dev.AdminLat || undefined;
          create.location.longitude = dev.AdminLon || undefined;
         }

         DeviceStore.create(create, resp => {  
         
           if((resp && resp.message)||(resp.response&&resp.response.obj&&resp.response.obj.error)){
             logError(resp,dev.devEUI);
             checkIfLast(elPos,data.length)
             queue.next();
            
           }else{
             let func;
             let activate = {};
             activate.devEUI = dev.devEUI;
             if(dev.cmd === 'OTAA'){
               func = 'createKeys';
               activate.nwkKey = dev.AppKey.toLowerCase();
             }else if(dev.cmd === 'ABP'){
               func = 'activate';
                 
               activate.appSKey = dev.AppSKeys !=='' ?     dev.AppSKeys : undefined;
               activate.devAddr = dev.devAddr
               activate.nwkSEncKey = dev.NwkSEncKey !=='' ?   dev.NwkSEncKey  : undefined;
               activate.fNwkSIntKey =dev.FNwkSIntKey !=='' ?  dev.FNwkSIntKey : activate.nwkSEncKey;
               activate.sNwkSIntKey = dev.SNwkSIntKey !=='' ? dev.SNwkSIntKey : activate.nwkSEncKey;
               activate.aFCntDown = dev.AFCntDown !=='' ?     dev.AFCntDown   : undefined;
               activate.fCntUp = dev.FCntUp !=='' ?           dev.FCntUp      : undefined;
               activate.nFCntDown = dev.NFCntDown !=='' ?     dev.NFCntDown   : undefined;
               
             }
           
             DeviceStore[func](activate, (resp) => {   
              if((resp &&  resp.response&&resp.response.ok===false)||(resp &&  resp.message)||(resp.response&&resp.response.obj&&resp.response.obj&&resp.response.obj.error)){
                 logError(resp,dev.devEUI);
               }
               that.changeProgress(49 +  (Math.round(elPos*49/data.length)),'Processing devices '+ elPos+' of '+data.length);
              
             
               that.setState({loadedCnt:that.state.loadedCnt+1})
               checkIfLast(elPos,data.length)
               queue.next();
             },false)
           }
         },false)

       }

     });

  })

  }

  changeProgress(value,msg){
    this.setState({ progressBar:{value:value,msg:msg}})
  }
  resetFileImport(){
    this.setState({ loadedCnt:0,validData:null,resMsg:[], progressBar:{value:0,msg:''}})
    this.fileInputRemove();
  }
  render() {
    if (this.state.object === undefined) {
      return null;
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.chooseFile}
        disabled={this.props.disabled}
      >
        <Tabs className={this.props.classes.tabsContainer} value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label="General" />
          <Tab label="Help" />
          {this.state.progressBar.value>0&&<ProgressBar classes={this.props.classes} data={this.state.progressBar}/>}
        </Tabs>

        {this.state.tab === 0 && <div>
          {this.state.progressBar.value===0 && <Typography  variant="h6" gutterBottom >
            Open File to Continue
          </Typography>}
          {(this.state.progressBar.value>0 || this.state.resMsg.length>0) && <div className={this.props.classes.inputResult}>

            <div className={this.props.classes.inputResultLabel}>Import Result</div>
            
            {this.state.progressBar.value===100&&this.state.loadedCnt>0&&<div  className={this.props.classes.doneMessage} ><Typography variant="h6" gutterBottom>
              Import devices completed
            </Typography></div>}
            {this.state.resMsg.length>0&&this.state.progressBar.value===100&&this.state.loadedCnt===0&&<div  className={this.props.classes.doneMessage} ><Typography variant="h6" gutterBottom>
              Import failed
            </Typography></div>}
            {this.state.resMsg.length>0&&<div className={this.props.classes.inputResultLog}>
              <ul>
                {this.state.resMsg.map((item) => {
                      return <li>{item}</li>
                    }
                )}
              </ul>
              <div className={this.props.classes.resultButtonContainer}>
                <Button variant="outlined" onClick={this.resetFileImport} className={this.props.classes.resetButton} >
                  Reset
                </Button>
                {this.state.validData!==null && !this.state.loading && this.state.validData.length>0 && <Button variant="outlined" onClick={this.loadValid} className={this.props.classes.resetButton}>
                  Load Valid
                </Button>}
              </div>

            </div>}

          </div> }

        </div>}

        {this.state.tab === 1 && <div className={this.props.classes.manualContainer}>


          <div>
            <Typography variant="h6" gutterBottom >
              OTAA device csv
            </Typography>
            <h3>A csv file must be created with the following values:</h3>
            <ol type="A">
              <li>CMD<span className={this.props.classes.requiredSpan}> * </span><span>CMD = CREATE_OTAA</span></li>
              <li>DevEUI<span className={this.props.classes.requiredSpan}> *</span></li>
              <li>DevAddr<span className={this.props.classes.requiredSpan}> *</span></li>
              <li>Device Profile ID<span className={this.props.classes.requiredSpan}> *</span></li>
              <li>LoRaWAN JoinEUI/AppEUI</li>
              <li>LoRaWAN AppKey<span className={this.props.classes.requiredSpan}> *</span></li>
              <li>Service Profile ID<span className={this.props.classes.requiredSpan}> *</span></li>
              <li>AS Routing Profile ID<span className={this.props.classes.requiredSpan}> *</span></li>
              <li>Device Name<span className={this.props.classes.requiredSpan}> *</span></li>
              <li>Admin LAT</li>
              <li>Admin LON</li>
              <li>Admin info</li>
              <li>Disable frame counter validation</li>
            </ol>
          </div>

          <div className={this.props.classes.manualAbp}>
            <Typography variant="h6" gutterBottom >
              ABP device csv
            </Typography>
            <h3>A csv file must be created with the following values:</h3>
            <ol type="A">
                <li>CMD<span className={this.props.classes.requiredSpan}> * </span><span>CMD = CREATE_ABP</span></li>
                <li>DevEUI<span className={this.props.classes.requiredSpan}> *</span></li>
                <li>DevAddr<span className={this.props.classes.requiredSpan}> *</span></li>
                <li>Device Profile ID<span className={this.props.classes.requiredSpan}> *</span></li>
                <li>AppSKeys<span className={this.props.classes.requiredSpan}> *</span></li>
                <li>NwkSEncKey<span className={this.props.classes.requiredSpan}> *</span></li>
                <li>FNwkSIntKey</li>
                <li>SNwkSIntKey</li>
                <li>FCntUp</li>
                <li>NFCntDown</li>
                <li>AFCntDown</li>
                <li>Service Profile ID<span className={this.props.classes.requiredSpan}> *</span></li>
                <li>AS Routing Profile ID<span className={this.props.classes.requiredSpan}> *</span></li>
                <li>Device Name<span className={this.props.classes.requiredSpan}> *</span></li>
                <li>Admin LAT</li>
                <li>Admin LON</li>
                <li>Admin info</li>
                <li>Disable frame counter validation</li>
            </ol>
          </div>
          <div className={this.props.classes.manualAbp}>
            <Typography variant="h6" gutterBottom >
              Delete device
            </Typography>
            <h3>A csv file must be created with the following values:</h3>
            <ol type="A">
                <li>CMD<span className={this.props.classes.requiredSpan}> * </span><span>CMD = DELETE</span></li>
                <li>DevEUI<span className={this.props.classes.requiredSpan}> *</span></li>
            </ol>
          </div>
        </div>}
        {this.state.tab === 1 && <div className={this.props.classes.requiredSpan}>* This field is required</div>}

      </Form>
    );
  }
}

export default withStyles(styles)(ImportForm);

import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { CardContent } from "@material-ui/core";

import UserStore from "../../stores/UserStore";
import UserForm from "./UserForm";
import moment from 'moment'

class UpdateUser extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(user) {

    if(user.sessionTTL===undefined || user.sessionTTL===''|| user.sessionTTL===null || isNaN(user.sessionTTL)){
      user.sessionTTL = 0
    }
    if (user && user.email && user.email.length) {
      user.email = user.email.toLowerCase();
    }
    if(user.phone){
      user.phone = user.phone.toString()
    }
    user.sessionTTL = user.sessionTTL * 60  
    UserStore.update(user, resp => {
      this.props.history.push("/users");
    });
  }
  render() {
    let dates = {}

    if (this.props.user.createdAt){
      dates.createdAt = moment(this.props.user.createdAt,moment.ISO_8601).format('DD-MM-YYYY HH:mm:ss')
     
    }
    if(this.props.user.user.loginAt && this.props.user.user.loginAt!=="0001-01-01T00:00:00Z"){
      dates.loginAt = moment(this.props.user.user.loginAt).format('DD-MM-YYYY HH:mm:ss')
    }else{
      dates.loginAt = 'Never'
    }

    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <UserForm
                submitLabel="Update user"
                dates={dates}
                object={this.props.user.user}
                onSubmit={this.onSubmit}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(UpdateUser);

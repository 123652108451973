import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import TextField from '@material-ui/core/TextField';

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import UserStore from "../../stores/UserStore";
import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import SessionStore from "../../stores/SessionStore";
import generator from 'password-generator'
import {CopyHelper} from '../../classes/Helper'
import { IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import Refresh from "mdi-material-ui/Refresh";
import ClipboardTextMultipleOutline from "mdi-material-ui/ClipboardTextMultipleOutline";
import OrganizationBreadcrumps from "../../components/OrganizationBreadcrumps";
import VisibilityIcon from '@material-ui/icons/Visibility';


let length = 13;

const isStrongEnough = (password) => {

  return (password.length >= length) //  длина 13
  && (/[A-Z].*[A-Z]/.test(password)) //2 больших символа
  &&  (/[a-z].*[a-z]/.test(password)) // 2 маленьких символа
&& (/[-_+=#@&^()*/><{}?|`~,.\\]/.test(password)) // 1 special
 
}

class PasswordForm  extends FormComponent {
  constructor(){
    super();
    this.generatePassword = this.generatePassword.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.changeType = this.changeType.bind(this);


    this.state = {
      passOpen:false
    }
  }
  changeType(){
    this.setState({
      passOpen : !this.state.passOpen
    })
  }
  generatePassword () {
  

    const customPassword = () => {
      let password = "";
      while (!isStrongEnough(password)) {
        password = generator(length, false , /[\w\d?-]/);
      }
      return password;
    }
    let pswd = customPassword();
    CopyHelper({
      target:{innerText:pswd}
    });
    this.onChange({
      target: {
        id: 'password',
        value: pswd,
      }
    })
  }

  copyToClipboard() {
    if(!this.state.object.password){
      this.generatePassword()
    }
    CopyHelper({
      target:{innerText: this.state.object.password}
    });
  }


  render() {
    if (this.state.object === undefined) {
      return (<div></div>);
    }
    let passwordError = false
   
    if(this.state.object.password&&this.state.object.password.length>0  ){
      passwordError =  !isStrongEnough(this.state.object.password)
    }
    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={(e)=>{
          if(passwordError){
            e.preventDefault()
            return
          }
          this.onSubmit(e)
        }}
      >
        <TextField
          id="password"
          label="Password"
          type={(this.state.passOpen)?"text":"password"}
          margin="normal"
          error={passwordError}
          helperText={`Must have at least 2 uppercase characters, 2 numbers, 1 special char: ${'-_+=#@&^()*/><{}?|`~,.'} and 13 chars min legnth`}
          value={this.state.object.password || ""}
          onChange={this.onChange}
          required
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <>
              <Tooltip title="Watch password">
                  <IconButton
                    aria-label="Watch password"
                    onClick={this.changeType}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Generate random password.">
                  <IconButton
                    aria-label="Generate random password"
                    onClick={this.generatePassword}
                  >
                    <Refresh />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Copy password to clipboard">
                  <IconButton
                    aria-label="Copy password to clipboard"
                    onClick={this.copyToClipboard}
                  >
                    <ClipboardTextMultipleOutline />
                  </IconButton>
                </Tooltip>
             </>
            </InputAdornment>
          }}
        />
      </Form>
    );
  }
}


class ChangeUserPassword extends Component {
  constructor() {
    super();
    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    UserStore.get(this.props.match.params.userID, resp => {
      this.setState({
        user: resp,
      });
    });
  }

  onSubmit(password) {
    UserStore.updatePassword(this.props.match.params.userID, password.password, resp => {

      if(SessionStore.isAdmin()  ){
        this.props.history.push(`/users/${this.props.match.params.userID}`);
        return
      }else if(this.props.match.params.organizationID && SessionStore.isOrganizationAdmin( SessionStore.getOrganizationID() )){
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/users/${this.props.match.params.userID}`);
        return
      }

      this.props.history.push("/");
      
      
    });
  }

  render() {
    if (this.state.user === undefined) {
      return(<div></div>);
    }

    return(
      <Grid container spacing={4}>
        <TitleBar>
        <OrganizationBreadcrumps org={this.props.org}>
          <TitleBarTitle title={(this.props.match.params.organizationID)?"Organization users":"Users"} to={(this.props.match.params.organizationID)?`/organizations/${this.props.match.params.organizationID}/users/`:`/users/`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title={this.state.user.user.email} to={(this.props.match.params.organizationID)?`/organizations/${this.props.match.params.organizationID}/users/${this.props.match.params.userID}`:`/users/${this.props.match.params.userID}`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Change password" />
          </OrganizationBreadcrumps>
        </TitleBar>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <PasswordForm
                submitLabel="Update password"
                onSubmit={this.onSubmit}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ChangeUserPassword);
export {isStrongEnough}

import React from "react";

import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from '@material-ui/core/TextField';
import FormHelperText from "@material-ui/core/FormHelperText";

import FormComponent from  '../../../../classes/FormComponent'
import Form from "../../../../components/Form";
import AutocompleteSelect from "../../../../components/AutocompleteSelect";
 

class MQTTIntegrationForm extends FormComponent {
  constructor(){
    super()
    this.onChangePass = this.onChangePass.bind(this)
  }
  getMarshalerOptions = (search, callbackFunc) => {
    const marshalerOptions = [
      {value: "JSON_SHRINKED", label: "JSON (general)"},
      {value: "JSON", label: "JSON (legacy)"},
      {value: "ACTILITY_JSON", label: "JSON Actility-Style (legacy)"},
      {value: "PROTOBUF", label: "Protocol Buffers"},
    ];

    callbackFunc(marshalerOptions);
  }
  onChangePass(e){
    let event = {
      target:{
        id:e.target.id,
        value:e.target.value,
        type:"text"
      }
    }
    if(!this.state.newPassClicked){
      this.setState({newPassClicked:true},()=>{
        this.onChange(event);
      })
    }else{
      this.onChange(event);
    }
  
  }
  render() {
    if (this.state.object === undefined) {
      return null;
    }

    return(
      <Form submitLabel={this.props.submitLabel} autoComplete="off" onSubmit={this.onSubmit}>
        <FormControl fullWidth margin="normal">
          <FormLabel required>Payload marshaler</FormLabel>
          <AutocompleteSelect
            id="marshaler"
            label="Select payload marshaler"
            value={this.state.object.marshaler || ""}
            onChange={this.onChange}
            getOptions={this.getMarshalerOptions}
            required
          />
          <FormHelperText>This defines how the payload will be encoded.</FormHelperText>
        </FormControl>
      
        <TextField
        
          id="username"
          label="Username"
          value={this.state.object.username || ""}
          onChange={this.onChange}
          margin="normal"
          fullWidth
          required
        />
        <TextField
         type={(this.state.newPassClicked || this.state.object.password)?"password":"text"}
          id="password"
          label="Password"
          value={this.state.object.password || ""}
          onChange={this.onChangePass}
          margin="normal"
          autoComplete="off"
          fullWidth
          required
        />
          <TextField
        
        id="clientId"
        label="Client ID"
        value={this.state.object.clientId || ""}
        onChange={this.onChange}
        margin="normal"
        fullWidth
        
      />
        <TextField
          id="server"
          label="Server"
          placeholder="tcp://localhost:1883"
          value={this.state.object.server || ""}
          onChange={this.onChange}
          margin="normal"
          fullWidth
          required
        />
      </Form>
    );
  }
}


export default MQTTIntegrationForm;
